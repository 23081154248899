import { Circle, Icon, IconProps, SquareProps } from '@chakra-ui/react'
import { Icon as TablerIcon } from '@tabler/icons-react'
import React from 'react'

interface Props extends SquareProps {
  colorScheme?: string
  icon: TablerIcon | React.ElementType
  iconSize?: IconProps['boxSize']
}

export default function CircleIcon({ colorScheme = 'gray', icon, iconSize, ...props }: Props) {
  return (
    <Circle
      flex="none"
      bg={`${colorScheme}.50`}
      color={`${colorScheme}.500`}
      padding={props.size ? undefined : 1}
      {...props}
    >
      <Icon as={icon} boxSize={iconSize} />
    </Circle>
  )
}
