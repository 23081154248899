import {
  Box,
  BoxProps,
  Button,
  Divider,
  Flex,
  Icon,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { IconSettings, IconSparkles, IconUserBolt } from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import createPersistedState from 'use-persisted-state'
import { concurrentGET } from '../../../../lib/api'
import router from '../../../../lib/router'
import { Account } from '../../../../types/Account'
import { Apps } from '../../../../types/App'
import { Company, ProfileRecord } from '../../../../types/Profile'
import { usePersonas } from '../../../data/use-personas'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { projectPath } from '../../../ui/ProjectsContext'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { useCurrentUser } from '../../../ui/UserContext'
import { useSearchParams } from '../../../ui/useSearchState'
import { humanize } from '../../accounts/facets/filter-cloud'
import { ProspectorHeader } from '../../prospector/components/ProspectorHeader'
import { AIPersonaTab } from '../../prospects/ai-persona-tab'
import { AIProspecting } from '../../prospects/ai-prospecting'
import { FilterPersonaTab } from '../../prospects/filter-persona-tab'
import { Persona } from '../../prospects/personas'
import { AutoProspectDataSources, AutoProspectingSetting, AutoProspectPersona } from '../../prospects/settings'

interface Props {
  profile?: ProfileRecord
  account?: Account
  company: Company
  headerProps?: BoxProps
  apps: Apps
}

export interface PersonaTabsProps {
  account: Account
  apps: Apps
  domain: string
  persona: Persona
  onProspect: (persona: Persona) => void
  onSearch: (persona: Persona) => void
  onChange: (persona: Persona) => void
}

// TODO: use actual filters here
const filterAsPrompt = (persona: Persona) => {
  const lines: string[] = [
    `# Persona\n Look for *${persona.name}* people.`,

    // titles
    (persona.filters?.title_keywords?.values?.length ?? 0) > 0 &&
      ` ## Titles\n Their job title can be: \n${persona.filters?.title_keywords?.values?.join(', ')}.`,

    // seniority
    (persona.filters?.job_title_levels?.values?.length ?? 0) > 0 &&
      `## Seniority\n With the following Seniority levels: \n ${persona.filters?.job_title_levels?.values
        ?.map((level) => `- ${humanize(level, false)}`)
        .join('\n')}.\n(Rank by most senior first)`,

    // roles
    (persona.filters?.job_title_role?.values?.length ?? 0) > 0 &&
      `## Departments \n In the following departments: \n ${persona.filters?.job_title_role?.values
        ?.map((role) => `- ${humanize(role)}`)
        .join('\n')}.`,

    // countries
    (persona.filters?.location_country?.values?.length ?? 0) > 0 &&
      `## Geo\n Look for folks in: \n${
        persona.filters?.location_country?.values?.map((c) => `- ${humanize(c)}`).join(', ') ?? 'any country'
      }.`,

    // not keywords
    (persona.filters?.not_keywords?.values?.length ?? 0) > 0 &&
      `## Things to avoid\n Avoid people with these job titles:\n ${persona.filters?.not_keywords?.values?.join(', ')}.`
  ].filter(Boolean) as string[]

  return lines.join('\n\n')
}

export function AutoProspectCard(props: Props) {
  const personaData = usePersonas()

  const personas = useMemo(() => {
    if (personaData.isLoading) {
      return []
    }
    return personaData.data?.personas ?? []
  }, [personaData.data, personaData.isLoading])

  const user = useCurrentUser()
  const isAdmin = user.isInternalUser
  const [selectedPersona, setSelectedPersona] = useState<Persona | null>(null)

  const { searchParams, setSearchParam } = useSearchParams()

  // Create a mapping of tab IDs to their respective indexes
  const tabMapping = useMemo(() => {
    const mapping: { [key: string]: number } = {}
    let index = 0

    if (props.profile) {
      mapping['suggestions'] = index++
    }

    personas.forEach((persona) => {
      mapping[persona.id || 'new-persona'] = index++
    })

    mapping['ai'] = index++
    if (isAdmin) {
      mapping['search'] = index++
    }
    mapping['settings'] = index++

    return mapping
  }, [props.profile, personas, isAdmin])

  // Function to get the current tab ID from URL
  const getCurrentTabId = useCallback(() => {
    return (searchParams.personaTab ?? (props.profile ? 'suggestions' : (personas[0]?.id ?? 'ai'))) as unknown as string
  }, [searchParams, props.profile, personas])

  // Function to get the index from tab ID
  const getTabIndex = useCallback(
    (tabId: string) => {
      return tabMapping[tabId] ?? 0
    },
    [tabMapping]
  )

  const [tabIndex, setTabIndex] = useState(getTabIndex(getCurrentTabId()))

  useEffect(() => {
    const currentTabId = getCurrentTabId()
    setTabIndex(getTabIndex(currentTabId))
  }, [getCurrentTabId, getTabIndex])

  const handleTabChange = (index: number) => {
    const newTabId = Object.keys(tabMapping).find((key) => tabMapping[key] === index) ?? 'ai'
    setTabIndex(index)
    setSearchParam('personaTab', newTabId)
  }

  const onProspect = useCallback(
    (persona: Persona) => {
      setSelectedPersona(persona)
      setSearchParam('personaTab', 'ai')
    },
    [setSearchParam]
  )

  const onSearch = useCallback(
    (persona: Persona) => {
      router.visit(projectPath(`/prospector?domain=${props.company.domain}&persona=${persona.id}`))
    },
    [props.company]
  )

  if (personaData.isLoading) {
    return (
      <Stack p="4" w="100%">
        <Spinner size="sm" />
      </Stack>
    )
  }

  if (personaData && personas.length === 0) {
    return (
      <Stack spacing="8" pt="4" w="100%">
        <ProspectorHeader company={props.company} />
        <ProspectSettingsForm />
      </Stack>
    )
  }

  return (
    <Stack p="0" w="100%" spacing="0">
      <Tabs variant={'line'} size="sm" fontSize="sm" isLazy index={tabIndex} onChange={handleTabChange}>
        <Box paddingBottom="1px" overflowX="auto">
          <TabList w="100%">
            {personas.map((persona) => (
              <Tab key={persona.id} fontSize="sm">
                <Flex flex="none" gap={1.5} alignItems="center" isTruncated>
                  <Icon as={IconUserBolt} flex="none" boxSize={4} color="purple.500" />
                  <TextEllipsis maxW="160px" tooltip>
                    {persona.name}
                  </TextEllipsis>
                </Flex>
              </Tab>
            ))}
            <Tab fontSize="sm">
              <Flex flex="none" gap={1.5} alignItems="center" isTruncated>
                <Icon as={IconSparkles} flex="none" boxSize={4} color="purple.500" />
                <Text isTruncated>AI Prospecting</Text>
              </Flex>
            </Tab>
            <Box flex="1"></Box>
            <Tab fontSize="sm">
              <Tooltip label="Settings">
                <Icon as={IconSettings} size="18" color="purple.500" />
              </Tooltip>
            </Tab>
          </TabList>
        </Box>
        <TabPanels>
          {personas.map((persona) => (
            <TabPanel key={persona.id} p="0" pt="2" pb="4">
              {persona.persona_type === 'ai' ? (
                <AIPersonaTab
                  account={props.account!}
                  domain={props.company.domain!}
                  persona={persona}
                  apps={props.apps}
                  onProspect={onProspect}
                  onSearch={onSearch}
                  onChange={(persona) => {
                    setTabIndex(
                      personas.findIndex((p) => {
                        return p.id === persona.id
                      })
                    )

                    personaData.reload()
                  }}
                />
              ) : (
                <FilterPersonaTab
                  account={props.account!}
                  domain={props.company.domain!}
                  persona={persona}
                  onProspect={onProspect}
                  onSearch={onSearch}
                  apps={props.apps}
                  onChange={(_persona) => {
                    setTabIndex(
                      personas.findIndex((p) => {
                        return p.id === persona.id
                      })
                    )

                    personaData.reload()
                  }}
                />
              )}
            </TabPanel>
          ))}

          <TabPanel>
            <AIProspecting
              account={props.account!}
              company={props.company}
              apps={props.apps}
              initialPrompt={
                selectedPersona?.persona_type === 'filter'
                  ? filterAsPrompt(selectedPersona)
                  : selectedPersona?.description
              }
            />
          </TabPanel>

          <TabPanel>
            <ProspectSettingsForm
              onPersonasChanged={() => {
                personaData.reload()
              }}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

function ProspectSettingsForm({ onPersonasChanged }: { onPersonasChanged?: (personas: Persona[]) => void }) {
  const [setting, setSetting] = useState<AutoProspectingSetting>({ data_sources: [], persona_prompt: '' })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    concurrentGET<{ setting: AutoProspectingSetting }>(projectPath(`/settings/prospects.json`))
      .then((res) => {
        setSetting(res.setting)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  if (loading) {
    return (
      <Stack p="4" w="100%">
        <Spinner size="sm" />
        <Text fontSize={'sm'} color="gray.500">
          Loading settings...
        </Text>
      </Stack>
    )
  }

  return (
    <Stack p="2">
      <form
        action={projectPath(`/settings/prospects?redirect_to=${window.location.toString()}?tab=prospects`)}
        method="post"
      >
        <AuthenticityToken />
        <Stack spacing="8" w="100%">
          <Stack w="100%" spacing="12">
            <Stack spacing="8" w="100%" alignItems={'baseline'}>
              <AutoProspectPersona onChange={onPersonasChanged} />
              <Divider />
              <AutoProspectDataSources setting={setting} onChange={(setting) => setSetting(setting)} />
            </Stack>

            <Divider />

            <Button type="submit" colorScheme="purple" w="100%">
              Save Settings
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  )
}

export const useEmailNotFound = createPersistedState<Record<string, boolean>>('email_not_found')
