import React from 'react'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { TopBarContent } from '../../ui/TopBarContext'
import { projectPath } from '../../ui/ProjectsContext'
import { Breadcrumb } from '../../ui/Breadcrumb'
import {
  Box,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { IconCirclePlus, IconCopy, IconDotsVertical, IconEdit, IconTrash } from '@tabler/icons-react'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import SquareIcon from '../../ui/SquareIcon'
import { ListKindSelectionProps, ListKindSelection } from '../account_views/new'
import { EditTemplateModal } from './components/EditTemplateModal'

interface MissionControlTemplatesShowProps {
  template: any
}

export default function MissionControlTemplatesShow(props: MissionControlTemplatesShowProps) {
  const deletion = useDisclosure()
  const newListModal = useDisclosure()
  const editTemplateModal = useDisclosure()

  return (
    <PageLayout size="full" flush gap={0}>
      <PageTitle skipRendering>{props.template.name} | Templates</PageTitle>

      <TopBarContent>
        <Flex width="100%" alignItems="center" justifyContent="space-between" gap={3}>
          <Breadcrumb
            paths={[
              {
                path: projectPath('/mission-control/setup?tab=templates'),
                title: 'Templates'
              },
              {
                path: projectPath('/mission-control/templates/' + props.template.id),
                title: props.template.name
              }
            ]}
          />

          <Menu>
            <MenuButton
              size="xs"
              as={IconButton}
              icon={<IconDotsVertical size={15} />}
              variant="ghost"
              borderColor="gray.200"
            />
            <MenuList fontSize="sm">
              <MenuItem icon={<IconEdit size={16} />} onClick={editTemplateModal.onOpen}>
                Edit name
              </MenuItem>
              <MenuItem
                icon={<IconCopy size={16} />}
                as="a"
                href={projectPath(`/mission-control/templates/${props.template.id}/clone`)}
              >
                Clone
              </MenuItem>
              <MenuItem icon={<IconTrash size={16} />} color="red.500" onClick={deletion.onOpen}>
                Delete…
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </TopBarContent>

      {props.template.template_views.length === 0 ? (
        <Stack spacing={6} alignItems="center" padding={10} maxW="800px" marginX="auto">
          <Stack w="100%" spacing={3}>
            <Box
              border="1px solid"
              borderColor="gray.200"
              cursor="pointer"
              rounded="lg"
              bg="white"
              padding={4}
              transition="border-color 150ms ease-in-out"
              _hover={{ borderColor: 'gray.300', shadow: 'sm' }}
              tabIndex={0}
              onClick={newListModal.onOpen}
            >
              <Flex alignItems="center" gap={3}>
                <SquareIcon icon={IconCirclePlus} colorScheme="purple" padding={2.5} />
                <Box>
                  <Heading size="sm">Create a list</Heading>
                  <Text fontSize="sm" color="gray.500">
                    Add a list to your template. Tip: use the "Current user" filter to define account owners.
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Stack>
        </Stack>
      ) : null}

      <DeleteConfirmation
        isOpen={deletion.isOpen}
        onClose={deletion.onClose}
        title={`Are you sure you want to delete this template?`}
        confirmLabel="Delete template"
        deletePath={projectPath(`/mission-control/templates/${props.template.id}`)}
      >
        <Text fontSize="sm" color="gray.600">
          Your teammates will no longer be able to apply this template going forward.
        </Text>
      </DeleteConfirmation>

      <NewListModal {...newListModal} template={props.template} />
      <EditTemplateModal {...editTemplateModal} template={props.template} />
    </PageLayout>
  )
}

interface NewListModalProps extends UseDisclosureProps, ListKindSelectionProps {
  template: any
}

export function NewListModal({ kind, template, ...props }: NewListModalProps) {
  const disclosure = useDisclosure(props)

  return (
    <Modal {...disclosure} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <ListKindSelection
            kind={kind}
            isTemplate
            newPath={projectPath(`/mission-control/templates/${template.id}/views/new`)}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
