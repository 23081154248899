import { Box, Skeleton, Text, TextProps } from '@chakra-ui/react'
import React from 'react'
import { Copyable } from './Copyable'

interface Props extends TextProps {
  copyable?: boolean
  label: React.ReactNode
  fallback?: string
  skeleton?: boolean
}

export function StackedField({
  children,
  copyable = true,
  label,
  fallback = '–',
  skeleton,
  ...rest
}: React.PropsWithChildren<Props>) {
  const hasValue = typeof children !== 'undefined' && children !== null && children !== ''

  return (
    <Box role="group">
      <Text fontSize="xs" color="gray.500">
        {label}
      </Text>
      {skeleton ? (
        <Skeleton animation="none" height="18px" />
      ) : (
        <Copyable
          copyEnabled={copyable && hasValue}
          fontSize="sm"
          fontWeight={hasValue ? 'medium' : 'normal'}
          color={hasValue ? 'gray.700' : 'gray.400'}
          {...rest}
        >
          {hasValue ? children : fallback}
        </Copyable>
      )}
    </Box>
  )
}
