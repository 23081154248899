import { Box, Flex, IconButton, Text } from '@chakra-ui/react'
import {
  IconAt,
  IconBrandLinkedin,
  IconBriefcase,
  IconBuilding,
  IconCrosshair,
  IconLayoutColumns
} from '@tabler/icons-react'
import React, { useMemo } from 'react'
import { Apps } from '../../../types/App'
import { Crm } from '../../../types/Crm'
import { PageMeta } from '../../../types/PageMeta'
import { StaticList } from '../../../types/StaticList'
import { ColumnInfo, ColumnSelectionDropdown, defaultAccountColumns, useColumns } from '../../ui/ColumnSelector'
import EmptyState from '../../ui/EmptyState'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { TableFooter } from '../../ui/TableFooter'
import { TopBarContent } from '../../ui/TopBarContext'
import { AccountList } from '../icps/icp/account-list'
import { mergeParams } from '../icps/types'
import { ImportType } from '../imports/components/ImportType'
import { ProfileList } from '../profiles/components/profile-list'

interface Props {
  list: StaticList
  records: any[]
  crm?: Crm
  apps: Apps
  columns: string[]
  page_meta: PageMeta
}

const defaultProfileColumns: ColumnInfo[] = [
  {
    id: 'Title',
    label: 'Title',
    key: 'title',
    icon: IconBriefcase
  },
  {
    id: 'Email',
    label: 'Email',
    key: 'email',
    icon: IconAt
  },
  {
    id: 'LinkedIn',
    label: 'LinkedIn',
    key: 'linkedin_url',
    icon: IconBrandLinkedin
  },
  {
    id: 'Company',
    label: 'Company',
    key: 'company',
    icon: IconBuilding
  }
]

export default function Show(props: Props) {
  const recordType = props.list.kind === 'account' ? 'account' : 'contact'
  const apps = useMemo(() => Object.values(props.apps), [props.apps])

  const defaultColumns = props.list.kind === 'account' ? defaultAccountColumns : defaultProfileColumns

  const { columns, loadingColumns, onColumnChange, onColumnRemove } = useColumns({
    data: props.records,
    columns: props.columns?.length ? props.columns : defaultColumns.map((c) => c.key),
    initialColumns: props.columns?.length ? props.columns : defaultColumns.map((c) => c.key)
  })

  return (
    <PageLayout flush>
      <PageTitle skipRendering>{props.list.name}</PageTitle>

      <TopBarContent>
        <Flex width="100%" alignItems="center" justifyContent="space-between" gap={2}>
          <Flex alignItems="center" gap={2}>
            <Text fontSize="15px" fontWeight="semibold">
              {props.list.name}
            </Text>
            <ImportType kind={props.list.kind === 'account' ? 'accounts' : 'contacts'} />
          </Flex>

          <ColumnSelectionDropdown
            audienceKind={props.list.kind}
            apps={apps}
            selectedColumns={columns}
            onChange={onColumnChange}
          >
            <IconButton
              aria-label="Edit columns"
              variant="outline"
              size="sm"
              flex="none"
              icon={<IconLayoutColumns size={18} />}
            />
          </ColumnSelectionDropdown>
        </Flex>
      </TopBarContent>

      {props.records.length === 0 ? (
        <EmptyState
          size="md"
          heading={`No ${recordType}s yet`}
          description={`Add a ${recordType} to this list or import a CSV`}
          icon={IconCrosshair}
        />
      ) : (
        <Box marginTop="-1px">
          {props.list.kind === 'profile' ? (
            <ProfileList
              profiles={props.records}
              // facetParams={facets}
              range="any"
              apps={apps}
              crm={props.crm}
              canAddColumns
              columns={columns}
              loadingColumns={loadingColumns}
              onColumnChange={onColumnChange}
              onColumnRemove={onColumnRemove}
              // sortingBy={facets.sortBy}
              // onSortChange={facets.setSortBy}
            />
          ) : props.list.kind === 'account' ? (
            <AccountList
              accounts={props.records}
              // facetParams={facets}
              range="any"
              apps={apps}
              crm={props.crm}
              canAddColumns
              columns={columns}
              loadingColumns={loadingColumns}
              onColumnChange={onColumnChange}
              onColumnRemove={onColumnRemove}
              // sortingBy={facets.sortBy}
              // onSortChange={facets.setSortBy}
              // onRefresh={() => {
              //   router.visit(location.toString(), {
              //     fetch: true
              //   })
              // }}
            />
          ) : null}
          <TableFooter
            word={recordType}
            pageMeta={props.page_meta}
            page={props.page_meta.current_page}
            prevPath={mergeParams(window.location.toString(), {
              page: props.page_meta.prev_page?.toString() ?? '1'
            })}
            nextPath={mergeParams(window.location.toString(), {
              page: props.page_meta.next_page?.toString() ?? '1'
            })}
            sticky
            px={3}
          />
        </Box>
      )}
    </PageLayout>
  )
}
