import type { App } from '../../../../types/App'
import React from 'react';
import type { Account } from '../../../../types/Account'
import { useUIState } from './DetailsV2/useUIState'
import {
  HStack,
  Stack,
  Text,
  Grid,
  Spinner,
  useDisclosure,
  Button,
  Icon,
} from '@chakra-ui/react'

import { StackedField } from '../../../ui/StackedField'
import { DetailsCard } from '../../../ui/Card'
import { CardHeading } from '../../../ui/CardHeading'
import { TimeAgo } from '../../../ui/TimeAgo'
import { useAccountEvents } from '@app/components/data/use-account-events'
import { IconArrowRight } from '@tabler/icons-react'
import { projectPath } from '../../../ui/ProjectsContext'

interface G2CardProps {
  app?: App
  account: Account
  initiallyCollapsed?: boolean
}

export function G2Card(props: G2CardProps) {
  const [ui, setUI] = useUIState()

  const { isLoading, data } = useAccountEvents({
    accountId: props.account.domain,
    source: 'g2',
    event_type: 'intent_activity',
    page_size: 1,
    page: 1,
    order: 'desc'
  })

  const cardDisclosure = useDisclosure({
    defaultIsOpen: (ui.open?.g2 || !props.initiallyCollapsed) && Boolean(props.app),
    onOpen: () => {
      setUI({ open: { g2: true } })
    },
    onClose: () => {
      setUI({ open: { g2: false } })
    }
  })

  const g2Data = data?.data[0]?.properties

  return (
    <DetailsCard>
      <CardHeading icon={'https://asset.brandfetch.io/idN7c_7lEQ/idMiolluN4.png'} disclosure={cardDisclosure}>
        <HStack justifyContent="space-between">
          <Text>G2</Text>
          {isLoading && <Spinner size='xs' />}
        </HStack>
      </CardHeading>

      {cardDisclosure.isOpen && (
        <>
          {Boolean(props.app) ? (
            <Stack spacing={4}>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <StackedField label="Buying stage">{g2Data?.buying_stage}</StackedField>
                <StackedField label="Activity level">{g2Data?.activity_level}</StackedField>
                <StackedField label="Intent Score">{g2Data?.intent_score}</StackedField>
                <StackedField label="Visitor Count">{g2Data?.visitor_count}</StackedField>
                <StackedField label="Last seen"><TimeAgo time={g2Data?.last_seen_at} /></StackedField>
                <StackedField label="Page Vews">{g2Data?.total_pageviews}</StackedField>
              </Grid>
            </Stack>
          ) : (
            <>
              <Text fontSize="xs" color="gray.600">
                Collect and analyze data from G2 with Koala.
              </Text>
              <Button
                alignSelf="flex-start"
                size="sm"
                variant="outline"
                as="a"
                href={projectPath(`/apps/g2?return_to=${encodeURIComponent(window.location.pathname)}`)}
                iconSpacing={1}
                rightIcon={<Icon as={IconArrowRight} boxSize={4} />}
              >
                Connect
              </Button>
            </>
          )}
        </>
      )}
    </DetailsCard>
  );
}
