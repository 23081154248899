import UIEmptyState from '@app/components/ui/EmptyState'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Table,
  TableCellProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useOutsideClick
} from '@chakra-ui/react'
import {
  IconCopy,
  IconDots,
  IconEdit,
  IconFolderPlus,
  IconList,
  IconLock,
  IconPlus,
  IconTrash,
  IconUsers
} from '@tabler/icons-react'
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'
import Router from '../../../lib/router'
import { AccountView } from '../../../types/AccountView'
import { PageMeta } from '../../../types/PageMeta'
import { StaticList } from '../../../types/StaticList'
import { BuildingIcon } from '../../ui/icons'
import { SearchIcon } from '../../ui/icons/SearchIcon'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SquareIcon from '../../ui/SquareIcon'
import { TableFooter } from '../../ui/TableFooter'
import { TimeAgo } from '../../ui/TimeAgo'
import useLatestRef from '../../ui/useLatestRef'
import { useCurrentUser } from '../../ui/UserContext'
import useUpdateEffect from '../../ui/useUpdateEffect'
import { useTrackRecentNavItems } from '../navigation/useTrackRecentNavItems'
import CloneViewModal from './components/CloneViewModal'
import DeleteViewConfirmationModal from './components/DeleteViewConfirmationModal'
import { accountViewPath } from './lib/list-paths'

type CombinedList = (AccountView | StaticList) & {
  class_name?: string
  permissions?: any
}

function isAccountView(view: CombinedList): view is AccountView {
  return view.class_name === 'AccountView'
}

function isStaticList(view: CombinedList): view is StaticList {
  return view.class_name === 'StaticList'
}

export interface AccountViewProps {
  account_views: CombinedList[]
  page_meta: PageMeta
}

function OverflowMenu({ view }: { view: AccountView }) {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [modalToShow, setModalToShow] = React.useState<'delete' | 'clone' | null>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const currentUser = useCurrentUser()
  const { trackRecentNavItem } = useTrackRecentNavItems()

  const onClick = useCallback((e) => {
    e.stopPropagation()
    setIsOpen(true)
  }, [])

  const handleEdit = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (e) {
        e.stopPropagation()
      }

      trackRecentNavItem(`accountView:${view.id}`)
      Router.visit(accountViewPath(view))
    },
    [view, trackRecentNavItem]
  )

  const handleCloneClick = useCallback((e: MouseEvent<HTMLElement>) => {
    if (e) {
      e.stopPropagation()
    }
    setModalToShow('clone')
    setIsOpen(false)
  }, [])

  const handleDeleteClick = useCallback((e: MouseEvent<HTMLElement>) => {
    if (e) {
      e.stopPropagation()
    }
    setModalToShow('delete')
    setIsOpen(false)
  }, [])

  const onDialogClose = useCallback(() => {
    setModalToShow(null)
  }, [])

  useOutsideClick({
    ref: menuRef,
    enabled: isOpen,
    handler: () => setIsOpen(false)
  })

  const canEditView = view.permissions?.can_edit || currentUser.id === view.created_by
  const canDestroyView = view.permissions?.can_destroy || currentUser.id === view.created_by

  return (
    <>
      <Menu isOpen={isOpen}>
        <MenuButton as={IconButton} size="sm" icon={<IconDots size={16} />} variant="ghost" onClick={onClick} />
        <Portal>
          <MenuList ref={menuRef}>
            <MenuItem isDisabled={!canEditView} onClick={handleEdit} display="flex" icon={<IconEdit size={16} />}>
              Edit
            </MenuItem>
            {view.permissions && (
              <MenuItem
                isDisabled={!view.permissions.can_create}
                onClick={handleCloneClick}
                icon={<IconCopy size={16} />}
              >
                Clone
              </MenuItem>
            )}
            <MenuItem
              isDisabled={!canDestroyView}
              color="red.500"
              onClick={handleDeleteClick}
              icon={<IconTrash size={16} />}
            >
              Delete
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
      {modalToShow === 'delete' && <DeleteViewConfirmationModal view={view} onClose={onDialogClose} />}
      {modalToShow === 'clone' && <CloneViewModal view={view} onClose={onDialogClose} />}
    </>
  )
}

function AccountViewTable(props: Omit<AccountViewProps, 'page_meta'> & { compact?: boolean }) {
  const user = useCurrentUser()
  const views = props.account_views

  return (
    <Table width="100%" height="1px" overflow="auto" size="sm">
      {!props.compact && (
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th width="1px">Entries</Th>
            <Th width="1px">Created</Th>
            <Th width="1px">Access</Th>
            <Th />
          </Tr>
        </Thead>
      )}
      <Tbody width="100%" fontSize={'sm'}>
        {views.map((view) => {
          const { name, created_at, id, counts } = view
          const href = isAccountView(view) ? accountViewPath(view) : projectPath(`/lists/${view.id}`)
          const trackPageKey = isAccountView(view) ? `accountView:${view.id}` : `staticList:${view.id}`

          return (
            <Tr
              backgroundColor="white"
              _hover={{ backgroundColor: 'gray.50' }}
              key={id}
              width="100%"
              cursor="pointer"
              h="20"
            >
              <LinkCell href={href} trackPageKey={trackPageKey} minWidth="320px" maxWidth="35%">
                <HStack spacing={3} alignItems="center">
                  <SquareIcon
                    icon={view.kind === 'account' ? BuildingIcon : IconUsers}
                    iconSize={18}
                    p={2}
                    colorScheme={view.kind === 'account' ? 'purple' : 'blue'}
                    rounded="lg"
                  />
                  <Stack spacing={1}>
                    <Text fontWeight={props.compact ? 'normal' : 'medium'}>{name}</Text>
                    {view.created_by_user && (
                      <Text fontSize="xs" color="gray.500">
                        Created by {view.created_by_user.name}
                      </Text>
                    )}
                  </Stack>
                </HStack>
              </LinkCell>

              <Td width="1px" fontSize="sm" css={{ fontVariantNumeric: 'tabular-nums' }}>
                {counts?.all ? <Text>{counts.all.toLocaleString()}</Text> : <Text color="gray.500">Empty</Text>}
              </Td>

              {!props.compact && (
                <Td width="180px">
                  <TimeAgo time={created_at} />
                </Td>
              )}

              {!props.compact && (
                <Td width="1px">
                  {isAccountView(view) ? (
                    <>
                      {view.ownership === 'shared' && <Text color="gray.600">Everyone</Text>}
                      {view.ownership === 'private' && (
                        <HStack spacing={1.5}>
                          <Icon as={IconLock} boxSize={4} color="gray.500" />
                          <Text color="gray.600">{view.created_by === user?.id ? 'Only you' : 'Private'}</Text>
                        </HStack>
                      )}
                      {view.ownership === 'team' && (
                        <HStack spacing={1.5}>
                          <Icon as={IconUsers} boxSize={4} color="gray.500" />
                          <Text color="gray.600">{view.team?.name}</Text>
                        </HStack>
                      )}
                    </>
                  ) : isStaticList(view) ? (
                    <Text color="gray.600">Everyone</Text>
                  ) : null}
                </Td>
              )}

              {!props.compact && (
                <Td width="1px" textAlign="right">
                  {isAccountView(view) && <OverflowMenu view={view} />}
                </Td>
              )}
            </Tr>
          )
        })}
      </Tbody>
    </Table>
  )
}

export default function Index(props: AccountViewProps) {
  const params = useLatestRef(new URLSearchParams(location.search))
  const [views, setViews] = React.useState(props.account_views ?? [])
  const [pageMeta, setPageMeta] = React.useState<PageMeta>(props.page_meta)
  const [search, setSearch] = React.useState(params.current.get('query') ?? '')
  const [page, setPage] = useState(parseInt(params.current.get('page') ?? '1'))
  const searchRef = useRef<HTMLInputElement>(null)

  const [debouncedSearchText] = useDebounce(search, 300)

  useEffect(() => {
    setViews(props.account_views)
    setPageMeta(props.page_meta)
  }, [props.account_views, props.page_meta])

  useUpdateEffect(() => {
    let basePath = `/views`
    if (page) {
      basePath += `?page=${page}`
    }

    if (debouncedSearchText) {
      basePath += `&query=${debouncedSearchText}`
    }

    if (page || debouncedSearchText) {
      Router.visit(projectPath(basePath))
    }
  }, [debouncedSearchText, page])

  return (
    <PageLayout size="full">
      <SettingsBreadCrumb offscreen rootPath={{ path: '/views', title: 'Lists' }} />

      <Flex width="100%" justifyContent="space-between">
        <PageTitle skipRendering>Lists</PageTitle>

        <HStack spacing={3} flex="1">
          <IconList size={20} />
          <Heading size="md">Lists</Heading>
        </HStack>

        <HStack>
          <InputGroup maxWidth={320} size="sm">
            <InputLeftElement pointerEvents="none" color="gray.400">
              <SearchIcon size={16} />
            </InputLeftElement>
            <Input
              bg="white"
              ref={searchRef}
              rounded={'md'}
              size="sm"
              fontSize={'sm'}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              name="filter"
              placeholder="Search list names..."
            />
          </InputGroup>
          <Button
            size="sm"
            flex="none"
            colorScheme="purple"
            as={Link}
            leftIcon={<IconPlus size={14} />}
            href={projectPath('/views/new')}
          >
            Create List
          </Button>
        </HStack>
      </Flex>
      {views.length > 0 ? (
        <Box>
          <AccountViewTable {...props} />
          <TableFooter word="list" pageMeta={pageMeta} page={page || 1} setPage={setPage} sticky />
        </Box>
      ) : (
        <Box width="100%" display="flex" justifyContent={'center'} alignItems={'center'}>
          <UIEmptyState
            icon={IconFolderPlus}
            heading={'No Lists Found'}
            size={'sm'}
            description={`No lists found with the name "${debouncedSearchText}". Try searching with a different term, or go to accounts and create a new list.`}
            ctaText="Create new List..."
            onClick={() => Router.visit(projectPath('/views/new'))}
          />
        </Box>
      )}
    </PageLayout>
  )
}

interface LinkCellProps extends TableCellProps {
  href: string
  trackPageKey?: string
}

function LinkCell({ children, href, trackPageKey, ...props }: React.PropsWithChildren<LinkCellProps>) {
  const { trackRecentNavItem } = useTrackRecentNavItems()

  const ignoreChildLinks = React.useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (event.target === event.currentTarget) {
        if (trackPageKey) trackRecentNavItem(trackPageKey)
        return true
      }

      const targetAnchor = (event.target as HTMLElement).closest('a')
      if (targetAnchor !== event.currentTarget && event.currentTarget.contains(targetAnchor)) {
        event.preventDefault()
      }

      if (event.defaultPrevented) {
        return false
      } else if (trackPageKey) {
        trackRecentNavItem(trackPageKey)
      }
    },
    [trackPageKey, trackRecentNavItem]
  )

  return (
    <Td height="100%" width="1px" padding={0} {...props}>
      <Box
        as="a"
        href={href}
        cursor="pointer"
        display="flex"
        alignItems="center"
        width="100%"
        height="100%"
        textDecoration="none"
        onClick={ignoreChildLinks}
      >
        <Box width="100%" px={[2, 3]} py={2} overflow="hidden" textOverflow="ellipsis">
          {children}
        </Box>
      </Box>
    </Td>
  )
}
