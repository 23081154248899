import {
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Skeleton,
  Stack,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { IconExternalLink, IconSearch, IconUserPlus } from '@tabler/icons-react'
import React, { useEffect, useMemo, useState } from 'react'
import { usePersonas } from '../../../data/use-personas'
import { PartialAccount } from '../../../ui/AccountSelector'
import { projectPath } from '../../../ui/ProjectsContext'
import { Persona, PersonaBuilderModal, PersonaSelector } from '../../prospects/personas'
import { AutoProspectingSetting, ProspectorPreview } from '../../prospects/settings'

export function AutoProspect(props: {
  setting?: AutoProspectingSetting
  onChange: (setting: AutoProspectingSetting) => void
}) {
  const [selectedAccount, setSelectedAccount] = useState<PartialAccount | null>(null)
  const [isPreviewing, setIsPreviewing] = useState(false)
  const [includeIdentified, setIncludeIdentified] = useState(props.setting?.include_identified ?? true)
  const [numProspects, setNumProspects] = useState(props.setting?.num_prospects ?? 3)
  const personas = usePersonas()

  const [selectedPersona, setSelectedPersona] = useState<Persona | null>(
    personas.data?.personas?.find((p) => p.id === props.setting?.persona_id) ?? null
  )

  const personaDisclosure = useDisclosure()

  useEffect(() => {
    if (personas.data && !selectedPersona) {
      setSelectedPersona(personas.data?.personas?.find((p) => p.id === props.setting?.persona_id) ?? null)
    }
  }, [personas.data, selectedPersona, props.setting])

  const hasPersonas = useMemo(() => (personas.data?.personas?.length ?? 0) > 0, [personas.data])

  return (
    <Stack spacing="8">
      <HStack spacing="8" justifyContent="space-between" h="100%" alignItems={'flex-start'}>
        <Stack w="100%" as={FormControl} spacing="0">
          <Heading as={FormLabel} size="xs" fontWeight={'semibold'}>
            {hasPersonas ? 'Select a Persona' : 'Define a Persona'}
          </Heading>
          {!personas.isLoading && (
            <Flex direction={'column'} gap="1">
              {hasPersonas && <PersonaSelector selectedPersona={selectedPersona} onSelect={setSelectedPersona} />}
              {!hasPersonas && (
                <Button
                  size="sm"
                  variant={'outline'}
                  colorScheme={'purple'}
                  leftIcon={<IconUserPlus size="12" />}
                  onClick={() => personaDisclosure.onOpen()}
                >
                  Create a Persona
                </Button>
              )}
            </Flex>
          )}

          {personas.isLoading && (
            <Stack spacing="4">
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          )}

          <HStack w="100%" pt="2">
            <FormHelperText flex="1">
              {hasPersonas && 'Select the persona that you would like to target with your outbound campaigns.'}
              {!hasPersonas && 'Create a persona to target with your outbound campaigns.'}
            </FormHelperText>
            <Flex justifyContent={'flex-end'}>
              {hasPersonas && (
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme="lightPurple"
                  leftIcon={<IconUserPlus size={14} />}
                  onClick={() => personaDisclosure.onOpen()}
                >
                  Add New Persona
                </Button>
              )}
            </Flex>
            {selectedPersona && <input type="hidden" name="auto_prospecting[persona_id]" value={selectedPersona.id} />}
          </HStack>
        </Stack>
      </HStack>

      <Divider />

      <Flex>
        <FormControl>
          <FormLabel fontWeight={'semibold'}>Identified Visitors</FormLabel>
          <Checkbox
            name="auto_prospecting[include_identified]"
            value={includeIdentified ? '1' : '0'}
            isChecked={includeIdentified}
            onChange={(e) => {
              setIncludeIdentified(e.target.checked)
            }}
          >
            Include Identified Visitors
          </Checkbox>
          <FormHelperText lineHeight={'1.5'}>
            Whether Koala should also include visitors who have been identified by their email address.
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel fontWeight={'semibold'}>Enrollment Target</FormLabel>
          <NumberInput
            size="sm"
            bg="white"
            rounded={'md'}
            defaultValue={numProspects}
            value={numProspects}
            onChange={(value) => {
              setNumProspects(parseInt(value))
            }}
            min={1}
            max={10}
            name="auto_prospecting[num_prospects]"
          >
            <NumberInputField rounded="md" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>

          <FormHelperText>How many prospects Koala should attempt to find.</FormHelperText>
        </FormControl>
      </Flex>

      <Divider />
      <Flex gap="4" justifyContent={'flex-end'}>
        <Tooltip
          label="Preview prospects matching your Campaign Persona and settings"
          hasArrow
          placement="left"
          fontSize={'xs'}
          p="2"
        >
          <Button
            marginTop={'auto'}
            size="xs"
            leftIcon={<IconSearch size={10} />}
            variant={'link'}
            onClick={() => {
              setIsPreviewing(true)
            }}
          >
            Preview Prospects
          </Button>
        </Tooltip>

        <Divider orientation="vertical" w="2" h="4" />

        <Tooltip
          label="Manage your workspace level AI prospecting settings"
          aria-label="Manage your AI prospecting settings"
          hasArrow
          placement="right"
          fontSize={'xs'}
          p="2"
        >
          <Button
            variant={'link'}
            as={Link}
            size="xs"
            leftIcon={<IconExternalLink size={10} />}
            href={projectPath('/settings/prospects')}
            isExternal
          >
            Prospecting Settings
          </Button>
        </Tooltip>
      </Flex>

      <Drawer
        isOpen={isPreviewing}
        onClose={() => {
          setIsPreviewing(false)
        }}
        size="xl"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Preview Prospects</DrawerHeader>
          <DrawerBody fontSize={'sm'}>
            <ProspectorPreview
              selectedAccount={selectedAccount}
              setSelectedAccount={setSelectedAccount}
              selectedPersona={selectedPersona ?? undefined}
              autoProspectingSetting={props.setting ?? { data_sources: [], persona_prompt: '' }}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <PersonaBuilderModal
        onSave={(persona) => {
          setSelectedPersona(persona)
          personas.refetch()
        }}
        isOpen={personaDisclosure.isOpen}
        onClose={personaDisclosure.onClose}
      />
    </Stack>
  )
}
