import { Badge, Button, Flex, Heading, HStack, Icon, Image, Link, Stack, Text, Tooltip, Wrap } from '@chakra-ui/react'
import { IconAlertTriangle, IconArrowRight, IconCheck, IconExternalLink, IconLock } from '@tabler/icons-react'
import uniq from 'lodash/uniq'
import React, { useMemo } from 'react'
import { useBillingMetrics } from '../../data/use-billing-metrics'
import { openUpgradeFlow } from '../../ui/billing-banners/accounts-banner'
import { LightBgCard } from '../../ui/Card'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import { useCurrentUser } from '../../ui/UserContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'

interface IntegrationCardProps {
  name: string
  logo: string
  release: 'alpha' | 'beta' | 'GA'
  description: string
  connected?: boolean
  valid?: boolean
  docsUrl?: string
  editUrl?: string
  categories: string[]
  locked?: boolean
  planTier?: string
}

const IntegrationCard = (props: IntegrationCardProps) => {
  return (
    <LightBgCard w="320px" alignSelf="stretch" rounded="lg">
      <Stack spacing="5" h="100%">
        <HStack spacing="2" justifyContent="space-between" alignItems="flex-start">
          <Image src={props.logo} width="auto" height={9} />
          {props.connected && props.valid && (
            <HStack color="green.600" bg="green.50" paddingX={2} paddingY={0.5} rounded="full" spacing={1}>
              <Icon as={IconCheck} boxSize={4} />
              <Text fontSize="xs" fontWeight="semibold">
                Connected
              </Text>
            </HStack>
          )}

          {props.connected && !props.valid && !props.locked && (
            <HStack color="orange.600" bg="orange.50" paddingX={2} paddingY={0.5} rounded="full" spacing={1}>
              <Icon as={IconAlertTriangle} boxSize={4} />
              <Text fontSize="xs" fontWeight="semibold">
                Needs attention
              </Text>
            </HStack>
          )}

          {!props.connected && props?.planTier && (
            <HStack color="purple.600" bg="purple.50" paddingX={2} paddingY={0.5} rounded="full" spacing={1}>
              <Text fontSize="xs" fontWeight="semibold">
                {props.planTier.toUpperCase()}
              </Text>
            </HStack>
          )}
        </HStack>

        <Stack flex="1" spacing={2}>
          <HStack spacing="2">
            <Heading size="sm">{props.name}</Heading>
            <Flex>
              {props.release !== 'GA' && (
                <Badge variant="solid" colorScheme="purple">
                  {props.release}
                </Badge>
              )}
            </Flex>
          </HStack>

          <Text fontSize="sm" color="gray.600">
            {props.description}
          </Text>
        </Stack>

        {props.locked ? (
          <Flex>
            <Tooltip label="Upgrade to unlock multiple CRM connections">
              <Button
                leftIcon={<Icon as={IconLock} boxSize={4} />}
                colorScheme="lightPurple"
                variant="outline"
                size="sm"
                onClick={() => openUpgradeFlow('business')}
              >
                Upgrade to unlock
              </Button>
            </Tooltip>
          </Flex>
        ) : (
          <Flex>
            {props.editUrl ? (
              <Button
                variant="outline"
                size="sm"
                as="a"
                href={props.editUrl}
                iconSpacing={1}
                rightIcon={props.connected ? <Icon as={IconArrowRight} boxSize={4} /> : undefined}
              >
                {props.connected ? 'Manage' : 'Connect'}
              </Button>
            ) : props.docsUrl ? (
              <Button
                variant="outline"
                as={Link}
                href={props.docsUrl}
                isExternal
                rightIcon={<IconExternalLink size={16} />}
              >
                See Documentation
              </Button>
            ) : (
              <Tooltip label="Coming Soon" shouldWrapChildren>
                <Button isDisabled size="sm">
                  Connect
                </Button>
              </Tooltip>
            )}
          </Flex>
        )}
      </Stack>
    </LightBgCard>
  )
}

interface CatalogApp {
  title: string
  description: string
  logo: string
  module: string
  clean_module_name: string
  categories: string[]
  release: 'alpha' | 'beta' | 'GA'
  docs_url: string
  plan_tier?: string
}

interface App {
  id: string
  project_id: string
  app_module: string
  connected?: boolean
  client_valid?: boolean
}

interface AppsProps {
  apps: Record<string, App>
  catalog: CatalogApp[]
}

export default function Index(props: AppsProps) {
  const project = useCurrentProject()
  const connectedApps = Object.keys(props.apps).filter((mod) => props.apps[mod].connected)
  const validApps = Object.keys(props.apps).filter((mod) => props.apps[mod].connected && props.apps[mod].client_valid)
  const crmLimit = useMemo(() => project?.koala_subscription?.entitlements?.crm_limit, [project])
  const metrics = useBillingMetrics()
  const connectedCRMs = useMemo(() => metrics.data?.usage?.crms ?? 0, [metrics.data?.usage?.crms])
  const user = useCurrentUser()

  const apps = React.useMemo(
    () =>
      props.catalog.filter((app) => {
        // Only show Calendly if it's connected already
        if (app.title === 'Calendly') {
          return connectedApps.includes(app.module)
        }
        if (app.clean_module_name == 'salesloft' && !user.isInternalUser) {
          return false
        }

        return true
      }),
    [props.catalog, connectedApps, user]
  )

  const categories = React.useMemo(
    () =>
      uniq(apps.flatMap((app) => app.categories)).sort((a, b) => {
        const matchA = apps.filter((c) => c.categories.includes(a)).length
        const matchB = apps.filter((c) => c.categories.includes(b)).length
        // Pushes Tech Docs "apps" to the end
        if (a == 'Tech Docs') {
          return 1
        }
        if (matchA > matchB) {
          return -1
        }
        return 0
      }),
    [apps]
  )

  return (
    <PageLayout size="full">
      <SettingsBreadCrumb rootPath={{ path: projectPath('/apps'), title: 'Integrations' }} offscreen />

      <SettingsHeader>
        <PageTitle>Integrations</PageTitle>
        <PageDescription>
          Connect integrations to enrich visitor and company data in Koala or to send data from Koala to your favorite
          tools.
        </PageDescription>
      </SettingsHeader>

      <Stack spacing={10}>
        {categories.map((cat) => (
          <Stack key={cat} spacing="4">
            <Heading size="sm">{cat}</Heading>
            <Wrap spacing="6" w="100%" shouldWrapChildren justifyContent={'center'}>
              {props.catalog
                .filter((app) => app.categories.includes(cat))
                .map((app) => (
                  <IntegrationCard
                    key={app.module}
                    categories={app.categories}
                    release={app.release}
                    name={app.title}
                    description={app.description}
                    connected={connectedApps.includes(app.module)}
                    valid={validApps.includes(app.module)}
                    planTier={app.plan_tier}
                    locked={
                      !!crmLimit &&
                      connectedCRMs >= crmLimit &&
                      app.categories.includes('CRM') &&
                      !validApps.includes(app.module)
                    }
                    logo={app.logo}
                    docsUrl={app.docs_url ? app.docs_url : undefined}
                    editUrl={app.docs_url ? undefined : projectPath(`/apps/${app.clean_module_name}`)}
                  />
                ))}
            </Wrap>
          </Stack>
        ))}
      </Stack>
    </PageLayout>
  )
}
