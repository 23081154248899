import { HStack, Text } from '@chakra-ui/react'
import { IconUsers } from '@tabler/icons-react'
import React from 'react'
import { ImportList } from '../../../../types/Imports'
import { BuildingIcon } from '../../../ui/icons'
import SquareIcon from '../../../ui/SquareIcon'

interface Props {
  kind: ImportList['kind']
}

export function ImportType(props: Props) {
  return (
    <HStack flex="none" spacing={1} border="1px solid" borderColor="gray.100" rounded="lg" pl={0.5} pr={1.5} py={0.5}>
      <SquareIcon
        colorScheme={props.kind === 'accounts' ? 'purple' : 'blue'}
        icon={props.kind === 'accounts' ? BuildingIcon : IconUsers}
        iconSize="14px"
        padding="2px"
      />
      <Text color="gray.600" fontSize="xs" fontWeight="semibold" textTransform="capitalize">
        {props.kind}
      </Text>
    </HStack>
  )
}
