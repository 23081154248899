import { Alert, AlertIcon, AlertTitle, Box, Button, Flex, HStack, Link, Stack, Text } from '@chakra-ui/react'
import { IconAlertTriangle } from '@tabler/icons-react'
import React from 'react'
import type Stripe from 'stripe'
import { formatDate } from '../../../../lib/dayjs'
import { billingManagePath } from '../lib/path-helpers'
import { Plan } from '../plans'
import { PlanData, plansV1 } from '../PricingTable'
import { KoalaSubscription } from '../show'

interface Props {
  live_subscription?: Stripe.Subscription & { quantity: number; plan: Stripe.Plan }
  live_customer?: Stripe.Customer
  koala_subscription: KoalaSubscription
  plan?: Plan | 'unlimited'
}

export function SubscriptionStatus(props: Props) {
  const isTrialing = props.koala_subscription.status === 'trialing'
  const isCanceled = props.live_subscription?.cancel_at_period_end
  const currentPlan: PlanData | null = props.plan ? plansV1[props.plan] : null

  return (
    <>
      {props.koala_subscription.past_due && (
        <>
          <Alert status="error" alignItems="flex-start" rounded="lg" py={5}>
            <AlertIcon />
            <Stack spacing={6}>
              <Stack fontSize={'sm'}>
                <AlertTitle>Your subscription payment was unsuccessful</AlertTitle>
                <Text>
                  We were unable to charge your payment method. Please update your payment information to continue using
                  Koala without disruption. If we are unable to collect payment, your subscription we be downgraded to
                  the Free plan.
                </Text>
              </Stack>
              <Button
                colorScheme={'red'}
                size="sm"
                as={Link}
                alignSelf="flex-start"
                href={billingManagePath()}
                onClick={(e) => {
                  e.preventDefault()
                  // @ts-ignore simple redirect
                  window.location = billingManagePath('?flow=payment_method_update')
                }}
              >
                Update payment information
              </Button>
            </Stack>
          </Alert>
        </>
      )}

      {isTrialing && !isCanceled && (
        <Box>
          {!props.koala_subscription.trial_past_due && (
            <Alert status="success" alignItems="flex-start" rounded="lg">
              <AlertIcon />
              <Stack fontSize={'sm'}>
                <AlertTitle>You're on a free trial of the {currentPlan?.title} plan.</AlertTitle>
                <Text>
                  Your {currentPlan?.title} plan will automatically start on{' '}
                  {formatDate(props.koala_subscription.expires_at)}.
                </Text>
              </Stack>
            </Alert>
          )}

          {props.koala_subscription.trial_past_due && props.koala_subscription.plan === 'team' && (
            <Alert status="warning" alignItems="flex-start" rounded="lg">
              <AlertIcon />
              <Stack fontSize={'sm'}>
                <AlertTitle>Your free trial of Koala is over</AlertTitle>
                <Text>Please choose a plan to continue using Koala. You can upgrade or downgrade at any time.</Text>
              </Stack>
            </Alert>
          )}
        </Box>
      )}

      {isCanceled && (
        <Stack padding={4} bg="orange.50" border="1px solid" borderColor="orange.200" rounded="lg" spacing="1">
          <HStack spacing="2">
            <IconAlertTriangle size="18" />

            <Text fontSize="sm" fontWeight="semibold">
              Your plan will be downgraded on {formatDate((props.live_subscription?.current_period_end ?? 0) * 1000)}
            </Text>
          </HStack>
          <Text fontSize="sm">Any billing changes you make will automatically void your scheduled downgrade.</Text>
        </Stack>
      )}

      {props.live_subscription?.default_source === null &&
        props.live_subscription?.default_payment_method === null &&
        props.live_customer?.default_source === null &&
        props.live_customer?.invoice_settings.default_payment_method === null && (
          <Alert status="warning" alignItems="flex-start" rounded="lg" py={5}>
            <AlertIcon />
            <Stack fontSize={'sm'}>
              <AlertTitle>Missing Credit Card on File</AlertTitle>
              <Text>
                We don't have a credit card for your account on file. Please add a credit card in order to not disrupt
                your Koala subscription {props.koala_subscription.trialing ? 'when your trial ends.' : ''}
              </Text>
              <Flex justifyContent={'flex-start'} paddingTop={4}>
                <Button
                  colorScheme="orange"
                  size="sm"
                  as={Link}
                  href={billingManagePath()}
                  onClick={(e) => {
                    e.preventDefault()
                    // @ts-ignore simple redirect
                    window.location = billingManagePath('?flow=payment_method_update')
                  }}
                >
                  Add Card
                </Button>
              </Flex>
            </Stack>
          </Alert>
        )}
    </>
  )
}
