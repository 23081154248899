import {
  Badge,
  Box,
  Button,
  Center,
  Circle,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconArrowRight,
  IconArrowUpRight,
  IconCheck,
  IconCircle,
  IconCircleDashed,
  IconCopy,
  IconDotsVertical,
  IconExternalLink,
  IconPlayerPlay,
  IconReload,
  IconSettings,
  IconTrash,
  IconX
} from '@tabler/icons-react'
import { flatten, orderBy } from 'lodash'
import pluralize from 'pluralize'
import React, { FormEventHandler, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { useMountedState } from 'react-use'
import { toast } from 'sonner'
import { del, post, put } from '../../../lib/api'
import { formatNumber } from '../../../lib/number-format'
import router from '../../../lib/router'
import { AutoOutboundRecord, AutoOutboundRule } from '../../../types/AutoOutbound'
import { PageMeta } from '../../../types/PageMeta'
import { useOutboundSequenceStats } from '../../data/use-outbound-sequence-stats'
import Avatar from '../../ui/Avatar'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import { HelpTooltip } from '../../ui/HelpTooltip'
import { HoverCard } from '../../ui/HoverCard'
import { LinkedinIcon, OutreachIcon } from '../../ui/icons'
import { IntentBolt } from '../../ui/IntentBolt'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import SelectInput from '../../ui/SelectInput'
import { TableFooter } from '../../ui/TableFooter'
import { TimeAgo } from '../../ui/TimeAgo'
import { accountPath } from '../accounts/lib/account-path'
import { Trendline } from '../icps/icp/breakdown'
import { mergeParams } from '../icps/types'
import { SignalType } from '../kql_definitions/components/SignalType'
import { KqlDefinition } from '../kql_definitions/types'
import { automationsPath } from '../notifications/lib/path-helper'
import { profilePath } from '../profiles/lib/path'
import {
  autoOutboundEditPath,
  autoOutboundLogsPath,
  autoOutboundNewFromRulePath,
  autoOutboundPath,
  autoOutboundRulePath
} from './lib/path-helpers'

interface Props {
  auto_outbound_rule: AutoOutboundRule
  prospects: AutoOutboundRecord[]
  intent_signals: Record<string, KqlDefinition>
  page_meta: PageMeta
}

const statusLabels = {
  staged: 'Pending',
  executed: 'Synced',
  failed: 'Error'
}

const statusColors = {
  executed: 'green.500',
  failed: 'red.500',
  staged: 'gray.500'
}

const statuses = [
  { label: 'All', value: undefined },
  { label: 'Synced', value: 'executed' },
  { label: 'Pending', value: 'staged' },
  { label: 'Failed', value: 'failed' }
]

export default function Show(props: Props) {
  const [prospects, setProspects] = useState<AutoOutboundRecord[]>(props.prospects)
  const [rule, setAutoOutboundRule] = useState<AutoOutboundRule>(props.auto_outbound_rule)
  const stats = useOutboundSequenceStats(props.auto_outbound_rule.id)
  const params = new URLSearchParams(window.location.search)
  const [syncing, setSyncing] = useState({})
  const mounted = useMountedState()

  useEffect(() => {
    setProspects(props.prospects)
  }, [props.prospects])

  const removeFromList = useCallback((recordId: number) => {
    setProspects((prev) => prev.filter((record) => record.id !== recordId))
  }, [])

  const syncProspect = useCallback(
    (recordId: number) => {
      setSyncing((syncing) => ({ ...syncing, [recordId]: true }))

      const record_ids = [recordId]

      post(autoOutboundRulePath(rule.id, '/sync'), { record_ids: record_ids })
        .then((_res) => {
          toast.success(`Success! Synced ${pluralize('prospect', record_ids.length, true)} to your destination.`)

          if (!mounted()) {
            return
          }

          setProspects((prev) => {
            return prev.map((record) => {
              if (record.id === recordId) {
                return {
                  ...record,
                  status: 'executed'
                }
              }
              return record
            })
          })
        })
        .catch((e) => {
          toast.error(`Error: ${e?.body?.error ?? e?.message}`)
        })
        .finally(() => {
          if (mounted()) {
            setSyncing((syncing) => ({ ...syncing, [recordId]: false }))
          }
        })
    },
    [mounted, rule.id]
  )

  const setPage = useCallback(
    (page: number) => {
      const url = mergeParams(autoOutboundRulePath(rule.id, `?${window.location.search}`), { page: String(page || 1) })
      router.visit(url)
    },
    [rule.id]
  )

  return (
    <PageLayout size="full">
      <Breadcrumb
        paths={[
          { path: automationsPath('/overview'), title: 'Automations' },
          { path: autoOutboundPath(), title: 'Auto Outbound' },
          { path: autoOutboundRulePath(rule.id), title: rule.name }
        ]}
      />

      <PageTitle skipRendering>Auto Outbound - {rule.name}</PageTitle>

      <Flex gap={4} alignItems="center" justifyContent="space-between" flexWrap="wrap">
        <Stack spacing={0}>
          <Heading flex="none" size="md">
            {rule.name}
          </Heading>
          <Text fontSize="xs" color="gray.600">
            Updated <TimeAgo time={rule.updated_at} />
          </Text>
        </Stack>
        <HStack spacing="4">
          <HStack fontSize={'sm'}>
            <Text>{rule.enabled ? 'Enabled' : 'Disabled'}</Text>
            <Switch
              defaultChecked={rule.enabled}
              value="1"
              onChange={async (e) => {
                await put(autoOutboundRulePath(rule.id, `/status`), {
                  auto_outbound: {
                    enabled: e.target.checked
                  }
                }).then(() => {
                  setAutoOutboundRule((rule) => {
                    return {
                      ...rule,
                      enabled: e.target.checked
                    }
                  })
                  toast.success(`Auto Outbound Rule ${e.target.checked ? 'enabled' : 'disabled'}`)
                })
              }}
            />
          </HStack>
          <HStack fontSize={'sm'}>
            <Text>{rule.autopilot ? 'Autopilot' : 'Manual'}</Text>
            <HelpTooltip>
              <Stack fontSize="xs">
                <Heading size="xs">Autopilot</Heading>
                <Text>You can choose to run this rule in Autopilot or Manual mode.</Text>
                <Text>Running this rule in Autopilot will automatically outbound contacts based on their actions.</Text>
                <Text>
                  Setting this rule to Manual Mode will require clicking the "Run" button in the AutoOutbound logs for
                  this rule when you want to Outbound a contact.
                </Text>
              </Stack>
            </HelpTooltip>
            <Switch
              defaultChecked={rule.autopilot}
              value="1"
              onChange={async (e) => {
                await put(autoOutboundRulePath(rule.id, `/status`), {
                  auto_outbound: {
                    autopilot: e.target.checked
                  }
                })
                  .then(() => {
                    setAutoOutboundRule((rule) => {
                      return {
                        ...rule,
                        autopilot: e.target.checked
                      }
                    })
                    toast.success(`Autopilot ${e.target.checked ? 'enabled' : 'disabled'}`)
                  })
                  .catch((ee) => {
                    toast.error(`Error: ${ee}`)
                  })
              }}
            />
          </HStack>
          <HStack>
            <Button
              size="sm"
              leftIcon={<IconSettings size={15} />}
              as={Link}
              href={autoOutboundEditPath(rule.id)}
              variant="outline"
            >
              Edit Rule
            </Button>
            <Menu size="sm" autoSelect={false} placement="bottom-end">
              <MenuButton
                as={IconButton}
                aria-label="Actions"
                icon={<IconDotsVertical size={16} />}
                variant="ghost"
                size="xs"
              />
              <MenuList>
                <MenuItem as="a" icon={<IconCopy size={16} />} href={autoOutboundNewFromRulePath(rule.id)}>
                  Clone
                </MenuItem>
                <MenuItem
                  icon={<IconTrash size={16} />}
                  onClick={(e) => {
                    e.preventDefault()

                    if (confirm('Are you sure?')) {
                      del(autoOutboundRulePath(rule.id)).then(() => {
                        router.visit(autoOutboundPath())
                      })
                    }
                  }}
                >
                  Delete Rule
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>
      </Flex>

      <Card p={0}>
        <Grid width="100%" templateColumns={['repeat(1, 1fr)', 'repeat(auto-fit, minmax(120px, 1fr))']} gap={2}>
          <Stat label="Prospects" value={rule.prospect_stats?.matched} trend={rule.timeseries_stats?.total} />
          <Stat label="Pending" value={rule.prospect_stats?.eligible} trend={rule.timeseries_stats?.staged} />
          <Stat label="Synced" value={rule.prospect_stats?.executed} trend={rule.timeseries_stats?.executed} />
          <Stat label="Failed" value={rule.prospect_stats?.failed} trend={rule.timeseries_stats?.failed} />
        </Grid>
      </Card>

      {stats.isLoading ? (
        <Box paddingY={3}>
          <Spinner size="sm" color="gray.400" thickness="1px" />
        </Box>
      ) : stats.data?.outreach_sequence_stats && stats.data.outreach_sequence_stats.length > 0 ? (
        <Card p={0}>
          <Stack spacing={1} paddingX={4} paddingY={4} borderBottom="1px solid" borderBottomColor="gray.200">
            <Heading size="sm" fontWeight="semibold">
              Connected Sequences ({stats.data.outreach_sequence_stats.length})
            </Heading>
            <Text fontSize="sm" color="gray.500">
              View stats pulled from your connected Outreach Sequences
            </Text>
          </Stack>

          <TableContainer paddingTop={2}>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Sequence</Th>
                  <Th isNumeric>Prospects</Th>
                  <Th isNumeric>Delivered</Th>
                  <Th isNumeric>Opened</Th>
                  <Th isNumeric>Clicked</Th>
                  <Th isNumeric>Replied</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {stats.data.outreach_sequence_stats.map((sequence) => (
                  <Tr key={sequence.id}>
                    <Td>
                      <HStack py={2}>
                        <OutreachIcon color="outreach" size={20} />
                        <Link href={sequence.permalink} target="_blank">
                          {sequence.name} (#{sequence.id})
                        </Link>
                      </HStack>
                    </Td>
                    <Td isNumeric>{sequence.stats?.prospects ? formatNumber(sequence.stats.prospects) : '-'}</Td>
                    <Td isNumeric>{sequence.stats?.delivered ? formatNumber(sequence.stats.delivered) : '-'}</Td>
                    <Td isNumeric>{sequence.stats?.opened ? formatNumber(sequence.stats.opened) : '-'}</Td>
                    <Td isNumeric>{sequence.stats?.clicked ? formatNumber(sequence.stats.clicked) : '-'}</Td>
                    <Td isNumeric>{sequence.stats?.replied ? formatNumber(sequence.stats.replied) : '-'}</Td>
                    <Td w="1px" isNumeric pl={10}>
                      <Button
                        variant="outline"
                        size="xs"
                        as={Link}
                        href={sequence.permalink}
                        target="_blank"
                        iconSpacing={1}
                        rightIcon={<IconExternalLink size={14} />}
                      >
                        View Externally
                      </Button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Card>
      ) : null}

      <Card p={0}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          gap={6}
          paddingX={4}
          paddingY={4}
          borderBottom="1px solid"
          borderBottomColor="gray.200"
        >
          <Stack spacing={1}>
            <Heading size="sm" fontWeight="semibold">
              Enrolled Prospects ({(rule.prospect_stats?.total ?? 0).toLocaleString()})
            </Heading>
            <Text fontSize="sm" color="gray.500">
              Prospects that have been matched and are eligible to be synced to your outbound destination based on the
              configuration of this Auto Outbound rule.
            </Text>
          </Stack>

          <Button
            as={Link}
            rightIcon={<IconArrowUpRight size={16} />}
            iconSpacing={1}
            flex="none"
            size="sm"
            variant="outline"
            href={autoOutboundLogsPath({ rule_id: rule.id })}
            isExternal
          >
            View logs
          </Button>
        </Flex>

        <Box paddingX={4} paddingY={2.5} borderBottom="1px solid" borderBottomColor="gray.200">
          <SelectInput
            variant="outline"
            size="sm"
            triggerProps={{ width: 'auto' }}
            items={statuses}
            selectedItem={statuses.find((s) => s.value === (params.get('status') || undefined)) || null}
            onSelectedItemChange={({ selectedItem }) => {
              const status = selectedItem?.value
              const url = mergeParams(window.location.toString(), { status, page: '1' })
              router.visit(url)
            }}
            itemRenderer={(item) => (
              <HStack spacing={2}>
                <StatusIcon status={item.value} />
                <Text color="gray.600" fontWeight="medium" fontSize="sm">
                  {item.label}
                </Text>
              </HStack>
            )}
            itemToString={(item) => item?.label ?? item?.value ?? ''}
          />
        </Box>

        {prospects.length > 0 ? (
          <>
            <TableContainer paddingTop={2}>
              <Table size="sm">
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Source</Th>
                    <Th>Prospect Geo</Th>
                    <Th>Company</Th>
                    <Th>Company HQ</Th>
                    <Th>Industry</Th>
                    <Th>Signal</Th>
                    <Th>Added</Th>
                    <Th px={8}>Status</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {prospects.map((prospect) => {
                    const profile = prospect.profile || prospect.merged_profile
                    const displayName = profile?.display_name ?? profile?.email
                    const signals = flatten(prospect.context?.rules?.intent?.info?.signals ?? [])
                    const latest: any = orderBy(signals, ['triggered_at'], ['desc'])?.[0]
                    const errors = prospect.context?.errors || []
                    const autoProspected =
                      prospect.context['auto_prospecting.profile_id'] || prospect.profile_type === 'ProspectedProfile'

                    return (
                      <Tr
                        key={prospect.id}
                        _hover={{
                          bg: 'gray.50'
                        }}
                      >
                        <Td width="1px">
                          <HStack isTruncated minW="300px" maxW="400px" spacing={3}>
                            <Box py={1.5}>
                              <Avatar size="sm" name={displayName} src={prospect.profile?.avatar} />
                            </Box>
                            <Stack spacing={0.5} flex="1" alignItems="flex-start" w="100%">
                              <HStack spacing="1" alignItems={'center'} role="group" w="100%">
                                <Link
                                  fontSize="sm"
                                  fontWeight="medium"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  maxWidth="100%"
                                  href={
                                    autoProspected && prospect.profile?.linkedin_url
                                      ? (`https://${prospect.profile.linkedin_url.replace(/https?:\/\//, '')}` ?? '')
                                      : profilePath({
                                          id: prospect.profile?.id || prospect.profile_id,
                                          email: prospect.profile?.email
                                        })
                                  }
                                >
                                  {displayName ?? prospect.context?.email ?? (
                                    <Text fontSize="sm" color="gray.500">
                                      Anonymous
                                    </Text>
                                  )}
                                </Link>

                                {prospect.profile?.linkedin_url && (
                                  <Tooltip label="View on LinkedIn" placement="top" hasArrow arrowSize={6}>
                                    <Link
                                      display="flex"
                                      alignItems="center"
                                      _groupHover={{
                                        color: 'linkedin.700'
                                      }}
                                      color="gray.400"
                                      isExternal
                                      href={`https://${prospect.profile.linkedin_url.replace(/https?:\/\//, '')}`}
                                    >
                                      <LinkedinIcon size={14} />
                                    </Link>
                                  </Tooltip>
                                )}
                              </HStack>

                              {prospect.profile?.title ? (
                                <Text
                                  fontSize="xs"
                                  color="gray.500"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  maxWidth="100%"
                                >
                                  {prospect.profile.title}
                                </Text>
                              ) : null}
                            </Stack>
                          </HStack>
                        </Td>
                        <Td>
                          <Stack>
                            <HStack>
                              {autoProspected ? (
                                <Badge colorScheme={'blue'}>Persona match</Badge>
                              ) : (
                                <Badge colorScheme={'purple'}>Direct match</Badge>
                              )}
                            </HStack>
                            {autoProspected && (
                              <>
                                <Text
                                  fontSize={'xs'}
                                  style={{
                                    whiteSpace: 'pre-wrap'
                                  }}
                                  color="gray.500"
                                >
                                  {prospect.context['auto_prospecting.reason']}
                                </Text>
                              </>
                            )}
                          </Stack>
                        </Td>
                        <Td>
                          <Text fontSize="sm" color="gray.500">
                            {profile?.simple_location}
                          </Text>
                        </Td>
                        <Td width="1px" maxW="200px" paddingX={0}>
                          {profile?.company && (
                            <Box paddingX={2}>
                              <Flex
                                as="a"
                                href={accountPath({ company: profile.company })}
                                display="inline-flex"
                                alignItems="center"
                                gap={1.5}
                                width="200px"
                                overflow="hidden"
                                rounded="lg"
                                padding={2}
                                marginX={-2}
                                _hover={{
                                  background: 'white',
                                  shadow: 'sm',
                                  '& .hover-icon': {
                                    opacity: 1
                                  }
                                }}
                              >
                                <CompanyAvatar
                                  size="28px"
                                  name={profile.company.name}
                                  domain={profile.company.domain}
                                />
                                <Text
                                  flex="1 1 auto"
                                  minW="100px"
                                  overflow="hidden"
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  maxWidth="100%"
                                  fontSize="xs"
                                  fontWeight="medium"
                                >
                                  {profile.company.name ?? profile.company.domain}
                                </Text>
                                <Circle flex="none" className="hover-icon" opacity={0} size="20px" bg="gray.100">
                                  <Icon as={IconArrowRight} boxSize={4} color="gray.600" />
                                </Circle>
                              </Flex>
                            </Box>
                          )}
                        </Td>
                        <Td>
                          <Text fontSize="sm" color="gray.500">
                            {profile?.company?.simple_location}
                          </Text>
                        </Td>
                        <Td>
                          <Text fontSize="sm" color="gray.500">
                            {profile?.company?.industry}
                          </Text>
                        </Td>
                        <Td width="1px">
                          {latest && (
                            <HStack lineHeight="20px">
                              <SignalType
                                label={latest.kql_definition_name}
                                signalType={props.intent_signals[latest.kql_definition_id]?.signal_type}
                                marginRight={1}
                                compact
                                isTruncated
                                fontSize="sm"
                                fontWeight="normal"
                                flexShrink={1}
                                minW="60px"
                              />
                              <IntentBolt count={signals.length} variant="subtle" />
                            </HStack>
                          )}
                        </Td>
                        <Td width="1px">
                          <TimeAgo time={prospect.created_at} mode="relative" canToggle={false} />
                        </Td>
                        <Td width="1px" px={8}>
                          <Box>
                            <ErrorHoverCard errors={errors}>
                              <HStack display="inline-flex" spacing={1.5} color={statusColors[prospect.status]}>
                                <StatusIcon status={prospect.status} />
                                <Text fontSize="sm" fontWeight="medium">
                                  {statusLabels[prospect.status]}
                                </Text>
                              </HStack>
                            </ErrorHoverCard>
                          </Box>
                        </Td>
                        <Td width="1px">
                          <Flex gap={2} alignItems="center">
                            {prospect.status === 'staged' && (
                              <Button
                                size="sm"
                                colorScheme="gray"
                                isDisabled={prospect.executed}
                                isLoading={syncing[prospect.id]}
                                leftIcon={<IconPlayerPlay size="14" />}
                                onClick={() => syncProspect(prospect.id)}
                              >
                                Sync
                              </Button>
                            )}
                            {prospect.status === 'failed' && (
                              <Button
                                size="sm"
                                colorScheme="gray"
                                isDisabled={prospect.executed}
                                isLoading={syncing[prospect.id]}
                                leftIcon={<IconReload size="14" />}
                                onClick={() => syncProspect(prospect.id)}
                              >
                                Retry
                              </Button>
                            )}

                            <OverflowMenu record={prospect} onRemoved={removeFromList} />
                          </Flex>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Box px={4}>
              <TableFooter
                pageMeta={props.page_meta}
                page={props.page_meta.current_page || 1}
                setPage={setPage}
                scrollToTop={false}
              />
            </Box>
          </>
        ) : rule.prospect_stats?.total ? (
          <Center
            display="flex"
            flexWrap="wrap"
            gap={1}
            maxWidth="80%"
            margin="auto"
            fontSize="sm"
            color="gray.500"
            px={4}
            py={10}
          >
            <Text textAlign="center">
              No prospects match your filter. Try filtering by another status or select "All" to see everything.
            </Text>
            <Button
              size="sm"
              variant="link"
              colorScheme="purple"
              onClick={() => {
                const url = mergeParams(window.location.toString(), { status: undefined, page: '1' })
                router.visit(url)
              }}
            >
              Reset filters
            </Button>
          </Center>
        ) : (
          <Center fontSize="sm" color="gray.500" px={4} py={10}>
            No prospects enrolled in this Auto Outbound list yet. They'll show up here when once some become eligible
            based on your rules.
          </Center>
        )}
      </Card>
    </PageLayout>
  )
}

function Stat(props: { label: string; value?: number | null; trend?: Array<{ day: string; value: number }> }) {
  return (
    <Flex flex="1 1 0%" gap={2} direction="column" justifyContent="space-between" px={3} py={3}>
      <Stack spacing={1.5}>
        <Heading size="xs" fontWeight="normal" color="gray.600">
          {props.label}
        </Heading>
        <Text fontSize="xl" fontWeight="semibold" lineHeight={1}>
          {(props.value || 0).toLocaleString()}
        </Text>
        <Trendline
          color="purple"
          range="month"
          trend={{
            trends: props.trend || []
          }}
          height={24}
          svgHeight={24}
        />
      </Stack>
    </Flex>
  )
}

function StatusIcon(props: { status: string }) {
  switch (props.status) {
    case 'executed':
      return <CircleIcon icon={IconCheck} iconSize={3.5} size={5} colorScheme="green" />
    case 'failed':
      return <CircleIcon icon={IconX} iconSize={3.5} size={5} colorScheme="red" />
    case 'staged':
      return <Icon as={IconCircleDashed} boxSize={5} color="gray.300" />
    default:
      return <Icon as={IconCircle} boxSize={5} color="gray.400" />
  }
}

interface OverflowMenuProps {
  record: AutoOutboundRecord
  onRemoved: (id: number) => void
}

function OverflowMenu({ record, onRemoved }: OverflowMenuProps) {
  const disclosure = useDisclosure()
  const deleteDisclosure = useDisclosure()
  const profile = record.profile ?? record.merged_profile
  const displayName = profile?.display_name ?? profile?.email

  const handleRemoval: FormEventHandler<HTMLFormElement> = useCallback(
    async (event) => {
      event.preventDefault()

      const removeEnrollmentPath = autoOutboundRulePath(record.rule_id, `/members/${record.id}`)
      try {
        await del(removeEnrollmentPath)
        onRemoved(record.id)
        toast(`Successfully removed ${displayName}!`)
      } catch (err) {
        toast(`There was an issue removing ${displayName} from this list!`)
      }
    },
    [record, displayName, onRemoved]
  )

  return (
    <>
      <Menu {...disclosure} autoSelect={false}>
        <MenuButton as={IconButton} size="xs" icon={<IconDotsVertical size={16} />} variant="ghost" />
        <Portal>
          <MenuList>
            <MenuItem
              as="a"
              icon={<IconArrowRight size={16} />}
              href={profilePath({
                id: record.profile?.id || record.profile_id,
                email: record.profile?.email
              })}
            >
              View full profile
            </MenuItem>
            <MenuDivider />
            <MenuItem
              isDisabled={record.status === 'executed'}
              color="red.500"
              icon={<IconX size={16} />}
              onClick={deleteDisclosure.onOpen}
            >
              Remove from list
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>

      <DeleteConfirmation
        title={`Remove ${displayName}?`}
        confirmLabel="Yes, continue"
        onConfirm={handleRemoval}
        isCentered
        {...deleteDisclosure}
      >
        Are you sure you want to remove {displayName} from this Auto Outbound list?
      </DeleteConfirmation>
    </>
  )
}

function ErrorHoverCard(props: PropsWithChildren<{ errors?: string[] }>): JSX.Element {
  if (props.errors?.length) {
    return (
      <HoverCard
        trigger="hover"
        isPortal
        hoverContent={
          <Stack maxW="380px" overflow="hidden" alignItems="stretch" lineHeight="20px" spacing={1}>
            {props.errors.map((error, index) => (
              <Box key={error + index} maxW="100%">
                <Text fontSize="sm" whiteSpace="normal">
                  {error}
                </Text>
              </Box>
            ))}
          </Stack>
        }
      >
        {props.children}
      </HoverCard>
    )
  }

  return props.children as JSX.Element
}
