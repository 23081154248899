import EmptyState from '@app/components/ui/EmptyState'
import PageTitle from '@app/components/ui/PageTitle'
import { TableFooter } from '@app/components/ui/TableFooter'
import { Account } from '@app/types/Account'
import { AccountView } from '@app/types/AccountView'
import { Apps } from '@app/types/App'
import { Crm } from '@app/types/Crm'
import { PageMeta } from '@app/types/PageMeta'
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Text,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  IconButton
} from '@chakra-ui/react'
import {
  IconChevronDown,
  IconCrosshair,
  IconInbox,
  IconListSearch,
  IconRadar,
  IconDotsVertical
} from '@tabler/icons-react'
import React, { FormEvent, useCallback, useEffect, useMemo } from 'react'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { TopBarContent } from '../../ui/TopBarContext'
import { projectPath } from '../../ui/ProjectsContext'
import { SegmentedControl } from '../../ui/SegmentedControl'
import useLocation from '../../ui/useLocation'
import { FacetFilters } from '../accounts'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { KoalaWeeklyPreviewAlert, viewingKoalaWeekly } from '../digests/components/koala-weekly-preview-alert'
import { AccountList } from '../icps/icp/account-list'
import { ListContainer, useListsNav } from '../lists/components/ListContainer'
import { PlayPauseButton } from '../profiles/components/play-pause-button'
import { HighlightedProfile } from '../profiles/components/profile-list'
import { ProfileLiveList } from '../profiles/components/profile-live-list'
import { LiveButton } from '../profiles/components/visitor-tabs'
import DrawnArrowUpperLeft from './components/ArrowUp'
import DownloadCsvMenuItem from '../../ui/DownloadCsvButtons'
import { MCHeader } from '../mission_control/Header'
import { put } from '../../../lib/api'
import { useMountedState } from 'react-use'
import { toast } from 'sonner'
import { useUrlFilters } from '../../data/use-url-filters'
import { mergeParams } from '../icps/types'
import router from '../../../lib/router'
import { viewChanged } from './lib/view-changed'

export interface MyAccountsProps {
  accounts: Account[]
  profiles?: HighlightedProfile[]
  total_count?: number
  page_meta: PageMeta
  crm?: Crm
  crms?: Crm[]
  has_saved_filters?: boolean
  suggested_users?: Array<{
    id: string
    name: string
    email: string
  }>
  apps: Apps
  account_view: AccountView
  facet_filters?: FacetFilters
  sort_by?: string
  permissions: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
  could_onboard?: boolean
  is_live?: boolean
}

export default function Mine(props: MyAccountsProps) {
  const loc = useLocation()
  const isKoalaWeekly = useMemo(() => viewingKoalaWeekly(loc), [loc])
  const onClaimed = window.location.pathname.endsWith('/claimed')
  const onUpForGrabs = window.location.pathname.endsWith('/unowned')
  const onLive = window.location.pathname.endsWith('/live')
  const onMissionControl = window.location.pathname.includes('/mission-control')
  const onAssigned = !onClaimed && !onUpForGrabs && !onLive
  const pathPrefix = projectPath(onMissionControl ? '/mission-control/my-accounts' : '/views/mine')

  const [submitting, setSubmitting] = React.useState(false)
  const [paused, setPaused] = React.useState(!props.is_live)
  const [accountView, setAccountView] = React.useState(props.account_view)
  const [accounts, setAccounts] = React.useState(props.accounts)
  const [profiles, setProfiles] = React.useState(props.profiles)
  const [totalCount, setTotalCount] = React.useState(props.total_count || 0)
  const [pageMeta, setPageMeta] = React.useState(props.page_meta)
  const mounted = useMountedState()

  useEffect(() => {
    setAccountView(props.account_view)
  }, [props.account_view])

  useEffect(() => {
    setAccounts(props.accounts)
    setProfiles(props.profiles)
    setTotalCount(props.total_count || 0)
    setPageMeta(props.page_meta)
  }, [props.accounts, props.profiles, props.total_count, props.page_meta])

  const facets = useUrlFilters({
    initialRange: 'all',
    initialFacets: accountView.filters.facets,
    initialFocusTime: accountView.filters.focus_time,
    initialSortBy: accountView.filters.sort_by || props.sort_by
  })

  const saveChanges = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      setSubmitting(true)

      const body = { facets: facets.facetFilters, focus_time: facets.focusTime, sort_by: facets.sortBy }
      put<Pick<MyAccountsProps, 'account_view'>>(window.location.pathname, body)
        .then((response) => {
          if (mounted()) {
            if (response.account_view) {
              setAccountView(response.account_view)
            }
            toast.success('Territory filters saved successfully!')
            setSubmitting(false)
          }
        })
        .catch((error) => {
          toast.error('Failed to save territory filters')
          console.error(error)

          if (mounted()) {
            setSubmitting(false)
          }
        })
    },
    [mounted, facets.facetFilters, facets.focusTime, facets.sortBy]
  )

  const discardChanges = useCallback(() => {
    router.visit(window.location.pathname)
  }, [])

  const apps = useMemo(() => Object.values(props.apps), [props.apps])

  const viewDiff = useMemo(() => {
    return viewChanged(
      accountView?.filters || {},
      {
        focus_time: facets?.focusTime,
        sort_by: facets?.sortBy,
        facets: facets?.facetFilters
      },
      { facets: {} }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountView?.filters, facets?.range, facets?.focusTime, facets?.sortBy, facets?.facetFilters])

  const isDirty = (viewDiff ?? []).length > 0

  const canSaveChanges = !isKoalaWeekly && isDirty && onAssigned && (props.permissions?.can_create ?? false)
  const hasFilters = facets.isFiltering
  const navContext = useListsNav()

  return (
    <ListContainer>
      <PageTitle skipRendering>{accountView.name}</PageTitle>

      <Flex direction="column" gap={6} alignItems="stretch">
        {onMissionControl ? (
          <MCHeader space={props.account_view.space} viewId={accountView.id} apps={props.apps} />
        ) : (
          <TopBarContent>
            <Breadcrumb
              paths={[
                {
                  path: projectPath('/accounts'),
                  title: 'Accounts'
                },
                { path: projectPath('/views/mine'), title: accountView.name || 'My Accounts' },
                ...(window.location.pathname.includes('/claimed')
                  ? [
                      {
                        path: projectPath('/views/mine/claimed'),
                        title: 'Claimed'
                      }
                    ]
                  : window.location.pathname.includes('/live')
                    ? [{ path: projectPath('/views/mine/live'), title: 'Live' }]
                    : [])
              ]}
              paddingTop={1}
              display={['none', 'flex']}
            />
          </TopBarContent>
        )}
        <VStack spacing={0} alignItems="stretch">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            flexWrap={['wrap', 'nowrap']}
            direction={['column-reverse', 'row']}
            gap={3}
          >
            <HStack flex="1 1 100%" width="100%" justifyContent="space-between" spacing={3}>
              <Flex alignItems="center" gap={2} justifyContent="flex-start" flexWrap="nowrap">
                <IconInbox size={20} />
                <Heading
                  display="flex"
                  gap={1.5}
                  alignItems="center"
                  size="md"
                  whiteSpace="nowrap"
                  onClick={!isKoalaWeekly && !navContext.isOpen ? navContext.onOpen : undefined}
                >
                  {accountView.name}
                  {!isKoalaWeekly && navContext.offScreen && (
                    <Icon as={IconChevronDown} color="purple.500" boxSize="18px" />
                  )}
                </Heading>
                {isKoalaWeekly && <Text color="gray.500">(Koala Weekly Preview)</Text>}
                {props.is_live && <Badge colorScheme={paused ? 'gray' : 'green'}>Live</Badge>}
              </Flex>

              {props.is_live && <PlayPauseButton paused={paused} onToggle={(mode) => setPaused(mode === 'paused')} />}
            </HStack>

            <SegmentedControl
              size="sm"
              display={['grid', 'flex']}
              width={['100%', 'auto']}
              gridTemplateColumns="1fr 1px 1fr 1px 1fr"
              marginBottom={['4', '0']}
            >
              <Button
                isActive={
                  location.pathname.endsWith('/my-accounts') ||
                  location.pathname.endsWith('/mine') ||
                  location.pathname.endsWith('/assigned')
                }
                as={Link}
                href={pathPrefix + '/assigned'}
              >
                Assigned
              </Button>
              <Button isActive={location.pathname.endsWith('/claimed')} as={Link} href={pathPrefix + '/claimed'}>
                Claimed
              </Button>
              <LiveButton path={pathPrefix + '/live'} />
            </SegmentedControl>

            <Menu>
              <MenuButton
                size="sm"
                as={IconButton}
                icon={<IconDotsVertical size={16} />}
                variant="outline"
                borderColor="gray.200"
              />
              <MenuList fontSize="sm">
                <DownloadCsvMenuItem
                  url={`${window.location.pathname}.csv`}
                  isMenuDisabled={
                    window.location.pathname.includes('/feed') ||
                    window.location.pathname.includes('/live') ||
                    accountView.id === null
                  }
                  apps={apps}
                  audienceKind="account"
                  itemTitle={accountView.id ? null : 'Please save your territory to download CSVs'}
                  allowColumnSelection
                />
              </MenuList>
            </Menu>
          </Flex>

          {isKoalaWeekly && (
            <Flex py="4">
              <KoalaWeeklyPreviewAlert />
            </Flex>
          )}

          <Flex alignItems="flex-start" justifyContent="space-between" gap={4} paddingY={4}>
            <FilterPreview
              isEditable={!isKoalaWeekly}
              {...facets}
              kind="account"
              apps={props.apps}
              canClearFilters={false}
              range={isKoalaWeekly ? undefined : facets.range}
            />

            {canSaveChanges && (
              <Flex gap={2} alignItems="center">
                <Button
                  flex="none"
                  variant="ghost"
                  size="sm"
                  color="gray.500"
                  _hover={{ color: 'gray.700' }}
                  onClick={discardChanges}
                >
                  Discard changes
                </Button>
                <form method="POST" onSubmit={saveChanges}>
                  <AuthenticityToken />
                  <input type="hidden" name="_method" value="PUT" />
                  <Button
                    type="submit"
                    size="sm"
                    colorScheme="purple"
                    isDisabled={!canSaveChanges}
                    isLoading={submitting}
                  >
                    Save
                  </Button>
                </form>
              </Flex>
            )}
          </Flex>

          {props.is_live && (profiles?.length ?? 0) > 0 && totalCount && hasFilters && (
            <ProfileLiveList
              paused={paused}
              total_count={totalCount}
              {...props}
              apps={apps}
              profiles={profiles!}
              onChangeMode={(mode) => setPaused(mode === 'paused')}
            />
          )}

          {accounts.length > 0 && hasFilters && !props.is_live && (
            <AccountList
              accounts={accounts}
              range={facets.range || 'all'}
              sortingBy={facets.sortBy ?? 'overall_grade'}
              onSortChange={facets.setSortBy}
              crm={props.crm}
            />
          )}
        </VStack>
      </Flex>

      {hasFilters && pageMeta && pageMeta.total_pages > 1 && !props.is_live && (
        <TableFooter
          word="account"
          pageMeta={pageMeta}
          page={pageMeta.current_page}
          prevPath={mergeParams(window.location.toString(), {
            page: props.page_meta.prev_page?.toString() ?? '1'
          })}
          nextPath={mergeParams(window.location.toString(), {
            page: props.page_meta.next_page?.toString() ?? '1'
          })}
          sticky
        />
      )}

      {!hasFilters ? (
        <Box position="relative" paddingTop={20}>
          <Box position="absolute" top="10px" left="20px" display="flex" alignItems="flex-end" gap="2">
            <DrawnArrowUpperLeft width="80px" color="purple.500" paddingBottom={2} />
            <Text fontSize="sm" fontWeight="semibold" fontStyle="italic" color="purple.600">
              Add filters to define
              <br /> your territory
            </Text>
          </Box>
          <EmptyState
            size="md"
            heading="Set up your account filters"
            description={`Click "Filter" to define your territory and see your accounts.`}
            icon={IconListSearch}
          />
        </Box>
      ) : accounts.length === 0 && onClaimed ? (
        <EmptyState
          size="md"
          heading="No claimed accounts"
          description="Claim some accounts or adjust your filters."
          icon={IconCrosshair}
        />
      ) : accounts.length === 0 && onLive ? (
        <EmptyState
          size="md"
          heading="No live accounts"
          description="None of your assigned accounts are currently online (based on the filters you've set)."
          icon={IconRadar}
        />
      ) : accounts.length === 0 && onAssigned ? (
        <EmptyState
          size="md"
          heading="No assigned accounts matching your filters"
          description="Adjust your filters to find accounts you own or that fall within your territory."
          icon={IconCrosshair}
        />
      ) : accounts.length === 0 && onUpForGrabs ? (
        <EmptyState
          size="md"
          heading="No accounts are unowned"
          description="Adjust your filters to find accounts that are unowned."
          icon={IconCrosshair}
        />
      ) : null}
    </ListContainer>
  )
}
