import {
  Box,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Kbd,
  List,
  ListItem,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Square,
  Stack,
  Text
} from '@chakra-ui/react'
import {
  IconArrowRight,
  IconArrowUpRight,
  IconBook,
  IconChevronRight,
  IconLock,
  IconSettings,
  IconUsers
} from '@tabler/icons-react'
import { useCombobox } from 'downshift'
import Fuse from 'fuse.js'
import groupBy from 'lodash/groupBy'
import uniqBy from 'lodash/uniqBy'
import React, { useMemo } from 'react'
import { useDebounce } from 'use-debounce'
import { isMacOS } from '../../../lib/isMacOS'
import Router from '../../../lib/router'
import { Account } from '../../../types/Account'
import { AccountView } from '../../../types/AccountView'
import {
  OmnisearchAccount,
  OmnisearchData,
  OmnisearchProfile,
  OmnisearchProject,
  OmnisearchView,
  useOmnisearch
} from '../../data/use-omnisearch'
import { accountPath } from '../../pages/accounts/lib/account-path'
import { accountViewPath } from '../../pages/account_views/lib/list-paths'
import CompanyAvatar from '../CompanyAvatar'
import { BuildingIcon } from '../icons'
import { SearchIcon } from '../icons/SearchIcon'
import { projectPath } from '../ProjectsContext'
import SquareIcon from '../SquareIcon'
import StatusAvatar from '../StatusAvatar'
import { Route, useRoutes } from '../useRoutes'

interface ProfileItem extends OmnisearchProfile {
  type: 'people'
}

interface AccountItem extends OmnisearchAccount {
  type: 'account'
}

interface AccountViewItem extends OmnisearchView {
  type: 'list'
}

interface ProjectItem extends OmnisearchProject {
  type: 'project'
}

interface RouteItem extends Route {
  id: string
  type: 'navigation'
}

type Item = ProfileItem | AccountItem | AccountViewItem | ProjectItem | RouteItem

function isAccountItem(item: Item | null | undefined): item is AccountItem {
  return (item as AccountItem)?.type === 'account'
}

function isProfileItem(item: Item | null | undefined): item is ProfileItem {
  return (item as ProfileItem)?.type === 'people'
}

function isAccountViewItem(item: Item | null | undefined): item is AccountViewItem {
  return (item as AccountViewItem)?.type === 'list'
}

function isRouteItem(item: Item | null | undefined): item is RouteItem {
  return (item as RouteItem)?.type === 'navigation'
}

function isProjectItem(item: Item | null | undefined): item is ProjectItem {
  return (item as ProjectItem)?.type === 'project'
}

const sectionLabels = {
  account: 'Accounts',
  people: 'People',
  list: 'Lists',
  navigation: 'Pages',
  project: 'Workspaces'
}

const model = {
  account: 'Account',
  people: 'Profile',
  list: 'AccountView',
  project: 'Project',
  navigation: 'Route'
}

const categories = Object.keys(sectionLabels)

interface MenuItemProps extends React.HTMLAttributes<HTMLLIElement> {
  isHighlighted: boolean
  item: Item
}

const MenuItem = React.forwardRef<HTMLLIElement, MenuItemProps>(function MenuItem(props, ref) {
  const { item, ...rest } = props
  if (isAccountItem(item)) {
    return <AccountMenuItem ref={ref} {...rest} item={item} />
  }

  if (isProfileItem(item)) {
    return <ProfileMenuItem ref={ref} {...rest} item={item} />
  }

  if (isAccountViewItem(item)) {
    return <AccountViewMenuItem ref={ref} {...rest} item={item} />
  }

  if (isRouteItem(item)) {
    return <RouteMenuItem ref={ref} {...rest} item={item} />
  }

  if (isProjectItem(item)) {
    return <ProjectMenuItem ref={ref} {...rest} item={item} />
  }

  return null
})

type ProjectMenuItemProps = Omit<MenuItemProps, 'item'> & { item: ProjectItem }
const ProjectMenuItem = React.forwardRef<HTMLLIElement, ProjectMenuItemProps>(function ProjectMenuItem(props, ref) {
  const { isHighlighted, item, ...rest } = props

  return (
    <ListItem
      ref={ref}
      as="li"
      backgroundColor={isHighlighted ? 'gray.100' : undefined}
      rounded="md"
      cursor="pointer"
      {...rest}
    >
      <Flex as="a" href={`/projects/${item.slug}`} gap={2.5} padding={2} alignItems="center">
        <CompanyAvatar name={item.name} domain={item.domain} size="xs" />
        <Flex gap={1.5} alignItems="baseline">
          <Text fontSize="sm" fontWeight="medium">
            {item.name}
          </Text>
          <Text color="gray.500" fontSize="sm">
            ({item.slug})
          </Text>
        </Flex>
      </Flex>
    </ListItem>
  )
})

type AccountMenuItemProps = Omit<MenuItemProps, 'item'> & { item: AccountItem }
const AccountMenuItem = React.forwardRef<HTMLLIElement, AccountMenuItemProps>(function AccountMenuItem(props, ref) {
  const { isHighlighted, item, ...rest } = props

  return (
    <ListItem
      ref={ref}
      as="li"
      backgroundColor={isHighlighted ? 'gray.100' : undefined}
      rounded="md"
      cursor="pointer"
      {...rest}
    >
      <Flex as="a" href={accountPath(item as unknown as Account)} gap={2.5} padding={2} alignItems="center">
        <CompanyAvatar name={item.company?.name} domain={item.company?.domain} size="xs" />
        <Flex gap={1.5} alignItems="baseline">
          <Text fontSize="sm" fontWeight="medium">
            {item.company?.name || item.company?.domain}
          </Text>
          {item.company?.name && item.company?.domain && (
            <Text color="gray.500" fontSize="sm">
              ({item.company.domain})
            </Text>
          )}
        </Flex>
      </Flex>
    </ListItem>
  )
})

type ProfileMenuItemProps = Omit<MenuItemProps, 'item'> & { item: ProfileItem }
const ProfileMenuItem = React.forwardRef<HTMLLIElement, ProfileMenuItemProps>(function ProfileMenuItem(props, ref) {
  const { isHighlighted, item, ...rest } = props
  return (
    <ListItem
      ref={ref}
      as="li"
      backgroundColor={isHighlighted ? 'gray.100' : undefined}
      rounded="md"
      cursor="pointer"
      {...rest}
    >
      <Flex
        as="a"
        href={projectPath(`/profiles/${item.email || item.id}`)}
        gap={2.5}
        padding={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <StatusAvatar name={item.name} email={item.email} size={'xs'} status={item.status} src={item.avatar} />

        <Flex flex="1 1 auto" gap={1.5} alignItems="baseline">
          <Text fontSize="sm" fontWeight="medium">
            {item.name || item.display_name || item.anonymous_name || 'Unknown User'}
          </Text>
          {(item.email || item.title) && (
            <Text fontSize="sm" color="gray.500">
              &ndash; {item.title || item.email}
            </Text>
          )}
        </Flex>

        <CompanyAvatar name={item.company?.name} domain={item.company?.domain} size="20px" />
      </Flex>
    </ListItem>
  )
})

type RouteMenuItemProps = Omit<MenuItemProps, 'item'> & { item: RouteItem }
const RouteMenuItem = React.forwardRef<HTMLLIElement, RouteMenuItemProps>(function RouteMenuItem(props, ref) {
  const { isHighlighted, item, ...rest } = props
  const isExternal = item.href?.startsWith('http')
  let icon: React.ReactElement = <IconArrowRight size={16} />

  if (item.icon) {
    icon = item.icon
  } else if (item.href?.startsWith('/admin/')) {
    icon = <IconLock size={16} />
  } else if (item.href?.includes('/settings') || item.breadcrumbs?.includes('Settings')) {
    icon = <IconSettings size={16} />
  } else if (item.href?.includes('getkoala.com/docs')) {
    icon = <IconBook size={16} />
  } else if (isExternal) {
    icon = <IconArrowUpRight size={16} />
  }

  return (
    <ListItem
      ref={ref}
      as="li"
      backgroundColor={isHighlighted ? 'background.highlight' : undefined}
      rounded="md"
      cursor="pointer"
      {...rest}
    >
      <Flex
        as="a"
        href={item.href}
        target={isExternal ? '_blank' : undefined}
        alignItems="center"
        paddingX={2}
        paddingY={1.5}
        gap={2.5}
      >
        <Square
          display="flex"
          justifyContent="center"
          alignItems="center"
          size={6}
          bg="background.highlight"
          rounded="md"
        >
          {icon}
        </Square>
        <Flex direction="column">
          <HStack
            fontSize="sm"
            fontWeight="medium"
            spacing={1}
            divider={<Icon as={IconChevronRight} border="none" boxSize={4} color="gray.400" />}
          >
            {item.breadcrumbs?.map((crumb) => (
              <Text key={crumb} color="gray.600">
                {crumb}
              </Text>
            ))}
            <Text fontWeight={'semibold'}>{item.title}</Text>
          </HStack>
        </Flex>
      </Flex>
    </ListItem>
  )
})

type AccountViewMenuItemProps = Omit<MenuItemProps, 'item'> & { item: AccountViewItem }
const AccountViewMenuItem = React.forwardRef<HTMLLIElement, AccountViewMenuItemProps>(function AccountViewMenuItem(
  props,
  ref
) {
  const { isHighlighted, item, ...rest } = props
  const isAccountView = item.kind === 'account'

  return (
    <ListItem
      ref={ref}
      as="li"
      backgroundColor={isHighlighted ? 'gray.100' : undefined}
      rounded="md"
      cursor="pointer"
      {...rest}
    >
      <Flex as="a" href={accountViewPath(item as unknown as AccountView)} gap={2.5} padding={2} alignItems="center">
        <Square
          display={['none', 'none', 'flex']}
          size={6}
          rounded="md"
          color="white"
          bgGradient={isAccountView ? 'linear(to-br, purple.300, purple.600)' : 'linear(to-br, blue.200, blue.600)'}
        >
          {isAccountView ? <BuildingIcon size={14} /> : <IconUsers size={14} />}
        </Square>
        <Text fontSize="sm" fontWeight={'semibold'}>
          {item.name}
        </Text>
      </Flex>
    </ListItem>
  )
})

type GroupedItem = {
  type: Item['type']
  items: Array<Item & { id: string }>
}

type Grouped = GroupedItem[]

function flatten(grouped: Grouped): Item[] {
  return grouped.reduce((items, currentItem) => {
    return [...items, ...currentItem.items]
  }, [] as Item[])
}

function rollup(items: Item[]): Grouped {
  const grouped = groupBy(items, (item) => item.type)
  return Object.keys(grouped).map((key) => {
    return {
      type: key,
      items: grouped[key]
    }
  }) as unknown as Grouped
}

function Omnisearch({ onClose }) {
  return (
    <Modal size="xl" isOpen onClose={onClose} preserveScrollBarGap>
      <ModalOverlay />
      <ModalContent overflow="hidden">
        <OmnisearchBody onClose={onClose} />
      </ModalContent>
    </Modal>
  )
}

function getResultCount(data: OmnisearchData | undefined, filteredRoutes: RouteItem[]) {
  if (!data?.results) {
    return 0
  }

  return (
    (data.results?.profiles?.length || 0) +
    (data.results?.accounts?.length || 0) +
    (data.results?.views?.length || 0) +
    (data.results?.projects?.length || 0) +
    filteredRoutes.length
  )
}

function OmnisearchBody({ onClose }) {
  const [selectedCategory, setSelectedCategory] = React.useState<string | null>()
  const [groups, setGroups] = React.useState<Grouped>([])

  const { getInputProps, inputValue, highlightedIndex, getComboboxProps, getItemProps, getMenuProps } = useCombobox({
    isOpen: true,
    items: flatten(groups),
    scrollIntoView: (node, _menuNode) => {
      node.scrollIntoView({
        block: 'nearest',
        // @ts-expect-error untyped
        behavior: 'instant'
      })
    },
    itemToString: (item) => {
      if (isAccountItem(item)) {
        return item.company?.name || ''
      }

      if (isProfileItem(item)) {
        return item.name as string
      }

      return ''
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (isProfileItem(selectedItem)) {
        Router.visit(projectPath(`/profiles/${selectedItem.id}`))
      }

      if (isAccountItem(selectedItem)) {
        Router.visit(accountPath(selectedItem as unknown as Account))
      }

      if (isAccountViewItem(selectedItem)) {
        Router.visit(accountViewPath(selectedItem as unknown as AccountView))
      }

      if (isProjectItem(selectedItem)) {
        Router.visit(`/projects/${selectedItem.slug}`)
      }

      if (isRouteItem(selectedItem)) {
        if (typeof selectedItem.onClick === 'function') {
          selectedItem.onClick()
        }

        if (selectedItem.href?.startsWith('http')) {
          window.open(selectedItem.href, '_blank')
        } else if (selectedItem.href) {
          Router.visit(selectedItem.href)
        }
      }

      onClose()
    }
  })

  const [query] = useDebounce(inputValue, 300)
  const { data, isLoading } = useOmnisearch(query, { model: selectedCategory ? model[selectedCategory] : null })

  const routes = useRoutes()
  const fuse = useMemo(
    () =>
      new Fuse(
        routes.filter((r) => !r.hide),
        { minMatchCharLength: 2, threshold: 0.3, keys: ['title', 'href', 'breadcrumbs', 'tags'] }
      ),
    [routes]
  )

  const filteredRoutes = useMemo(() => {
    return fuse
      .search(query)
      .slice(0, 8)
      .map((r) => ({ ...r.item, id: r.item.title + r.item.href, type: 'navigation' })) as RouteItem[]
  }, [query, fuse])

  React.useEffect(() => {
    const profiles: ProfileItem[] = []
    const accounts: AccountItem[] = []
    const views: AccountViewItem[] = []
    const projects: ProjectItem[] = []

    if (isLoading) {
      return
    }

    if (!data) {
      setGroups(rollup(filteredRoutes))
      return
    }

    if (data.results?.profiles && Array.isArray(data.results.profiles)) {
      data.results.profiles.forEach((profile) => {
        profiles.push({ ...profile, type: 'people' })
      })
    }

    if (data.results?.accounts && Array.isArray(data.results.accounts)) {
      data.results.accounts.forEach((account) => {
        accounts.push({ ...account, type: 'account' })
      })
    }

    if (data.results?.views && Array.isArray(data.results.views)) {
      data.results.views.forEach((profile) => {
        views.push({ ...profile, type: 'list' })
      })
    }

    if (data.results?.projects && Array.isArray(data.results.projects)) {
      data.results.projects.forEach((project) => {
        projects.push({ ...project, type: 'project' })
      })
    }

    const grouped = rollup([
      ...filteredRoutes,
      ...uniqBy(accounts, (account) => account.company_id),
      ...uniqBy(profiles, (profile) => profile.id),
      ...uniqBy(views, (view) => view.id),
      ...uniqBy(projects, (project) => project.id)
    ])

    setGroups(grouped)
  }, [isLoading, data, filteredRoutes])

  // we only want to run this is the results changed
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const totalResults = useMemo(() => getResultCount(data, filteredRoutes), [data?.results, filteredRoutes])

  React.useEffect(() => {
    if (data?.query) {
      const resultCount = getResultCount(data, filteredRoutes)
      window.ko?.track('Omnisearch Query', { query: data.query, result_count: resultCount })
    }
    // we only want to run this if the query changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.query])

  return (
    <Flex width="100%" direction="column" zIndex="modal" {...getComboboxProps()}>
      <InputGroup borderBottom="1px solid" borderColor="gray.200">
        <InputLeftElement pointerEvents="none" color="gray.500" width={12} height={12}>
          <SearchIcon size={20} />
        </InputLeftElement>
        <Input
          {...getInputProps()}
          placeholder="Search for visitors, accounts, lists, pages…"
          variant="unstyled"
          height={12}
          paddingLeft={12}
          paddingRight={6}
        />
        {isLoading && (
          <InputRightElement pointerEvents="none" width={12} height={12}>
            <Spinner size="sm" thickness="1.5px" color="gray.400" />
          </InputRightElement>
        )}
      </InputGroup>
      <List
        {...getMenuProps()}
        maxHeight="min(600px, calc(80vh - 40px))"
        overflowY="auto"
        overscrollBehavior="contain"
        paddingX={2}
      >
        {(totalResults > 0 || selectedCategory) && (
          <Box paddingX={2} paddingY={1} fontSize="xs" color="gray.600" fontWeight="medium" marginTop={3}>
            <Flex gap={1.5}>
              {categories.map((category) => (
                <Box
                  key={`cat::${category}`}
                  fontSize="xs"
                  rounded="full"
                  paddingY={0.5}
                  paddingX={2}
                  border="1px solid"
                  cursor="pointer"
                  borderColor={selectedCategory === category ? 'purple.500' : 'gray.200'}
                  bg={selectedCategory === category ? 'purple.500' : 'white'}
                  color={selectedCategory === category ? 'white' : 'gray.600'}
                  onClick={() =>
                    selectedCategory === category ? setSelectedCategory(null) : setSelectedCategory(category)
                  }
                >
                  {sectionLabels[category] || category}
                </Box>
              ))}
            </Flex>
          </Box>
        )}
        {
          groups.reduce(
            (results, group) => {
              // skip if not the selected category
              if (selectedCategory && selectedCategory !== group.type) {
                return results
              }

              results.sections.push(
                <React.Fragment key={`${group.type}:header`}>
                  <Heading
                    paddingX={2}
                    paddingY={1}
                    fontSize="xs"
                    color="gray.600"
                    fontWeight="medium"
                    key={group.type}
                    marginTop={3}
                  >
                    {sectionLabels[group.type] || group.type}
                  </Heading>
                  {group.items.map((item) => {
                    const resultIndex = results.itemIndex++
                    return (
                      <MenuItem
                        key={`${group.type}:${item.id}`}
                        {...getItemProps({
                          item,
                          index: resultIndex,
                          onClick: (event) => {
                            // if the thing that was clicked was a link
                            // stop the event from propagating up to the combobox
                            const anchor = (event.target as HTMLElement).closest('a')
                            if (anchor && anchor.href && event.currentTarget.contains(anchor)) {
                              // @ts-expect-error untyped
                              event.preventDownshiftDefault = true

                              // close the menu unless opening in new tab
                              if (!(event.ctrlKey || event.metaKey || event.shiftKey)) {
                                onClose()
                              }
                            }
                          }
                        })}
                        item={item}
                        isHighlighted={resultIndex === highlightedIndex}
                      />
                    )
                  })}
                </React.Fragment>
              )

              return results
            },
            { sections: [] as JSX.Element[], itemIndex: 0 }
          ).sections
        }
      </List>
      {totalResults === 0 && inputValue && query === inputValue && !isLoading && (
        <Stack alignItems="center" paddingX={6} paddingY={10} spacing={3}>
          <SquareIcon icon={SearchIcon} size={8} colorScheme="gray" />
          <Text fontSize="sm" color="gray.700">
            We couldn't find anything for "{inputValue}"
          </Text>
        </Stack>
      )}
      <Flex as="footer" justifyContent="flex-end" alignItems="center" paddingY={2} paddingX={4} gap={1}>
        <Kbd fontSize="xs">{isMacOS() ? '⌘' : 'Ctrl'}</Kbd>
        <Kbd fontSize="xs">K</Kbd>
        <Text fontSize="xs" color="gray.500">
          to search from anywhere
        </Text>
      </Flex>
    </Flex>
  )
}

export default Omnisearch
