import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Switch,
  Tooltip
} from '@chakra-ui/react'
import { IconArrowRight, IconDragDrop2 } from '@tabler/icons-react'
import { arrayMoveImmutable } from 'array-move'
import React, { useEffect, useMemo, useState } from 'react'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { useAppDep } from '../../../../data/use-app-dep'
import { Card } from '../../../../ui/Card'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import { SignalType } from '../../../kql_definitions/components/SignalType'
import { KqlDefinition } from '../../../kql_definitions/types'
import { SalesforceActionProps } from './salesforce-setup'

interface SFCampaign {
  Id: string
  Name: string
}

export function AddToCampaign(
  props: SalesforceActionProps & {
    kqlDefinitions?: KqlDefinition[]
  }
) {
  const campaigns = useAppDep<'campaigns', SFCampaign>('salesforce', 'campaigns')
  const options = useMemo(() => (campaigns.data?.data?.campaigns ?? []) as SFCampaign[], [campaigns.data])

  const [enabled, setEnabled] = useState(Boolean(props.delivery_rules?.salesforce?.add_to_campaign?.enabled))
  const [selectedCampaignId, setSelectedCampaignId] = useState(props.delivery_rules?.salesforce?.add_to_campaign?.id)
  const [campaignOption, setCampaignOption] = React.useState(
    props.delivery_rules?.salesforce?.add_to_campaign?.strategy ?? 'single'
  )

  const contactOrLeadEnabled = useMemo(
    () =>
      props.delivery_rules?.salesforce?.import_contact?.enabled ||
      props.delivery_rules?.salesforce?.import_lead?.enabled,
    [props.delivery_rules?.salesforce]
  )

  return (
    <Stack w="100%">
      <FormControl>
        <Switch
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.delivery_rules,
              salesforce: {
                ...props.delivery_rules?.salesforce,
                add_to_campaign: {
                  ...props.delivery_rules?.salesforce?.add_to_campaign,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={enabled ? 'purple' : 'gray'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][salesforce][add_to_campaign][enabled]"
        >
          Enable
        </Switch>
        <FormHelperText>
          Add the current Visitor as a Lead or Contact to a campaign in Salesforce. (Requires an identified visitor.)
        </FormHelperText>
      </FormControl>

      {enabled && (
        <Stack spacing={'8'}>
          <Divider />

          {enabled && !contactOrLeadEnabled && (
            <Alert status="warning" fontSize="sm" bg="orange.50">
              <Stack>
                <HStack spacing="0">
                  <AlertIcon />
                  <AlertTitle>Campaigns work better when importing Contacts</AlertTitle>
                </HStack>
                <AlertDescription lineHeight={1.4}>
                  Enabling either the "Import Contact" or "Import Lead" actions will gurantee that the prospect is
                  exists in Salesforce before adding them to a campaign. Otherwise, Koala will only add prospects that
                  already exist in Salesforce.
                </AlertDescription>
              </Stack>
            </Alert>
          )}

          {props.kqlDefinitions && (
            <Stack>
              <Heading size="xs">Campaign Strategy</Heading>
              <RadioGroup
                size="sm"
                defaultValue={campaignOption}
                onChange={(e) => setCampaignOption(e as 'single' | 'custom')}
                name="follow_rule[delivery_rules][salesforce][add_to_campaign][strategy]"
              >
                <Stack spacing="0.5">
                  <Radio bg="white" value="single">
                    Single Campaign
                  </Radio>
                  <Radio bg="white" value="custom">
                    Custom per Intent Signal
                  </Radio>
                </Stack>
              </RadioGroup>
            </Stack>
          )}

          {campaignOption === 'single' && (
            <Stack>
              <FormLabel mb="0">Salesforce Campaign</FormLabel>

              <Flex>
                <HStack w="100%">
                  <ComboboxWithSearch
                    items={options}
                    selectedItem={options.find((o) => o.Id === selectedCampaignId)}
                    onChange={(camp) => setSelectedCampaignId(camp?.Id)}
                    filterItem={(a, val) => (a?.Name ?? '').toLowerCase().includes(val)}
                    itemToString={(camp) => camp?.Name ?? ''}
                  />
                  {campaigns.isLoading && <Spinner size="sm" />}
                </HStack>
                {selectedCampaignId && (
                  <input
                    type="hidden"
                    name="follow_rule[delivery_rules][salesforce][add_to_campaign][id]"
                    value={selectedCampaignId}
                  />
                )}
              </Flex>
            </Stack>
          )}

          {campaignOption === 'custom' && (
            <IntentSignalPairs
              campaigns={options}
              mappings={props.delivery_rules?.salesforce?.add_to_campaign?.mappings ?? []}
              kqlDefinitions={props.kqlDefinitions ?? []}
            />
          )}

          {props.kqlDefinitions && campaignOption === 'single' && (
            <FormHelperText>Every prospect will be added into the same campaign</FormHelperText>
          )}

          {campaignOption === 'custom' && (
            <FormHelperText>Each prospect will be added to a specific campaign depending on intent.</FormHelperText>
          )}
        </Stack>
      )}
    </Stack>
  )
}

interface IntentCampaignMapping {
  campaign_id?: string
  kql_definition_id: string
}

interface IntentSignalPairsProps {
  kqlDefinitions: KqlDefinition[]
  mappings: IntentCampaignMapping[]
  campaigns?: SFCampaign[]
}

function IntentSignalPairs(props: IntentSignalPairsProps) {
  const mappings = useMemo(() => {
    const maps = props.mappings ?? []

    const mapped = props.kqlDefinitions.reduce((acc, kqlDef) => {
      const map = maps.find((m) => m.kql_definition_id === kqlDef.id)

      acc.push({
        kql_definition_id: kqlDef.id!,
        campaign_id: map?.campaign_id
      })

      return acc
    }, [] as IntentCampaignMapping[])

    // sort mapped by the positions in props.mappings
    return mapped.sort((a, b) => {
      const aIndex = props.mappings.findIndex((m) => m.kql_definition_id === a.kql_definition_id)
      const bIndex = props.mappings.findIndex((m) => m.kql_definition_id === b.kql_definition_id)

      return aIndex - bIndex
    })
  }, [props.mappings, props.kqlDefinitions])

  const [localMappings, setLocalMappings] = useState<IntentCampaignMapping[]>(mappings)

  useEffect(() => {
    setLocalMappings(mappings)
  }, [mappings])

  return (
    <>
      <Stack>
        <Heading size="xs">Pair each Intent Signal to a Campaign</Heading>
        <SortableList
          onSortEnd={(oldIndex: number, newIndex: number) => {
            setLocalMappings((array) => arrayMoveImmutable(array, oldIndex, newIndex))
          }}
          draggedItemClassName="dragged"
          style={{
            userSelect: 'none'
          }}
        >
          <Stack>
            {localMappings.map((entry) => {
              const kqlDefinition = props.kqlDefinitions.find((def) => def.id === entry.kql_definition_id)
              if (!kqlDefinition?.id) {
                return null
              }

              const campaignId = entry.campaign_id

              return (
                <SortableItem key={kqlDefinition.id}>
                  <HStack w="100%">
                    <SortableKnob>
                      <Box cursor={'grab'}>
                        <Tooltip label="Drag to change priorities" placement="top">
                          <IconDragDrop2 color="gray" size="14" />
                        </Tooltip>
                      </Box>
                    </SortableKnob>
                    <IntentCampaignPair
                      campaigns={props.campaigns ?? []}
                      kqlDefinition={kqlDefinition}
                      campaignId={campaignId}
                    />
                  </HStack>
                </SortableItem>
              )
            })}
          </Stack>
        </SortableList>
      </Stack>
    </>
  )
}

interface IntentSignalPairProps {
  kqlDefinition: KqlDefinition
  campaignId?: SFCampaign['Id']
  campaigns: SFCampaign[]
}

function IntentCampaignPair(props: IntentSignalPairProps) {
  const [selectedCampaignId, setSelectedCampaignId] = React.useState<SFCampaign['Id'] | null>(props.campaignId ?? null)
  const kqlDefinition = props.kqlDefinition

  return (
    <>
      <HStack w="100%">
        <Flex bg="white" as={Card} p="2" flex="1" fontWeight={'normal'}>
          <SignalType
            label={`${kqlDefinition.name}${kqlDefinition.enabled === false ? ' (paused)' : ''}`}
            signalType={kqlDefinition.signal_type}
            compact
          />
        </Flex>
        <IconArrowRight size="14" />
        <Flex w="100%" flex="1">
          <CampaignSelector
            campaigns={props.campaigns}
            setSelectedCampaignId={setSelectedCampaignId}
            selectedCampaignId={selectedCampaignId}
          />
        </Flex>
      </HStack>
      {selectedCampaignId && (
        <>
          <input
            type={'hidden'}
            name={`follow_rule[delivery_rules][salesforce][add_to_campaign][mappings][][campaign_id]`}
            value={selectedCampaignId}
          />
          <input
            type={'hidden'}
            name={`follow_rule[delivery_rules][salesforce][add_to_campaign][mappings][][kql_definition_id]`}
            value={kqlDefinition.id}
          />
        </>
      )}
    </>
  )
}

interface CampaignSelectorProps {
  selectedCampaignId?: SFCampaign['Id'] | null
  setSelectedCampaignId: (id: SFCampaign['Id'] | null) => void
  campaigns: SFCampaign[]
}

function CampaignSelector(props: CampaignSelectorProps) {
  const campaigns = useMemo(() => {
    return props.campaigns ?? []
  }, [props.campaigns])

  const selectedItem = useMemo(
    () =>
      campaigns.find((s) => {
        return s.Id.toString() === props.selectedCampaignId?.toString()
      }) ?? null,
    [campaigns, props.selectedCampaignId]
  )

  return (
    <>
      <HStack w="100%">
        <ComboboxWithSearch
          items={campaigns}
          selectedItem={selectedItem}
          onChange={(selectedItem) => {
            props.setSelectedCampaignId(selectedItem?.Id ?? null)
          }}
          filterItem={(a, val) => a.Name.toLowerCase().includes(val)}
          itemToString={(item) => `[${item?.Id}] ${item?.Name || ''}`}
        />
      </HStack>
    </>
  )
}
