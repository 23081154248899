import EmptyState from '@app/components/ui/EmptyState'
import { TableFooter } from '@app/components/ui/TableFooter'
import { Account } from '@app/types/Account'
import { AccountView } from '@app/types/AccountView'
import { Apps } from '@app/types/App'
import { ProjectActions } from '@app/types/AppActions'
import { Crm } from '@app/types/Crm'
import { PageMeta } from '@app/types/PageMeta'
import { AvatarGroup, Box, Divider, Flex, Icon, Text, useDisclosure } from '@chakra-ui/react'
import { IconChevronRight, IconCrosshair } from '@tabler/icons-react'
import startCase from 'lodash/startCase'
import React, { useCallback, useMemo } from 'react'
import router from '../../../lib/router'
import { useUrlFilters } from '../../data/use-url-filters'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { defaultAccountColumns, defaultProfileColumns, useColumns } from '../../ui/ColumnSelector'
import { TopBarContent } from '../../ui/TopBarContext'
import { projectPath } from '../../ui/ProjectsContext'
import useLocation from '../../ui/useLocation'
import useTitle from '../../ui/useTitle'
import useUpdateEffect from '../../ui/useUpdateEffect'
import { PartialAccountView, ViewSelector } from '../../ui/ViewSelector'
import { FacetFilters } from '../accounts'
import { AccountList } from '../icps/icp/account-list'
import { HighlightedAccount, mergeParams } from '../icps/types'
import { ListContainer } from '../lists/components/ListContainer'
import { HighlightedProfile, ProfileList } from '../profiles/components/profile-list'
import { NewListModal } from './components/NewListModal'
import ViewDetails from './components/ViewDetails'
import { accountViewPath } from './lib/list-paths'
import { MCHeader } from '../mission_control/Header'
import Avatar from '../../ui/Avatar'
import { HelpTooltip } from '../../ui/HelpTooltip'
import { UserSelect } from '../mission_control/UserSelect'
import { usePermission } from '../../ui/PermissionsContext'
import { useCurrentUser } from '../../ui/UserContext'
import { SpaceHeader } from '../spaces/components/SpaceHeader'
import { useTrackRecentNavItems } from '../navigation/useTrackRecentNavItems'

type AccountWithStatus = Account & { status?: null | 'archived' | 'prospect' }

export interface AccountViewShowProps {
  project_actions: ProjectActions
  crm?: Crm
  apps: Apps
  columns: string[]
  counts: Record<string, number>
  page_meta: PageMeta
  account_view: AccountView
  permissions: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
  accounts: Array<AccountWithStatus | HighlightedProfile>
  facet_filters?: FacetFilters
  range?: AccountView['filters']['range']
  sort_by?: string
}

export default function Show(props: AccountViewShowProps) {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const currentUser = useCurrentUser()
  const [selectedUserId, setSelectedUserId] = React.useState<string | null>(params.get('user') || currentUser.id!)
  const { hasPermission: canViewAsMember } = usePermission({ on: 'project', action: 'can_view_as_member' })

  useUpdateEffect(() => {
    const user = selectedUserId && selectedUserId !== currentUser.id ? selectedUserId : undefined
    const params = new URLSearchParams(window.location.search)
    const existing = params.get('user') || undefined

    if (user !== existing) {
      const path = mergeParams(window.location.toString(), { user })
      router.visit(path)
    }
  }, [selectedUserId, currentUser.id])

  const facets = useUrlFilters({
    initialRange: props.account_view.filters.range ?? 'month',
    initialFacets: props.account_view.filters.facets,
    initialFocusTime: props.account_view.filters.focus_time,
    initialSortBy: props.account_view.filters.sort_by || props.sort_by,
    facetCloudPath: props.account_view.kind === 'profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud'
  })

  const profiles = props.accounts as HighlightedProfile[]
  const accounts = props.accounts as HighlightedAccount[]

  const entityType = props.account_view.kind === 'profile' ? 'visitor' : 'account'

  const apps = useMemo(() => Object.values(props.apps), [props.apps])

  const defaultColumns = props.account_view?.kind === 'profile' ? defaultProfileColumns : defaultAccountColumns

  const { columns, loadingColumns, onColumnChange, onColumnRemove } = useColumns({
    data: props.accounts,
    columns: props.columns?.length ? props.columns : defaultColumns.map((c) => c.key),
    initialColumns: props.columns?.length ? props.columns : defaultColumns.map((c) => c.key)
  })

  const columnsChanged = useMemo(() => {
    // check that the columns are not the defaults / default order
    const persistedColumns = props.account_view?.display_settings?.display_columns
    const defaults = (props.account_view?.kind === 'profile' ? defaultProfileColumns : defaultAccountColumns).map(
      (c) => c.key
    )

    if (!persistedColumns) {
      return defaults.join(',') !== columns.join(',')
    } else {
      return persistedColumns.join(',') !== columns.join(',')
    }
  }, [columns, props.account_view])

  const { trackRecentNavItem } = useTrackRecentNavItems()

  const changeView = useCallback(
    (accountView: PartialAccountView | null) => {
      if (accountView && accountView.id !== props.account_view.id) {
        const path = accountViewPath(accountView)
        if (accountView.id) {
          trackRecentNavItem(`accountView:${accountView.id}`)
        }
        router.visit(path)
      }
    },
    [props.account_view, trackRecentNavItem]
  )

  const newListModal = useDisclosure()
  const onMissionControl = window.location.pathname.includes('/mission-control')
  const hasCurrentUserFilter = JSON.stringify(props.facet_filters || {}).includes('Current user')

  useTitle(`${props.account_view.name} | ${startCase(entityType)} List | Koala`)

  return (
    <ListContainer
      paddingY={props.account_view.space_id ? 0 : undefined}
      paddingX={props.account_view.space_id ? 0 : undefined}
    >
      <form
        action={location.pathname + location.search}
        method="POST"
        onSubmit={(e) => {
          if (!props.permissions.can_edit) {
            e.preventDefault()
          }
        }}
      >
        <input type="hidden" value="PATCH" name="_method" />
        <AuthenticityToken />

        <Flex direction="column" paddingTop={props.account_view?.space_id ? 2 : 0}>
          {onMissionControl ? (
            <MCHeader space={props.account_view.space} viewId={props.account_view.id} apps={props.apps} />
          ) : props.account_view.space ? (
            <SpaceHeader space={props.account_view.space} viewId={props.account_view.id} apps={props.apps} />
          ) : (
            <TopBarContent px={[4, 5]}>
              <Flex width="100%" alignItems="center" justifyContent="space-between" gap={4}>
                <Flex alignItems="center" gap={1.5}>
                  <Breadcrumb
                    paths={[
                      props.account_view.team
                        ? {
                            path: projectPath(`/settings/teams/${props.account_view.team.id}`),
                            title: props.account_view.team.name,
                            isCurrentPage: false
                          }
                        : {
                            path: projectPath(props.account_view.kind === 'profile' ? '/visitors' : '/accounts'),
                            title: props.account_view.kind === 'profile' ? 'Visitors' : 'Accounts',
                            isCurrentPage: false
                          }
                    ]}
                    display={['none', 'flex']}
                  />

                  {props.account_view.id && (
                    <>
                      <Icon as={IconChevronRight} color="gray.400" boxSize={4} display={['none', 'flex']} />

                      <Box>
                        <ViewSelector
                          selectedAccountViewId={props.account_view.id}
                          kind={
                            props.account_view.ownership === 'team' || props.account_view.ownership === 'space'
                              ? undefined
                              : props.account_view.kind
                          }
                          teamId={props.account_view.team_id}
                          spaceId={props.account_view.space_id}
                          ownership={['private', 'team', 'shared']}
                          includePrivate
                          onChange={changeView}
                          onCreateAccountview={newListModal.onOpen}
                        />

                        <NewListModal
                          {...newListModal}
                          teamId={props.account_view.team_id}
                          spaceId={props.account_view.space_id}
                        />
                      </Box>
                    </>
                  )}
                </Flex>
                <Flex alignItems="center" gap={2}>
                  {canViewAsMember && props.account_view.ownership === 'team' && hasCurrentUserFilter && (
                    <>
                      <Flex alignItems="center" justifyContent="center" gap={1}>
                        <Text fontSize="sm" color="gray.600">
                          Viewing as
                        </Text>
                        <UserSelect
                          selectedUserId={selectedUserId || currentUser!.id}
                          onChange={setSelectedUserId}
                          isReadOnly={!canViewAsMember}
                          includeCrmUsers
                        />
                      </Flex>
                      <Divider orientation="vertical" h={6} borderColor="gray.300" />
                    </>
                  )}
                  {(props.account_view.team?.members ?? []).length > 0 && (
                    <AvatarGroup size="xs" fontSize="xs" max={4} spacing={-1.5}>
                      {props.account_view.team?.members?.map((member) => (
                        <Avatar
                          key={member.email}
                          src={member.image}
                          name={member.name || member.email}
                          borderWidth="2px"
                          boxSizing="content-box"
                        />
                      ))}
                    </AvatarGroup>
                  )}
                  {['shared', 'team'].includes(props.account_view.ownership || 'unknown') && (
                    <HelpTooltip variant="info" placement="left">
                      This is a shared list. Modifying it will update it for everyone.
                    </HelpTooltip>
                  )}
                </Flex>
              </Flex>
            </TopBarContent>
          )}

          <ViewDetails
            apps={props.apps}
            counts={props.counts}
            account_view={props.account_view}
            columns={columns}
            onColumnChange={onColumnChange}
            columnsChanged={columnsChanged}
            permissions={props.permissions}
            hideMenu={['my_accounts', 'trending_accounts'].includes(props.account_view.view_type || 'unknown')}
            facets={facets}
            defaults={{
              range: 'month',
              sort_by: props.sort_by
            }}
          />

          {props.accounts.length === 0 && (
            <EmptyState
              size="md"
              heading={`No ${entityType}s match these filters`}
              description="Adjust your filters or check back later"
              icon={IconCrosshair}
            />
          )}

          {props.accounts.length > 0 && (
            <Box px={[4, 5]}>
              {props.account_view.kind === 'profile' && (
                <ProfileList
                  facetParams={facets}
                  profiles={profiles}
                  range={props.range ?? 'all'}
                  apps={apps}
                  crm={props.crm}
                  columns={columns}
                  sortingBy={facets.sortBy}
                  canAddColumns
                  onColumnChange={onColumnChange}
                  onColumnRemove={onColumnRemove}
                  onSortChange={facets.setSortBy}
                />
              )}
              {props.account_view.kind === 'account' && (
                <AccountList
                  accounts={accounts}
                  facetParams={facets}
                  range={props.range ?? 'all'}
                  apps={apps}
                  crm={props.crm}
                  columns={columns}
                  loadingColumns={loadingColumns}
                  sortingBy={facets.sortBy}
                  canAddColumns
                  onColumnChange={onColumnChange}
                  onColumnRemove={onColumnRemove}
                  onSortChange={facets.setSortBy}
                  onRefresh={() => {
                    router.visit(location.toString(), {
                      fetch: true
                    })
                  }}
                />
              )}
              <TableFooter
                word={entityType}
                pageMeta={props.page_meta}
                page={props.page_meta.current_page}
                prevPath={mergeParams(window.location.toString(), {
                  page: props.page_meta.prev_page?.toString() ?? '1'
                })}
                nextPath={mergeParams(window.location.toString(), {
                  page: props.page_meta.next_page?.toString() ?? '1'
                })}
                sticky
              />
            </Box>
          )}
        </Flex>
      </form>
    </ListContainer>
  )
}
