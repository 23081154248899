import { Badge, Divider, Flex, Grid, Stack, Text } from '@chakra-ui/react'
import React, { useMemo, useState } from 'react'
import { CRMAccount, CRMMatch } from '../../../../types/Account'
import { StackedField } from '../../../ui/StackedField'
import { TimeAgo } from '../../../ui/TimeAgo'
import { useCurrentUser } from '../../../ui/UserContext'
import { SearchBar } from '../facets/search-bar'
import { Toggle } from './Toggle'

interface Props {
  crmEntity: CRMAccount
  strongMatches: CRMMatch[]
}

export function CRMDebugPanel(props: Props) {
  const user = useCurrentUser()
  if (!user.isInternalUser) {
    return null
  }

  return (
    <Stack spacing="4">
      <Divider />
      <Stack spacing="1">
        <Badge size="xs" colorScheme="orange">
          Internal
        </Badge>

        <CRMProperties {...props} />
        <ExtraCRMMatches {...props} />
      </Stack>
    </Stack>
  )
}

export function CRMProperties(props: Props) {
  const user = useCurrentUser()
  const crmEntity = props.crmEntity
  const [filter, setFilter] = useState('')

  const orderedKeys = useMemo(
    () => (props.crmEntity.payload ? Object.keys(props.crmEntity.payload).sort() : []),
    [props.crmEntity.payload]
  )

  const filteredKeys = useMemo(
    () =>
      orderedKeys.filter(
        (key) =>
          key.toLowerCase().includes(filter.toLowerCase()) ||
          crmEntity.payload?.[key]?.toString().toLowerCase().includes(filter.toLowerCase())
      ),
    [filter, orderedKeys, crmEntity.payload]
  )

  if (!crmEntity.payload) {
    return null
  }

  if (!user.isInternalUser) {
    return null
  }

  return (
    <Toggle
      showIcon={false}
      title={(isOpen) => (
        <Flex justifyContent={'flex-end'} w="100%">
          <Text fontSize="xs">{isOpen ? 'Show less (-)' : 'View all properties (+)'}</Text>
        </Flex>
      )}
    >
      <Stack spacing={4} w="100%" p="1">
        <SearchBar
          value={filter}
          onChange={setFilter}
          placeholder="Search properties or values"
          collapsible={false}
          size="xs"
        />
        <Stack maxH="400px" overflow={'auto'}>
          {filteredKeys.map((key) => {
            const value = crmEntity.payload?.[key]
            return (
              <StackedField key={key} label={key}>
                {typeof value === 'object' ? JSON.stringify(value) : value}
              </StackedField>
            )
          })}
        </Stack>
      </Stack>
    </Toggle>
  )
}

export function ExtraCRMMatches(props: Props) {
  const totalMatches = useMemo(() => props.strongMatches.length, [props.strongMatches])
  const extraMatches = useMemo(() => props.strongMatches.slice(1), [props.strongMatches])
  if (totalMatches <= 1) {
    return null
  }

  return (
    <Toggle
      showIcon={false}
      title={(isOpen) => (
        <Flex justifyContent={'flex-end'} w="100%">
          <Text fontSize="xs">{isOpen ? 'Show less (-)' : `${totalMatches - 1} more account matches (+)`}</Text>
        </Flex>
      )}
    >
      <Stack spacing={4} w="100%" overflow="auto" maxH={'400px'}>
        {extraMatches.map((match) => (
          <Grid
            key={match.crm_entity_id}
            templateColumns="repeat(2, 1fr)"
            gap={4}
            bg="gray.50"
            p="2"
            rounded="md"
            borderWidth={'thin'}
          >
            <StackedField fontSize="xs" label={'Id'}>
              {match.crm_entity.crm_entity_id}
            </StackedField>
            <StackedField fontSize="xs" label={'Type'}>
              {match.crm_entity.account_type}
            </StackedField>
            <StackedField fontSize="xs" label={'Name'}>
              {match.crm_entity.name}
            </StackedField>
            <StackedField fontSize="xs" label={'Website'}>
              {match.crm_entity.website}
            </StackedField>

            <StackedField fontSize="xs" label="Account Owner">
              {match.crm_entity.account_owner}
            </StackedField>
            <StackedField fontSize="xs" label="Opportunity Stage">
              {match.crm_entity.stage_name?.join(',')}
            </StackedField>
            <StackedField fontSize="xs" label="Last Modified">
              <TimeAgo time={match.crm_entity.external_last_modified_at} mode="calendar" />
            </StackedField>
          </Grid>
        ))}
      </Stack>
    </Toggle>
  )
}
