import { Button, Divider, HStack, Link, Stack } from '@chakra-ui/react'
import React from 'react'
import { Account } from '../../../types/Account'
import { KQLEvent } from '../../../types/KQL'
import { PageMeta } from '../../../types/PageMeta'
import { Company, Identify, PageView, ProfileEvent, ProfileRecord } from '../../../types/Profile'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { FormSubmission } from '../forms/reports/submission-list'
import { VisitorFeed } from './components/Feed'

export type PageViewEntry = PageView & { type: 'PageView'; id: string; redacted?: boolean }
export type FormSubmissionEntry = FormSubmission & { type: 'FormSubmission'; id: string; redacted?: boolean }
export type IdentifyEntry = Identify & { type: 'Identify'; id: string; redacted?: boolean }
export type KQLEventEntry = KQLEvent & { type: 'KqlEvent'; id: string; redacted?: boolean }
export type ProfileEventEntry = ProfileEvent & { type: 'Event'; id: string; redacted?: boolean }
export type ProfileTrait = Trait<any> & { type: 'ProfileTrait'; id: string; redacted?: boolean }
export type AccountTrait = Trait<any> & { type: 'AccountTrait'; id: string; redacted?: boolean }
export type SummaryEntry = { type: 'Summary'; id: string }

export type Trait<T> = {
  id: number
  profile_id: string
  project_id: string
  name: string
  first_value: T
  current_value: T
  previous_value: T | null
  created_at: Date
  updated_at: Date
  identify_id: number
  type: 'ProfileTrait' | 'AccountTrait'
}

export type TimelineEntry = { search_highlight?: boolean } & (
  | PageViewEntry
  | ProfileTrait
  | AccountTrait
  | KQLEventEntry
  | ProfileEventEntry
  | FormSubmissionEntry
  | SummaryEntry
)

export interface Session {
  id: string
  session_id: number
  profile_id?: string
  project_id: string
  last_touched_at: Date | string
  created_at: Date
  updated_at: Date
  duration: number
  company: Company | null
  account?: Account | null
  profile?: ProfileRecord | null
  session_profiles?: ProfileRecord[]
  num_interactions: number
  num_pages?: number
  fuzzy_company_match?: boolean
  count: number
  kind: 'Profile' | 'Account'
  intent_signals?: KQLEventEntry[]
  highlights?: TimelineEntry[]
  sources?: Array<{ source: string; source_label?: string; param: string; minimal_search?: string }>
  limited?: boolean
  record_type?: "DailyProfileFeed"
  timestamp?: string
  source?: string
}

export interface AccountEventSession {
  id: string
  project_id: string
  source: string
  record_type: "AccountEvent"
  account_id: string
  timestamp: string
  properties: Record<string, any>
  event: string
  company_id: string
  company: Company
  last_touched_at?: string
  metadata?: Record<string, any>
}

export interface AccountKqlEventSession {
  id: string
  project_id: string
  account_id: string
  timestamp: string
  kql_definition_name: string
  target_type: 'Account',
  context: Record<string, any>
  company_id: string
  company: Company
  record_type: 'AccountKqlEvent'
  last_touched_at?: string
  source: string
}

export type FeedActivityEntries = AccountEventSession
  | AccountKqlEventSession
  | Session

interface Props {
  materialized_sessions: FeedActivityEntries[]
  page_meta: PageMeta
}

export default function Index(props: Props) {
  return (
    <PageLayout size="full" bg="gray.50">
      <Stack spacing={6} width="100%" maxW="1024px" marginX="auto">
        <PageTitle>Sessions</PageTitle>
        <HStack spacing={3} divider={<Divider orientation="vertical" h="4" />}>
          <Button
            isActive={!location.search.includes('account')}
            as={Link}
            variant={'link'}
            href={projectPath(`/sessions?type=visitor`)}
            _active={{
              color: 'purple.500'
            }}
          >
            Visitors
          </Button>

          <Button
            isActive={location.search.includes('account')}
            as={Link}
            variant={'link'}
            href={projectPath(`/sessions?type=account`)}
            _active={{
              color: 'purple.500'
            }}
          >
            Accounts
          </Button>
        </HStack>
      </Stack>
      <VisitorFeed {...props} />
    </PageLayout>
  )
}
