import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Stack,
  Text,
  Textarea
} from '@chakra-ui/react'
import { IconFilter, IconX } from '@tabler/icons-react'
import { uniq } from 'lodash'
import uniqBy from 'lodash/uniqBy'
import Papa from 'papaparse'
import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import { validEmail } from '../../../../lib/valid-email'
import { User, useUsers } from '../../../data/use-users'
import Avatar from '../../../ui/Avatar'
import { LightBgCard } from '../../../ui/Card'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { SegmentedControl } from '../../../ui/SegmentedControl'
import { SearchBar } from '../../accounts/facets/search-bar'
import { Team } from '../show'

export function UserPicker(props: { team: Team }) {
  const users = useUsers()
  const [selected, setSelected] = React.useState<User[]>([])
  const [mode, setMode] = React.useState<'search' | 'list'>('search')
  const [search, setSearch] = React.useState('')

  const allUsers = React.useMemo(() => {
    const userLike = (props.team.emails ?? []).map((email) => ({ email }) as User)
    return uniqBy((props.team.members ?? []).concat(userLike), 'email')
  }, [props.team.members, props.team.emails])

  const filtered = React.useMemo(() => {
    return selected.filter((u) => {
      return [u.name, u.email].join('').toLowerCase().includes(search.toLowerCase())
    })
  }, [search, selected])

  React.useEffect(() => {
    setSelected(allUsers)
  }, [allUsers])

  return (
    <FormControl id="team[emails]">
      <FormLabel>Add Members</FormLabel>

      <Stack spacing="4" w="100%">
        <SegmentedControl size="sm" display={'flex'} w="100%">
          <Button
            isActive={mode === 'search'}
            flex="1"
            onClick={() => {
              setMode('search')
            }}
          >
            Search
          </Button>
          <Button
            isActive={mode === 'list'}
            flex="1"
            onClick={() => {
              setMode('list')
            }}
          >
            Email List
          </Button>
        </SegmentedControl>

        {mode === 'search' && (
          <Stack pt="2">
            <Heading fontWeight={'normal'} size="xs">
              Search from your existing workspace members
            </Heading>
            <UserSearch users={users} selected={selected} setSelected={setSelected} />
          </Stack>
        )}
        {mode === 'list' && (
          <UserListInput users={users} setSelected={(users) => setSelected(uniqBy([...users, ...selected], 'email'))} />
        )}

        <Flex py="2" pb="4">
          <Divider />
        </Flex>

        {selected.length > 0 && (
          <Stack>
            <Text fontSize="xs" fontWeight={'semibold'} textTransform={'uppercase'} color="gray.500">
              Team Members ({selected.length})
            </Text>

            {(mode === 'list' || selected.length >= 5) && (
              <SearchBar
                searchIcon={<IconFilter size={12} />}
                inputProps={{
                  bg: mode === 'search' ? 'whiteAlpha.700' : 'white'
                }}
                collapsible={false}
                size="sm"
                placeholder="Filter members"
                value={search}
                onChange={(e) => setSearch(e)}
              />
            )}
            <Stack as={LightBgCard} bg="white" divider={<Divider />} p="4" spacing="3" maxH="300" overflow={'auto'}>
              {filtered.length === 0 && (
                <Text fontSize="xs" color="gray.500">
                  No members found
                </Text>
              )}

              {filtered.map((item) => (
                <Flex w="100%" key={item.email} px="1" alignItems={'center'} gap="2">
                  <input type="hidden" name="team[emails][]" value={item.email} />

                  <HStack flex="1" fontSize={'sm'}>
                    <Avatar size={'sm'} src={item.avatar} name={item.name} />
                    <Stack spacing="-1" flex="1">
                      <Text fontSize="sm">{item.name}</Text>
                      <Text fontSize={'xs'} color="gray.500">
                        {item.email}
                      </Text>
                    </Stack>
                    {!item.id && (
                      <HStack>
                        <Text fontSize={'xs'} color="gray.500">
                          Not in workspace
                        </Text>
                        <HelpTooltip>
                          This user is not in your Koala workspace. They will be invited to join when they sign up for
                          Koala.
                        </HelpTooltip>
                      </HStack>
                    )}
                  </HStack>

                  <IconButton
                    size={'xs'}
                    icon={<Icon as={IconX} />}
                    aria-label={'Remove'}
                    variant={'ghost'}
                    onClick={() => {
                      setSelected((prev) => prev.filter((u) => u.email !== item.email))
                    }}
                  />
                </Flex>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </FormControl>
  )
}

function UserListInput(props: { users: ReturnType<typeof useUsers>; setSelected: (users: User[]) => void }) {
  const { setSelected } = props
  const splitter = /[\s,\n]+/
  const [value, setValue] = React.useState('')

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': ['.csv']
    },
    autoFocus: true,
    multiple: false,
    noClick: true
  })

  React.useEffect(() => {
    const csvFileToArray = (rawEntries: string) => {
      const { data } = Papa.parse(rawEntries)
      const emails = uniq(data.map((v) => (v[0] ?? '').trim()).filter(validEmail))
      const userLike = emails.map((email) => ({ email }) as User)
      setSelected(userLike)
    }

    if (acceptedFiles[0]) {
      const fileReader = new FileReader()
      fileReader.onload = function (event) {
        if (!event.target?.result) {
          return
        }

        const text = event.target.result
        csvFileToArray(text as string)
      }

      fileReader.readAsText(acceptedFiles[0])
    }
  }, [acceptedFiles, setSelected])

  return (
    <Stack py="2" spacing="2" {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      <Textarea
        bg="white"
        size="sm"
        rounded="md"
        value={value}
        placeholder="Enter a list of emails separated by commas"
        rows={8}
        onChange={(e) => {
          setValue(e.target.value)
        }}
      />

      <Button
        colorScheme={'blue'}
        size="sm"
        onClick={() => {
          const emails = uniq(value.split(splitter).map((e) => e.trim())).filter(validEmail)

          const found = emails
            .map((email) => props.users.data?.users.find((u) => u.email === email))
            .filter((u) => u !== undefined) as User[]

          const notFound = emails
            .filter((email) => !found.find((u) => u.email === email))
            .map((email) => ({ email }) as User)

          setSelected(uniqBy(found.concat(notFound), 'email'))
          setValue('')
        }}
      >
        Continue
      </Button>

      <FormHelperText pt="2">
        Enter a list of emails separated by commas or drop a CSV file containing a list of emails.
        <br />
        <br />
        If the user exists in your Koala workspace, they will be added to the team automatically, if not, they will
        automatically join the team when they sign up for Koala.
      </FormHelperText>
    </Stack>
  )
}

function UserSearch(props: {
  users: ReturnType<typeof useUsers>
  selected: User[]
  setSelected: React.Dispatch<React.SetStateAction<User[]>>
}) {
  const { users, selected, setSelected } = props
  const availableUsers = React.useMemo(() => {
    return users.data?.users.filter((user) => !selected.find((u) => u.email === user.email)) ?? []
  }, [users.data, selected])

  return (
    <ComboboxWithSearch
      isLoading={users.isLoading}
      virtual
      estimateSize={() => 54}
      items={availableUsers}
      filterItem={(a, val) => [a?.name, a?.email].join('').toLowerCase().includes(val)}
      itemToString={(item) => item?.name ?? ''}
      selectedItem={null}
      selectButtonRenderer={() => (
        <HStack flex="1" fontSize={'sm'}>
          <Text color="gray.500">Search members...</Text>
        </HStack>
      )}
      itemRenderer={({ item }) => (
        <HStack w="100%" key={item.email} px="1">
          <HStack flex="1" fontSize={'sm'}>
            <Avatar size={'xs'} src={item.avatar} name={item.name} />
            <Stack spacing="-1.5">
              <Text fontSize="sm">{item.name}</Text>
              <Text fontSize={'xs'} color="gray.500">
                {item.email}
              </Text>
            </Stack>
          </HStack>
        </HStack>
      )}
      onChange={(e) => {
        if (e === null) {
          return
        }

        setSelected((prev) => uniqBy([e].concat(prev), 'email'))
      }}
    />
  )
}
