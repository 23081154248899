import qs from 'qs'
import { useQuery, UseQueryOptions } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

export interface OmnisearchProfile {
  id: string
  name?: string
  email?: string
  title?: string
  display_name?: string
  anonymous_name?: string
  avatar?: string
  status?: 'online' | 'away' | 'offline'
  company?: {
    id: string
    name?: string | null
    domain: string
    logo?: string | null
  }
}

export interface OmnisearchAccount {
  id: string
  name?: string
  domain?: string
  company_id?: string
  company?: {
    id: string
    name: string | null
    domain: string
    logo: string | null
  }
}

export interface OmnisearchView {
  id: string
  name: string
  slug: string
  kind: 'account' | 'profile'
  owernship: 'private' | 'shared' | 'team'
  created_by_user?: {
    id: string
    email: string
    name?: string
    avatar?: string
  }
}

export interface OmnisearchProject {
  id: string
  name: string
  slug: string
  domain: string
}

export interface OmnisearchData {
  query?: string | null
  results: {
    accounts: OmnisearchAccount[]
    profiles: OmnisearchProfile[]
    views: OmnisearchView[]
    projects: OmnisearchProject[]
    matches: number
  }
}

const emptyResults = {
  results: {
    accounts: [],
    profiles: [],
    views: [],
    projects: [],
    matches: 0
  }
}

export function useOmnisearch(
  query: string | undefined,
  filters?: {
    model?: 'Account' | 'Profile' | 'AccountView' | 'Project' | 'Route'
    [key: string]: any
  },
  opts?: UseQueryOptions<OmnisearchData>,
  selectedProject?: { id: string; slug: string } | null
) {
  const currentProject = useCurrentProject()
  const project = selectedProject || currentProject
  const search = {}

  if (query) {
    search['query'] = query
  }

  if (filters) {
    for (const key of Object.keys(filters)) {
      search[key] = filters[key] || null
    }
  }

  const querystring = qs.stringify(search, { arrayFormat: 'brackets', skipNulls: true, encodeValuesOnly: true })
  const path = selectedProject?.slug
    ? `/projects/${selectedProject.slug}/autocomplete?${querystring}`
    : projectPath(`/autocomplete?${querystring}`)

  return useQuery<OmnisearchData>(
    ['omnisearch', { querystring, projectId: project?.id }],
    () => (filters?.model === 'Route' ? Promise.resolve({ results: emptyResults }) : concurrentGET<any>(path)),
    {
      ...opts,
      enabled: opts?.enabled !== false && Boolean(querystring && project?.id)
    }
  )
}
