import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Spinner,
  Stack,
  Text,
  TextProps,
  Tooltip,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconDatabaseSearch,
  IconCircleMinus,
  IconCirclePlus,
  IconCompass,
  IconDots,
  IconExternalLink,
  IconEyeOff,
  IconFlag,
  IconRefresh
} from '@tabler/icons-react'
import { FastAverageColor } from 'fast-average-color'
import ms from 'ms'
import pluralize from 'pluralize'
import React, { useMemo } from 'react'
import { useMountedState } from 'react-use'
import { colord } from '../../../../../lib/colors'
import { ordinalSuffix } from '../../../../../lib/ordinal-suffix'
import { hashCode } from '../../../../../lib/string-to-hash'
import type { Account } from '../../../../../types/Account'
import { Company } from '../../../../../types/Profile'
import { useClaimAccount } from '../../../../data/use-claim-account'
import { useHiddenAccounts, useHideAccount, useRestoreAccount } from '../../../../data/use-hide-account'
import { useReindexRecord } from '../../../../data/use-reindex-record'
import { useUnclaimAccount } from '../../../../data/use-unclaim-account'
import { DetailsCard } from '../../../../ui/Card'
import CompanyAvatar from '../../../../ui/CompanyAvatar'
import { HelpTooltip } from '../../../../ui/HelpTooltip'
import { CrunchbaseIcon, LinkedinBoxIcon, TwitterIcon } from '../../../../ui/icons'
import { MiddotDivider } from '../../../../ui/Middot'
import { TimeAgo } from '../../../../ui/TimeAgo'
import { useCurrentUser } from '../../../../ui/UserContext'
import { salesNavigatorCompanyLink } from '../../../account_views/components/ImportContactsSideSheet'
import { Breakdown, grade, LetterGrade } from '../../../icps/icp/breakdown'
import { getScoreLabel, scoreLabelColor } from '../Intent/IntentSummary'
import { SuggestionsModal } from '../SuggestionsModal'
import { ShareInSlackModal } from './ShareInSlackModal'

interface Props {
  account: Account
  scoringEnabled?: boolean
}

interface Assignee {
  name?: string
  email?: string
  id?: string
}

const gradients = [
  'linear(to-br, orange.100, purple.300, blue.200)',
  'linear(to-br, gray.300, cyan.500, yellow.300)',
  'linear(to-br, orange.100, purple.300)',
  'linear(to-br, pink.100, purple.300)',
  'linear(to-br, red.300, yellow.300, orange.300)',
  'linear(to-br, teal.300, yellow.400)',
  'linear(to-br, orange.100, purple.300)',
  'linear(to-br, teal.300, green.500, orange.200)'
]

const fac = new FastAverageColor()

export function useAverageColor(url?: string) {
  const [color, setColor] = React.useState<string>()
  const [colors, setColors] = React.useState<null | string[]>(null)
  const mounted = useMountedState()

  React.useEffect(() => {
    if (!url) {
      return
    }

    fac
      .getColorAsync(url, {
        ignoredColor: [
          [255, 255, 255, 255] // white
          // [0, 0, 0, 255] // black
        ]
      })
      .then((color) => {
        if (color?.hex && mounted()) {
          const harmonies = colord(color?.hex)
            .harmonies('analogous')
            .map((c) => c.toHex())
          setColor(color.hex)
          setColors(harmonies)
        }
      })
      .catch(() => {
        // do nothing
      })
  }, [mounted, url])

  return { color, colors }
}

function useGradient(id: string) {
  const hash = React.useMemo(() => hashCode(id), [id])
  const gradient = gradients[hash % gradients.length]
  return gradient
}

export function SummaryCard(props: Props) {
  const [assignee, setAssignee] = React.useState<Assignee | undefined>(props.account.assignee)
  const user = useCurrentUser()
  const scoreLabel = getScoreLabel(props.account.intent?.score)
  const fit = grade(props.account.fit_grade, props.account.fit_grade_letter)
  const claimedByYou = assignee?.id === user.id
  const company = props.account.company
  const gradient = useGradient(props.account.domain)
  const { colors } = useAverageColor(props.account.company.logo)
  const smaller = useBreakpointValue({ base: true, sm: true, md: false, lg: false, xl: false })

  return (
    <DetailsCard padding={0}>
      <Box
        roundedTop="inherit"
        roundedBottom="none"
        height="64px"
        bg="white"
        bgGradient={
          colors === null
            ? `linear(to-br, gray.100, gray.200)`
            : colors.length
              ? `linear(to-br, ${colors.join(', ') || ', '})`
              : gradient
        }
        marginTop="-1px"
        marginX="-1px"
        padding={4}
      />

      <Stack paddingX={[5, 5, 6]} paddingBottom={[5, 5, 6]} paddingTop={0} spacing={6}>
        <HStack justifyContent="space-between" alignItems="flex-start">
          <Flex gap={4} alignItems="flex-end" flexWrap="wrap">
            <CompanyAvatar
              name={company.name}
              domain={company.domain}
              size={['xl', '2xl']}
              bg="white"
              shadow="0 1px 4px 0 rgba(0, 0, 0, 0.08), 0 0 0 4px var(--chakra-colors-gray-50)"
              rounded="xl"
              flex="none"
              marginTop={-10}
            />
            <Stack display={['none', 'none', 'none', 'flex']} spacing={0} paddingBottom={0.5}>
              <Heading size="lg" fontWeight="bold" letterSpacing="tight">
                {company.name || company.domain}
              </Heading>
              <HStack spacing={1} fontSize="sm" color="gray.600" divider={<MiddotDivider />}>
                <Link fontWeight="semibold" isExternal href={`https://${company.domain}`}>
                  {company.domain}
                </Link>
                {assignee && (
                  <Text>
                    Claimed by{' '}
                    <Text as="span" fontWeight="semibold">
                      {claimedByYou ? 'You' : assignee.name || assignee.email}
                    </Text>
                  </Text>
                )}
              </HStack>
            </Stack>
          </Flex>
          <HStack>
            <Button
              size={smaller ? 'sm' : 'md'}
              variant="outline"
              as={Link}
              isExternal
              href={`https://${company.domain}`}
              rightIcon={<Icon as={IconExternalLink} boxSize={4} />}
              iconSpacing={1.5}
            >
              Visit Website
            </Button>
            <ShareInSlack account={props.account} size={smaller ? 'sm' : 'md'} />
            <CompanyMenu
              account={props.account}
              assignee={assignee}
              onChangeAssignee={setAssignee}
              size={smaller ? 'sm' : 'md'}
            />
          </HStack>
        </HStack>
        <Flex direction="column" gap={6}>
          <Stack display={['flex', 'flex', 'flex', 'none']} spacing={0}>
            <Heading size="lg" fontWeight="bold" letterSpacing="tight">
              {company.name || company.domain}
            </Heading>
            <HStack spacing={1} fontSize="sm" color="gray.600" divider={<MiddotDivider />}>
              <Link fontWeight="semibold" isExternal href={`https://${company.domain}`}>
                {company.domain}
              </Link>
              {assignee && (
                <Text>
                  Claimed by{' '}
                  <Text as="span" fontWeight="semibold">
                    {claimedByYou ? 'You' : assignee.name || assignee.email}
                  </Text>
                </Text>
              )}
            </HStack>
          </Stack>
          <Grid gridTemplateColumns={['1fr', 'repeat(auto-fit, minmax(125px, 1fr))']} gap={[2, 2, 3]}>
            <CompanyField label="Intent" color={`${scoreLabelColor[scoreLabel || 'none']}.500`} fontWeight="semibold">
              {scoreLabel || 'Not enough data'}
            </CompanyField>
            {props.scoringEnabled && (
              <CompanyField label="ICP Score">
                {props.account.fit_grade ? (
                  <Tooltip
                    label={`This account is in the ${ordinalSuffix(props.account.fit_grade)} percentile of accounts.`}
                  >
                    <Box display="inline-flex" textAlign="left" alignItems="center" gap={1} color={`${fit.color}.500`}>
                      <LetterGrade
                        flex="none"
                        fontSize="inherit"
                        display="inline"
                        value={props.account.fit_grade}
                        label={props.account.fit_grade_letter}
                        tooltip={false}
                      />
                      <Text>({props.account.fit_grade}%)</Text>
                      <Breakdown domain={props.account.domain} showInfo />
                    </Box>
                  </Tooltip>
                ) : (
                  'Not scored yet'
                )}
              </CompanyField>
            )}
            <CompanyField label="Visitors">
              {pluralize('visitor', props.account.visitor_stats?.visitors?.month ?? 0, true)}
            </CompanyField>
            {Boolean(props.account.focus_time_trend?.month?.current?.value ?? 0) && (
              <CompanyField
                label="Activity"
                info="This measures how much *active* session time visitors spent on your site in the past month"
              >
                {ms(props.account.focus_time_trend?.month?.current?.value ?? 0, { long: true })}
              </CompanyField>
            )}
            {props.account.last_seen_at && (
              <CompanyField label="Last Seen">
                <TimeAgo time={props.account.last_seen_at} fallback="—" />
              </CompanyField>
            )}
          </Grid>
        </Flex>
      </Stack>
    </DetailsCard>
  )
}

type CompanyFieldProps = React.PropsWithChildren<{
  label?: string
  info?: string
}>

export function CompanyField({ children, label, info, ...rest }: CompanyFieldProps & TextProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      position="relative"
      borderWidth="1px"
      rounded="lg"
      paddingY={[2, 3, 4]}
      paddingX={3}
    >
      <Stack flex="1" direction={['row', 'column']} alignItems="center" textAlign="center">
        <Flex
          gap={[1, 2]}
          alignItems="center"
          justifyContent="center"
          color="gray.600"
          flexWrap="nowrap"
          whiteSpace="nowrap"
        >
          <Text fontSize="sm">{label}</Text>
        </Flex>
        <Box fontSize={['md', 'md', 'md', 'md', 'lg']} fontWeight="semibold" lineHeight={1} color="gray.800" {...rest}>
          {children}
        </Box>
      </Stack>
      {info && (
        <HelpTooltip placement="top" position={['static', 'absolute']} marginLeft={2} right={2} top={2} variant="info">
          {info}
        </HelpTooltip>
      )}
    </Box>
  )
}

interface ShareInSlackProps {
  account: Account
  size?: 'sm' | 'md'
}

function ShareInSlack(props: ShareInSlackProps) {
  const modal = useDisclosure()

  return (
    <>
      <IconButton
        aria-label="Share in Slack"
        size={props.size ?? 'md'}
        variant="outline"
        icon={<Image src="https://cdn.worldvectorlogo.com/logos/slack-new-logo.svg" boxSize={4} />}
        onClick={modal.onToggle}
      />
      <ShareInSlackModal account={props.account} {...modal} />
    </>
  )
}

interface CompanyMenuProps {
  account: Account
  assignee?: Assignee
  size?: 'sm' | 'md'
  onChangeAssignee?: (user: Assignee | undefined) => void
}

function CompanyMenu(props: CompanyMenuProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const suggestions = useDisclosure()
  const user = useCurrentUser()
  const claim = useClaimAccount()
  const unclaim = useUnclaimAccount()
  const hide = useHideAccount()
  const unhide = useRestoreAccount()
  const hiddenAccounts = useHiddenAccounts()
  const reindexAccount = useReindexRecord()

  const claimedByYou = props.assignee?.id === user.id
  const domain = useMemo(() => props.account.company.domain!, [props.account.company.domain])

  const isHidden = useMemo(
    () => hiddenAccounts.data?.filters.domains?.filters?.facets?.['company.domain']?.['not']?.includes(domain),
    [domain, hiddenAccounts.data]
  )

  const company = props.account.company || {}
  const clearbit = (props.account.clearbit || {}) as Company
  const twitterHandle = company.twitter?.handle || clearbit.twitter?.handle
  const linkedinHandle = company.linkedin?.handle || clearbit.linkedin?.handle
  const crunchbaseHandle = company.crunchbase?.handle || clearbit.crunchbase?.handle

  return (
    <>
      <Menu isOpen={isOpen} placement="bottom-end" onOpen={onOpen} onClose={onClose}>
        <MenuButton
          as={IconButton}
          size={props.size ?? 'md'}
          variant="outline"
          aria-label="More"
          icon={<Icon as={IconDots} boxSize={5} />}
        />
        <Portal>
          <MenuList>
            <MenuItem
              icon={claimedByYou ? <IconCircleMinus size={16} /> : <IconCirclePlus size={16} />}
              isDisabled={!claimedByYou && Boolean(props.assignee)}
              onClick={() =>
                claimedByYou
                  ? unclaim.mutateAsync({ domain: domain }).then(() => props.onChangeAssignee?.(undefined))
                  : claim
                      .mutateAsync({ assigneeId: user.id!, domain: domain })
                      .then(() => props.onChangeAssignee?.(user))
              }
            >
              {claimedByYou ? 'Unclaim' : 'Claim'}
            </MenuItem>
            <MenuItem
              icon={<IconEyeOff size={16} />}
              onClick={() => {
                if (isHidden) {
                  unhide.mutateAsync({ domain: domain }).then(() => hiddenAccounts.refetch())
                } else {
                  hide.mutateAsync({ domain: domain }).then(() => hiddenAccounts.refetch())
                }
              }}
            >
              {isHidden ? 'Unhide' : 'Hide'}
            </MenuItem>
            <MenuDivider />
            {linkedinHandle && (
              <MenuItem
                icon={<LinkedinBoxIcon size={16} />}
                as={Link}
                href={`https://linkedin.com/company/${linkedinHandle.replace(/(linkedin\.com\/|company\/)/gi, '')}`}
                _hover={{ textDecoration: 'none' }}
                isExternal
                onClick={() => {
                  window.ko?.track('LinkedIn Visit Action', {
                    app: 'linkedin',
                    company: company
                  })
                }}
              >
                View on LinkedIn
              </MenuItem>
            )}
            {twitterHandle && (
              <MenuItem
                icon={<TwitterIcon size={16} />}
                as={Link}
                href={`https://twitter.com/${twitterHandle}`}
                _hover={{ textDecoration: 'none' }}
                isExternal
                onClick={() => {
                  window.ko?.track('Twitter Visit Action', {
                    app: 'twitter',
                    company: company
                  })
                }}
              >
                View on Twitter
              </MenuItem>
            )}
            {crunchbaseHandle && (
              <MenuItem
                icon={<CrunchbaseIcon size={16} />}
                as={Link}
                href={`https://crunchbase.com/${crunchbaseHandle}`}
                _hover={{ textDecoration: 'none' }}
                isExternal
                onClick={() => {
                  window.ko?.track('Crunchbase Visit Action', {
                    app: 'crunchbase',
                    company: company
                  })
                }}
              >
                View on Crunchbase
              </MenuItem>
            )}
            <MenuItem
              icon={<IconCompass size={16} />}
              as={Link}
              href={salesNavigatorCompanyLink(props.account)}
              _hover={{ textDecoration: 'none' }}
              isExternal
              onClick={() => {
                window.ko?.track('Salesnav Visit Action', {
                  app: 'salesnav',
                  company: company
                })
              }}
            >
              Find in Sales Navigator
            </MenuItem>
            <MenuDivider />
            <MenuItem icon={<IconFlag size={16} />} onClick={suggestions.onOpen}>
              Suggest edits
            </MenuItem>
            {user.isInternalUser && props.account.id && (
              <MenuItem
                closeOnSelect={false}
                icon={
                  reindexAccount.isLoading ? (
                    <Center boxSize={4}>
                      <Spinner boxSize={3.5} thickness="1px" color="gray.700" />
                    </Center>
                  ) : (
                    <IconRefresh size={16} />
                  )
                }
                onClick={() => reindexAccount.mutate({ record_id: props.account.id, record_type: 'Account' })}
              >
                Reindex account{' '}
                <Badge variant="regular" colorScheme="orange">
                  Internal
                </Badge>
              </MenuItem>
            )}
            {user.isInternalUser && props.account.domain && (
              <MenuItem
                icon={<IconDatabaseSearch size={16} />}
                as={Link}
                href={`/admin/kdb/${props.account.domain}`}
                _hover={{ textDecoration: 'none' }}
                isExternal
              >
                View admin page{' '}
                <Badge variant="regular" colorScheme="orange">
                  Internal
                </Badge>
              </MenuItem>
            )}
          </MenuList>
        </Portal>
      </Menu>
      <SuggestionsModal isOpen={suggestions.isOpen} onClose={suggestions.onClose} company={props.account.company} />
    </>
  )
}
