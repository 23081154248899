import {
  Box,
  Button,
  Center,
  Circle,
  Collapse,
  Flex,
  HStack,
  Link,
  LinkBox,
  LinkOverlay,
  ScaleFade,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconArrowBarToUp, IconArrowRight, IconSelector } from '@tabler/icons-react'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import React, { useMemo } from 'react'
import { useWindowScroll } from 'react-use'
import { Session } from '..'
import { get } from '../../../../lib/api'
import { PageMeta } from '../../../../types/PageMeta'
import { CardStack } from '../../../ui/CardStack'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { CollapseIcon } from '../../../ui/icons'
import { isLimitedAccount, RedactedAccountCell, useEntitlements } from '../../../ui/useEntitlements'
import { AccountSession } from '../../accounts/feed'
import { accountPath } from '../../accounts/lib/account-path'
import { mergeParams } from '../../icps/types'
import { CompanyActorDetails, MaterializedSession } from './MaterializedSession'

export interface AccountFeedProps {
  materialized_sessions: AccountSession[]
  page_meta: PageMeta & {
    next_page_token?: string
    prev_page_token?: string
  }
}

function CompanyActors(props: Session & { redacted?: boolean }) {
  if (!props.company) {
    return null
  }

  return (
    <HStack spacing="4">
      <Stack justifyContent={'center'} alignItems="center" spacing="0.5" as={LinkBox}>
        <LinkOverlay href={accountPath({ company: props.company })}>
          <Stack justifyContent={'center'} alignItems="center" spacing="0">
            <CompanyAvatar size="md" name={props.company.name} domain={props.company.domain} />
          </Stack>
        </LinkOverlay>
      </Stack>
      <CompanyActorDetails {...props} company={props.company} />
    </HStack>
  )
}

function AccountMaterializedSession(props: Session & { redacted?: boolean }) {
  const [expanded, setExpanded] = React.useState(false)
  const entitlements = useEntitlements()

  return (
    <Stack spacing="4" paddingY={2}>
      <HStack w="100%" justifyContent={'space-between'}>
        <RedactedAccountCell
          entitlements={entitlements}
          element={{ last_seen_at: props.last_touched_at, limited: props.limited ?? props.account?.limited }}
          flexProps={{ gap: 4 }}
        >
          <CompanyActors {...props} />
        </RedactedAccountCell>

        {props.session_profiles && (
          <Button
            variant={'ghost'}
            leftIcon={expanded ? <CollapseIcon size={14} /> : <IconSelector size={14} />}
            iconSpacing={1}
            size="xs"
            onClick={() => {
              setExpanded((prev) => !prev)
            }}
          >
            {expanded ? 'Hide' : 'Show'} Details
          </Button>
        )}
      </HStack>
      {props.session_profiles && (
        <CompanyPreview
          {...props}
          redacted={props.redacted}
          expanded={expanded}
          onExpand={() => {
            setExpanded(true)
          }}
        />
      )}
    </Stack>
  )
}

const noop = () => {}

export function AccountFeed(props: AccountFeedProps & { skipInfiniteLoading?: boolean; loadPath?: string }) {
  const [sessions, setSessions] = React.useState(props.materialized_sessions)
  const [loading, setLoading] = React.useState(false)
  const [nextPageToken, setNextPageToken] = React.useState(props.page_meta.next_page_token)
  const loadingRef = React.useRef(loading)
  const nextPageRef = React.useRef(nextPageToken)

  React.useEffect(() => {
    setSessions(props.materialized_sessions)
  }, [props.materialized_sessions])

  React.useEffect(() => {
    setNextPageToken(props.page_meta.next_page_token)
  }, [props.page_meta.next_page_token])

  const loadMore = React.useCallback(async () => {
    if (!nextPageRef.current || loadingRef.current) {
      return
    }

    setLoading(true)
    loadingRef.current = true

    const url = mergeParams(props.loadPath ?? location.toString(), {
      pagination_token: nextPageRef.current.toString()
    })

    const res = await get<AccountFeedProps>(url)

    setSessions((prev) => uniqBy(prev.concat(res.materialized_sessions), (s) => s.id))
    const next = res.page_meta.next_page_token

    setNextPageToken(next)
    nextPageRef.current = next

    setLoading(false)
    loadingRef.current = false
  }, [props.loadPath])

  const { y: scrollY } = useWindowScroll()
  const scrollToTop = React.useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  const endOfPageRef = React.useRef<HTMLDivElement | null>(null)
  const entitlements = useEntitlements()

  React.useEffect(() => {
    if (props.skipInfiniteLoading) {
      return
    }

    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0
    }

    const observer = new IntersectionObserver((entries) => {
      const target = entries[0]
      if (target.isIntersecting) {
        loadMore()
      }
    }, option)

    if (endOfPageRef.current) {
      observer.observe(endOfPageRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [loadMore, props.skipInfiniteLoading])

  const interactiveSessions = useMemo(() => {
    return sessions.filter((s) => {
      return s.sample_daily_profile_feeds?.some((sp) => sp.num_interactions > 0) ?? false
    })
  }, [sessions])

  return (
    <Box w="100%">
      <Stack spacing={6} width="100%" maxW="1024px" marginX="auto">
        <Stack spacing="4">
          {interactiveSessions.map((session) => (
            <AccountMaterializedSession
              key={session.id}
              redacted={isLimitedAccount(entitlements, {
                last_seen_at: session.last_touched_at,
                limited: session.limited ?? session.account?.limited ?? session.profile?.limited
              })}
              {...session}
            />
          ))}
        </Stack>

        <Center paddingTop={10} paddingBottom={20}>
          {props.skipInfiniteLoading && nextPageToken && (
            <>
              <Button onClick={loadMore}>Load More</Button>
            </>
          )}

          {nextPageToken ? (
            <Box ref={endOfPageRef} height="100px">
              {loading && (
                <Button variant="link" isLoading loadingText="Loading more…">
                  Loading more…
                </Button>
              )}
            </Box>
          ) : (
            <Text fontSize="sm" color="gray.500">
              That's all there is, there isn't any more
            </Text>
          )}
        </Center>
      </Stack>

      <Box position="fixed" bottom={10} right={10}>
        <ScaleFade initialScale={0.9} in={scrollY > 150}>
          <Circle
            padding={3}
            bg="gray.500"
            cursor="pointer"
            opacity={0.5}
            _hover={{ opacity: 0.8 }}
            transition="opacity 150ms cubic-bezier(0.4, 0, 0.2, 1)"
            onClick={scrollToTop}
          >
            <IconArrowBarToUp size={24} color="white" />
          </Circle>
        </ScaleFade>
      </Box>
    </Box>
  )
}

function CompanyPreview(
  props: AccountSession & {
    redacted?: boolean
    expanded?: boolean
    onExpand?: () => void
  }
) {
  const { expanded, onExpand } = props
  const items = useMemo(
    () =>
      orderBy(props.sample_daily_profile_feeds ?? [], (d) => d.last_touched_at, 'desc').filter(
        (s) => s.num_interactions > 0
      ),
    [props.sample_daily_profile_feeds]
  )
  const topItems = useMemo(() => items.slice(0, 3), [items])
  const heights = {
    [1]: '100px',
    [2]: '125px',
    [3]: '150px'
  }

  return (
    <Stack justifyContent={'center'} alignItems="center" w="100%" px={[0, 0, 0, 4]}>
      {!expanded && (
        <Flex w="100%">
          <CardStack
            draggable={false}
            items={topItems}
            itemHeight="95px"
            height={heights[topItems.length]}
            renderItem={(item, index) => {
              return (
                <Flex role="group" key={item.id}>
                  <MaterializedSession
                    {...item}
                    hideCompany
                    hideIntent
                    hideTimestamps
                    redacted={props.redacted}
                    onShowMore={index === 0 ? onExpand : noop}
                  />
                </Flex>
              )
            }}
          />
        </Flex>
      )}

      <Box w="100%">
        <Collapse in={expanded} style={{ margin: '-1px -3px -3px', padding: '1px 3px 3px' }}>
          <Stack spacing={4}>
            {items.map((item) => (
              <Flex key={item.id} w="100%">
                <MaterializedSession
                  {...item}
                  hideCompany
                  hideTimestamps
                  hideIntent={false}
                  redacted={props.redacted}
                />
              </Flex>
            ))}
          </Stack>
        </Collapse>
      </Box>

      {expanded && props.company && (
        <Box paddingTop={4}>
          <Button
            size="sm"
            variant="ghost"
            as={Link}
            href={accountPath({
              company: props.company
            })}
            rightIcon={<IconArrowRight size={16} />}
            iconSpacing={1}
          >
            See all intent
          </Button>
        </Box>
      )}

      {items.length > 3 && !expanded && (
        <Box>
          <Button
            onClick={() => {
              onExpand?.()
            }}
            size="sm"
          >
            and {items.length - 3} more samples
          </Button>
        </Box>
      )}
    </Stack>
  )
}
