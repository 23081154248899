import React, { FormEvent, useMemo } from 'react'

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  Grid,
  Img,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import { StackedField } from '../../../ui/StackedField'
import { DetailsCard } from '../../../ui/Card'
import { CardHeading } from '../../../ui/CardHeading'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import type { Account } from '../../../../types/Account'
import type { App } from '../../../../types/App'
import { useUIState } from './DetailsV2/useUIState'
import { useAppDep } from '../../../data/use-app-dep'
import { toast } from 'sonner'
import { postForm } from '../../../../lib/api'
import { projectPath } from '../../../ui/ProjectsContext'

interface ApolloCardProps {
  app?: App
  account: Account
  initiallyCollapsed?: boolean
}

export function ApolloCard(props: ApolloCardProps) {
  const listDisclosure = useDisclosure()
  const [ui, setUI] = useUIState()

  const cardDisclosure = useDisclosure({
    defaultIsOpen: (ui.open?.apollo || !props.initiallyCollapsed) && Boolean(props.app),
    onOpen: () => {
      setUI({ open: { apollo: true } })
    },
    onClose: () => {
      setUI({ open: { apollo: false } })
    }
  })

  const apolloData = props.account?.apollo_org_cache

  return (
    <DetailsCard>
      <CardHeading icon={'https://asset.brandfetch.io/ideEin4YhC/id-83Yirn2.png'} disclosure={cardDisclosure}>
        <HStack justifyContent="space-between">
          <Text>Apollo</Text>
        </HStack>
      </CardHeading>
      {cardDisclosure.isOpen && (
        <Stack spacing={4}>
          {props.app?.connected ? (
            <>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <StackedField label="Name">{apolloData?.name}</StackedField>
                <StackedField label="Country">{apolloData?.country}</StackedField>
                <StackedField label="City">{apolloData?.city}</StackedField>
                <StackedField label="Industry">{apolloData?.industry}</StackedField>
              </Grid>

              <Button size="sm" variant="outline" onClick={listDisclosure.onOpen}>
                Add to List…
              </Button>
            </>
          ) : (
            <>
              <Text color="gray.600" fontSize="xs">
                Enrich prospects on demand.
              </Text>
              <Button
                leftIcon={<Img w="4" src={'https://asset.brandfetch.io/ideEin4YhC/id-83Yirn2.png'} />}
                size="sm"
                variant={'outline'}
                onClick={() => (window.location.href = projectPath('/apps/apollo'))}
              >
                Connect Apollo
              </Button>
            </>
          )}
        </Stack>
      )}
      <AddToListModal {...listDisclosure} account={props.account} />
    </DetailsCard>
  )
}

interface AddToListModalProps extends UseDisclosureProps {
  account: Account
}

type ApolloLabel = {
  id: string
  name: string
}

export function AddToListModal({ account, ...props }: AddToListModalProps) {
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose
  const [saving, setSaving] = React.useState(false)
  const [selectedLabel, setSelectedLabel] = React.useState<ApolloLabel | null>(null)

  const { data: listsData, isLoading: loadingLabels } = useAppDep<'account_labels', ApolloLabel[]>(
    'Apollo',
    'account_labels'
  )
  const list = useMemo(() => selectedLabel ?? listsData?.data?.account_labels[0] ?? null, [selectedLabel, listsData])

  const onSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      if (!list) return

      setSaving(true)

      const form = e.target as HTMLFormElement
      const data = new FormData(form)

      postForm(projectPath(`/apollo/actions/account_lists?account_id=${account.id}`), data)
        .then(() => {
          toast.success('Prospect added to List!', {
            description: `${account.company.domain} has been added to ${list.name} in Apollo.io.`
          })
          setSaving(false)
          onClose()
        })
        .catch((e) => {
          toast.error('Error adding prospect to list', {
            description: e.message
          })
          setSaving(false)
        })
    },
    [account, list, onClose]
  )

  return (
    <Modal {...disclosure} size="sm" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <CompanyAvatar src="https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/imnotvasciiltr2dikl8" />
            <Stack spacing="0">
              <Heading size="sm">Add to Apollo.io List</Heading>
              <Text fontSize={'sm'} fontWeight="normal">
                Domain: {account.company?.domain}
              </Text>
            </Stack>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody pb="4" fontWeight={'normal'}>
          <form onSubmit={onSubmit}>
            <Stack fontSize="sm" spacing={'4'}>
              <FormControl>
                <FormLabel>Select a List</FormLabel>
                {loadingLabels && <Spinner size="sm" />}
                <ComboboxWithSearch
                  items={listsData?.data?.account_labels ?? []}
                  selectedItem={list}
                  onChange={(selectedItem) => {
                    setSelectedLabel(selectedItem)
                  }}
                  filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                  itemToString={(item) => item?.name || ''}
                />
              </FormControl>

              <Flex w="100%" pt="4">
                <Button w="100%" size="sm" colorScheme={'purple'} type="submit" isLoading={saving}>
                  Add to List
                </Button>
              </Flex>
            </Stack>
            {list && <input type="hidden" name="list[label_id]" value={list.id} />}
            {list && <input type="hidden" name="list[label_name]" value={list.name} />}
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
