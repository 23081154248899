import { ConnectionDetail, ConnectionDetails } from '@app/components/pages/apps/components/ConnectionDetails'
import { ConnectOauthAppDialog } from '@app/components/pages/apps/components/ConnectOauthAppDialog'
import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import PageLayout from '@app/components/ui/PageLayout'
import PageTitle from '@app/components/ui/PageTitle'
import type { Project } from '@app/types/Project'
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Stack,
  Switch
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { Card, LightBgCard } from '../../../ui/Card'
import PageDescription from '../../../ui/PageDescription'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { Introduction } from '../components/Introduction'

type DiscordChannel = any

interface Props {
  app_id: string
  project: Project
  title: string
  description: string
  deps: {
    channels: DiscordChannel[]
  }
  logo: string
  valid?: boolean
  connected?: boolean
  settings?: {
    signal_joined_server?: boolean
    verify_server_membership?: boolean
  }
  connection_details: ConnectionDetail[]
}

export default function Show(props: Props) {
  const { hasPermission: canEditProject } = usePermission({
    on: 'project',
    action: 'can_edit'
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
        paths={[
          {
            path: projectPath('/apps/discord'),
            title: 'Discord'
          }
        ]}
        offscreen
      />
      <HStack w="100%">
        <Box w="100%">
          <HStack w="100%">
            <Image src={props.logo} maxW="6" />
            <PageTitle>{props.title}</PageTitle>
            {props.connected && props.valid && <Badge colorScheme="green">Connected</Badge>}
            {props.connected && !props.valid && <Badge colorScheme="orange">Requires Reconnection</Badge>}
          </HStack>

          <PageDescription>{props.description}</PageDescription>
        </Box>

        {props.connected && <DisconnectAppDialog appTitle={'Discord'} showRemoveCachesOption={false} />}
      </HStack>

      <Introduction
        app="discord"
        icon={props.logo}
        description="The Koala App for Discord helps you track engagement signals in your Discord servers."
        docsLink="https://getkoala.com/docs/integrations/discord"
      />

      {!props.connected && (
        <LightBgCard p={5}>
          <ConnectOauthAppDialog {...props} allowNonAdmins />
        </LightBgCard>
      )}

      {props.connected && (
        <Stack spacing="12">
          <ConnectionDetails appTitle={'Discord'} valid={props.valid} details={props.connection_details} />

          <form
            method="POST"
            onSubmit={(e) => {
              e.preventDefault()
              setIsSubmitting(true)
              e.currentTarget.submit()
            }}
          >
            <AuthenticityToken />
            <input type="hidden" name="_method" value="PUT" />

            <Stack as={Card} spacing="8">
              <Heading size="sm">Signal Settings</Heading>

              <FormControl>
                <HStack>
                  <input type="hidden" name="app_instance_settings[verify_server_membership]" value="false" />
                  <Switch
                    id="verify_server_membership"
                    name="app_instance_settings[verify_server_membership]"
                    defaultChecked={
                      props.settings?.verify_server_membership !== undefined
                        ? props.settings?.verify_server_membership
                        : true
                    }
                    value="true"
                  />
                  <FormLabel htmlFor="verify_server_membership">Verify Server Membership</FormLabel>
                </HStack>
                <FormHelperText>
                  When enabled, Koala will send a message to any new members joining your server to collect their email
                  via OAuth.
                </FormHelperText>
              </FormControl>

              <Divider />

              <FormControl>
                <FormLabel>Engagement Signals</FormLabel>
                <input type="hidden" name="app_instance_settings[signal_joined_server]" value="false" />

                <Stack>
                  <Checkbox
                    name="app_instance_settings[signal_joined_server]"
                    defaultChecked={
                      props.settings?.signal_joined_server !== undefined ? props.settings?.signal_joined_server : true
                    }
                    value="true"
                  >
                    Event: Member Joined Server
                  </Checkbox>
                </Stack>
                <FormHelperText>
                  Select which engagement signals you want to monitor. Koala will automatically issue a track event
                  every time one of these signals occurs.
                </FormHelperText>
              </FormControl>

              <Divider />

              <Button colorScheme="purple" type="submit" w="100%" isDisabled={isSubmitting || !canEditProject}>
                Save
              </Button>
            </Stack>
          </form>
        </Stack>
      )}
    </PageLayout>
  )
}
