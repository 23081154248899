import { useQuery, UseQueryOptions } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'
import useLocation from '../ui/useLocation'

interface FacetValuesResponse {
  stats?: {
    count: number
    min: number
    max: number
  }
  values: Array<{ key: string; doc_count: number }>
  count?: number
  exists?: number
}

// get the values for a specific facet for the current workspace, for a given index
export function useFieldValues(field: string, facetValuesPath = '/accounts/facet-values', query = '') {
  const project = useCurrentProject()

  const [pathname, search] = facetValuesPath.split('?')
  const params = new URLSearchParams(search)
  params.set('property', field)

  if (query?.trim()) {
    params.set('facet_search', query.trim())
  }

  if (!params.has('range')) {
    params.set('range', 'all')
  }

  const path = projectPath(`${pathname}?${params.toString()}`)

  return useQuery<FacetValuesResponse>(
    ['facet-values', { projectId: project?.id, path }],
    () => concurrentGET<FacetValuesResponse>(path),
    { enabled: Boolean(project?.id) }
  )
}

export function useCompanyFieldValues(field: string, query?: string, opts?: UseQueryOptions<FacetValuesResponse>) {
  let path = `/web/companies/facet-values?property=${field}`

  if (query?.trim()) {
    path += `&facet_search=${query.trim()}`
  }

  return useQuery<FacetValuesResponse>(
    ['company-facet-values', { field, query }],
    () => concurrentGET<FacetValuesResponse>(path),
    opts
  )
}

export function useProspectFieldValues(
  field: string,
  facet_search?: string,
  domain?: string,
  search?: string,
  opts?: UseQueryOptions<FacetValuesResponse>
) {
  const project = useCurrentProject()
  const location = useLocation()
  const params = new URLSearchParams(search || location.search)

  params.set('property', field)

  if (facet_search?.trim()) {
    params.set('facet_search', facet_search.trim())
  }

  if (domain) {
    params.set('domain', domain)
  }

  // manually construct this because we dont want the keys to be uri-encoded
  const entries = Array.from(params.entries()).map(([key, value]) => `${key}=${encodeURIComponent(value)}`)

  const path = projectPath(`/prospector/facet-values?${entries.join('&')}`)

  return useQuery<FacetValuesResponse>(
    ['prospect-facet-values', { projectId: project?.id, path }],
    () => concurrentGET<FacetValuesResponse>(path),
    { enabled: Boolean(project?.id), ...opts }
  )
}
