import { Box, Grid, Heading, HStack, Square, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { projectPath } from '../../../ui/ProjectsContext'
import { KqlDefinition } from '../types'
import { SignalType } from './SignalType'
import { signalTypes } from './triggers'

const signals = signalTypes.filter((s) => s.suggestions?.length)

function newSignalUrl(name: string, conditions: any = []) {
  const query = new URLSearchParams({
    'kql_definition[name]': name,
    'kql_definition[trigger]': JSON.stringify({ conditions })
  })

  return projectPath(`/signals/new?${query.toString()}`)
}

interface Props {
  onSelect?: (signal: Partial<KqlDefinition>) => void
}

export function SuggestedSignals(props: Props) {
  return (
    <Stack spacing={8}>
      <Stack spacing={2}>
        <Heading size="sm">Suggested intent signals</Heading>
        <Text fontSize="sm" color="gray.600">
          Start with these pre-built intent signals to capture the most common use-cases.
        </Text>
      </Stack>
      {signals.map((s) => (
        <Stack key={s.kind}>
          <HStack paddingX={1}>
            <Square size="8px" bg={`${s.colorScheme}.500`} rounded="sm" />
            <Text fontSize="xs" color="gray.500" textTransform="uppercase" fontWeight="medium">
              High Intent {s.label}
            </Text>
          </HStack>
          <Grid gap={2.5} gridTemplateColumns="repeat(auto-fill, minmax(320px, 1fr))">
            {(s.suggestions ?? []).map((suggestion) => (
              <Box
                key={suggestion.name}
                border="1px solid"
                borderColor="gray.200"
                cursor="pointer"
                rounded="lg"
                bg="white"
                padding={4}
                transition="border-color 150ms ease-in-out"
                _hover={{ borderColor: 'gray.300', shadow: 'sm' }}
                tabIndex={0}
                as="a"
                href={newSignalUrl(suggestion.name, suggestion.conditions)}
                onClick={(e: any) => {
                  if (props.onSelect) {
                    e.preventDefault()
                    props.onSelect({ name: suggestion.name, trigger: { conditions: suggestion.conditions } })
                  }
                }}
              >
                <SignalType
                  label={suggestion.name}
                  description={suggestion.description}
                  icon={s.icon}
                  colorScheme={s.colorScheme}
                />
              </Box>
            ))}
          </Grid>
        </Stack>
      ))}
    </Stack>
  )
}
