import { Box, Center, Circle, FlexProps, HStack, Icon, Spinner, Text, UsePopperProps } from '@chakra-ui/react'
import React, { useCallback, useMemo } from 'react'
import { useUsers, User } from '../../data/use-users'
import { useCurrentUser } from '../../ui/UserContext'
import Avatar from '../../ui/Avatar'
import { ComboboxWithSearch } from '../../ui/ComboboxWithSearch'
import { CrmUser, useCrmUsers } from '../../data/use-crm-users'
import { Iconify } from '../../ui/Iconify'
import { SalesforceIcon, UserCircleIcon } from '../../ui/icons'
import { HubSpotIcon } from '../../ui/icons/HubspotIcons'

const emptyArray = []

export interface UserSelectProps {
  users?: Array<User | CrmUser>
  selectedUserId?: string | null
  isReadOnly?: boolean
  includeCrmUsers?: boolean
  onChange?: (userId: string | null) => void
  triggerProps?: FlexProps
  popoverProps?: FlexProps
  popperOptions?: UsePopperProps
}

export function UserSelect({
  selectedUserId,
  users: specificUsers,
  isReadOnly,
  includeCrmUsers,
  onChange,
  triggerProps,
  popoverProps,
  popperOptions
}: UserSelectProps) {
  const users = useUsers({ cached: false, enabled: !specificUsers })
  const crm = useCrmUsers({ enabled: includeCrmUsers })
  const currentUser = useCurrentUser()

  const allUsers = useMemo(() => {
    const all: Array<User | CrmUser> = [...(specificUsers || users.data?.users || emptyArray)]
    if (!includeCrmUsers) {
      return all
    }

    const emails = all.flatMap((u) => (u as User).emails)

    for (const user of crm.data?.users || emptyArray) {
      if (!emails.includes(user.email)) {
        all.push(user)
        emails.push(user.email)
      }
    }

    return all
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.data?.users, crm.data?.users, specificUsers, includeCrmUsers])

  const selected = useMemo(() => {
    return allUsers.find((u) => u.id === selectedUserId) || null
  }, [allUsers, selectedUserId])

  const handleChange = useCallback(
    (user) => {
      onChange?.(user?.id || null)
    },
    [onChange]
  )

  if (users.isLoading && selectedUserId !== currentUser.id) {
    return (
      <Center height="30px" px={1}>
        <Spinner color="gray.300" thickness="1.5px" size="sm" />
      </Center>
    )
  }

  return (
    <Box>
      <ComboboxWithSearch
        items={allUsers}
        selectedItem={selected}
        isReadOnly={isReadOnly}
        isLoading={users.isLoading}
        onChange={handleChange}
        filterItem={(a, val) => a.name?.toLowerCase().includes(val) || a.email?.toLowerCase().includes(val) || false}
        itemToString={(item) => item?.name || ''}
        itemRenderer={UserRenderer}
        selectButtonRenderer={UserRenderer}
        triggerProps={{
          py: 1,
          px: 1.5,
          border: 'none',
          gap: 1,
          _hover: { bg: 'gray.100' },
          ...triggerProps
        }}
        popoverProps={{
          maxW: '280px',
          ...popoverProps
        }}
        popperOptions={{
          matchWidth: false,
          placement: 'bottom-start',
          ...popperOptions
        }}
      />
    </Box>
  )
}

interface UserRendererProps {
  item: User | CrmUser | null
  selectedItem?: User | CrmUser | null
  isToggleButton?: boolean
}

function isCrmUser(user?: User | CrmUser | null): user is CrmUser {
  return !!user && (user as CrmUser).external_id !== undefined
}

function isUser(user?: User | CrmUser | null): user is User {
  return !!user && !('external_id' in user)
}

function UserRenderer(props: UserRendererProps) {
  const user = props.item

  return (
    <HStack spacing={1.5} isTruncated>
      {isCrmUser(user) ? (
        <Iconify
          icon={user.source === 'salesforce' ? SalesforceIcon : HubSpotIcon}
          size={18}
          color={user.source === 'salesforce' ? 'salesforce' : 'hubspot'}
        />
      ) : isUser(user) ? (
        <Avatar size="20px" name={user.name || user.email} src={user.avatar || user.image || undefined} />
      ) : (
        <Circle borderWidth="1.5px" borderStyle="dashed" borderColor="gray.300" overflow="hidden">
          <Icon boxSize="17px" bg="gray.300" as={UserCircleIcon} color="white" />
        </Circle>
      )}
      <Text
        display={props.isToggleButton ? ['none', 'none', 'block'] : undefined}
        fontSize="sm"
        fontWeight="medium"
        isTruncated
      >
        {isUser(user)
          ? user.name || user.email || 'Unknown'
          : isCrmUser(user)
            ? user.email
            : props.isToggleButton
              ? 'Select a user'
              : 'Unknown'}
      </Text>
    </HStack>
  )
}
