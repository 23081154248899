import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Img,
  Input,
  Link,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconBookmarks, IconPlus, IconUsers } from '@tabler/icons-react'
import * as React from 'react'
import { AccountView } from '../../../types/AccountView'
import { Apps } from '../../../types/App'
import { filtersAsText } from '../../data/use-facets'
import { User } from '../../data/use-users'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { GrayCard, LightBgCard } from '../../ui/Card'
import { BuildingIcon } from '../../ui/icons'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { usePermission } from '../../ui/PermissionsContext'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { FacetFilters, NumericFilter } from '../accounts'
import { accountsPath } from '../accounts/lib/account-path'
import { accountViewPath } from '../account_views/lib/list-paths'
import { channelLogos } from '../follow_rules/components/delivery-setup'
import { slackAlertPath } from '../notifications/lib/path-helper'
import { visitorsPath } from '../profiles/lib/path'
import { SlackAlertTitle } from '../slack_alerts'
import { SlackAlert } from '../slack_alerts/types'
import { UserPicker } from './components/user-picker'

export interface Team {
  id: string
  name: string
  emails: string[]
  members?: TeamMember[]
}

interface TeamMember extends User {}

export interface DefaultFilters {
  filters: Filters
  created_at: Date
  updated_at: Date
  list_type: string
  'new_record?': boolean
}

export interface Filters {
  range?: 'day' | 'week' | 'month' | 'all'
  facets?: FacetFilters
  focus_time?: NumericFilter
}

interface Props {
  team: Team
  apps: Apps
  views: AccountView[]
  slack_alerts: SlackAlert[]
  errors?: {
    [key: string]: string[]
  }
}

export default function Show(props: Props) {
  const { hasPermission: canManageMembers } = usePermission({ on: 'project', action: 'can_manage_members' })
  const [submitting, setSubmitting] = React.useState(false)
  const [deleting, setDeleting] = React.useState(false)
  const [name, setName] = React.useState(props.team.name ?? '')
  const nameError = name === (props.team.name || '') ? props.errors?.name?.join(', ') : ''

  return (
    <PageLayout size="sm">
      <Flex pb="4">
        <SettingsBreadCrumb
          paths={[
            { path: projectPath('/settings/teams'), title: 'Teams' },
            { path: window.location.toString(), title: props.team.name }
          ]}
        />
      </Flex>
      <SettingsHeader border="none">
        <PageTitle>{props.team.name}</PageTitle>
        <PageDescription>Manage territory or role-specific settings for this team.</PageDescription>
      </SettingsHeader>

      <Stack as="section" spacing={10}>
        <Stack spacing={4} as={GrayCard}>
          <Heading size="sm" fontWeight={'semibold'}>
            General
          </Heading>
          <form
            action={projectPath(`/settings/teams/${props.team.id}`)}
            method="POST"
            onSubmit={() => setSubmitting(true)}
          >
            <AuthenticityToken />
            <input type="hidden" name="_method" value="PUT" />
            <Stack spacing={4}>
              <FormControl id="team[name]" isInvalid={!!nameError}>
                <FormLabel>Team name</FormLabel>
                <Input
                  type="text"
                  bg="white"
                  size="sm"
                  name="team[name]"
                  placeholder="e.g. SDRs, APAC"
                  isRequired
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                  }}
                />
                <FormErrorMessage>{nameError}</FormErrorMessage>
              </FormControl>

              <UserPicker team={props.team} />

              <Button
                type="submit"
                alignSelf="flex-start"
                size="sm"
                colorScheme="purple"
                isLoading={submitting}
                isDisabled={!canManageMembers}
              >
                Save
              </Button>
            </Stack>
          </form>
        </Stack>

        <Stack spacing={4} as={GrayCard}>
          <HStack w="100%" justifyContent="space-between">
            <Heading size="sm" fontWeight={'semibold'}>
              Team Lists
            </Heading>

            {props.views.length > 0 && (
              <Button
                variant={'ghost'}
                leftIcon={<IconPlus size="14" />}
                iconSpacing={1.5}
                size="sm"
                colorScheme={'purple'}
                as={Link}
                href={projectPath(`/views/new?account_view[ownership]=team&account_view[team_id]=${props.team.id}`)}
              >
                Add List
              </Button>
            )}
          </HStack>

          {props.views.length > 0 && (
            <Stack as={LightBgCard} p="2" bg="white" spacing="0" divider={<Divider />}>
              {props.views.map((view) => (
                <Link key={view.id} href={accountViewPath(view)} style={{ textDecoration: 'none' }}>
                  <ListPreview list={view} />
                </Link>
              ))}
            </Stack>
          )}

          {props.views.length === 0 && (
            <Stack spacing="4" bg="white" p="8" rounded="md">
              <Stack spacing="2">
                <HStack>
                  <IconBookmarks />
                  <Heading size="xs">Create your first Team List</Heading>
                </HStack>
                <Text fontSize="sm">
                  Create your first Team List, so people on your Team can have a shared setup for Notifications,
                  Territory definitions, and access these lists throughout the app.
                </Text>
              </Stack>
              <Button
                leftIcon={<IconPlus size="12" />}
                size="sm"
                colorScheme={'purple'}
                as={Link}
                href={projectPath(`/views/new?account_view[ownership]=team&account_view[team_id]=${props.team.id}`)}
              >
                Create List
              </Button>
            </Stack>
          )}

          <Text as={'span'} fontSize="sm" color="gray.600">
            Lists are saved filters that can be shared across your team. You can create a view by saving a filter on the{' '}
            <Link href={accountsPath()}>Accounts</Link> and <Link href={visitorsPath()}>Visitors</Link> pages.
          </Text>
        </Stack>

        <Stack spacing={4} as={GrayCard}>
          <HStack w="100%" justifyContent="space-between">
            <Heading size="sm" fontWeight={'semibold'}>
              Team Slack Alerts
            </Heading>

            {props.slack_alerts.length > 0 && (
              <Button
                variant={'ghost'}
                leftIcon={<IconPlus size="14" />}
                iconSpacing={1.5}
                size="sm"
                colorScheme={'purple'}
                as={Link}
                href={projectPath(`/slack-alerts/new`)}
              >
                Add Alert
              </Button>
            )}
          </HStack>

          {props.slack_alerts.length > 0 && (
            <Stack as={LightBgCard} p="2" bg="white" spacing="0">
              {props.slack_alerts.map((alert, index) => (
                <>
                  <Link
                    key={alert.id}
                    href={slackAlertPath(alert.id)}
                    style={{ textDecoration: 'none' }}
                    fontSize="sm"
                    p="2"
                    _hover={{ bg: 'gray.50' }}
                    rounded="md"
                  >
                    <Box p="2">
                      <SlackAlertTitle alert={alert} />
                    </Box>
                  </Link>
                  {index < props.slack_alerts.length - 1 && <Divider key={alert.id + 'div'} />}
                </>
              ))}
            </Stack>
          )}

          {props.slack_alerts.length === 0 && props.views.length > 0 && (
            <Stack spacing="4" bg="white" p="8" rounded="md">
              <Stack spacing="2">
                <HStack>
                  <Img boxSize={4} src={channelLogos.slack} />
                  <Heading size="xs">Create your first Team Alert</Heading>
                </HStack>
                <Text fontSize="sm">
                  Create your first Team Alert, so folks on your team can have a shared setup for Notifications.
                </Text>
              </Stack>
              <Button
                leftIcon={<IconPlus size="12" />}
                size="sm"
                colorScheme={'purple'}
                as={Link}
                href={projectPath(`/slack-alerts/new`)}
              >
                Create Slack Alert
              </Button>
            </Stack>
          )}

          {props.slack_alerts.length === 0 && props.views.length === 0 && (
            <Stack spacing="4" bg="white" p="8" rounded="md">
              <Stack spacing="2">
                <HStack>
                  <Img boxSize={4} src={channelLogos.slack} />
                  <Heading size="xs">Create your first Team List & Alert</Heading>
                </HStack>
                <Text fontSize="sm">Team Slack Alerts require that you create a Team List first.</Text>
              </Stack>

              <Button
                leftIcon={<IconPlus size="12" />}
                size="sm"
                colorScheme={'purple'}
                as={Link}
                variant="outline"
                href={projectPath(`/views/new?account_view[ownership]=team&account_view[team_id]=${props.team.id}`)}
              >
                Create List
              </Button>
            </Stack>
          )}
        </Stack>

        <Stack spacing={4} paddingBottom={20}>
          <Heading size="sm" fontWeight={'semibold'}>
            Delete team
          </Heading>
          <Text fontSize="sm" color="gray.600">
            This will delete the "{props.team.name}" team and all of its settings. However, members in this team will
            still be a part of the workspace.
          </Text>

          <form
            action={projectPath(`/settings/teams/${props.team.id}`)}
            method="POST"
            onSubmit={() => setDeleting(true)}
          >
            <AuthenticityToken />
            <input type="hidden" name="_method" value="DELETE" />
            <Button
              type="submit"
              colorScheme="red"
              alignSelf="flex-start"
              size="sm"
              isLoading={deleting}
              isDisabled={!canManageMembers}
            >
              Delete team
            </Button>
          </form>
        </Stack>
      </Stack>
    </PageLayout>
  )
}

interface ListPreviewProps {
  list: AccountView
}

function ListPreview(props: ListPreviewProps) {
  const { list } = props
  const filterSummary = filtersAsText(list.filters ?? {})

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      bg="white"
      p="2"
      _hover={{ bg: 'gray.50' }}
      rounded="md"
    >
      <HStack width="100%" spacing={2}>
        <Center flex="none" width={8}>
          <Icon as={list.kind === 'profile' ? IconUsers : BuildingIcon} color="gray.600" boxSize={5} />
        </Center>
        <Box flex="1">
          <HStack spacing={1.5}>
            <Text fontSize="sm" fontWeight="medium" lineHeight="20px">
              {list.name || 'Unknown list'}
            </Text>
          </HStack>
          <Text fontSize="xs" fontStyle="italic" color="gray.500">
            {filterSummary ?? `No applicable filters (all ${list.kind === 'profile' ? 'visitors' : 'accounts'})`}
          </Text>
        </Box>
      </HStack>
    </Flex>
  )
}
