import { HStack, Square, Stack, Heading, Badge, Text, Flex } from '@chakra-ui/react'
import React from 'react'
import { Company } from '../../../../types/Profile'
import { AiSparklesIcon } from '../../../ui/icons'

interface Props {
  company?: Company
}

export function ProspectorHeader(props: Props) {
  return (
    <Flex alignItems="flex-start" gap={4}>
      <Square padding={2} border="1px solid" borderColor="gray.200" bg="white" shadow="sm" rounded="md">
        <AiSparklesIcon color="accent.500" boxSize={6} />
      </Square>
      <Stack flex="1 1 auto" spacing={1}>
        <HStack>
          <Heading size="sm" lineHeight={1.5}>
            Prospector
          </Heading>
          <Badge colorScheme="purple">Beta</Badge>
        </HStack>
        <Text fontSize="sm" color="gray.500" maxW="550px">
          Find contact data for top prospects from{' '}
          {props.company ? props.company.name || props.company.domain : 'companies in your ICP'}. Discover your buyer
          personas and find your best path to multithread the account.
        </Text>
      </Stack>
    </Flex>
  )
}

export function ProspectorTitle() {
  return (
    <HStack spacing={3}>
      <AiSparklesIcon color="accent.500" boxSize={8} />
      <Heading size="lg" fontWeight="semibold">
        Koala Prospector
      </Heading>
    </HStack>
  )
}
