import { DisconnectAppDialog } from '@app/components/pages/apps/components/DisconnectAppDialog'
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { IconCalendar } from '@tabler/icons-react'
import uniq from 'lodash/uniq'
import React, { useEffect } from 'react'
import { toast } from 'sonner'
import { get, postForm } from '../../../../lib/api'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import Avatar from '../../../ui/Avatar'
import { LightBgCard as Card } from '../../../ui/Card'
import PageDescription from '../../../ui/PageDescription'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { usePermission } from '../../../ui/PermissionsContext'
import { projectPath } from '../../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../../ui/SettingsBreadCrumb'
import { User } from '../../../ui/UserContext'

interface Props {
  title: string
  description: string
  logo: string
  settings: {
    general?: string
    overrides?: Array<{ user_id: string; url: string }>
  }
  deps: {
    agents: User[]
  }
  hideBreadcrumbs?: boolean
  onSave?: () => unknown
}

type AgentCalendar = User & {
  url?: string
}

interface ModalProps {}

export function CalendlyModal(props: ModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const [baseProps, setBaseProps] = React.useState<Props | null>(null)

  useEffect(() => {
    if (baseProps !== null) {
      return
    }

    get(projectPath('/apps/calendly')).then((props) => {
      setBaseProps(props as Props)
    })
  }, [props, baseProps, onOpen])

  const calendarsSetUp = React.useMemo(() => {
    const general = baseProps?.settings.general
    const overrides = baseProps?.settings.overrides?.filter((override) => !!override.url).map((o) => o.url) || []

    return uniq([general, ...overrides].filter((url) => !!url))
  }, [baseProps])

  return (
    <>
      <HStack w="100%">
        {calendarsSetUp.length > 0 && (
          <VStack w="100%" alignItems="normal" spacing="0.5">
            <Heading size="xs">Calendars</Heading>
            {calendarsSetUp.map((url) => (
              <Link fontSize="sm" href={url} isExternal key={url}>
                {url}
              </Link>
            ))}
          </VStack>
        )}
        <Button
          variant="ghost"
          onClick={onOpen}
          colorScheme="purple"
          minW="20"
          leftIcon={<IconCalendar />}
          isLoading={baseProps === null}
          isDisabled={!canEditProject}
        >
          Configure
        </Button>
      </HStack>

      {baseProps !== null && (
        <Modal isOpen={isOpen} onClose={onClose} size="full" preserveScrollBarGap>
          <ModalOverlay />
          <ModalContent bg="gray.100">
            <ModalHeader>Calendly</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Show
                {...baseProps}
                hideBreadcrumbs
                onSave={() => {
                  setBaseProps(null)
                  onClose()
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default function Show(props: Props) {
  const allRows: AgentCalendar[] = props.deps.agents.map((agent) => {
    const url = props.settings.overrides?.find((override) => override.user_id === agent.id)?.url

    return {
      ...agent,
      url
    }
  })

  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const [rows, setRows] = React.useState(allRows)

  const onFormSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const data = new FormData(event.target as HTMLFormElement)

      postForm(projectPath('/apps/calendly'), data)
        .then(() => {
          toast.success('Calendly settings updated')
          props.onSave?.()
        })
        .catch(() => {
          toast.error('Uh oh, there was an issue saving your change')
        })
    },
    [props]
  )

  return (
    <PageLayout size="sm">
      {!props.hideBreadcrumbs && (
        <SettingsBreadCrumb
          rootPath={{ path: projectPath('/apps'), title: 'Integrations' }}
          paths={[
            {
              path: projectPath('/apps/calendly'),
              title: 'Calendly'
            }
          ]}
          offscreen
        />
      )}
      <HStack>
        <Image src={props.logo} maxW="6" />
        <PageTitle flex="1">{props.title}</PageTitle>

        {Object.keys(props.settings).length > 0 && (
          <DisconnectAppDialog appTitle={'Calendly'} showRemoveCachesOption={false} />
        )}
      </HStack>
      <PageDescription>{props.description}</PageDescription>

      <VStack
        as="form"
        w="100%"
        method="POST"
        spacing="6"
        onSubmit={(e) => onFormSubmit(e as unknown as React.FormEvent<HTMLFormElement>)}
      >
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PUT" />

        <Card w="100%">
          <VStack w="100%" alignItems="normal" spacing="8">
            <FormControl id="general">
              <FormLabel>Main Calendar</FormLabel>
              <Input
                bg="white"
                w="100%"
                name="app_instance_settings[general]"
                placeholder="General Calendly Link"
                defaultValue={props.settings['general'] ?? ''}
                type="url"
                required
              />
              <FormHelperText>
                A generic link that can be used as a fallback if no user is currently online.
              </FormHelperText>
            </FormControl>
          </VStack>
        </Card>

        <Card w="100%">
          <FormControl id="overrides">
            <VStack w="100%" alignItems="normal">
              <FormLabel>Agent Specific Calendars</FormLabel>

              {rows.map((row) => {
                return (
                  <HStack key={row.id}>
                    <Input name="app_instance_settings[overrides][][user_id]" value={row.id} type="hidden" />
                    <HStack spacing="2" pr="4" flex={1}>
                      <Avatar size="sm" src={row.image} />
                      <Text
                        fontSize="sm"
                        maxW="20"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        wordBreak="break-all"
                      >
                        {row.name}
                      </Text>
                    </HStack>

                    <Input
                      flex={3}
                      w="100%"
                      onChange={(e) => {
                        setRows((rows) => rows.map((r) => (r.id === row.id ? { ...r, url: e.target.value } : r)))
                      }}
                      bg="white"
                      value={row.url}
                      type="url"
                      name="app_instance_settings[overrides][][url]"
                      placeholder="Calendly Link"
                    />
                    <Button
                      isDisabled={!row.url}
                      onClick={() => {
                        setRows((rows) => rows.map((r) => (r.id === row.id ? { ...r, url: '' } : r)))
                      }}
                    >
                      clear
                    </Button>
                  </HStack>
                )
              })}
            </VStack>

            <FormHelperText>
              Find your calendly links at:{' '}
              <Link variant="primary" href="https://calendly.com/event_types/user/me" isExternal>
                https://calendly.com/event_types/user/me
              </Link>
            </FormHelperText>
          </FormControl>
        </Card>

        <HStack w="100%">
          <Button colorScheme="purple" type="submit" w="100%" isDisabled={!canEditProject}>
            Save
          </Button>
        </HStack>
      </VStack>
    </PageLayout>
  )
}
