import {
  Button,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { IconArrowRight, IconExternalLink, IconRefresh } from '@tabler/icons-react'
import React, { useCallback, useMemo } from 'react'
import { toast } from 'sonner'
import type { Account, CRMMatch } from '../../../../types/Account'
import type { App } from '../../../../types/App'
import { AppActions } from '../../../../types/AppActions'
import { DetailsCard } from '../../../ui/Card'
import { CardHeading } from '../../../ui/CardHeading'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { SfAccountIcon } from '../../../ui/icons'
import { projectPath } from '../../../ui/ProjectsContext'
import { StackedField } from '../../../ui/StackedField'
import { TimeAgo } from '../../../ui/TimeAgo'
import useFuzzyCrmMatches from '../../../ui/useFuzzyCrmMatches'
import { AssignCrmOwnerModal } from './AssignCrmOwnerModal'
import { CRMDebugPanel } from './CrmProperties'
import { useUIState } from './DetailsV2/useUIState'

interface SalesforceCardProps {
  account?: Account
  app?: App
  actions?: AppActions
  match?: CRMMatch
  crmEntity?: CRMMatch['crm_entity']
  initiallyCollapsed?: boolean
  onRequestImport?: (app: App) => void
}

export function SalesforceCard(props: SalesforceCardProps) {
  const [ui, setUI] = useUIState()

  const claimDisclosure = useDisclosure()
  const cardDisclosure = useDisclosure({
    defaultIsOpen: ui.open?.salesforce ?? !props.initiallyCollapsed,
    onOpen: () => {
      setUI({ open: { salesforce: true } })
    },
    onClose: () => {
      setUI({ open: { salesforce: false } })
    }
  })

  const [lastRefreshed, setLastRefreshed] = React.useState<Date | null>(null)
  const onRefetch = useCallback(() => {
    toast(
      'Salesforce account details updated. Please note that it may take a few moments for the changes to take effect on your searches and filters'
    )
  }, [])

  const crmMatches = useFuzzyCrmMatches({
    account: props.account,
    app: props.app,
    fuzzy: false
  })

  const crmEntity = useMemo(() => {
    return crmMatches.mainCrmMatch?.crm_entity
  }, [crmMatches])

  const match = useMemo(() => {
    return crmMatches.mainCrmMatch
  }, [crmMatches])

  const refresh = useCallback(() => {
    crmMatches
      .refetch({
        shouldReset: false,
        hardRefresh: true
      })
      ?.then(() => {
        setLastRefreshed(new Date())
        onRefetch()
      })
  }, [crmMatches, onRefetch])

  return (
    <DetailsCard>
      <CardHeading icon={SfAccountIcon} disclosure={cardDisclosure}>
        <HStack spacing={1} justifyContent="space-between">
          <Text>Salesforce Account</Text>

          {props.app?.connected && (
            <Tooltip label="Refresh Salesforce record" placement="top" hasArrow arrowSize={6}>
              <IconButton
                variant="ghost"
                size="xs"
                aria-label="Refresh"
                icon={<IconRefresh size={16} />}
                color="gray.500"
                _hover={{ color: 'purple.600' }}
                isLoading={crmMatches.isLoading}
                onClick={(e) => {
                  e.preventDefault()
                  refresh()
                }}
              />
            </Tooltip>
          )}
          {crmEntity?.permalink && (
            <Tooltip label="View in Salesforce" placement="top" hasArrow arrowSize={6}>
              <IconButton
                aria-label="View in Salesforce"
                as={Link}
                href={crmEntity.permalink}
                isExternal
                size="xs"
                variant="link"
                icon={<IconExternalLink size={16} />}
                color="gray.500"
                _hover={{ color: 'purple.600' }}
                onClick={() => {
                  window.ko?.track('Salesforce Visit Action', {
                    app: 'salesforce',
                    account: crmEntity
                  })
                }}
              />
            </Tooltip>
          )}
        </HStack>
      </CardHeading>
      {!cardDisclosure.isOpen ? null : crmEntity ? (
        <Stack spacing={4}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <StackedField label="Name">{crmEntity.name}</StackedField>
            <StackedField label="Type">{crmEntity.account_type}</StackedField>
            <StackedField label="Account Owner">{crmEntity.account_owner}</StackedField>
            <StackedField label="Opportunity Stage">{crmEntity.stage_name?.join(',')}</StackedField>
            <StackedField label="Last Modified">
              <TimeAgo time={crmEntity.external_last_modified_at} mode="calendar" />
            </StackedField>

            <StackedField label="Last Successful Sync">
              <TimeAgo time={lastRefreshed ?? crmEntity.last_finished_integration_sync?.stopped_at} mode="calendar" />
              {(crmEntity.last_finished_integration_sync || lastRefreshed) && (
                <HelpTooltip>
                  <Stack>
                    <Heading size="xs">Last Successful Sync</Heading>
                    <Text>
                      Koala last pulled your Salesforce account for changes{' '}
                      <TimeAgo time={lastRefreshed ?? crmEntity.last_finished_integration_sync?.started_at} />.
                    </Text>
                  </Stack>
                </HelpTooltip>
              )}
            </StackedField>
          </Grid>

          {props.actions?.sf_claim_in_crm?.enabled && match && (
            <>
              <Button variant="outline" width="full" size="sm" onClick={claimDisclosure.onOpen}>
                Claim in Salesforce…
              </Button>
              <AssignCrmOwnerModal
                {...claimDisclosure}
                app={props.app}
                account={match}
                appModule="Apps::Salesforce::App"
              />
            </>
          )}

          <CRMDebugPanel crmEntity={crmEntity} strongMatches={crmMatches.strongMatches} />
        </Stack>
      ) : props.app?.connected ? (
        <Stack>
          {!crmMatches.isLoading && (
            <Text fontSize="xs" color="gray.500">
              No accounts linked yet
            </Text>
          )}
          {props.actions?.sf_import_accounts?.enabled && props.onRequestImport ? (
            <Button
              variant="outline"
              width="full"
              size="sm"
              onClick={() => {
                window.ko?.track('Salesforce Import Action', {
                  app: 'salesforce',
                  account: match
                })
                props.onRequestImport?.(props.app!)
              }}
            >
              Create account…
            </Button>
          ) : // TODO stuff for enabling the write action
          null}
        </Stack>
      ) : (
        <>
          <Text fontSize="xs" color="gray.600">
            Connect the Salesforce integration to see details from Salesforce or sync data from Koala.
          </Text>
          <Button
            alignSelf="flex-start"
            size="sm"
            variant="outline"
            as="a"
            href={projectPath(`/apps/salesforce?return_to=${encodeURIComponent(window.location.pathname)}`)}
            iconSpacing={1}
            rightIcon={<Icon as={IconArrowRight} boxSize={4} />}
          >
            Connect
          </Button>
        </>
      )}
    </DetailsCard>
  )
}
