import { Box, Button, Collapse, Flex, Heading, HStack, Spinner, Stack, Text } from '@chakra-ui/react'
import { IconArrowDown, IconArrowRight, IconMail } from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useMountedState } from 'react-use'
import { toast } from 'sonner'
import { concurrentGET, post } from '../../../../lib/api'
import { Apps } from '../../../../types/App'
import { useFacets } from '../../../data/use-facets'
import { LightBgCard } from '../../../ui/Card'
import { projectPath } from '../../../ui/ProjectsContext'
import { useCurrentUser } from '../../../ui/UserContext'
import { FacetFilters } from '../../accounts'
import { FilterPreview } from '../../accounts/components/FilterPreview'
import { accountViewPath } from '../../account_views/lib/list-paths'
import { AccountList } from '../../icps/icp/account-list'
import { HighlightedAccount } from '../../icps/types'
import { HeroImage } from '../components/HeroImage'
import { Step } from '../components/SetupProject'
import { StepProps } from '../rep_onboarding'
import img from './my-accounts.png'

export function RepAlternativeEmail(
  props: StepProps & {
    accounts: HighlightedAccount[]
    apps: Apps
    my_accounts_filters?: FacetFilters
  }
) {
  const [loading, setLoading] = React.useState(false)
  const [accounts, setAccounts] = React.useState<HighlightedAccount[]>([])
  const [loadingMine, setIsLoadingMine] = React.useState(false)
  const [confirmed, setConfirmed] = React.useState(false)

  const safeFilters = useMemo(() => {
    let asObject = props.my_accounts_filters ?? {}
    if (asObject['_or']) {
      asObject = asObject['_or'][0]
    }

    return asObject
  }, [props.my_accounts_filters])

  const facets = useFacets({
    facet_filters: safeFilters
  })

  const newFacets = useFacets({
    facet_filters: {}
  })

  const user = useCurrentUser()
  const allowSave = useMemo(() => {
    if (newFacets.queryString.length > 0) {
      return true
    }

    return accounts.length > 0
  }, [newFacets.queryString, accounts])

  const mounted = useMountedState()

  useEffect(() => {
    const query = newFacets.queryString
    const path = `${projectPath('/accounts')}${query}&no_defaults=true&range=all`

    setConfirmed(false)
    if (query.length === 0) {
      setAccounts([])
      return
    }

    setLoading(true)
    concurrentGET<{ accounts: HighlightedAccount[] }>(path).then((res) => {
      if (!mounted()) return
      setAccounts(res.accounts)
      setLoading(false)
    })
  }, [newFacets.queryString, mounted])

  const updateFilters = useCallback(() => {
    setIsLoadingMine(true)

    post(accountViewPath({ slug: 'mine' }) + newFacets.queryString).then(() => {
      setIsLoadingMine(false)
      setConfirmed(true)
      toast.success('Filters updated', {
        description: 'Your filters have been confirmed.'
      })
    })
  }, [newFacets.queryString])

  return (
    <Step
      cardProps={{ maxW: 800 }}
      {...props}
      onBack={
        confirmed
          ? () => {
              setConfirmed(false)
            }
          : props.onBack
      }
    >
      <HeroImage src={img} h="180px" />
      <Stack p="8" spacing="8" w="100%" pt="0">
        {!confirmed && (
          <>
            <Heading size="md">We couldn't find accounts assigned to you, {user.name}</Heading>
            <Stack>
              <Text fontSize="sm" color="gray.500">
                Our default filter below doesn’t seem to be finding the right accounts
              </Text>
              <LightBgCard bg="gray.50" as={Stack}>
                <Heading size="xs">CURRENT FILTER</Heading>
                <FilterPreview
                  {...facets}
                  isEditable={false}
                  canClearFilters={false}
                  kind="account"
                  apps={props.apps}
                  shouldShowIntentFilters={false}
                  range={undefined}
                />
              </LightBgCard>
            </Stack>

            <Flex justifyContent={'center'}>
              <IconArrowDown />
            </Flex>

            <Stack>
              <Heading size="sm">Help us to find your data by trying another filter</Heading>
              <Text fontSize="sm" color="gray.500">
                Select new filters that correspond to the accounts you own
              </Text>
              <LightBgCard bg="gray.50" w="100%">
                <Stack spacing={4}>
                  <HStack>
                    <Heading size="xs">NEW FILTER</Heading>
                    {loading && <Spinner />}
                  </HStack>
                  <FilterPreview
                    {...newFacets}
                    canClearFilters={false}
                    kind="account"
                    apps={props.apps}
                    shouldShowIntentFilters={false}
                    range={undefined}
                  />
                </Stack>
                <Collapse in={accounts.length > 0} unmountOnExit>
                  <Box maxH="200" overflow={'auto'} paddingTop={4}>
                    <AccountList compact useExternalLinks accounts={accounts} showActions={false} />
                  </Box>
                </Collapse>
              </LightBgCard>
            </Stack>
          </>
        )}
        {!confirmed && (
          <Stack>
            <Button
              flex="none"
              colorScheme={'purple'}
              onClick={updateFilters}
              isLoading={loadingMine}
              disabled={!allowSave}
            >
              {allowSave ? 'Looks good!' : 'Select a filter'}
            </Button>
            <Button variant={'ghost'} onClick={props.onSkip} isLoading={loadingMine}>
              Skip this step
            </Button>
          </Stack>
        )}

        {confirmed && (
          <Stack p="8" spacing="8" w="100%" pt="0">
            <Heading size="md" fontWeight={'semibold'}>
              🎉 Nice! Your accounts have been set up.
            </Heading>
            <Stack>
              <Text>
                Now let's get you set up with <strong>Koala Weekly</strong> emails.
              </Text>
              <HStack bg="gray.50" p="8" borderWidth="1px" rounded="md" spacing="4">
                <IconMail size="32" />
                <Text>
                  You'll be able to subscribe to Weekly Emails containing the hottest accounts from a variety of
                  Audiences.
                </Text>
              </HStack>
            </Stack>

            <Button colorScheme={'purple'} onClick={props.onContinue} rightIcon={<IconArrowRight size="18" />}>
              Continue
            </Button>
          </Stack>
        )}
      </Stack>
    </Step>
  )
}
