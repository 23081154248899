import { Text } from '@chakra-ui/react'
import React from 'react'
import { AccountView } from '../../../../types/AccountView'
import { DeleteConfirmation } from '../../../ui/DeleteConfirmation'
import { projectPath } from '../../../ui/ProjectsContext'

function DeleteViewConfirmationModal({ view, onClose }: { view: AccountView; onClose: () => void }) {
  return (
    <DeleteConfirmation
      isOpen
      onClose={onClose}
      title="Delete list"
      deletePath={projectPath(`/views/${view.slug}`)}
      confirmLabel="Delete list"
    >
      Are you sure you want to delete{' '}
      <Text as="span" fontWeight="semibold">
        {view.name}
      </Text>
      ? This action can not be undone.
    </DeleteConfirmation>
  )
}

export default DeleteViewConfirmationModal
