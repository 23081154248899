import React from 'react'

import { Stack, HStack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Link, Text, Spinner } from '@chakra-ui/react'

import { TimeAgo } from '../../../ui/TimeAgo'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import { AdminBreadcrumb } from '..'
import { IconClock, IconArrowRight, IconCircleCheck } from '@tabler/icons-react'
import { get } from '@app/lib/api'
import SyncDuration from '../components/SyncDuration'

interface DataTaskSupervisor {
  id: number
  task_name: string
  description: string
  created_at: string
  updated_at: string
  pid: number
  status: 'running' | 'done'
  metadata: Record<string, any>
}

interface Props {
  tasks: DataTaskSupervisor[]
}

export const iconStatus = {
  running: <Spinner />,
  pending: <IconClock color="gray" />,
  done: <IconCircleCheck color="green" />
}

export default function Index(props: Props) {
  const [tasks, setTasks] = React.useState<DataTaskSupervisor[]>(props.tasks)
  const [refreshedAt, setRefreshedAt] = React.useState(Date.now())

  const fetchData = async () => {
    const data = await get<Props>(`/admin/data-tasks`)

    setTasks(data.tasks)
    setRefreshedAt(Date.now())
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      fetchData()
    }, 60000)

    return () => clearInterval(interval)
  })

  return (
    <PageLayout size="md">
      <HStack>
        <PageTitle flex="1">Data Tasks</PageTitle>
        <Text fontSize={'xs'}>Last refresh: {new Date(refreshedAt).toLocaleTimeString()}</Text>
      </HStack>

      <AdminBreadcrumb paths={[{ path: '/admin/data-tasks', title: 'Data Tasks' }]} />

      <Stack spacing={8}>
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Task</Th>
                <Th>Status</Th>
                <Th>Recovered</Th>
                <Th>Time</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {tasks.map((task) => (
                <Tr
                  key={task.id}
                  _hover={{ bg: 'gray.50' }}
                  onClick={() => (window.location.href = `/admin/data-tasks/${task.id}`)}
                  style={{ cursor: 'pointer' }}
                >
                  <Td>{task.id}</Td>
                  <Td>{task.task_name}</Td>
                  <Td minWidth={100}>
                    <HStack>
                      {iconStatus[task.status]}
                      <Text>{task.status}</Text>
                    </HStack>
                  </Td>
                  <Td>{task.metadata.recovered ? 'Recovered' : 'No'}</Td>
                  <Td>
                    <SyncDuration
                      start={task.metadata.started_at}
                      stop={task.metadata.completed_at}
                      status={task.status}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </PageLayout>
  )
}
