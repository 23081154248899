import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import React, { FormEvent, useMemo } from 'react'
import { toast } from 'sonner'
import { postForm } from '../../../../lib/api'
import { Account } from '../../../../types/Account'
import { App } from '../../../../types/App'
import { Sequence } from '../../../../types/Outreach'
import { useAppDep } from '../../../data/use-app-dep'
import { useOutreachSequences } from '../../../data/use-outreach-sequences'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { projectPath } from '../../../ui/ProjectsContext'
import { useCurrentUser } from '../../../ui/UserContext'

interface AddToSequenceModalProps extends UseDisclosureProps {
  profile: {
    id: string
    email: string
  }
  profileType?: 'Profile' | 'ProspectedProfile'
  account?: Account
  app: App
}

export function AddToOutreachSequenceModal(props: AddToSequenceModalProps) {
  const disclosure = useDisclosure(props)
  const onClose = disclosure.onClose
  const currentUser = useCurrentUser()
  const [saving, setSaving] = React.useState(false)

  const [selectedSequence, setSelectedSequence] = React.useState<Sequence | null>(null)
  const [selectedOwnerEmail, setSelectedOwnerEmail] = React.useState<string | null>(currentUser?.email ?? null)

  const {
    data: sequenceData,
    isLoading: loadingSequences,
    isError: sequencesError,
    error: sequencesErrorData
  } = useOutreachSequences(props.app.app_instance_id)

  const { data: usersData, isLoading: loadingUsers } = useAppDep<'users', any[]>('Outreach', 'users')

  const selectedOwner = useMemo(
    () =>
      usersData?.data?.users?.find((s) => {
        return s.attributes.email.toString() === selectedOwnerEmail?.toString()
      }) ?? null,
    [usersData, selectedOwnerEmail]
  )

  const seq = useMemo(
    () => selectedSequence ?? sequenceData?.outreach_sequences?.[0] ?? null,
    [selectedSequence, sequenceData]
  )

  const onSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      if (!seq) return

      setSaving(true)

      const form = e.target as HTMLFormElement
      const data = new FormData(form)

      postForm(
        projectPath(
          `/outreach/actions/sequences?profile_id=${props.profile.id}&profile_type=${props.profileType ?? 'Profile'}`
        ),
        data
      )
        .then(() => {
          toast.success('Prospect added to Sequence!', {
            description: `${props.profile.email} has been added to ${seq.name} in Outreach.`
          })
          setSaving(false)
          onClose()
        })
        .catch((e) => {
          toast.error('Error adding prospect to sequence', {
            description: e.message
          })
          setSaving(false)
        })
    },
    [props, seq, onClose]
  )

  return (
    <Modal {...disclosure} size="md" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <HStack>
            <CompanyAvatar domain={props.account?.company?.domain} />
            <Stack spacing="0">
              <Heading size="sm">Add to Outreach Sequence</Heading>
              <Text fontSize={'sm'} fontWeight="normal">
                Contact: {props.profile.email}
              </Text>
            </Stack>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="6">
          <form onSubmit={onSubmit}>
            <Stack fontSize="sm" spacing={'4'}>
              <FormControl>
                <FormLabel>Sequence</FormLabel>
                {loadingSequences && <Spinner size="sm" />}
                {sequencesErrorData && (
                  <Text p="4" bg="orange.50" m="2">
                    {' '}
                    {(sequencesErrorData as any)?.body?.error}
                  </Text>
                )}
                {!sequencesError && !loadingSequences && (
                  <ComboboxWithSearch
                    items={sequenceData?.outreach_sequences ?? []}
                    selectedItem={seq}
                    onChange={(selectedItem) => {
                      setSelectedSequence(selectedItem)
                    }}
                    filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                    itemToString={(item) => item?.name || ''}
                  />
                )}
              </FormControl>

              <FormControl>
                <FormLabel>Send Emails From</FormLabel>
                {loadingUsers && <Spinner size="sm" />}
                {!loadingUsers && !usersData?.data?.users?.length && (
                  <Text p="4" bg="orange.50" m="2">
                    We couldn't find any email accounts to display. Make sure to check the API key permissions in your
                    Outreach workspace.
                  </Text>
                )}
                <ComboboxWithSearch
                  items={usersData?.data.users ?? []}
                  selectedItem={selectedOwner}
                  onChange={(selectedItem) => {
                    setSelectedOwnerEmail(selectedItem.attributes.email)
                  }}
                  filterItem={(a, val) => (a?.attributes?.email ?? '').toLowerCase().includes(val)}
                  itemToString={(item) => item?.attributes?.email || ''}
                />
              </FormControl>

              <Flex w="100%" pt="4">
                <Button
                  w="100%"
                  size="sm"
                  colorScheme={'purple'}
                  type="submit"
                  isLoading={saving || loadingUsers || loadingSequences}
                  disabled={sequencesError}
                >
                  Add to Sequence
                </Button>
              </Flex>
            </Stack>

            {seq && <input type="hidden" name="sequence[sequence_id]" value={seq.outreach_id} />}
            <input type="hidden" name="sequence[profile_id]" value={props.profile.id} />
            {props.account && <input type="hidden" name="sequence[account_id]" value={props.account.id} />}
            {selectedOwnerEmail && <input type="hidden" name="sequence[owner_email]" value={selectedOwnerEmail} />}
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
