import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react'
import {
  IconCalendarTime,
  IconClick,
  IconClock,
  IconHelp,
  IconNotebook,
  IconPlus,
  IconTransferIn,
  IconTransform,
  IconTrendingUp,
  IconUsers,
  IconWorldWww
} from '@tabler/icons-react'
import { motion } from 'framer-motion'
import update from 'immutability-helper'
import { nanoid } from 'nanoid'
import React, { useCallback, useMemo, useState } from 'react'
import { GrayCard } from '../../../ui/Card'
import { CardRadioGroup } from '../../../ui/CardRadioGroup'
import { HoverCard } from '../../../ui/HoverCard'
import { TopK } from '../../../ui/top-k-v2'
import { useHover } from '../../../ui/use-hover'
import { useSearchParams } from '../../../ui/useSearchState'
import { titleize } from '../../accounts/facets/filter-cloud'
import { Change, ChangeOperator } from '../../kql_definitions/components/Condition'
import { operators } from '../../kql_definitions/components/triggers'
import { ConditionType, JSONArray } from '../../kql_definitions/types'
import { FollowRule } from '../../notifications'
import { TriggerMultiSelect } from '../../slack_alerts/components/DefinitionForm'

interface Props {
  follow_rule?: FollowRule
  targetType: 'Profile' | 'Account'
}

type ConditionValue = ConditionType & {
  onChange: (condition: Partial<ConditionType>) => void
  isHovered?: boolean
}

const simpleStringOperators = operators.filter(
  (o) => (o.supportedType === 'string' || !o.supportedType) && o.needsValue
)
function Form(props: ConditionValue) {
  const addOrRule = useCallback(() => {
    props.onChange({
      value: [...arr(props.value), '']
    })
  }, [props])

  const values = useMemo(() => arr(props.value), [props.value])

  return (
    <FormControl w="100%">
      <Stack w="100%" spacing="4">
        <HStack w="100%" alignItems={'center'} spacing="4">
          <HStack spacing="4">
            <Icon as={IconNotebook} boxSize={3.5} />
            <FormLabel m="0">Form Submission</FormLabel>
          </HStack>
          <Select
            size="xs"
            rounded="md"
            flex="2.5"
            bg="white"
            value={props.operator}
            onChange={(e) => {
              props.onChange({
                operator: e.target.value
              })
            }}
          >
            {simpleStringOperators.map((o) => (
              <option key={o.name} value={o.name}>
                {o.label}
              </option>
            ))}
          </Select>
          <OperatorHint operator={props.operator} />
          {values.length > 1 && <Text fontSize={'xs'}>any of</Text>}
          <Flex w="100%" flex="6" direction={'column'} gap="1.5">
            {values.map((val, index) => (
              <HStack key={index}>
                <TopK
                  property="form_fill.page_path"
                  defaultValue={val}
                  skipExamples={index > 0}
                  onChange={(value) => {
                    props.onChange({
                      value: [...values.slice(0, index), value, ...values.slice(index + 1)]
                    })
                  }}
                  inputProps={{
                    w: '100%',
                    isRequired: true,
                    size: 'xs',
                    rounded: 'md'
                  }}
                />
                {index > 0 && (
                  <Button
                    size="xs"
                    onClick={() => {
                      props.onChange({
                        value: [...values.slice(0, index), ...values.slice(index + 1)]
                      })
                    }}
                    variant="ghost"
                    fontSize={'sm'}
                  >
                    &times;
                  </Button>
                )}
              </HStack>
            ))}
          </Flex>
          <OrQualifier isVisible={props.isHovered} onClick={addOrRule} />
        </HStack>
      </Stack>
    </FormControl>
  )
}

function val(v: ConditionType['value']): string {
  if (Array.isArray(v)) {
    return v[0]?.toString() ?? ''
  }

  return v?.toString() ?? ''
}

function arr(v: ConditionType['value']): JSONArray {
  if (Array.isArray(v)) {
    return v
  }

  return [v] as JSONArray
}

function PageView(props: ConditionValue) {
  const addOrRule = useCallback(() => {
    props.onChange({
      value: [...arr(props.value), '']
    })
  }, [props])

  const values = useMemo(() => arr(props.value), [props.value])

  return (
    <FormControl>
      <Stack w="100%" spacing="4">
        <HStack w="100%" spacing="4">
          <HStack spacing="4">
            <Icon as={IconWorldWww} boxSize={3.5} />
            <FormLabel m="0">Page Path</FormLabel>
          </HStack>
          <Select
            size="xs"
            rounded="md"
            flex="2.5"
            bg="white"
            value={props.operator}
            onChange={(e) => {
              props.onChange({
                operator: e.target.value
              })
            }}
          >
            {simpleStringOperators.map((o) => (
              <option key={o.name} value={o.name}>
                {o.label}
              </option>
            ))}
          </Select>
          <OperatorHint operator={props.operator} />
          {values.length > 1 && <Text fontSize={'xs'}>any of</Text>}
          <Flex w="100%" flex="6" direction={'column'} gap="1.5">
            {values.map((val, index) => (
              <HStack key={index}>
                <TopK
                  property="page_view.path"
                  defaultValue={val}
                  skipExamples={index > 0}
                  onChange={(value) => {
                    props.onChange({
                      value: [...values.slice(0, index), value, ...values.slice(index + 1)]
                    })
                  }}
                  inputProps={{
                    w: '100%',
                    isRequired: true,
                    size: 'xs',
                    rounded: 'md'
                  }}
                />
                {index > 0 && (
                  <Button
                    size="xs"
                    onClick={() => {
                      props.onChange({
                        value: [...values.slice(0, index), ...values.slice(index + 1)]
                      })
                    }}
                    variant="ghost"
                    fontSize={'sm'}
                  >
                    &times;
                  </Button>
                )}
              </HStack>
            ))}
          </Flex>
          <OrQualifier isVisible={props.isHovered} onClick={addOrRule} />
        </HStack>
        <Flex pl="8">
          <HStack
            w="100%"
            alignItems={'flex-start'}
            spacing="4"
            borderLeftWidth={'medium'}
            py="1"
            mt="0.5"
            px="6"
            ml="1"
            borderColor={'gray.200'}
          >
            <HStack>
              <Text fontSize={'sm'}>with</Text>
              <FormLabel m="0">Time on page</FormLabel>
            </HStack>
            <Select
              size="xs"
              rounded="md"
              flex="2"
              bg="white"
              value={props.with?.operator}
              onChange={(e) => {
                props.onChange({
                  with: {
                    ...props.with!,
                    operator: e.target.value
                  }
                })
              }}
            >
              <option value="greater_than_or_equal">greater than</option>
              <option value="less_than_or_equal">less than</option>
            </Select>
            <Flex w="100%" flex="3" gap={1}>
              <Input
                value={val(props.with?.value)}
                onChange={(e) => {
                  props.onChange({
                    with: {
                      ...props.with!,
                      value: e.target.value
                    }
                  })
                }}
                bg="white"
                w="100%"
                isRequired
                size="xs"
                rounded={'md'}
              />
              <HoverCard
                hoverContent={
                  <Stack p="4" fontSize={'sm'}>
                    <Text>Time on page is the amount of time a user spends on a page before navigating away.</Text>
                    <Text>You can set any value in seconds or minutes</Text>
                    <Text fontWeight={'semibold'} pt="2">
                      Examples:
                    </Text>
                    <Stack spacing="1">
                      {['5 seconds', '30 seconds', '1 minute', '15 minutes'].map((v) => (
                        <HStack
                          justifyContent={'space-between'}
                          role="group"
                          w="100%"
                          px="2"
                          cursor={'pointer'}
                          key={v}
                          onClick={(e) => {
                            e.stopPropagation()
                            props.onChange({
                              with: {
                                ...props.with!,
                                value: v
                              }
                            })
                          }}
                          _hover={{
                            bg: 'gray.50'
                          }}
                        >
                          <Text>{v}</Text>
                          <IconButton
                            size="xs"
                            variant={'ghost'}
                            visibility="hidden"
                            _groupHover={{
                              visibility: 'visible'
                            }}
                            aria-label="Copy"
                            icon={<IconTransferIn size="10" />}
                          />
                        </HStack>
                      ))}
                    </Stack>
                  </Stack>
                }
              >
                <IconButton
                  onClick={() => {}}
                  size="xs"
                  variant={'ghost'}
                  aria-label="Examples"
                  icon={<IconHelp size={14} />}
                />
              </HoverCard>
            </Flex>
          </HStack>
        </Flex>
      </Stack>
    </FormControl>
  )
}

function OperatorHint({ operator }: { operator: string }) {
  const info = simpleStringOperators.find((o) => o.name === operator)?.info
  if (!info) {
    return null
  }

  return (
    <HoverCard
      hoverContent={
        <Box p="4">
          <Heading size="xs">Hint:</Heading>
          <Box pt="2" fontSize={'sm'} maxW="300px">
            {info}
          </Box>
        </Box>
      }
    >
      <IconButton size="xs" variant={'ghost'} icon={<IconHelp size={14} />} aria-label="Hint" />
    </HoverCard>
  )
}

function Event(props: ConditionValue) {
  const addOrRule = useCallback(() => {
    props.onChange({
      value: [...arr(props.value), '']
    })
  }, [props])

  const values = useMemo(() => arr(props.value), [props.value])

  return (
    <FormControl w="100%">
      <Stack w="100%" spacing="4">
        <HStack w="100%" alignItems={'center'} spacing="4">
          <HStack spacing="4">
            <Icon as={IconClick} boxSize={3.5} />
            <FormLabel m="0">Event name</FormLabel>
          </HStack>
          <Select
            size="xs"
            rounded="md"
            flex="2.5"
            bg="white"
            value={props.operator}
            onChange={(e) => {
              props.onChange({
                operator: e.target.value
              })
            }}
          >
            {simpleStringOperators.map((o) => (
              <option key={o.name} value={o.name}>
                {o.label}
              </option>
            ))}
          </Select>
          <OperatorHint operator={props.operator} />
          {values.length > 1 && <Text fontSize={'xs'}>any of</Text>}
          <Flex w="100%" flex="6" direction={'column'} gap="1.5">
            {values.map((val, index) => (
              <HStack key={index}>
                <TopK
                  property="event"
                  defaultValue={val}
                  skipExamples={index > 0}
                  onChange={(value) => {
                    props.onChange({
                      value: [...values.slice(0, index), value, ...values.slice(index + 1)]
                    })
                  }}
                  inputProps={{
                    w: '100%',
                    isRequired: true,
                    size: 'xs',
                    rounded: 'md'
                  }}
                />
                {index > 0 && (
                  <Button
                    size="xs"
                    onClick={() => {
                      props.onChange({
                        value: [...values.slice(0, index), ...values.slice(index + 1)]
                      })
                    }}
                    variant="ghost"
                    fontSize={'sm'}
                  >
                    &times;
                  </Button>
                )}
              </HStack>
            ))}
          </Flex>
          <OrQualifier isVisible={props.isHovered} onClick={addOrRule} />
        </HStack>
      </Stack>
    </FormControl>
  )
}

function Trait(props: ConditionValue & { type: 'profile' | 'account' }) {
  return (
    <FormControl w="100%">
      <Stack w="100%" spacing="4">
        <HStack w="100%" alignItems={'center'} spacing="4">
          <HStack spacing="4">
            <Icon as={IconUsers} boxSize={3.5} />
            <FormLabel m="0">{titleize(props.type)} Trait</FormLabel>
          </HStack>
          <Flex w="100%" flex="5">
            <TopK
              defaultValue={val(props.property)}
              property={props.type === 'profile' ? 'profile_trait.name' : 'account_trait.name'}
              onChange={(value) => {
                props.onChange({
                  property: value
                })
              }}
              inputProps={{
                w: '100%',
                isRequired: true,
                size: 'xs',
                rounded: 'md'
              }}
            />
          </Flex>
        </HStack>
        <Flex w="100%" pl="8">
          <HStack
            w="100%"
            alignItems={'flex-start'}
            spacing="8"
            borderLeftWidth={'medium'}
            py="1"
            mt="0.5"
            px="6"
            ml="1"
            borderColor={'gray.200'}
          >
            <FormLabel fontSize={'sm'} m="0">
              Changes
            </FormLabel>
            <ChangeOperator
              defaultValue={props.value as Change}
              onChange={(value) => {
                props.onChange({
                  value
                })
              }}
              condition={props}
              property={`${props.type}_trait.${props.value}.value`}
            />
          </HStack>
        </Flex>
      </Stack>
    </FormControl>
  )
}

function SessionTime(props: ConditionValue) {
  return (
    <FormControl w="100%">
      <Stack w="100%" spacing="4">
        <HStack w="100%" alignItems={'center'} spacing="4">
          <HStack spacing="4">
            <Icon as={IconClock} boxSize={3.5} />
            <FormLabel m="0">Active Session Time</FormLabel>
            <Text fontSize="sm">greater than</Text>
          </HStack>
          <Flex w="100%" flex="3">
            <Input
              value={val(props.value)}
              onChange={(e) => {
                props.onChange({
                  value: e.target.value
                })
              }}
              bg="white"
              w="100%"
              isRequired
              size="xs"
              rounded={'md'}
            />
            <HoverCard
              hoverContent={
                <Stack p="4" fontSize={'xs'}>
                  <Heading size="xs">Active Session Time</Heading>
                  <Text maxW="300px">
                    Active Session Time is the aggregated amount of time a user spends on your website before navigating
                    away across every page visit in a given time period.
                  </Text>
                  <Text>You can set any value in seconds or minutes</Text>
                  <Text fontWeight={'semibold'} pt="2">
                    Examples:
                  </Text>
                  <Divider />
                  <Stack spacing="1">
                    {['5 seconds', '30 seconds', '1 minute', '15 minutes'].map((v) => (
                      <HStack
                        justifyContent={'space-between'}
                        role="group"
                        w="100%"
                        px="2"
                        cursor={'pointer'}
                        key={v}
                        onClick={(e) => {
                          e.stopPropagation()
                          props.onChange({
                            value: v
                          })
                        }}
                        _hover={{
                          bg: 'gray.50'
                        }}
                      >
                        <Text>{v}</Text>
                        <IconButton
                          size="xs"
                          variant={'ghost'}
                          visibility="hidden"
                          _groupHover={{
                            visibility: 'visible'
                          }}
                          aria-label="Copy"
                          icon={<IconTransferIn size="10" />}
                        />
                      </HStack>
                    ))}
                  </Stack>
                </Stack>
              }
            >
              <IconButton
                onClick={() => {}}
                size="xs"
                variant={'ghost'}
                aria-label="Examples"
                icon={<IconHelp size={14} />}
              />
            </HoverCard>
          </Flex>
        </HStack>
        <Flex pl="8">
          <HStack
            w="100%"
            alignItems={'center'}
            spacing="4"
            borderLeftWidth={'medium'}
            py="1"
            mt="0.5"
            px="6"
            ml="1"
            borderColor={'gray.200'}
          >
            <Flex w="48">
              <FormLabel m="0">In the past...</FormLabel>
            </Flex>
            <Select
              bg="white"
              size="xs"
              rounded="md"
              value={val(props.property)}
              onChange={(e) => {
                props.onChange({
                  property: e.target.value
                })
              }}
            >
              <option value="trend.day">Day</option>
              <option value="trend.week">Week</option>
              <option value="trend.month">Month</option>
            </Select>
          </HStack>
        </Flex>
      </Stack>
    </FormControl>
  )
}

function VisitorAnalytics(props: ConditionValue & { targetType: 'Profile' | 'Account' }) {
  const timeUnit = useMemo(() => {
    return props.property.split('.')[2] ?? 'day'
  }, [props.property])

  const operator = useMemo(() => {
    const [variant, _type, _time] = props.property.split('.')
    if (variant === 'visitor_deltas' && props.operator.includes('greater_than')) {
      return 'increased'
    }

    if (variant === 'visitor_deltas' && props.operator.includes('less_than')) {
      return 'decreased'
    }

    return props.operator
  }, [props.operator, props.property])

  return (
    <FormControl w="100%">
      {props.targetType === 'Profile' && (
        <Alert mb={'4'} status="warning">
          <AlertIcon />
          <Stack spacing="0.5" my="2">
            <Heading size="xs">Visitor level Active Users metrics are not support.</Heading>
            <Text fontSize={'sm'} lineHeight={'1.2'} py="1">
              You can only use `Active Users Change` when notifying about accounts, not visitors. Please change your
              targeting criteria to Accounts.
            </Text>
          </Stack>
        </Alert>
      )}

      <Stack w="100%" spacing="4">
        <HStack w="100%" alignItems={'center'} spacing="4">
          <HStack spacing="4">
            <Icon as={IconTrendingUp} boxSize={3.5} />
            <FormLabel m="0">Total Active Users</FormLabel>
            <Select
              size="xs"
              rounded="md"
              w="32"
              bg="white"
              value={operator}
              onChange={(e) => {
                const selectedValue = e.target.value
                let op = e.target.value
                const [_variant, type, time] = props.property.split('.')
                let variant = ''

                if (selectedValue === 'increased') {
                  op = 'greater_than_or_equal'
                  variant = 'visitor_deltas'
                }

                if (selectedValue !== 'increased' && selectedValue !== 'decreased') {
                  variant = 'visitor_stats'
                }

                props.onChange({
                  operator: op,
                  property: [variant, type, time].join('.')
                })
              }}
            >
              <option value="greater_than">greater than</option>
              <option value="greater_than_or_equal">greater than or equal to</option>
              <option value="less_than">less than</option>
              <option value="less_than_or_equal">less than or equal to</option>
              <option value="is">equal to</option>
              <option value="increased">increased by</option>
            </Select>
          </HStack>
          <Flex w="100%" flex="5" gap="4">
            <Input
              type="number"
              size="xs"
              rounded="md"
              bg="white"
              min={1}
              value={parseInt(props.value?.toString() ?? '1')}
              onChange={(e) => {
                props.onChange({
                  value: e.target.value
                })
              }}
            />
          </Flex>
        </HStack>
        <Flex pl="8" flexDir={'column'} gap="0">
          <HStack
            w="100%"
            alignItems={'center'}
            spacing="4"
            borderLeftWidth={'medium'}
            py="1"
            mt="0.5"
            px="6"
            ml="1"
            borderColor={'gray.200'}
          >
            <Flex w="48">
              <FormLabel m="0">In the past...</FormLabel>
            </Flex>
            <Select
              bg="white"
              size="xs"
              rounded="md"
              value={timeUnit}
              onChange={(e) => {
                const [variant, type, _time] = props.property.split('.')
                props.onChange({
                  property: [variant, type, e.target.value].join('.')
                })
              }}
            >
              <option value="day">Day (DAU)</option>
              <option value="week">Week (WAU)</option>
              <option value="month">Month (MAU)</option>
            </Select>
          </HStack>
          <HStack
            w="100%"
            alignItems={'center'}
            spacing="4"
            borderLeftWidth={'medium'}
            py="1"
            px="6"
            ml="1"
            borderColor={'gray.200'}
          >
            <Checkbox
              size="sm"
              fontSize="sm"
              isChecked={props.property.includes('identified')}
              onChange={(e) => {
                const [variant, _type, time] = props.property.split('.')
                props.onChange({
                  property: e.target.checked
                    ? [variant, 'identified', time].join('.')
                    : [variant, 'visitors', time].join('.')
                })
              }}
            >
              <Text>Require Email</Text>
            </Checkbox>
            <HoverCard
              hoverContent={
                <Stack p="4">
                  <Text fontSize="xs">
                    Whether you want the automation to fire for all users or only users with an email.
                  </Text>
                </Stack>
              }
            >
              <IconButton aria-label="Help" size="xs" variant={'ghost'} icon={<IconHelp size={14} />} />
            </HoverCard>
          </HStack>
        </Flex>
      </Stack>
    </FormControl>
  )
}

const options = [
  {
    label: 'Page View',
    kind: 'page_view',
    description: 'When a user visits a page',
    defaultType: 'is',
    defaultProperty: 'path',
    with: {
      operator: 'greater_than_or_equal',
      property: 'focus_time',
      value: '5 seconds'
    },
    icon: IconWorldWww
  },
  {
    label: 'Event',
    kind: 'event',
    description: 'When a user triggers an event',
    defaultType: 'is',
    defaultProperty: 'event',
    icon: IconClick
  },
  {
    label: 'Form Submission',
    kind: 'form_submission',
    description: 'When a user submits a form',
    defaultType: 'is',
    defaultProperty: 'page_path',
    icon: IconNotebook
  },
  {
    label: 'Account Trait Change',
    kind: 'account_trait',
    defaultType: 'changed',
    defaultProperty: undefined,
    description: 'Whenever an Account trait changes state',
    icon: IconTransform,
    defaultValue: {
      from_operator: 'is',
      to_operator: 'is',
      from: '*',
      to: '*'
    }
  },
  {
    label: 'Visitor Trait Change',
    kind: 'profile_trait',
    defaultType: 'changed',
    defaultProperty: undefined,
    description: 'Whenever a user trait changes state',
    icon: IconTransform,
    defaultValue: {
      from_operator: 'is',
      to_operator: 'is',
      from: '*',
      to: '*'
    }
  },
  {
    label: 'Active Session Time',
    kind: 'total_time',
    defaultType: 'greater_than_or_equal',
    description: 'When a user is active for a certain amount of time',
    defaultProperty: 'trend.day',
    defaultValue: '5 minutes',
    icon: IconCalendarTime
  },
  {
    label: 'Active Users Change',
    kind: 'visitor_analytics',
    defaultType: 'greater_than_or_equal',
    description: 'When the number of users in an account increases',
    defaultProperty: 'visitor_stats.visitors.month',
    defaultValue: '1',
    icon: IconTrendingUp
  }
]

type ConditionProps = ConditionType & {
  onChange: (condition: Partial<ConditionType>) => void
  onRemove: () => void
  targetType: 'Profile' | 'Account'
}

function Condition(props: ConditionProps) {
  const [ref, isHovered] = useHover()
  return (
    <Stack
      ref={ref as any}
      spacing="4"
      w="100%"
      rounded={'md'}
      p="6"
      // bg="gray.50"
      _hover={{
        bg: 'gray.50'
      }}
    >
      <HStack w="100%" spacing="8" alignItems={'flex-start'}>
        <Flex w="100%">
          {props.kind === 'page_view' && <PageView {...props} isHovered={isHovered} />}
          {props.kind === 'event' && <Event {...props} isHovered={isHovered} />}
          {props.kind === 'account_trait' && <Trait type="account" {...props} />}
          {props.kind === 'profile_trait' && <Trait type="profile" {...props} />}
          {props.kind === 'total_time' && <SessionTime {...props} />}
          {props.kind === 'form_submission' && <Form {...props} isHovered={isHovered} />}
          {props.kind === 'visitor_analytics' && <VisitorAnalytics {...props} />}
        </Flex>
        <Button size="xs" onClick={props.onRemove} variant="ghost" fontSize={'sm'}>
          &times;
        </Button>
      </HStack>
    </Stack>
  )
}

function OrQualifier(props: { isVisible?: boolean; onClick?: () => void }) {
  return (
    <>
      <Button onClick={props.onClick} variant={'ghost'} size="xs" visibility={props.isVisible ? 'visible' : 'hidden'}>
        OR
      </Button>
    </>
  )
}

type ConditionChoice = 'kql' | 'custom'

export function TriggerSetup(props: Props) {
  const [choice, setChoice] = useState<ConditionChoice>(props.follow_rule?.trigger_type === 'custom' ? 'custom' : 'kql')
  const [kqlIds, setKqlIds] = useState<string[]>(props.follow_rule?.kql_definition_ids ?? [])
  const [anyIntent, setAnyIntent] = useState<boolean | undefined>(props.follow_rule?.any_intent)

  return (
    <Stack spacing="6" p="4">
      <Box>
        <FormLabel>When should this run?</FormLabel>
        <CardRadioGroup
          size="sm"
          value={choice}
          onChange={(e) => {
            setChoice(e as ConditionChoice)
          }}
          options={[
            {
              label: 'Intent Signal',
              value: 'kql',
              description: 'Use an existing Intent Signal to trigger this automation.'
            },
            {
              label: 'Custom Intent',
              value: 'custom',
              description: 'Define your own set of intent rules to trigger this automation.'
            }
          ]}
        />
      </Box>
      {choice === 'custom' && <TriggerV2 {...props} />}
      {choice === 'kql' && (
        <Flex w="100%">
          <Stack w="100%" as={GrayCard} borderWidth="thin">
            <TriggerMultiSelect
              onChange={(incomingKQLIDs, isAnyIntent) => {
                if (isAnyIntent && !anyIntent) {
                  setAnyIntent(isAnyIntent)
                  setKqlIds([])
                  return
                }

                if (!isAnyIntent && anyIntent) {
                  setAnyIntent(isAnyIntent)
                  return
                }

                if (incomingKQLIDs.length === 0 && kqlIds.length === 0) {
                  return
                }

                setAnyIntent(undefined)
                setKqlIds(incomingKQLIDs)
              }}
              skipForms
              skipCreation
              selectedSignalIds={kqlIds}
              anyIntent={anyIntent}
            />
          </Stack>

          {kqlIds.length === 1 && (
            <>
              <input type="hidden" name="follow_rule[kql_definition_id]" value={kqlIds[0]} />
              <input
                type="hidden"
                name="follow_rule[intent_rules]"
                value={JSON.stringify({ from_kql_id: kqlIds[0] })}
              />
            </>
          )}

          {kqlIds.length > 1 && (
            <>
              <input type="hidden" name="follow_rule[intent_rules]" value={JSON.stringify({ multi_kql_ids: kqlIds })} />
            </>
          )}

          {anyIntent && (
            <input type="hidden" name="follow_rule[intent_rules]" value={JSON.stringify({ any_intent: true })} />
          )}
        </Flex>
      )}

      <FormControl>
        <FormLabel>How often?</FormLabel>
        <Select
          size="sm"
          rounded="md"
          name="follow_rule[cadence_rules][period]"
          defaultValue={props.follow_rule?.cadence_rules?.period}
        >
          <option value="day">
            No more than once a day per {props.targetType === 'Profile' ? 'Visitor' : 'Account'}
          </option>
          <option value="week">
            No more than once a week per {props.targetType === 'Profile' ? 'Visitor' : 'Account'}
          </option>
          <option value="ever">
            No more than once per {props.targetType === 'Profile' ? 'Visitor' : 'Account'}. Regardless of time frame.
          </option>
          <option value="immediate">Every time it happens</option>
        </Select>
        <FormHelperText>
          e.g. Do you want to get every "Pricing page visit" for a given Account every day? Or just the first time the
          event happens every day.
        </FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel>Timing/Delay (minutes)</FormLabel>
        <Input
          size="sm"
          type="number"
          name="follow_rule[delivery_rules][delay_minutes]"
          defaultValue={props.follow_rule?.delivery_rules?.delay_minutes || ''}
        />
        <FormHelperText>
          The number of minutes you want to delay running this automation after the trigger event happens. Delayed
          actions will check all conditions and audience filters at the time they are delayed until. Default is no
          delay.
        </FormHelperText>
      </FormControl>
    </Stack>
  )
}

export function TriggerV2(props: Props) {
  const KQLConditions = useMemo(() => {
    let conditions = props.follow_rule?.intent_rules?.inline_conditions ?? []
    if (!Array.isArray(conditions)) {
      conditions = [conditions]
    }
    return { conditions }
  }, [props.follow_rule])

  const { searchParams } = useSearchParams()

  const [selected, setSelected] = React.useState<ConditionType[]>(KQLConditions.conditions ?? [])
  const [showOptions, setShowOptions] = React.useState(KQLConditions.conditions.length === 0)

  const onChange = useCallback(
    (condition: Partial<ConditionType>, item: ConditionType) => {
      const after = update(item, {
        $merge: condition
      })

      const index = selected.findIndex((s) => s.id === item.id)
      const updooted = update(selected, {
        $merge: {
          [index]: after
        }
      })

      setSelected(Object.values(updooted))
    },
    [selected]
  )

  const [type, setType] = React.useState<FollowRule['intent_rules']['condition_type']>(
    props.follow_rule?.intent_rules?.condition_type ?? 'AND'
  )

  return (
    <Stack w="100%" spacing={'4'} as={FormControl} py="4">
      <FormLabel>
        <HStack>
          <Text>When the {props.targetType === 'Profile' ? 'Visitor' : 'Account'} performs </Text>
          <Select
            w="80px"
            size="xs"
            rounded="md"
            value={type}
            onChange={(e) => setType(e.target.value as FollowRule['intent_rules']['condition_type'])}
          >
            <option value="AND">all of</option>
            <option value="OR">any of</option>
          </Select>{' '}
          <Text>the following events...</Text>
        </HStack>
      </FormLabel>
      {selected.length > 0 && (
        <Stack
          pr="4"
          spacing="2"
          alignItems={'center'}
          w="100%"
          listStyleType={'none'}
          divider={
            <Flex w="100%" border="none" py="2">
              <Text fontSize={'sm'} fontWeight={'semibold'}>
                {type}
              </Text>
            </Flex>
          }
        >
          {selected.map((item) => {
            return (
              <motion.li
                key={item.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ type: 'spring' }}
                style={{
                  width: '100%'
                }}
              >
                <Condition
                  {...item}
                  targetType={props.targetType}
                  onChange={(condition) => onChange(condition, item)}
                  onRemove={() => {
                    setSelected(selected.filter((i) => i.id !== item.id))
                  }}
                />
              </motion.li>
            )
          })}
        </Stack>
      )}

      <Box>
        <Button
          variant={'ghost'}
          size="sm"
          colorScheme={'purple'}
          leftIcon={<IconPlus size={14} />}
          onClick={() => {
            setShowOptions((prev) => !prev)
          }}
        >
          Add {type === 'OR' ? 'OR ' : ''}Condition
        </Button>
        <Collapse in={showOptions}>
          <HStack wrap="wrap" justifyContent={'center'} alignItems="center" gap="2" pt={4}>
            {options.map((option) => (
              <Tooltip label={option.description} key={option.kind}>
                <Button
                  leftIcon={<Icon as={option.icon} />}
                  variant="outline"
                  size="xs"
                  onClick={() => {
                    setSelected([
                      ...selected,
                      {
                        id: nanoid(),
                        kind: option.kind,
                        operator: option.defaultType,
                        property: option.defaultProperty!,
                        with: option.with,
                        value: option.defaultValue
                      }
                    ])
                  }}
                >
                  {option.label}
                </Button>
              </Tooltip>
            ))}
          </HStack>
        </Collapse>
      </Box>

      {searchParams['debug'] && (
        <pre>
          <Text>{JSON.stringify({ condition_type: type, inline_conditions: selected }, null, 2)}</Text>
        </pre>
      )}

      <input
        type="hidden"
        name="follow_rule[intent_rules]"
        value={JSON.stringify({ condition_type: type, inline_conditions: selected })}
      />

      <Stack pt="8">
        <FormHelperText>When should this run?</FormHelperText>
        <FormHelperText>
          Select as many conditions as you want. Koala will match against the combination of all conditions you specify.
        </FormHelperText>
      </Stack>
    </Stack>
  )
}
