import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from '@chakra-ui/react'
import { IconActivity, IconDotsVertical, IconUpload, IconUsers } from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import React, { useEffect } from 'react'
import { ImportList } from '../../../types/Imports'
import { PageMeta } from '../../../types/PageMeta'
import CircleIcon from '../../ui/CircleIcon'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { TableFooter } from '../../ui/TableFooter'
import { TimeAgo } from '../../ui/TimeAgo'
import { titleize } from '../accounts/facets/filter-cloud'
import { mergeParams } from '../icps/types'
import { ImportType } from './components/ImportType'
import { importListPath, importPath } from './lib/path-helper'

interface Props {
  lists: ImportList[]
  page_meta: PageMeta
}

export function ImportActions(props: { list: ImportList; showDeepLink?: boolean }) {
  const list = props.list

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Actions"
        icon={<IconDotsVertical size={16} />}
        variant="ghost"
        size="xs"
      />
      <MenuList fontSize={'sm'}>
        {props.showDeepLink && (
          <MenuItem
            as={Link}
            href={importPath(list.id)}
            icon={<IconActivity size={16} />}
            _focus={{
              boxShadow: 'unset',
              background: 'gray.100'
            }}
            style={{
              textDecoration: 'none'
            }}
          >
            Inspect Progress
          </MenuItem>
        )}

        {list.kind === 'contacts' ? (
          <MenuItem
            icon={<IconUsers size={16} />}
            as={Link}
            href={importListPath(list.slug, 'visitors')}
            _focus={{
              boxShadow: 'unset',
              background: 'gray.100'
            }}
            style={{
              textDecoration: 'none'
            }}
          >
            <Text>View Contacts</Text>
          </MenuItem>
        ) : (
          <MenuItem
            icon={<IconUsers size={16} />}
            as={Link}
            href={importListPath(list.slug, 'accounts')}
            _focus={{
              boxShadow: 'unset',
              background: 'gray.100'
            }}
            style={{
              textDecoration: 'none'
            }}
          >
            <Text>View Accounts</Text>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}

export function statusColor(status: ImportList['status']) {
  if (status === 'pending') {
    return 'gray'
  }

  if (status === 'started') {
    return 'purple'
  }

  return 'green'
}

export function statusLabel(status: ImportList['status']) {
  switch (status) {
    case 'started':
      return 'started'
    case 'done':
      return 'completed'
    default:
      return 'pending'
  }
}

export default function ImportLists(props: Props) {
  const [lists, setLists] = React.useState<ImportList[]>(props.lists)
  const [pageMeta, setPageMeta] = React.useState(props.page_meta)

  useEffect(() => {
    setLists(props.lists)
    setPageMeta(props.page_meta)
  }, [props])

  return (
    <PageLayout>
      <PageTitle skipRendering>Import History</PageTitle>

      <HStack justifyContent={'space-between'}>
        <Heading size="md">Import History</Heading>

        <HStack>
          <Button
            size="sm"
            variant="outline"
            as={Link}
            href={projectPath('/profiles/imports/new')}
            leftIcon={<IconUpload size={14} />}
            iconSpacing={1.5}
          >
            Import Contacts
          </Button>

          <Button
            size="sm"
            variant="outline"
            as={Link}
            href={projectPath('/accounts/imports/new')}
            leftIcon={<IconUpload size={14} />}
            iconSpacing={1.5}
          >
            Import Accounts
          </Button>
        </HStack>
      </HStack>

      <Box>
        {lists.length === 0 && (
          <Stack
            alignItems={'center'}
            bg="gray.50"
            p="8"
            borderColor={'gray.200'}
            borderWidth="1px"
            rounded="lg"
            spacing={'6'}
          >
            <CircleIcon icon={IconUpload} iconSize={6} padding={2.5} colorScheme="purple" />

            <VStack spacing="1">
              <Heading size="sm">You haven't imported any people or companies yet</Heading>
              <Text fontSize="sm" color="gray.500">
                You can create a new static list by uploading a CSV file containing people or companies.
              </Text>
            </VStack>

            <HStack>
              <Button size="sm" colorScheme="purple" as={Link} href={projectPath('/profiles/imports/new')}>
                Import contacts
              </Button>
              <Button size="sm" colorScheme="purple" as={Link} href={projectPath('/accounts/imports/new')}>
                Import accounts
              </Button>
            </HStack>
          </Stack>
        )}
        {lists.length > 0 && (
          <TableContainer>
            <Table variant="bordered" size="sm">
              <Thead>
                <Tr>
                  <Th height="38px">Name</Th>
                  <Th>Status</Th>
                  <Th isNumeric>Records</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody bg="white" fontSize="sm">
                {lists.map((list) => {
                  return (
                    <Tr key={list.id}>
                      <Td>
                        <Stack spacing={0} py={1}>
                          <Flex gap={2} alignItems="center">
                            <Link fontSize="sm" href={projectPath(`/imports/${list.id}`)}>
                              {list.name}
                            </Link>
                            <ImportType kind={list.kind} />
                          </Flex>
                          <Flex gap={1} fontSize="xs" color="gray.500">
                            Created
                            {list.created_by_user && (
                              <Text fontSize="xs" color="gray.500">
                                by {list.created_by_user.name}
                              </Text>
                            )}
                            <TimeAgo time={list.created_at} />
                          </Flex>
                        </Stack>
                      </Td>
                      <Td w="1px">
                        <Badge variant="regular" px={1.5} py={0.5} rounded="lg" colorScheme={statusColor(list.status)}>
                          {titleize(list.status)}
                        </Badge>
                      </Td>
                      <Td w="1px" isNumeric fontFamily="mono" fontSize="sm">
                        {format(list.total_complete)}/{format(list.total_items)}
                      </Td>
                      <Td w="1px">
                        <ImportActions list={list} showDeepLink />
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {lists.length > 0 && (
          <TableFooter
            page={pageMeta.current_page}
            pageMeta={pageMeta}
            nextPath={mergeParams(window.location.toString(), {
              page: pageMeta.next_page?.toString()
            })}
            prevPath={mergeParams(window.location.toString(), {
              page: pageMeta.prev_page?.toString()
            })}
            px={3}
            sticky
          />
        )}
      </Box>
    </PageLayout>
  )
}
