import React, { useCallback, useMemo } from 'react'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { TopBarContent } from '../../ui/TopBarContext'
import { projectPath } from '../../ui/ProjectsContext'
import { Breadcrumb } from '../../ui/Breadcrumb'
import {
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { UserSelect } from '../mission_control/UserSelect'
import useLocation from '../../ui/useLocation'
import { useCurrentUser } from '../../ui/UserContext'
import { usePermission } from '../../ui/PermissionsContext'
import useUpdateEffect from '../../ui/useUpdateEffect'
import { mergeParams } from '../icps/types'
import router from '../../../lib/router'
import { useUrlFilters } from '../../data/use-url-filters'
import EmptyState from '../../ui/EmptyState'
import {
  IconChevronRight,
  IconCrosshair,
  IconDotsVertical,
  IconEdit,
  IconLayoutColumns,
  IconTrash
} from '@tabler/icons-react'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { Apps } from '../../../types/App'
import { ProfileList } from '../profiles/components/profile-list'
import { AccountList } from '../icps/icp/account-list'
import { PartialAccountView, TemplateViewSelector } from '../../ui/TemplateSelector'
import { deepEqual } from 'fast-equals'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { NewListModal } from '../mission_control_templates/show'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import { EditTemplateModal } from '../mission_control_templates/components/EditTemplateModal'
import {
  ColumnSelectionDropdown,
  defaultAccountColumns,
  defaultProfileColumns,
  useColumns
} from '../../ui/ColumnSelector'

interface Props {
  apps: Apps
  columns: string[]
  records: any[]
  view: any
}

const emptyArray = []

export default function MissionControlTemplateViewShow(props: Props) {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const currentUser = useCurrentUser()
  const [submitting, setSubmitting] = React.useState(false)
  const [selectedUserId, setSelectedUserId] = React.useState<string | null>(params.get('user') || currentUser.id!)
  const { hasPermission: canViewAsMember } = usePermission({ on: 'project', action: 'can_view_as_member' })
  const { hasPermission: canSaveChanges } = usePermission({ on: 'project', action: 'can_create' })
  const viewType = props.view.kind || 'account'
  const entityType = props.view.kind === 'profile' ? 'visitor' : 'account'

  useUpdateEffect(() => {
    const user = selectedUserId && selectedUserId !== currentUser.id ? selectedUserId : undefined
    const params = new URLSearchParams(window.location.search)
    const existing = params.get('user') || undefined

    if (user !== existing) {
      const path = mergeParams(window.location.toString(), { user })
      router.visit(path)
    }
  }, [selectedUserId, currentUser.id])

  const defaultColumns = viewType === 'profile' ? defaultProfileColumns : defaultAccountColumns

  const { columns, loadingColumns, onColumnChange, onColumnRemove } = useColumns({
    data: props.records,
    columns: props.columns?.length ? props.columns : defaultColumns.map((c) => c.key),
    initialColumns: props.columns?.length ? props.columns : defaultColumns.map((c) => c.key)
  })

  const facets = useUrlFilters({
    initialRange: props.view.filters.range ?? 'month',
    initialFacets: props.view.filters.facets,
    initialFocusTime: props.view.filters.focus_time,
    initialSortBy: props.view.filters.sort_by,
    facetCloudPath: props.view.kind === 'profile' ? '/profiles/facet-cloud' : '/accounts/facet-cloud'
  })

  const records = props.records || emptyArray
  const apps = useMemo(() => Object.values(props.apps), [props.apps])

  const changeView = useCallback(
    (newView: PartialAccountView | null) => {
      if (newView && newView.id !== props.view.id) {
        const path = projectPath(`/mission-control/templates/${props.view.template_id}/views/${newView.id}`)
        router.visit(path)
      }
    },
    [props.view]
  )

  const columnsChanged = useMemo(() => {
    // check that the columns are not the defaults / default order
    const persistedColumns = props.view?.display_settings?.display_columns
    const defaults = (props.view?.kind === 'profile' ? defaultProfileColumns : defaultAccountColumns).map((c) => c.key)

    if (!persistedColumns) {
      return defaults.join(',') !== columns.join(',')
    } else {
      return persistedColumns.join(',') !== columns.join(',')
    }
  }, [columns, props.view])

  const isDirty = useMemo(
    () => columnsChanged || !deepEqual(props.view.filters.facets || {}, facets.facetFilters),
    [props.view.filters.facets, facets.facetFilters, columnsChanged]
  )

  const hasFilters = facets.isFiltering

  const discardChanges = useCallback(() => {
    window.location.href = projectPath(`/mission-control/templates/${props.view.template_id}/views/${props.view.id}`)
  }, [props.view])

  const newListModal = useDisclosure()
  const editTemplateModal = useDisclosure()
  const deletion = useDisclosure()

  return (
    <PageLayout size="full" flush gap={0}>
      <PageTitle skipRendering>{props.view.name} | Templates</PageTitle>

      <TopBarContent>
        <Flex width="100%" alignItems="center" justifyContent="space-between" gap={3}>
          <Flex flex="none" alignItems="center" gap={1.5}>
            <Breadcrumb
              flex="none"
              paths={[
                {
                  path: projectPath('/mission-control/setup?tab=templates'),
                  title: 'Templates'
                },
                {
                  path: projectPath('/mission-control/templates/' + props.view.template_id),
                  title: props.view.template.name,
                  isCurrentPage: false
                }
              ]}
            />
            <Icon as={IconChevronRight} color="gray.400" boxSize={4} flex="none" />
            <Box ml={-1} flex="none">
              <TemplateViewSelector
                templateId={props.view.template_id}
                selectedViewId={props.view.id}
                onChange={changeView}
                onCreateTemplateView={newListModal.onOpen}
              />
              <NewListModal {...newListModal} kind="account" template={props.view.template} />
            </Box>
          </Flex>

          <Flex flex="none" alignItems="center" gap={2}>
            {canViewAsMember && (
              <Flex flex="none" alignItems="center" justifyContent="center" gap={1}>
                <Text fontSize="sm" color="gray.600">
                  Previewing as
                </Text>
                <UserSelect
                  selectedUserId={selectedUserId || currentUser!.id}
                  onChange={setSelectedUserId}
                  isReadOnly={!canViewAsMember}
                  includeCrmUsers
                />
              </Flex>
            )}
            <Menu>
              <MenuButton
                size="xs"
                as={IconButton}
                icon={<IconDotsVertical size={15} />}
                variant="ghost"
                borderColor="gray.200"
              />
              <MenuList fontSize="sm">
                <MenuItem icon={<IconEdit size={16} />} onClick={editTemplateModal.onOpen}>
                  Edit template
                </MenuItem>
                <MenuItem icon={<IconTrash size={16} />} color="red.500" onClick={deletion.onOpen}>
                  Remove list…
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </TopBarContent>

      <DeleteConfirmation
        isOpen={deletion.isOpen}
        onClose={deletion.onClose}
        title={`Are you sure you want to remove this list from your template?`}
        confirmLabel="Delete template list"
        deletePath={projectPath(`/mission-control/templates/${props.view.template_id}/views/${props.view.id}`)}
      >
        <Text fontSize="sm" color="gray.600">
          This list will be deleted and will no longer be included in your template going forward.
        </Text>
      </DeleteConfirmation>

      <EditTemplateModal {...editTemplateModal} template={props.view.template} />

      <Flex justifyContent="space-between" gap={4} padding={4}>
        <FilterPreview
          isEditable
          {...facets}
          canClearFilters={false}
          kind={props.view.kind}
          excludedKeys={
            props.view.kind === 'account' ? [] : ['visitor_stats.identified.month', 'visitor_stats.visitors.month']
          }
          apps={props.apps}
        />

        <Flex alignItems="center" gap={2}>
          {canSaveChanges && isDirty && (
            <>
              <Button
                flex="none"
                variant="ghost"
                size="sm"
                color="gray.500"
                _hover={{ color: 'gray.700' }}
                onClick={discardChanges}
              >
                Discard changes
              </Button>

              <form
                action={location.pathname + location.search}
                method="POST"
                onSubmit={() => {
                  setSubmitting(true)
                }}
              >
                <AuthenticityToken />
                <input type="hidden" name="_method" value="PUT" />
                <Button
                  flex="none"
                  colorScheme="purple"
                  type="submit"
                  size="sm"
                  isDisabled={!canSaveChanges || !hasFilters}
                  isLoading={submitting}
                >
                  Save Template
                </Button>
              </form>

              <Divider orientation="vertical" height="20px" />
            </>
          )}
          <ColumnSelectionDropdown
            audienceKind={props.view.kind || 'account'}
            apps={Object.values(apps)}
            selectedColumns={columns}
            onChange={onColumnChange}
          >
            <IconButton
              aria-label="Edit columns"
              variant="outline"
              size="sm"
              flex="none"
              icon={<IconLayoutColumns size={18} />}
            />
          </ColumnSelectionDropdown>
        </Flex>
      </Flex>

      {(props.records || []).length === 0 ? (
        <EmptyState
          size="md"
          heading={`No ${entityType}s match these filters`}
          description="Adjust your filters or again later"
          icon={IconCrosshair}
        />
      ) : (
        <Box>
          {props.view.kind === 'profile' && (
            <ProfileList
              facetParams={facets}
              profiles={records}
              apps={apps}
              columns={columns}
              loadingColumns={loadingColumns}
              canAddColumns
              onColumnChange={onColumnChange}
              onColumnRemove={onColumnRemove}
              // range={props.range ?? 'all'}
              sortingBy={facets.sortBy}
              onSortChange={facets.setSortBy}
            />
          )}
          {props.view.kind === 'account' && (
            <AccountList
              accounts={records}
              facetParams={facets}
              apps={apps}
              columns={columns}
              loadingColumns={loadingColumns}
              canAddColumns
              onColumnChange={onColumnChange}
              // range={props.range ?? 'all'}
              sortingBy={facets.sortBy}
              onSortChange={facets.setSortBy}
            />
          )}
        </Box>
      )}
    </PageLayout>
  )
}
