import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Center,
  Circle,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Link,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  StatProps,
  Switch,
  Tag,
  Text,
  Tooltip
} from '@chakra-ui/react'
import {
  IconEqual,
  IconInfoCircle,
  IconInfoCircleFilled,
  IconMinus,
  IconPlus,
  IconSitemap,
  IconX
} from '@tabler/icons-react'
import without from 'lodash/without'
import React, { useEffect, useMemo, useState } from 'react'
import { concurrentGET } from '../../../lib/api'
import { Apps } from '../../../types/App'
import { PageMeta } from '../../../types/PageMeta'
import { useFacets } from '../../data/use-facets'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { CollapsibleSection } from '../../ui/CollapsibleSection'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { usePermission } from '../../ui/PermissionsContext'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { AccountList } from '../icps/icp/account-list'
import { HighlightedAccount } from '../icps/types'
import { HighlightedProfile, ProfileList } from '../profiles/components/profile-list'
import { DefaultFilters } from '../teams/show'
import { GeoMode } from './components/hidden-geos'

function urlify(s: string) {
  const replaced = s.replace(/https?:\/\//, '').replace('www.', '')
  const url = `https://${replaced}`

  try {
    return new URL(url).host
  } catch (e) {
    return s
  }
}

export interface ExcludedAccountsProps {
  apps: Apps
  stats: {
    hidden: number
    total: number
  }
  filters: {
    visitors?: DefaultFilters
    accounts?: DefaultFilters
    domains?: DefaultFilters
    geos?: {
      filters: {
        mode: GeoMode
        country_codes: string[]
      }
    }
  }
  countries: string[][]
  project_settings: {
    hide_inactive?: boolean
  }
}

function AccountStat({ count, label, hint, ...rest }: StatProps & { count: number; label: string; hint?: string }) {
  return (
    <Stat
      key={label}
      textAlign={'center'}
      borderWidth="1px"
      rounded="lg"
      p="4"
      flex={1}
      shadow="sm"
      minH="100px"
      justifyContent={'center'}
      alignItems={'center'}
      display="flex"
      position="relative"
      {...rest}
    >
      {hint && (
        <Flex position="absolute" top="2" right="2">
          <Tooltip label={hint}>
            <Flex color="gray.500">
              <IconInfoCircleFilled size={18} />
            </Flex>
          </Tooltip>
        </Flex>
      )}
      <StatNumber>{new Intl.NumberFormat().format(count)}</StatNumber>
      <StatLabel fontWeight={'normal'} color="gray.500">
        {label}
      </StatLabel>
    </Stat>
  )
}

export default function Show(props: ExcludedAccountsProps) {
  const [excludedDomainValue, setExcludedDomainValue] = useState('')
  const { hasPermission, isLoading } = usePermission({ on: 'project', action: 'can_manage_hidden_accounts' })

  const inboundDomains = props.filters.domains?.filters?.facets?.['company.domain']?.not ?? []
  const [domains, setDomains] = useState<string[]>(inboundDomains)

  const [sampleAccounts, setSampleAccounts] = useState<HighlightedAccount[]>([])
  const [sampleVisitors, setSampleVisitors] = useState<HighlightedProfile[]>([])
  const [hiddenAccountPreview, setHiddenAccountPreview] = useState<number | undefined>()
  const [hiddenProfilePreview, setHiddenProfilePreview] = useState<number | undefined>()
  const [hideInactive, setHideInactive] = useState(props.project_settings.hide_inactive ?? true)

  const accountFacets = useFacets({
    facet_filters: props.filters.accounts?.filters?.facets,
    focus_time: props.filters.accounts?.filters?.focus_time
  })

  const visitorFacets = useFacets({
    facet_filters: props.filters.visitors?.filters?.facets,
    focus_time: props.filters.visitors?.filters?.focus_time,
    facetCloudPath: '/profiles/facet-cloud'
  })

  const accountFilters = useMemo(() => {
    return accountFacets.queryString
  }, [accountFacets.queryString])

  const accountsPath = useMemo(() => {
    return projectPath(`/accounts.json`) + `${accountFilters}&no_defaults=true&page_size=5&mode=OR&range=all`
  }, [accountFilters])

  const visitorFilters = useMemo(() => {
    return visitorFacets.queryString
  }, [visitorFacets.queryString])

  const visitorsPath = useMemo(() => {
    return projectPath(`/visitors.json`) + `${visitorFilters}&no_defaults=true&page_size=5&mode=OR&range=all`
  }, [visitorFilters])

  useEffect(() => {
    if (accountFilters.length > 0) {
      concurrentGET<{ accounts: HighlightedAccount[]; page_meta: PageMeta }>(accountsPath).then((res) => {
        setSampleAccounts(res.accounts.map((a) => ({ ...a, limited: false })))
        setHiddenAccountPreview(res.page_meta.total_count)
      })
    } else {
      setSampleAccounts([])
      setHiddenAccountPreview(undefined)
    }

    if (visitorFilters.length > 0) {
      concurrentGET<{ profiles: HighlightedProfile[]; page_meta: PageMeta }>(visitorsPath).then((res) => {
        setSampleVisitors(res.profiles.map((a) => ({ ...a, limited: false })))
        setHiddenProfilePreview(res.page_meta.total_count)
      })
    } else {
      setSampleVisitors([])
      setHiddenProfilePreview(undefined)
    }
  }, [visitorFilters, accountFilters, accountsPath, visitorsPath])

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb
        paths={[{ title: 'Hidden Accounts', path: projectPath('settings/excluded-accounts') }]}
        offscreen
      />

      <form action={projectPath('/settings/excluded-accounts')} method="POST">
        <input type="hidden" name="_method" value="PUT" />
        <AuthenticityToken />

        <Stack spacing="4" maxW={'container.md'} w="100%">
          <SettingsHeader border="none" paddingBottom={0}>
            <PageTitle>Hidden Accounts</PageTitle>
            <PageDescription>Manage Accounts and Visitors that you want to hide by default.</PageDescription>
          </SettingsHeader>

          <Stack spacing="8" w="100%">
            <Center>
              <HStack spacing="4" w="100%">
                <AccountStat
                  count={props.stats.total}
                  label="Total Accounts"
                  hint="These are all Accounts Koala has ever seen on your website."
                />
                <Circle bg="purple.100" color="purple.500" p="1">
                  <IconMinus size={16} />
                </Circle>
                <AccountStat
                  count={props.stats.hidden}
                  label="Hidden Accounts"
                  hint="These are all the Accounts you chose to hide from Koala."
                />
                <Circle bg="purple.100" color="purple.500" p="1">
                  <IconEqual size={16} />
                </Circle>
                <AccountStat
                  count={props.stats.total - props.stats.hidden}
                  label="Koala Accounts"
                  color="purple.500"
                  hint="Koala accounts are all the Accounts that are visible in Koala."
                />
              </HStack>
            </Center>

            <CollapsibleSection header="What are hidden accounts?" icon={<Icon as={IconInfoCircle} color="gray.500" />}>
              <Stack color="gray.500" rounded="md" spacing="6">
                <Text fontSize={'sm'}>
                  Koala supports a global exclusion list that you can configure to exclude certain companies from Koala.
                </Text>

                {!hasPermission && !isLoading && (
                  <Alert status="warning">
                    <AlertIcon />
                    <AlertDescription fontSize={'sm'}>
                      You need to be an admin of your workspace in order to set up Global Exclusion Lists.
                    </AlertDescription>
                  </Alert>
                )}

                <Text fontSize={'sm'}>
                  Most companies filter out their own employee activity, but you can also use this to filter competitors
                  or other accounts known not to be a good fit (eg., universities or visitors in particular countries
                  that you don't serve).
                </Text>

                {hasPermission && (
                  <Text fontSize={'sm'}>
                    <strong>Note:</strong> Changing the following filters <strong>will not</strong> delete your data.
                    Exclusion filters are used to filter out Accounts and Visitors from your Koala Lists and
                    Notifications.
                  </Text>
                )}
              </Stack>
            </CollapsibleSection>
          </Stack>

          <Stack spacing="8" pt="4">
            <Stack p="6" spacing="4" bg="background.light" rounded="lg">
              <Stack spacing="4">
                <HStack>
                  <IconSitemap size="18" />
                  <Heading size="sm" fontWeight={'semibold'}>
                    Hidden Company Domains
                  </Heading>
                </HStack>
                <Text fontSize={'sm'}>
                  Enter the domain for the companies you want to exclude. For example, you can use this to include all
                  employees at your own company, or from a competitor.
                </Text>
              </Stack>
              <FormControl>
                <Input
                  type="text"
                  bg="white"
                  size="sm"
                  rounded={'md'}
                  placeholder="e.g. example.com, example.org"
                  disabled={!hasPermission}
                  name="excluded_accounts[raw_domains]"
                  onChange={(e) => setExcludedDomainValue(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.code === 'Enter') {
                      if (excludedDomainValue.trim().length === 0) {
                        return
                      }

                      e.preventDefault()

                      const values = excludedDomainValue
                        .split(',')
                        .map((s) => s.trim())
                        .map(urlify)

                      setDomains([...domains, ...values])
                      setExcludedDomainValue('')
                    }
                  }}
                  value={excludedDomainValue}
                />
              </FormControl>
              <HStack flexWrap={'wrap'}>
                {domains.map((domain) => {
                  return (
                    <Flex key={domain}>
                      <Tooltip label="Remove">
                        <Button
                          colorScheme={'gray'}
                          variant="outline"
                          bg="white"
                          size="sm"
                          rightIcon={<Icon as={IconX} color="purple.500" boxSize={4} />}
                          fontWeight={'normal'}
                          disabled={!hasPermission}
                          onClick={() => {
                            setDomains(without(domains, domain))
                          }}
                        >
                          {domain}
                        </Button>
                      </Tooltip>
                      <input type="hidden" name="excluded_accounts[domains][]" value={domain} />
                    </Flex>
                  )
                })}
              </HStack>

              <Flex justifyContent={'flex-end'}>
                <Button
                  size="sm"
                  colorScheme={'purple'}
                  type="submit"
                  isDisabled={!hasPermission}
                  variant={domains.length > 0 ? 'solid' : 'outline'}
                >
                  Save
                </Button>
              </Flex>
            </Stack>
          </Stack>

          <Stack py="8" spacing="8">
            <Stack>
              <Heading size="md">Advanced Filters</Heading>
              <Text fontSize={'sm'}>
                You can use this to ignore specific traits about Accounts or Visitors such as students, or companies
                that don't fit your ICP.
              </Text>
            </Stack>

            <Stack spacing="4" bg="background.light" rounded="lg" p="6">
              <HStack justifyContent={'space-between'} w="100%">
                <Heading size="sm" fontWeight={'semibold'}>
                  Accounts
                </Heading>
                {hiddenAccountPreview && (
                  <Tag rounded="full" variant="subtle" bg="white" shadow={'sm'} borderWidth="1px">
                    {new Intl.NumberFormat().format(hiddenAccountPreview)} hidden
                  </Tag>
                )}
              </HStack>
              <FilterPreview
                apps={props.apps}
                {...accountFacets}
                canClearFilters={false}
                kind="account"
                shouldShowIntentFilters={false}
                shouldShowActiveVisitorsFilters={false}
              >
                <Button
                  leftIcon={<IconPlus size="14" />}
                  size="sm"
                  bg="white"
                  shadow="sm"
                  borderWidth={'1px'}
                  borderStyle="dashed"
                  variant="ghost"
                >
                  Filters
                </Button>
              </FilterPreview>

              {sampleAccounts.length > 0 && (
                <Stack spacing="0" bg="white" rounded={'md'} p="4" borderWidth={'1px'} position="relative">
                  <AccountList
                    accounts={sampleAccounts}
                    noGrays
                    range="week"
                    compact
                    columns={['company.category.industry']}
                    sticky={false}
                    showActions={false}
                  />
                  <Flex
                    position="absolute"
                    w="calc(100% - 32px)"
                    bottom="2"
                    pt="8"
                    pb="2"
                    h="20"
                    justifyContent={'center'}
                    backgroundImage="linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0.9))"
                  >
                    <Button
                      as={Link}
                      variant="outline"
                      bg="white"
                      size="sm"
                      textAlign={'center'}
                      fontSize={'sm'}
                      href={accountsPath.replace('.json', '').replace('&page_size=5', '')}
                      isExternal
                    >
                      See Full List
                    </Button>
                  </Flex>
                </Stack>
              )}

              <Text fontSize={'sm'}>
                Note: Koala will hide Accounts matching <strong>any</strong> of the criteria you set.
              </Text>

              <input
                type="hidden"
                name="excluded_accounts[accounts_filter]"
                value={accountFacets.queryString.split('?')[1] || ''}
              />

              <Flex justifyContent={'flex-end'}>
                <Button
                  size="sm"
                  colorScheme={'purple'}
                  type="submit"
                  isDisabled={!hasPermission}
                  variant={accountFacets.isFiltering ? 'solid' : 'outline'}
                >
                  Save
                </Button>
              </Flex>
            </Stack>

            <Stack spacing="4" bg="background.light" rounded="lg" p="6">
              <HStack w="100%" justifyContent={'space-between'}>
                <Heading size="sm">Visitors</Heading>
                {hiddenProfilePreview && (
                  <Tag rounded="full" variant="subtle" bg="white" shadow={'sm'} borderWidth="1px">
                    {new Intl.NumberFormat().format(hiddenProfilePreview)} hidden
                  </Tag>
                )}
              </HStack>
              <FilterPreview
                apps={props.apps}
                {...visitorFacets}
                canClearFilters={false}
                kind="profile"
                shouldShowIntentFilters={false}
                shouldShowActiveVisitorsFilters={false}
              >
                <Button
                  leftIcon={<IconPlus size="14" />}
                  size="sm"
                  bg="white"
                  shadow="sm"
                  borderWidth={'1px'}
                  borderStyle="dashed"
                  variant="ghost"
                >
                  Filters
                </Button>
              </FilterPreview>

              {sampleVisitors.length > 0 && (
                <Stack spacing="0" bg="white" rounded={'md'} p="4" borderWidth={'1px'} position="relative">
                  <ProfileList
                    profiles={sampleVisitors}
                    range="all"
                    compact
                    noGrays
                    columns={['Company', 'FitScore']}
                  />
                  <Flex
                    position="absolute"
                    w="calc(100% - 32px)"
                    bottom="2"
                    pt="8"
                    pb="2"
                    h="20"
                    justifyContent={'center'}
                    backgroundImage="linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0.9))"
                  >
                    <Button
                      as={Link}
                      variant="outline"
                      bg="white"
                      size="sm"
                      textAlign={'center'}
                      fontSize={'sm'}
                      href={visitorsPath.replace('.json', '').replace('&page_size=5', '')}
                      isExternal
                    >
                      See Full List
                    </Button>
                  </Flex>
                </Stack>
              )}

              <Text fontSize={'sm'}>
                Note: Koala will hide Visitors matching <strong>any</strong> of the criteria you set.
              </Text>

              <input
                type="hidden"
                name="excluded_accounts[visitors_filter]"
                value={visitorFacets.queryString.split('?')[1] || ''}
              />

              <Flex justifyContent={'flex-end'}>
                <Button
                  size="sm"
                  colorScheme={'purple'}
                  type="submit"
                  isDisabled={!hasPermission}
                  variant={visitorFacets.isFiltering ? 'solid' : 'outline'}
                >
                  Save
                </Button>
              </Flex>
            </Stack>

            <Divider />

            <Stack spacing="8">
              <Stack>
                <Heading size="md">Active Accounts & Visitors</Heading>
                <Text fontSize={'sm'}>
                  Choose if you want to only show Accounts and Visitors who have been active on your site. Or if you
                  want to show all Accounts and Visitors from your CRM.
                </Text>
              </Stack>
              <Stack spacing="4" bg="background.light" rounded="lg" p="6">
                <FormControl>
                  <HStack>
                    <Switch
                      name="project_settings[hide_inactive]"
                      isChecked={hideInactive}
                      onChange={(e) => setHideInactive(e.target.checked)}
                      value="1"
                    />
                    <FormLabel>Only show active accounts and visitors</FormLabel>
                  </HStack>
                  <FormHelperText>
                    Automatically ignore and hide Accounts and Visitors who have not been active on your site.
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Stack>
          </Stack>

          <Button type="submit" colorScheme="purple" isDisabled={!hasPermission}>
            Save
          </Button>
        </Stack>
      </form>
    </PageLayout>
  )
}
