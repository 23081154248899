import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { IconArrowUpRight, IconSettings, IconUserPlus } from '@tabler/icons-react'
import * as React from 'react'
import { Project } from '../../../../types/Project'
import { openUpgradeFlow } from '../../../ui/billing-banners/accounts-banner'
import { projectPath, useCurrentProject } from '../../../ui/ProjectsContext'
import { AllEntitlements } from '../../billing/show'

export const roles = [
  {
    name: 'Admin',
    value: 'admin',
    description: 'Full administrative access to see and manage everything'
  },
  {
    name: 'Member',
    value: 'member',
    description: 'Full read access and limited write access'
  },
  {
    name: 'View Only',
    value: 'read_only',
    description: 'Read-only access'
  },
  {
    name: (
      <HStack>
        <Text>Developer</Text>
        <Tooltip label="Developer seats are free of charge.">
          <Badge colorScheme={'blue'}>Free</Badge>
        </Tooltip>
      </HStack>
    ),
    selectName: 'Developer',
    value: 'developer',
    description: 'Full write access to Instrumentation, limited access to other features at no extra charge.'
  }
]

interface InviteProps {
  project: Project
  isInviting?: boolean
  isOpen: boolean
  onClose: () => void
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => unknown
  totalBillableMembers: number
  suggestedRole?: string
  entitlements?: AllEntitlements
  planAllowsNewMembers?: boolean
}

export function MemberLimitWarning(props: {
  entitlements?: AllEntitlements
  onClose: () => void
  totalBillableMembers: number
  planAllowsNewMembers?: boolean
  hideManage?: boolean
}) {
  const isOverMemberLimit = React.useMemo(() => {
    if (!props.entitlements?.members) {
      return false
    }

    if (props.planAllowsNewMembers !== false) {
      return false
    }

    const memberLimit = props.entitlements.members
    return props.totalBillableMembers >= memberLimit
  }, [props.entitlements, props.totalBillableMembers, props.planAllowsNewMembers])

  const project = useCurrentProject()

  if (!isOverMemberLimit) {
    return null
  }

  return (
    <Alert
      status="info"
      bg={props.hideManage ? 'background.light' : 'blue.100'}
      fontSize={'sm'}
      as={HStack}
      alignItems="flex-start"
      spacing="0"
      shadow="sm"
      rounded="lg"
      p="6"
    >
      <AlertIcon color={props.hideManage ? 'gray.500' : 'blue.500'} />
      <Stack spacing="1">
        <AlertTitle fontSize={'md'}>Your Workspace is out of seats</AlertTitle>
        <AlertDescription lineHeight={'1.5'}>
          Your current plan is limited to {props.entitlements?.members} workspace members. Upgrade your plan to unlock
          more seats, or remove existing members to make room for new ones.
        </AlertDescription>

        <Flex pt="2" justifyContent={props.hideManage ? 'flex-start' : 'flex-end'} gap="2">
          {props.hideManage !== true && (
            <Button
              colorScheme={'gray'}
              size="sm"
              rightIcon={<IconSettings size="14" />}
              px="4"
              shadow="sm"
              onClick={() => {
                props.onClose()
              }}
            >
              Manage Members
            </Button>
          )}
          <Button
            colorScheme={'purple'}
            size="sm"
            rightIcon={<IconArrowUpRight size="14" />}
            px="4"
            onClick={() => {
              props.onClose()
              openUpgradeFlow()
            }}
          >
            {project?.koala_subscription?.trial_available ? 'Start Free Trial' : 'Upgrade'}
          </Button>
        </Flex>
      </Stack>
    </Alert>
  )
}

export function InviteModal(props: InviteProps) {
  const isOverMemberLimit = React.useMemo(() => {
    if (!props.entitlements?.members) {
      return false
    }

    if (props.planAllowsNewMembers !== false) {
      return false
    }

    const memberLimit = props.entitlements.members
    return props.totalBillableMembers >= memberLimit
  }, [props.entitlements, props.totalBillableMembers, props.planAllowsNewMembers])

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="xl" preserveScrollBarGap>
      <form data-koala-collect="off" action={projectPath(`/invites`)} method="POST" onSubmit={props.onSubmit}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite people</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={8}>
              <MemberLimitWarning {...props} />

              <FormControl id="invite[email]" isRequired>
                <FormLabel>Email</FormLabel>
                <Input name="invite[email]" placeholder="Enter an email" disabled={isOverMemberLimit} />
              </FormControl>

              <FormControl id="role" isRequired>
                <FormLabel>Role</FormLabel>
                <RadioGroup name="invite[role]" defaultValue={props.suggestedRole ?? 'member'}>
                  <Box borderTop="1px solid" borderColor="gray.200">
                    {roles.map((role) => (
                      <Flex key={role.value} paddingY={3} borderBottom="1px solid" borderColor="gray.200" gap="2">
                        <Radio
                          id={`role:${role.value}`}
                          value={role.value}
                          flex="0 0 150px"
                          isRequired
                          disabled={isOverMemberLimit}
                        >
                          <Text as="label" htmlFor={`role:${role.value}`} fontSize="sm" fontWeight="medium">
                            {role.name}
                          </Text>
                        </Radio>
                        <Text flex="1 1 auto" fontSize="sm" color="gray.500">
                          {role.description}
                        </Text>
                      </Flex>
                    ))}
                  </Box>
                </RadioGroup>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" mr={3} onClick={props.onClose}>
              Cancel
            </Button>

            <Button
              type="submit"
              colorScheme="purple"
              isLoading={props.isInviting}
              disabled={isOverMemberLimit}
              leftIcon={<IconUserPlus size={14} />}
            >
              Invite
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  )
}
