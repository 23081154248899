import {
  Button,
  Divider,
  Heading,
  HStack,
  IconButton,
  Link,
  ListItem,
  Progress,
  Stack,
  Text,
  Tooltip as ChakraTooltip,
  UnorderedList
} from '@chakra-ui/react'
import { IconArrowUpRight, IconChevronRight, IconEyeOff, IconUserPlus } from '@tabler/icons-react'
import { format as shortNumber } from 'friendly-numbers'
import sum from 'lodash/sum'
import React from 'react'
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import type Stripe from 'stripe'
import { formatDate } from '../../../lib/dayjs'
import { theme } from '../../theme'
import { openUpgradeFlow } from '../../ui/billing-banners/accounts-banner'
import { Card, GrayCard, LightBgCard } from '../../ui/Card'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { membersPath } from '../members/path-helpers'
import { BillingHelp } from './components/billing-help'
import { PricingBreakdown, totalCost } from './components/pricing-breakdown'
import { ReferralProgramModal } from './components/referral-program'
import { SubscriptionSettings } from './components/subscription-settings'
import { SubscriptionStatus } from './components/subscription-status'
import { KoalaSubscription } from './show'
import { V3PlanBreakdown } from './v3'

export function friendlyNumber(num: number) {
  return new Intl.NumberFormat().format(num)
}

export type Plan = 'team' | 'business' | 'free' | 'pro' | 'starter'

export type SubscriptionMetricts = {
  maa: {
    count: number
    period_start: Date
    period_end: Date
    progression?: Array<{ date: string; maa: number }>
  }
}

export type V2SubscriptionItems = {
  seats?: Stripe.SubscriptionItem
  events?: Stripe.SubscriptionItem
  accounts?: Stripe.SubscriptionItem
}

export type IndexingEntitlements = {
  dedicated_index?: boolean
  advanced_crm_filters?: boolean
}

export type ScoringEntitlements = {
  icp_scoring: boolean
}

export type AutomationsEntitlements = {
  automations?: boolean
  automations_preview?: boolean
  automations_preview_ends_at?: string
  automation_action_runs?: number
  slack_alerts_monthly?: number
  auto_outbound_runs?: number
}

export type BaseEntitlements = IndexingEntitlements &
  ScoringEntitlements &
  AutomationsEntitlements & {
    content_reports?: boolean
    mission_control?: boolean
  }

export interface EntitlementsV2 extends BaseEntitlements {
  retention: number
  accounts: number
  events: number
  members: number
}

export interface EntitlementsV3 extends BaseEntitlements {
  accounts?: number
  events?: number
  retention: number
  members: number
  reveal_credits?: 10_000 | 25_000
  crm_limit?: number
}

export type Usage = {
  accounts: number
  hidden_accounts: number
  members: number
  events: number
  event_timeseries: Record<string, number>
  crms: number
} & Partial<AutomationsUsage>

export type AutomationsUsage = {
  slack_alerts: {
    current: {
      delivered: number
      limited: number
      grays: number
    }
  }
  automation_actions: {
    current: {
      delivered: number
      limited: number
      grays: number
    }
  }
  auto_outbounds: {
    current: {
      delivered: number
      skipped: number
    }
  }
}

interface Props {
  live_subscription?: Stripe.Subscription & { quantity: number; plan: Stripe.Plan }
  live_subscriptions: Array<Stripe.Subscription & { quantity: number; plan: Stripe.Plan }>
  live_customer?: Stripe.Customer
  koala_subscription: KoalaSubscription
  plan?: Plan | 'unlimited'

  usage: Usage
  entitlements: EntitlementsV2
  payment_methods: Stripe.PaymentMethod[]
}

export const freePlanLimits = {
  retention: 30,
  accounts: 250,
  members: 3,
  events: 100_000
}

export const businessPrices = {
  member: {
    cost: 50,
    unit: 1,
    included: 10
  }
}

export const proPrices = {
  member: {
    cost: 30,
    unit: 1,
    included: 10
  }
}

export const starterPrices = {
  member: {
    cost: 30,
    unit: 1,
    included: 3
  }
}

export const teamPrices = {
  base: {
    cost: 175,
    unit: 1
  },
  account_pack: {
    cost: 15,
    unit: 250,
    included: 1_000
  },
  member: {
    cost: 15,
    unit: 1,
    included: 3
  },
  event_pack: {
    cost: 45,
    unit: 100_000,
    included: 500_000
  }
}

function StatSummary(props: {
  entitlements: EntitlementsV2
  usage: Props['usage']
  metric: keyof EntitlementsV2
  href: string
  children?: React.ReactNode
  warning?: boolean
}) {
  const metric = props.entitlements[props.metric]
  const isOver = typeof metric === 'number' && props.usage[props.metric] > metric
  const isApproaching = metric && typeof metric === 'number' && props.usage[props.metric] > metric * 0.75

  return (
    <HStack spacing="1" fontSize="xs">
      <Text fontWeight={'semibold'} color={(isOver || isApproaching) && props.warning ? 'orange.500' : 'purple.500'}>
        {friendlyNumber(props.usage[props.metric])}
      </Text>
      <Text color="gray.500">
        of{' '}
        {typeof props.entitlements[props.metric] === 'number'
          ? friendlyNumber(props.entitlements[props.metric] as number)
          : '∞'}
      </Text>
      <IconButton
        variant={'ghost'}
        size="xs"
        aria-label="Manage"
        as={Link}
        href={props.href}
        icon={<IconChevronRight size={12} strokeWidth={2} />}
      />
    </HStack>
  )
}

function StatSummaryProgress(props: {
  entitlements: EntitlementsV2
  usage: Props['usage']
  metric: keyof EntitlementsV2
  warning?: boolean
}) {
  const isOver = props.entitlements[props.metric] && props.usage[props.metric] > props.entitlements[props.metric]!
  const isApproaching =
    typeof props.entitlements[props.metric] === 'number' &&
    props.usage[props.metric] > (props.entitlements[props.metric] as number) * 0.75

  return (
    <Progress
      value={props.usage[props.metric] ?? 0}
      max={(props.entitlements[props.metric] as number) ?? undefined}
      size="xs"
      rounded="full"
      colorScheme={isOver && props.warning ? 'orange' : isApproaching && props.warning ? 'orange' : 'purple'}
    />
  )
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Stack as={Card} bg="white" p="4">
        <Heading size="xs">{label}</Heading>
        <Stack spacing="-0.5" fontSize={'xs'}>
          <Text>
            Daily Volume: <strong>{friendlyNumber(payload[1].value)}</strong>
          </Text>
          <Text>
            Total: <strong>{friendlyNumber(payload[0].value)}</strong>
          </Text>
        </Stack>
        {payload[0].payload.isProjected && <Text fontSize={'xs'}>* Projected Volume</Text>}
      </Stack>
    )
  }

  return null
}

function EventVolume(props: { usage: Props['usage']; entitlements: Props['entitlements']; warning?: boolean }) {
  const today = new Date()

  const data = Object.entries(props.usage.event_timeseries).map(([date, count], index) => ({
    date: formatDate(date, 'MMM D'),
    isProjected: new Date(date) > today,
    count,
    sum: sum(Object.values(props.usage.event_timeseries).slice(0, index))
  }))

  const longestLabelLength = data
    .map((c) => shortNumber(c.sum))
    .reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0)

  const isOver = props.entitlements.events && props.usage.events >= props.entitlements.events && props.warning

  return (
    <div style={{ width: '100%', height: 200 }}>
      <ResponsiveContainer>
        <ComposedChart
          data={data}
          margin={{
            left: 0
          }}
        >
          <CartesianGrid horizontal={false} stroke="#f5f5f5" />

          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="sum" stackId={'a'} fill={theme.colors.purple['100']} name="Sum">
            {data.map((entry) => {
              return (
                <Cell
                  key={entry.date}
                  fill={
                    !entry.isProjected ? theme.colors[isOver ? 'orange' : 'purple']['100'] : theme.colors['gray']['50']
                  }
                />
              )
            })}
          </Bar>

          <Bar dataKey="count" stackId={'a'} fill={theme.colors.purple['400']} name="Volume" minPointSize={2}>
            {data.map((entry) => {
              return (
                <Cell
                  key={entry.date}
                  fill={
                    !entry.isProjected ? theme.colors[isOver ? 'orange' : 'purple']['400'] : theme.colors['gray']['200']
                  }
                />
              )
            })}
          </Bar>
          <XAxis
            style={{
              fontFamily: 'Inter'
            }}
            dataKey="date"
            fontSize={'10px'}
            minTickGap={50}
            axisLine={false}
          />
          <YAxis
            style={{
              fontFamily: 'Inter'
            }}
            axisLine={false}
            width={longestLabelLength * 15}
            dataKey={'sum'}
            fontSize={'10px'}
            tickFormatter={shortNumber}
            minTickGap={20}
            domain={[0, props.entitlements.events ? props.entitlements.events * 1.2 : 'dataMax']}
          />
          {props.entitlements.events && (
            <ReferenceLine y={props.entitlements.events} stroke="#999" strokeDasharray="3 3" />
          )}
          <ReferenceLine x={formatDate(today, 'MMM D')} stroke="#999" strokeDasharray="3 3" />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

export default function ShowV2(props: Props) {
  const cost = totalCost(props.entitlements, props.usage)

  return (
    <PageLayout size="md" maxW="900" gap={4}>
      <SettingsBreadCrumb paths={[{ path: projectPath('/settings/billing'), title: 'Billing' }]} offscreen />
      <SettingsHeader>
        <PageTitle>Billing</PageTitle>
        <PageDescription>Manage your workspace's plan and billing information.</PageDescription>
      </SettingsHeader>

      <Stack spacing={8}>
        <BillingHelp />

        <Divider />

        <SubscriptionStatus
          koala_subscription={props.koala_subscription}
          live_subscription={props.live_subscription}
          live_customer={props.live_customer}
          plan={props.plan}
        />

        {props.koala_subscription.version === 'v3' && props.plan !== 'business' && (
          <GrayCard>
            <V3PlanBreakdown
              koala_subscription={props.koala_subscription}
              live_customer={props.live_customer}
              plan={props.plan}
              live_subscription={props.live_subscription}
            />
          </GrayCard>
        )}

        {(props.koala_subscription.product_sku === 'free_plan' ||
          props.koala_subscription.product_sku === 'team_plan') && (
          <PricingBreakdown
            subscription={props.koala_subscription}
            plan={props.plan}
            entitlements={props.entitlements}
            usage={props.usage}
          />
        )}

        {props.koala_subscription && props.live_subscription && (
          <>
            <Divider />

            <SubscriptionSettings
              live_customer={props.live_customer}
              live_subscription={props.live_subscription}
              live_subscriptions={props.live_subscriptions}
              subscription={props.koala_subscription}
              payment_methods={props.payment_methods}
            />
          </>
        )}

        <Stack as={GrayCard} spacing={4} bg="gray.50" p="6" rounded="md">
          <Heading size="sm" fontWeight={'semibold'}>
            Platform Usage
          </Heading>

          <Stack as={Card} spacing="0" p="0">
            <Stack p="8" pb="0" spacing="4">
              <HStack justifyContent={'space-between'} w="100%">
                <Heading size="xs" fontWeight={'semibold'}>
                  Koala Accounts
                </Heading>
                {props.plan === 'free' && (
                  <StatSummary
                    entitlements={props.entitlements}
                    usage={props.usage}
                    metric="accounts"
                    href={projectPath('/settings/excluded-accounts')}
                    warning={props.plan === 'free'}
                  />
                )}
              </HStack>
              {props.plan === 'free' && (
                <StatSummaryProgress
                  entitlements={props.entitlements}
                  usage={props.usage}
                  metric="accounts"
                  warning={props.plan === 'free'}
                />
              )}
              {['team', 'pro', 'starter', 'business'].includes(props.plan ?? '') && (
                <Heading size="lg" fontWeight={'semibold'} color="purple.500">
                  {friendlyNumber(props.usage.accounts)} Accounts
                </Heading>
              )}

              {props.plan === 'free' && cost.extraAccounts > 0 && (
                <Text fontSize="xs">
                  Your workspace is {friendlyNumber(cost.extraAccounts)} over the base limit. You can reduce your
                  overall Account usage by{' '}
                  <Link href={projectPath('/settings/excluded-accounts')}>Managing Excluded Accounts</Link>.
                </Text>
              )}

              {props.plan === 'team' && cost.extraAccounts > 0 && (
                <UnorderedList pl="3" fontSize="xs">
                  <ListItem>Base package includes {friendlyNumber(props.entitlements.accounts)} accounts. </ListItem>
                  {props.koala_subscription.v2_subscription_items?.accounts && (
                    <ListItem>
                      {friendlyNumber(cost.extraAccounts)} extra Accounts will be charged at $
                      {friendlyNumber(teamPrices.account_pack.cost)} per each{' '}
                      {friendlyNumber(teamPrices.account_pack.unit)} accounts.
                    </ListItem>
                  )}
                </UnorderedList>
              )}
            </Stack>

            <Divider pt="8" />
            <HStack w="100%" justifyContent={'space-between'} spacing="0">
              <Button
                flex="1"
                colorScheme={cost.extraAccounts ? 'gray' : 'purple'}
                leftIcon={<IconEyeOff size="14" />}
                variant="ghost"
                as={Link}
                href={projectPath('/settings/excluded-accounts')}
                size="lg"
                fontSize={'sm'}
                borderTopRadius="0"
              >
                Manage Accounts
              </Button>

              {props.plan === 'free' && (
                <ReferralProgramModal>
                  <Button
                    w="100%"
                    flex="1"
                    colorScheme={cost.extraAccounts ? 'gray' : 'purple'}
                    leftIcon={<IconUserPlus size="14" />}
                    variant="ghost"
                    size="lg"
                    fontSize={'sm'}
                    borderTopRadius="0"
                  >
                    Referral Program
                  </Button>
                </ReferralProgramModal>
              )}

              {props.plan === 'free' && cost.extraAccounts && (
                <ChakraTooltip label="Upgrade your plan to unlock more Accounts">
                  <Button
                    flex="1"
                    colorScheme={cost.extraAccounts ? 'gray' : 'purple'}
                    leftIcon={<IconArrowUpRight size="14" />}
                    variant="ghost"
                    size="lg"
                    fontSize={'sm'}
                    borderTopRadius="0"
                    onClick={() => openUpgradeFlow()}
                  >
                    Upgrade Plan
                  </Button>
                </ChakraTooltip>
              )}
            </HStack>
          </Stack>

          <Stack as={Card} spacing="0" p="0">
            <Stack p="8" pb="0" spacing="4">
              <HStack justifyContent={'space-between'} w="100%">
                <Heading size="xs" fontWeight={'semibold'}>
                  Workspace Members
                </Heading>
                {props.plan === 'free' && (
                  <StatSummary
                    entitlements={props.entitlements}
                    usage={props.usage}
                    metric="members"
                    href={membersPath()}
                    warning={props.plan === 'free'}
                  />
                )}
              </HStack>
              {props.plan === 'free' && (
                <StatSummaryProgress
                  entitlements={props.entitlements}
                  usage={props.usage}
                  metric="members"
                  warning={props.plan === 'free'}
                />
              )}
              {['team', 'pro', 'starter', 'business'].includes(props.plan ?? '') && (
                <Heading size="lg" fontWeight={'semibold'} color="purple.500">
                  {friendlyNumber(props.usage.members)} Members
                </Heading>
              )}

              {props.plan === 'free' && cost.extraMembers > 0 && (
                <Text fontSize="xs">
                  Your workspace is {friendlyNumber(cost.extraMembers)} over the base limit. You can manage your
                  workspace members in the{' '}
                  <Link color="purple.500" href={membersPath()}>
                    Members Page
                  </Link>
                  .
                </Text>
              )}

              {['team', 'pro', 'starter', 'business'].includes(props.plan ?? '') && cost.extraMembers > 0 && (
                <UnorderedList pl="3" fontSize="xs">
                  <ListItem>Base package includes {friendlyNumber(props.entitlements.members)} members. </ListItem>
                  {props.plan === 'team' && props.koala_subscription.v2_subscription_items?.seats && (
                    <ListItem>
                      {friendlyNumber(cost.extraMembers)} extra Members will be charged at $
                      {friendlyNumber(teamPrices.member.cost)} per each {friendlyNumber(teamPrices.member.unit)} member.
                    </ListItem>
                  )}

                  {props.plan === 'starter' && (
                    <ListItem>
                      {friendlyNumber(cost.extraMembers)} extra Members will be charged at $
                      {friendlyNumber(starterPrices.member.cost)}/mo per each{' '}
                      {friendlyNumber(starterPrices.member.unit)} member.
                    </ListItem>
                  )}

                  {props.plan === 'pro' && (
                    <ListItem>
                      {friendlyNumber(cost.extraMembers)} extra Members will be charged at $
                      {friendlyNumber(proPrices.member.cost)}/mo per each {friendlyNumber(proPrices.member.unit)}{' '}
                      member.
                    </ListItem>
                  )}

                  {props.plan === 'business' && (
                    <ListItem>
                      {friendlyNumber(cost.extraMembers)} extra Members will be charged at $
                      {friendlyNumber(businessPrices.member.cost)} per each {friendlyNumber(businessPrices.member.unit)}{' '}
                      member.
                    </ListItem>
                  )}
                </UnorderedList>
              )}
            </Stack>

            <Divider pt="8" />
            <HStack w="100%" justifyContent={'space-between'} spacing="0">
              <Button
                flex="1"
                colorScheme={cost.extraMembers ? 'gray' : 'purple'}
                leftIcon={<IconUserPlus size="14" />}
                variant="ghost"
                as={Link}
                href={projectPath('/settings/members')}
                size="lg"
                fontSize={'sm'}
                borderTopRadius="0"
              >
                Manage Members
              </Button>
              {props.plan === 'free' && (
                <ReferralProgramModal>
                  <Button
                    w="100%"
                    flex="1"
                    colorScheme={cost.extraMembers ? 'gray' : 'purple'}
                    leftIcon={<IconUserPlus size="14" />}
                    variant="ghost"
                    size="lg"
                    fontSize={'sm'}
                    borderTopRadius="0"
                  >
                    Referral Program
                  </Button>
                </ReferralProgramModal>
              )}
              {props.plan === 'free' && cost.extraMembers && (
                <ChakraTooltip label="Upgrade your plan to unlock more seats">
                  <Button
                    flex="1"
                    colorScheme={cost.extraMembers ? 'gray' : 'purple'}
                    leftIcon={<IconArrowUpRight size="14" />}
                    variant="ghost"
                    size="lg"
                    fontSize={'sm'}
                    borderTopRadius="0"
                    onClick={() => openUpgradeFlow()}
                  >
                    Upgrade Plan
                  </Button>
                </ChakraTooltip>
              )}
            </HStack>
          </Stack>

          <Stack as={Card} spacing="4">
            <HStack justifyContent={'space-between'} w="100%">
              <Heading size="xs" fontWeight={'semibold'}>
                Event Volume (current month)
              </Heading>
              <StatSummary
                entitlements={props.entitlements}
                usage={props.usage}
                metric="events"
                href={projectPath('/events')}
                warning={props.plan === 'free'}
              />
            </HStack>

            <EventVolume usage={props.usage} entitlements={props.entitlements} warning={props.plan === 'free'} />
            <Text fontSize="xs">
              Note: You can manage which events you want to send to Koala to reduce your overall event volume via the{' '}
              <Link color={'purple.500'} href={projectPath('/events')}>
                Event Manager
              </Link>
              .
            </Text>
          </Stack>
        </Stack>

        {(props.koala_subscription.product_sku === 'free_plan' ||
          props.koala_subscription.product_sku === 'team_plan' ||
          props.koala_subscription.product_sku === 'pro_plan' ||
          props.koala_subscription.product_sku === 'starter_plan') && (
          <LightBgCard bg="purple.50" color="purple.900" borderWidth={0}>
            <Stack spacing={2}>
              <Heading size="md" fontWeight="medium">
                Refer connections. Earn credits.
              </Heading>
              <Text fontSize="sm">
                Share Koala with another company, help them get their data flowing — we'll give you extra credits!
              </Text>
              <ReferralProgramModal>
                <Button variant="solid" colorScheme="purple" size="md" marginTop={4}>
                  Learn more
                </Button>
              </ReferralProgramModal>
            </Stack>
          </LightBgCard>
        )}
      </Stack>
    </PageLayout>
  )
}
