import { Box, Flex, Image } from '@chakra-ui/react'
import { Icon as TablerIcon } from '@tabler/icons-react'
import React from 'react'
import * as ReactIs from 'react-is'
import { Facet, FacetMappings } from '../..'
import { FilterPreviewProps } from '../FilterPreview/types'
import Label from './Label'
import Operator from './Operator'
import RemoveButton from './RemoveButton'
import Values from './Values'

interface Props extends FilterPreviewProps {
  onEditOperator?: (operator: string) => void
  onRemove?: () => void
  facetMappings: FacetMappings
  onClick?: () => void
  overflow?: Array<string | number | boolean> | null
  facet?: string
  label?: string
  operator?: string
  value: Facet
  formatter?: (value: any) => string
  icon?: JSX.Element | TablerIcon | string
  isRemovable?: boolean
}

const FilterTag = (props: Props) => {
  const {
    icon,
    label,
    operator,
    value,
    onEditOperator,
    onRemove,
    facet,
    onClick,
    isEditable,
    overflow,
    formatter,
    ...rest
  } = props

  let iconElement: null | React.ReactElement = null
  if (typeof icon === 'string') {
    iconElement = <Image src={icon} boxSize="16px" />
  } else if (ReactIs.isValidElementType(icon)) {
    iconElement = React.createElement(icon, { size: 16 })
  } else if (React.isValidElement(icon)) {
    iconElement = React.cloneElement(icon, { size: 16 } as any)
  }

  const colorScheme = 'gray'

  return (
    <>
      <Box
        display="inline-flex"
        alignItems="stretch"
        flexGrow={0}
        height="32px"
        color={`${colorScheme}.700`}
        borderColor="gray.200"
        borderRadius={6}
        borderWidth="1px"
        bg="white"
        shadow="sm"
        maxWidth="100%"
        onClick={onClick}
        userSelect="none"
        __css={{
          '& > span:first-of-type': {
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6
          },
          '& > span:last-of-type': {
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6
          }
        }}
      >
        {(label || iconElement) && (
          <Flex as="span" flex="none" gap={[1, 1.5]} paddingX={[1.5, 2]}>
            {iconElement && (
              <Box
                as="span"
                display="flex"
                alignItems="center"
                rounded="sm"
                overflow={typeof icon === 'string' ? 'hidden' : undefined}
                flexShrink={0}
              >
                {iconElement}
              </Box>
            )}
            {label && <Label>{label}</Label>}
          </Flex>
        )}
        {label && operator && (
          <Operator colorScheme={colorScheme} isEditable={isEditable} onEditOperator={onEditOperator}>
            {operator}
          </Operator>
        )}
        <Values
          isEditable={isEditable}
          isRemovable={!!onRemove}
          value={value}
          overflow={overflow}
          facet={facet}
          colorScheme={colorScheme}
          {...rest}
        >
          {formatter ? formatter(value) : value}
        </Values>
        {onRemove && isEditable && <RemoveButton onClick={onRemove} />}
      </Box>
    </>
  )
}

export default FilterTag
