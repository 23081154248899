import React from 'react'
import {
  AvatarGroup,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text
} from '@chakra-ui/react'
import Avatar from '../../../ui/Avatar'
import { User } from '../../../../types/Invite'

interface SpaceMembersProps {
  members?: User[]
}

export function SpaceMembers({ members }: SpaceMembersProps) {
  if (!members?.length) {
    return null
  }

  return (
    <Popover lazyBehavior="unmount" isLazy trigger="hover" openDelay={600}>
      <PopoverTrigger>
        <AvatarGroup size="xs" fontSize="xs" max={3} spacing={-1.5}>
          {members?.map((member) => (
            <Avatar
              key={member.email}
              src={member.image}
              name={member.name || member.email}
              borderWidth="2px"
              boxSizing="content-box"
            />
          ))}
        </AvatarGroup>
      </PopoverTrigger>
      <Portal>
        <PopoverContent minW={{ base: '100%', lg: 'max-content' }}>
          <PopoverBody bg="transparent">
            <Stack py={1} px={0} spacing={1.5}>
              {members?.map((member) => (
                <HStack key={member.id} spacing={2}>
                  <Avatar src={member.image} name={member.name || member.email} size="tiny" />
                  <Text fontSize="sm" fontWeight="medium">
                    {member.name || member.email}
                  </Text>
                </HStack>
              ))}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
