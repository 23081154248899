import CreateOutreachTaskSideSheet from '@app/components/pages/account_views/components/CreateOutreachTaskSideSheet'
import { IconButton, useDisclosure } from '@chakra-ui/react'
import { IconDotsVertical } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { concurrentCachedGET } from '../../../../lib/api'
import { Account } from '../../../../types/Account'
import { App, Apps } from '../../../../types/App'
import { ProjectActions } from '../../../../types/AppActions'
import { Crm } from '../../../../types/Crm'
import { ImportAccountSideSheet } from '../../account_views/components/ImportAccountSideSheet'
import { ImportContactsSideSheet } from '../../account_views/components/ImportContactsSideSheet'
import { accountPath } from '../lib/account-path'
import { AccountActions } from './AccountActions'

interface AccountCTAProps {
  domain: string
  onClaim?: (acc: Account) => void
  onRefresh?: () => void
}

interface Props {
  account: Account
  project_actions: ProjectActions
  crm?: Crm
  apps: Apps
}

export function AccountCTA(props: AccountCTAProps) {
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: false
  })
  const [accountData, setAccountData] = React.useState<Props | null>(null)
  const [mode, setMode] = useState<'account' | 'contacts' | 'task' | null>(null)
  const [selectedApp, setSelectedApp] = useState<App | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (accountData || !isOpen) {
      return
    }

    setLoading(true)
    const path = accountPath({ company: { domain: props.domain } })
    concurrentCachedGET<Props>(path).then((data) => {
      setAccountData(data)
      setLoading(false)
    })
  }, [props.domain, isOpen, accountData])

  return (
    <>
      {!accountData && (
        <IconButton
          cursor="pointer"
          variant="ghost"
          aria-label="Inspect"
          size="xs"
          fontSize="16px"
          color="gray.500"
          icon={<IconDotsVertical size={14} />}
          transition="all 100ms ease-in-out"
          onClick={onOpen}
          isLoading={loading}
        />
      )}

      {accountData && (
        <>
          <AccountActions
            inline
            onClose={onClose}
            isOpen={isOpen}
            variant={'ghost'}
            account={accountData.account}
            apps={accountData.apps}
            projectActions={accountData.project_actions}
            hotkeys={false}
            onClaim={props.onClaim}
            size="xs"
            onRefresh={() => {
              props.onRefresh?.()
            }}
            onRequestImportAccount={(_acc, app) => {
              setMode('account')
              setSelectedApp(app)
            }}
            onRequestImportContacts={(_acc, app) => {
              setMode('contacts')
              setSelectedApp(app)
            }}
            onRequestCreateOutreachTask={(_acc, app) => {
              setMode('task')
              setSelectedApp(app)
            }}
          />

          {mode == 'account' && (
            <ImportAccountSideSheet
              key={accountData.account?.company_id ?? 'no-account-selected'}
              onClose={() => {
                onClose()
                setMode(null)
              }}
              account={accountData.account}
              app={selectedApp}
            />
          )}

          {mode === 'contacts' && (
            <ImportContactsSideSheet
              key={accountData.account?.company_id ?? 'no-account-selected'}
              isOpen={Boolean(accountData.account)}
              onClose={() => {
                onClose()
                setMode(null)
              }}
              account={accountData.account}
            />
          )}

          {mode === 'task' && (
            <CreateOutreachTaskSideSheet
              key={accountData.account?.company_id ?? 'no-account-selected'}
              isOpen={selectedApp && Boolean(accountData.account?.company_id)}
              onClose={() => {
                onClose()
                setMode(null)
              }}
              account={accountData.account}
              app={selectedApp}
            />
          )}
        </>
      )}
    </>
  )
}
