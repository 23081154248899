import {
  Button,
  Heading,
  HStack,
  VStack,
  Stack,
  Switch,
  Text,
  Input,
  Textarea,
  Link,
  FormControl,
  FormLabel,
  FormHelperText,
  useClipboard,
  IconButton
} from '@chakra-ui/react'
import { IconLink, IconPassword, IconKey, IconCheck, IconCopy } from '@tabler/icons-react'
import React, { useState } from 'react'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import { Card } from '../../ui/Card'
import CompanyAvatar from '../../ui/CompanyAvatar'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { useCurrentProject, projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { StepIcon } from '../../ui/StepIcon'
import SquareIcon from '../../ui/SquareIcon'

export interface SecuritySetting {
  supported_auth_methods: string[]
  saml_settings: SamlSettingsProps
  saml_available: boolean | false
}

export default function Settings(props: SecuritySetting) {
  const [useGoogleOauth, setUseGoogleOauth] = useState<boolean>(props.supported_auth_methods?.includes('oauth'))
  const [useMagicLink, setUseMagicLink] = useState<boolean>(props.supported_auth_methods?.includes('magic_link'))
  const [usePassword, setUsePassword] = useState<boolean>(props.supported_auth_methods?.includes('password'))
  const [useSAML, setUseSAML] = useState<boolean>(props.supported_auth_methods?.includes('saml'))
  const [showSAMLFields, setShowSAMLFields] = useState<boolean>(useSAML)

  const handleSAMLChange = (checked: boolean) => {
    setUseSAML(checked)
    setShowSAMLFields(checked)
  }

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb paths={[{ title: 'Security', path: projectPath('settings/security') }]} />

      <SettingsHeader border={'none'}>
        <HStack justifyContent="space-between" w="100%">
          <Stack>
            <PageTitle>Security Settings</PageTitle>
            <PageDescription>Define security settings for the workspace</PageDescription>
          </Stack>
        </HStack>
      </SettingsHeader>

      <form action={projectPath('/settings/security')} method="POST">
        <AuthenticityToken />
        <input type="hidden" name="_method" value="PATCH" />
        <Stack spacing="4" w="100%">
          <Stack>
            <HStack spacing="2">
              <Heading size="sm" fontWeight="semibold">
                Allowed Authentication Methods
              </Heading>
            </HStack>
            <Text fontSize="sm">Choose which authentication methods you want to allow in the workspace.</Text>
          </Stack>

          <Stack w="100%" fontSize="sm" as={Card} spacing="3" p="5">
            <HStack w="100%" justifyContent="space-between">
              <HStack spacing="2">
                <CompanyAvatar domain="google.com" size="xs" rounded="full" />
                <Heading size="sm" fontWeight="semibold">
                  Google OAuth
                </Heading>
              </HStack>
              <Switch
                isChecked={useGoogleOauth}
                onChange={(e) => {
                  setUseGoogleOauth(e.target.checked)
                }}
                isDisabled={useSAML}
              />
              {useGoogleOauth && !useSAML && (
                <input type="hidden" name="security_settings[supported_auth_methods][]" value="oauth" />
              )}
            </HStack>
            <Text color="gray.500">Enable Google OAuth as an authentication method.</Text>
          </Stack>

          <Stack w="100%" fontSize="sm" as={Card} spacing="3" p="5">
            <HStack w="100%" justifyContent="space-between">
              <HStack spacing="2">
                <SquareIcon iconSize={4} icon={IconLink} rounded="lg" colorScheme="purple" />
                <Heading size="sm" fontWeight="semibold">
                  Magic Links
                </Heading>
              </HStack>
              <Switch
                isChecked={useMagicLink}
                onChange={(e) => {
                  setUseMagicLink(e.target.checked)
                }}
                isDisabled={useSAML}
              />
              {useMagicLink && !useSAML && (
                <input type="hidden" name="security_settings[supported_auth_methods][]" value="magic_link" />
              )}
            </HStack>
            <Text color="gray.500">Enable magic links as an authentication method.</Text>
          </Stack>

          <Stack w="100%" fontSize="sm" as={Card} spacing="3" p="5">
            <HStack w="100%" justifyContent="space-between">
              <HStack spacing="2">
                <SquareIcon iconSize={4} icon={IconPassword} rounded="lg" colorScheme="blue" />
                <Heading size="sm" fontWeight="semibold">
                  Password
                </Heading>
              </HStack>
              <Switch
                isChecked={usePassword}
                onChange={(e) => {
                  setUsePassword(e.target.checked)
                }}
                isDisabled={useSAML}
              />
              {usePassword && !useSAML && (
                <input type="hidden" name="security_settings[supported_auth_methods][]" value="password" />
              )}
            </HStack>
            <Text color="gray.500">Enable passwords as an authentication method.</Text>
          </Stack>

          {props.saml_available && (
            <Stack w="100%" fontSize="sm" as={Card} spacing="3" p="5">
              <HStack w="100%" justifyContent="space-between">
                <HStack spacing="2">
                  <SquareIcon iconSize={4} icon={IconKey} rounded="lg" colorScheme="green" />
                  <Heading size="sm" fontWeight="semibold">
                    SAML
                  </Heading>
                </HStack>
                <Switch isChecked={useSAML} onChange={(e) => handleSAMLChange(e.target.checked)} />
                {useSAML && <input type="hidden" name="security_settings[supported_auth_methods][]" value="saml" />}
              </HStack>
              {showSAMLFields ? (
                <SamlSettings
                  idp_sso_target_url={props.saml_settings.idp_sso_target_url}
                  idp_cert={props.saml_settings.idp_cert}
                  idp_cert_fingerprint={props.saml_settings.idp_cert_fingerprint}
                  issuer={props.saml_settings.issuer}
                  assertion_consumer_service_url={props.saml_settings.assertion_consumer_service_url}
                  single_logout_service_url={props.saml_settings.single_logout_service_url}
                  metadata_url={props.saml_settings.metadata_url}
                />
              ) : (
                <Text color="gray.500">Use a single sign-on SAML provider (e.g. Okta, OneLogin).</Text>
              )}
            </Stack>
          )}

          <Button type="submit" colorScheme="purple" w="100%">
            Save Settings
          </Button>
        </Stack>
      </form>
    </PageLayout>
  )
}

interface SamlSettingsProps {
  idp_sso_target_url: string
  idp_cert: string
  idp_cert_fingerprint: string
  issuer: string
  assertion_consumer_service_url: string
  single_logout_service_url: string
  metadata_url: string
}

function SamlSettings(props: SamlSettingsProps) {
  const project = useCurrentProject()
  const [idpSsoTargetUrl, setIdpSsoTargetUrl] = useState(props.idp_sso_target_url)
  const [idpCert, setIdpCert] = useState(props.idp_cert)
  const [idpCertFingerprint, setIdpCertFingerprint] = useState(props.idp_cert_fingerprint)

  const InputWithClipboard = ({ value }: { value: string }) => {
    const { onCopy, hasCopied } = useClipboard(value)

    return (
      <HStack alignItems="center" width="100%">
        <Input value={value} size="sm" isReadOnly border="none" bg="gray.100" />
        <IconButton
          onClick={onCopy}
          aria-label="Copy to clipboard"
          color={hasCopied ? 'purple.500' : 'gray.500'}
          _hover={{ color: 'purple.500' }}
          size="sm"
          icon={hasCopied ? <IconCheck size="17" /> : <IconCopy size="17" />}
        />
      </HStack>
    )
  }

  return (
    <Stack spacing="4" mt="4">
      <Text fontSize="md" fontWeight="bold">
        First, update your IDP (Identity Provider) with these values from Koala
      </Text>

      <HStack alignItems="flex-start">
        <StepIcon step={1} />
        <VStack alignItems="flex-start" width="100%">
          <Text mb="1">Update the Audience/Entity ID for {project?.name}</Text>
          <InputWithClipboard value={props.issuer} />
        </VStack>
      </HStack>

      <HStack alignItems="flex-start">
        <StepIcon step={2} />
        <VStack alignItems="flex-start" width="100%">
          <Text mb="1">Update the Single Sign-on URL</Text>
          <InputWithClipboard value={props.assertion_consumer_service_url} />
          <Text fontSize="sm" color="gray.500">
            Also known as Assertion Consumer Service (ACS) URL
          </Text>
        </VStack>
      </HStack>

      <HStack alignItems="flex-start">
        <StepIcon step={3} />
        <VStack alignItems="flex-start" width="100%">
          <Text mb="1">(Optional) Set the Single Logout URL</Text>
          <InputWithClipboard value={props.single_logout_service_url} />
        </VStack>
      </HStack>

      <Text>
        You can also find our{' '}
        <Link href={props.metadata_url} isExternal color="purple.600">
          metadata XML here
        </Link>
        .
      </Text>

      <Text fontSize="md" fontWeight="bold">
        Next, update Koala with this information from your IDP
      </Text>

      <HStack alignItems="flex-start">
        <StepIcon step={4} />
        <VStack alignItems="flex-start" width="100%">
          <FormControl>
            <FormLabel>Enter the Single Sign-On URL provided by your IDP</FormLabel>
            <Input
              placeholder="Enter your identity provider URL"
              name="security_settings[saml_settings_attributes][idp_sso_target_url]"
              value={idpSsoTargetUrl}
              onChange={(e) => setIdpSsoTargetUrl(e.target.value)}
            />
          </FormControl>
        </VStack>
      </HStack>

      <HStack alignItems="flex-start">
        <StepIcon step={5} />
        <VStack alignItems="flex-start" width="100%">
          <FormControl>
            <FormLabel>Enter the public certificate provided by your IDP</FormLabel>
            <Textarea
              placeholder="Enter your public certificate content"
              name="security_settings[saml_settings_attributes][idp_cert]"
              value={idpCert}
              onChange={(e) => setIdpCert(e.target.value)}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Or, if you prefer, use the certificate fingerprint</FormLabel>
            <Input
              placeholder="Enter your public fingerprint"
              name="security_settings[saml_settings_attributes][idp_cert_fingerprint]"
              value={idpCertFingerprint}
              onChange={(e) => setIdpCertFingerprint(e.target.value)}
            />
            <FormHelperText fontSize="sm" color="gray.500">
              The certificate fingerprint is optional if sharing the full public certificate above.
            </FormHelperText>
          </FormControl>
        </VStack>
      </HStack>
    </Stack>
  )
}
