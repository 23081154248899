import { Box, Heading, HStack, Icon, SkeletonText, Spinner, Stack, Text, Tooltip, Wrap } from '@chakra-ui/react'
import { IconBuilding, IconMapPin, IconUserUp } from '@tabler/icons-react'
import { flatten, intersection } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Account } from '../../../types/Account'
import { Apps } from '../../../types/App'
import { Company, ProfileRecord } from '../../../types/Profile'
import {
  Prospect,
  ProspectFilter,
  ProspectFilters,
  ProspectFilterWithOperator,
  useProfileProspects,
  useProspectAggs
} from '../../data/use-prospects'
import { HelpTooltip } from '../../ui/HelpTooltip'
import { ClearbitIcon } from '../../ui/icons/ClearbitIcon'
import { TableFooter } from '../../ui/TableFooter'
import { humanize } from '../accounts/facets/filter-cloud'
import { SimpleFilter } from './prospect-filters'
import { ProspectorCTA } from '../prospector/components/ProspectorCTA'
import { ProspectsTable } from './prospects-table'

function filterValues(input?: ProspectFilter): string[] {
  if (input === undefined || input === null) {
    return []
  }

  if (Array.isArray(input)) {
    return input
  }

  return input.values
}

export function ProfileTab(props: { account: Account; company: Company; profile: ProfileRecord; apps: Apps }) {
  const [page, setPage] = useState(1)
  const aggs = useProspectAggs({})
  const [customFilters, setCustomFilters] = useState<Record<string, ProspectFilterWithOperator> | undefined>(undefined)

  const prospects = useProfileProspects(props.company.domain!, {
    profile_id: props.profile.id,
    page: page,
    customFilters: customFilters
  })

  const [pageMeta, setPageMeta] = useState(prospects.data?.page_meta)
  const [clearbitHints, setClearbitHints] = useState(prospects.data?.clearbit_hints)
  const [localProspects, setLocalProspects] = useState(prospects.data?.prospect_guesses ?? [])
  const [filters, setFilters] = useState<Record<string, ProspectFilterWithOperator>>(
    (prospects.data?.filters ?? {}) as Record<string, ProspectFilterWithOperator>
  )

  useEffect(() => {
    if (prospects.data && !prospects.isLoading && !prospects.isFetching) {
      setLocalProspects(prospects.data?.prospects ?? [])
      setPageMeta(prospects.data?.page_meta)
      setFilters((prospects.data?.filters ?? {}) as Record<string, ProspectFilterWithOperator>)
      setClearbitHints(prospects.data?.clearbit_hints)
    }
  }, [prospects.data, prospects.isLoading, prospects.isFetching])

  const noData = useMemo(
    () => !prospects.isLoading && !prospects.isFetching && localProspects.length === 0,
    [prospects.isLoading, prospects.isFetching, localProspects.length]
  )

  const selectedTitles = useMemo(() => {
    if (customFilters?.job_title_levels) {
      return customFilters.job_title_levels.values
    }

    if (filters['job_title_levels']?.operator === 'should' && !clearbitHints?.seniority) {
      return []
    }

    return filterValues(filters['job_title_levels'])
  }, [filters, customFilters, clearbitHints])

  const selectedRoles = useMemo(() => {
    if (customFilters?.job_title_role) {
      return customFilters.job_title_role.values
    }

    if (filters['job_title_role']?.operator === 'should' && !clearbitHints?.role) {
      return []
    }

    return filterValues(filters['job_title_role'])
  }, [filters, customFilters, clearbitHints])

  if ((prospects.isLoading || prospects.isFetching) && page === 1) {
    return (
      <Stack w="100%">
        <Spinner size="sm" />
        <Text fontSize={'sm'} color="gray.500">
          Triangulating potential prospects based on location, title, and seniority...
        </Text>
        <SkeletonText mt="4" noOfLines={8} spacing="4" lineHeight={'16px'} />
      </Stack>
    )
  }

  const matchesFilters = (
    prospect: Prospect,
    filters: ProspectFilters,
    key: string,
    value: string | string[] | null
  ) => {
    const val = ((Array.isArray(value) ? value : [value]).filter(Boolean) as string[]).map((v) => v.toLowerCase())
    const vals = filterValues(filters[key]).map((v) => v.toLowerCase())
    return intersection(val, vals)
  }

  return (
    <Stack w="100%" spacing="4">
      <Heading size="xs" fontWeight={'semibold'}>
        Prospect Suggestions
      </Heading>

      {Object.keys(filters).length > 0 && (
        <Stack spacing="4" p="4" borderWidth="thin" rounded="md" borderColor="gray.200" mb="4" fontSize="xs">
          <HStack>
            <Text color="gray.500">Showing prospects based on the following filters:</Text>
            <HelpTooltip mode="popover" placement="right">
              <Stack fontSize={'xs'}>
                <Heading size="xs" fontWeight={'semibold'}>
                  Why am I seeing these prospects?
                </Heading>
                <Text>
                  Koala's IP to Company matching algorithm is sometimes able to infer the job titles and seniority of
                  people at a given company.
                </Text>
                <Text>
                  We tie this information with the IP Geo location of the visitor to triangulate the most likely
                  prospects working at a company.
                </Text>
              </Stack>
            </HelpTooltip>
          </HStack>
          <Stack spacing="1">
            {filters['job_title_levels'] && (
              <HStack spacing="1" direction="row">
                <SimpleFilter
                  name={`Seniority: `}
                  triggerProps={{
                    color: 'gray.600',
                    fontWeight: 'normal',
                    leftIcon: clearbitHints?.seniority ? <ClearbitIcon /> : <Icon as={IconUserUp} />
                  }}
                  selected={selectedTitles}
                  operator={
                    customFilters?.['job_title_levels']?.operator ??
                    (filters['job_title_levels'] as ProspectFilterWithOperator).operator
                  }
                  hideCounts
                  aggs={aggs?.data?.aggs?.job_title_levels ?? { buckets: [] }}
                  isLoading={aggs.isLoading}
                  onChange={(values, operator) => {
                    const filter = {
                      values,
                      operator
                    } as ProspectFilterWithOperator

                    setCustomFilters({
                      ...customFilters,
                      job_title_levels: filter
                    })
                  }}
                />

                {selectedTitles.length > 0 && (
                  <Text color="purple.500" fontWeight="semibold">
                    {filterValues(filters['job_title_levels'])
                      .map((f) => {
                        return humanize(f, false)
                      })
                      .join(', ')}
                  </Text>
                )}

                {selectedTitles.length === 0 && <Text fontSize="xs">None selected</Text>}
              </HStack>
            )}

            {filters['job_title_role'] && (
              <HStack spacing="1" direction="row">
                <SimpleFilter
                  name={'Department'}
                  triggerProps={{
                    color: 'gray.600',
                    fontWeight: 'normal',
                    leftIcon: clearbitHints?.role ? <ClearbitIcon /> : <Icon as={IconBuilding} />,
                    _hover: { color: 'purple.500' }
                  }}
                  selected={selectedRoles}
                  operator={
                    customFilters?.['job_title_role']?.operator ??
                    (filters['job_title_role'] as ProspectFilterWithOperator).operator
                  }
                  hideCounts
                  aggs={aggs?.data?.aggs?.job_title_role ?? { buckets: [] }}
                  isLoading={aggs.isLoading}
                  onChange={(values, operator) => {
                    const filter = {
                      values,
                      operator
                    } as ProspectFilterWithOperator

                    setCustomFilters({
                      ...customFilters,
                      job_title_role: filter
                    })
                  }}
                />

                {selectedRoles.length > 0 && (
                  <Text color="purple.500" fontWeight="semibold">
                    {filterValues(filters['job_title_role'])
                      .map((f) => {
                        if (Array.isArray(f)) {
                          return f.map((v) => humanize(v)).join(', ')
                        }

                        return humanize(f)
                      })
                      .join(', ')}
                  </Text>
                )}

                {selectedRoles.length === 0 && <Text fontSize="xs">None selected</Text>}
              </HStack>
            )}

            {(filters['location_region'] || filters['location_city'] || filters['location_country']) && (
              <HStack spacing="1" direction="row">
                <Tooltip
                  label={`Looking for all prospects within a ${filters?.['lat_lng']?.['range']} radius`}
                  aria-label="Location"
                  hasArrow
                  placement="left"
                >
                  <Icon as={IconMapPin} ml="1.5" color="gray.500" />
                </Tooltip>
                <Text color="purple.500" fontWeight="semibold">
                  {[
                    filterValues(filters['location_city']),
                    filterValues(filters['location_region']),
                    filterValues(filters['location_country'])
                  ]
                    .filter((f) => f && f.length > 0)
                    .map((f) => {
                      if (Array.isArray(f)) {
                        return f.map((v) => humanize(v)).join(', ')
                      }

                      return humanize(f)
                    })
                    .join(', ')}
                </Text>

                {filters['lat_lng'] && filters['lat_lng']['range'] && (
                  <>
                    <Text color="gray.500">({filters['lat_lng']['range']} radius)</Text>
                  </>
                )}
              </HStack>
            )}
          </Stack>
        </Stack>
      )}

      {noData && (
        <Stack p="4" w="100%" spacing="2" bg="gray.50">
          <Heading size="xs">No prospects found</Heading>
          <Stack spacing="0.5">
            <Text fontSize={'sm'} color="gray.500">
              We couldn't find anyone who matches the hints for this Visitor.
            </Text>
          </Stack>
        </Stack>
      )}

      {localProspects.length > 0 && (
        <>
          <Box position={'relative'}>
            {prospects.isLoading && page > 1 && (
              <Box position={'absolute'} right="2" top="0">
                <Spinner size={'sm'} />
              </Box>
            )}

            <ProspectsTable
              account={props.profile.account!}
              context="triangulation"
              prospects={localProspects.map((p) => {
                const highlight = prospects.data?.highlights?.[p.external_id]?.['job_title.analyzed']?.[0]

                const textHiglight = highlight ? (
                  <Text
                    style={{ whiteSpace: 'nowrap' }}
                    sx={{
                      em: {
                        color: 'purple.500',
                        fontWeight: 'semibold'
                      },
                      textTransform: 'capitalize'
                    }}
                    dangerouslySetInnerHTML={{
                      __html: highlight
                    }}
                  />
                ) : null

                const reasons: string[][] = []
                if (textHiglight && highlight && typeof highlight === 'string') {
                  p.highlight = {
                    title_keywords: highlight.replace(/<em>/g, '').replace(/<\/em>/g, '')
                  }
                }

                if (!p.highlight) {
                  p.highlight = {}
                }

                if (matchesFilters(p, filters, 'job_title_levels', p.seniority).length > 0) {
                  const matches = matchesFilters(p, filters, 'job_title_levels', p.seniority)
                  reasons.push(matches)
                  p.highlight.job_title_levels = matches
                }

                if (matchesFilters(p, filters, 'job_title_role', p.departments).length > 0) {
                  const matches = matchesFilters(p, filters, 'job_title_role', p.departments)
                  reasons.push(matches)
                  p.highlight.job_title_roles = matches
                }

                if (matchesFilters(p, filters, 'location_city', p.city).length > 0) {
                  const matches = matchesFilters(p, filters, 'location_city', p.city)
                  reasons.push(matches)
                  if (!Array.isArray(p.highlight.location)) {
                    p.highlight.location = []
                  }
                  p.highlight.location = p.highlight.location.concat(matches)
                }

                if (matchesFilters(p, filters, 'location_region', p.region).length > 0) {
                  const matches = matchesFilters(p, filters, 'location_region', p.region)

                  if (!Array.isArray(p.highlight.location)) {
                    p.highlight.location = []
                  }

                  p.highlight.location = p.highlight.location.concat(matches)
                }

                if (matchesFilters(p, filters, 'location_country', p.country).length > 0) {
                  const matches = matchesFilters(p, filters, 'location_country', p.country)

                  if (!Array.isArray(p.highlight.location)) {
                    p.highlight.location = []
                  }

                  p.highlight.location = p.highlight.location.concat(matches)
                }

                if (textHiglight) {
                  p.reason = textHiglight
                } else if (reasons.length > 0) {
                  p.reason = (
                    <Wrap>
                      {flatten(reasons).map((r) => {
                        return (
                          <Text key={r} color={'purple.500'} fontWeight="normal">
                            {humanize(r, false)}
                          </Text>
                        )
                      })}
                    </Wrap>
                  )
                }

                return p
              })}
              domain={props.company.domain!}
              apps={props.apps}
            />
            {pageMeta && (
              <TableFooter
                isLoading={prospects.isLoading || prospects.isFetching}
                page={page}
                scrollToTop={false}
                setPage={(page) => {
                  setPage(page)
                }}
                pageMeta={pageMeta}
              />
            )}
          </Box>
        </>
      )}

      <ProspectorCTA account={props.account} />
    </Stack>
  )
}
