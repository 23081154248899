import { Box, Divider, Flex, HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { IconPlus, IconUsers } from '@tabler/icons-react'
import React, { useCallback, useMemo } from 'react'
import { useAccountViews } from '../data/use-account-views'
import { ComboboxWithSearch } from './ComboboxWithSearch'
import { BuildingIcon } from './icons'
import SquareIcon from './SquareIcon'

const emptyArray = []

export type PartialAccountView = {
  id?: string
  name: string
  slug: string
  kind?: 'account' | 'profile'
}

type MaybeArray<T> = T | T[]

interface ViewSelectorProps {
  kind?: 'account' | 'profile'
  ownership?: MaybeArray<'private' | 'shared' | 'team'>
  teamId?: string
  spaceId?: string
  includePrivate?: boolean
  placeholder?: PartialAccountView
  selectedAccountViewId?: string | null
  onChange?: (accountView: PartialAccountView | null) => void
  onCreateAccountview?: () => void
}

export function ViewSelector({
  kind,
  teamId,
  spaceId,
  ownership,
  includePrivate,
  placeholder,
  selectedAccountViewId,
  onChange,
  onCreateAccountview
}: ViewSelectorProps) {
  const { data, isLoading } = useAccountViews({ kind, includePrivate, ownership, teamId, spaceId })

  const selected = useMemo(() => {
    return data?.account_views?.find((u) => u.id === selectedAccountViewId) || null
  }, [data?.account_views, selectedAccountViewId])

  const handleChange = useCallback(
    (accountView) => {
      onChange?.(accountView || null)
    },
    [onChange]
  )

  return (
    <ComboboxWithSearch
      items={data?.account_views || emptyArray}
      selectedItem={selected || placeholder || null}
      onChange={handleChange}
      filterItem={(a, val) => a.name?.toLowerCase().includes(val) || a.slug?.toLowerCase().includes(val) || false}
      itemToString={(item) => item?.name || item?.slug || 'Unknown'}
      isLoading={isLoading}
      itemRenderer={AccountViewRenderer}
      selectButtonRenderer={AccountViewRenderer}
      triggerProps={{
        py: 1,
        px: 1.5,
        // border: 'none',
        gap: 1,
        _hover: { bg: 'gray.100' }
      }}
      popoverProps={{
        maxW: '300px'
      }}
      popperOptions={{
        matchWidth: false,
        placement: 'bottom-start'
      }}
      footer={
        onCreateAccountview
          ? (combobox) => {
              return (
                <Stack spacing={0}>
                  <Divider />

                  <Box padding={1}>
                    <Flex
                      as="button"
                      alignItems="center"
                      gap={1.5}
                      fontSize="sm"
                      fontWeight="medium"
                      rounded="md"
                      outline="none"
                      width="100%"
                      userSelect="none"
                      color="gray.700"
                      paddingX={2}
                      paddingY={1.5}
                      _focus={{ bg: 'gray.100' }}
                      _hover={{ bg: 'gray.100' }}
                      onClick={() => {
                        onCreateAccountview()
                        combobox.closeMenu()
                      }}
                    >
                      <Icon as={IconPlus} boxSize={3.5} />
                      Create new list
                    </Flex>
                  </Box>
                </Stack>
              )
            }
          : undefined
      }
    />
  )
}

interface AccountViewRendererProps {
  item: PartialAccountView | null
  selectedItem?: PartialAccountView | null
}

export function AccountViewRenderer({ item }: AccountViewRendererProps) {
  if (!item) {
    return null
  }

  const isAccountView = item.kind === 'account'
  const name = item.name || item.slug || 'Unknown'

  return (
    <HStack flex="1 1 auto" spacing={1.5} title={name} isTruncated>
      <SquareIcon
        colorScheme={isAccountView ? 'purple' : 'blue'}
        icon={isAccountView ? BuildingIcon : IconUsers}
        iconSize="14px"
        padding="2px"
      />
      <Text fontSize="sm" fontWeight="medium" isTruncated>
        {name}
      </Text>
    </HStack>
  )
}
