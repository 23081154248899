import {
  Button,
  Checkbox,
  CircularProgress,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { v4 } from '@lukeed/uuid'
import { IconAlertTriangle, IconUpload } from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import uniq from 'lodash/uniq'
import Papa from 'papaparse'
import pluralize from 'pluralize'
import React, { FormEvent, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'
import router from '../../../../lib/router'
import { validDomain } from '../../../../lib/valid-domain'
import { validEmail } from '../../../../lib/valid-email'
import { ImportList } from '../../../../types/Imports'
import { Card } from '../../../ui/Card'
import CircleIcon from '../../../ui/CircleIcon'
import { projectPath } from '../../../ui/ProjectsContext'
import { VirtualList } from '../../../ui/VirtualList'
import { humanize } from '../../accounts/facets/filter-cloud'
import { SelectedFile } from './SelectedFile'

function detectIdentifierColumn(data: Record<string, string>[], identifier: 'email' | 'domain'): number | null {
  if (!data || data.length === 0) {
    return null
  }

  const columns = Object.keys(data[0])
  const validIdentifer = identifier === 'email' ? validEmail : validDomain

  let columnIndex: number | null = null
  let maxValidIdentifiers = 0

  for (const [index, column] of columns.entries()) {
    let count = 0

    for (const row of data) {
      if (validIdentifer(row[column])) {
        count++
      }
    }

    if (count > maxValidIdentifiers) {
      maxValidIdentifiers = count
      columnIndex = index
    }
  }

  return columnIndex
}

const colors = {
  contacts: 'blue',
  accounts: 'purple'
}

interface Props {
  import: ImportList
}

export function Uploader(props: Props) {
  const [entries, setEntries] = useState<Record<string, string>[]>([])
  const [columns, setColumns] = useState<string[]>([])
  const [name, setName] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState(false)
  const [id, setId] = useState<string | null>(null)
  const [hasHeader, setHasHeader] = useState(true)

  const kind = props.import.kind || 'contacts'
  const identifierName = kind === 'contacts' ? 'email' : 'domain'
  const validIdentifier = kind === 'contacts' ? validEmail : validDomain

  const identifierColumn = React.useMemo(() => {
    return detectIdentifierColumn(entries, kind === 'contacts' ? 'email' : 'domain')
  }, [entries, kind])

  const identifiers = React.useMemo(() => {
    if (identifierColumn === null) {
      return []
    }

    const key = columns.length > 0 ? columns[identifierColumn] : identifierColumn

    return entries
      .map((entry) => {
        const value = entry[key]?.toLowerCase()
        if (validIdentifier(value)) {
          return value
        } else {
          return false
        }
      })
      .filter(Boolean)
  }, [identifierColumn, validIdentifier, columns, entries])

  const uniqIds = React.useMemo(() => uniq(identifiers), [identifiers])

  const dupes = React.useMemo(() => {
    const total = identifiers.length
    const diff = total - uniqIds.length
    return diff
  }, [identifiers, uniqIds])

  const [files, setFiles] = useState<File[]>([])
  const { isDragActive, getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'text/csv': ['.csv']
    },
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles)
    },
    noClick: true,
    multiple: false,
    disabled: submitting
  })

  const file = files[0]

  const suggestedName = React.useMemo(() => {
    if (!file) {
      return undefined
    }

    const fileName = file.name.replace('.csv', '')
    return humanize(fileName)
  }, [file])

  const onSubmit = React.useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      setSubmitting(true)
      const id = v4()
      setId(id)

      const payload: Record<string, any> = {
        id,
        kind,
        name: file.name,
        list_name: name ?? suggestedName
      }

      if (kind === 'contacts') {
        payload.emails = uniqIds.join(',')
      } else {
        payload.domains = uniqIds.join(',')
      }

      post(projectPath('/imports'), payload)
        .then(() => {
          toast.success('Import started')
          router.visit(projectPath(`/imports/${id}`))
        })
        .catch(() => {
          toast.error(`Failed to import ${kind}`)
        })
        .finally(() => {
          setSubmitting(false)
        })
    },
    [uniqIds, kind, file, name, suggestedName]
  )

  useEffect(() => {
    if (file) {
      setEntries([])

      const tempEntries: Record<string, string>[] = []
      const errors: any[] = []
      let columns: string[] = []

      Papa.parse(file, {
        header: hasHeader,
        chunk: (results) => {
          if (results.data) {
            tempEntries.push(...results.data)
          } else if (results.errors) {
            errors.push(...results.errors)
          }

          if (columns.length === 0 && Array.isArray(results.meta?.fields)) {
            columns = Array.from(results.meta.fields)
          }
        },
        complete: () => {
          setEntries(tempEntries)
          setColumns(columns)

          if (errors.length > 0) {
            toast.error('Failed to parse CSV', {
              position: 'bottom-right',
              duration: 2000
            })
            console.warn('Row errors:', errors)
          }
        }
      })
    }
  }, [file, hasHeader])

  const columnLabels = React.useMemo(() => {
    if (columns?.length) {
      return columns
    }

    return Array.from({ length: Object.keys(entries[0] || {}).length }).map((_, i) => `Column ${i + 1}`)
  }, [columns, entries])

  const disclosure = useDisclosure()

  return (
    <Flex as="form" id="import-form" flexDir="column" flex="1 1 auto" gap={6} overflow="hidden" onSubmit={onSubmit}>
      {id && (
        <Text fontSize="sm">
          Feel free to leave this page. <br /> You can keep track of your import in the{' '}
          <Link href={projectPath('/imports')}>Import History</Link> page.
        </Text>
      )}

      <Flex
        {...getRootProps({ className: 'dropzone' })}
        position="relative"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="5"
        p={20}
        border="1.5px solid"
        borderStyle="dashed"
        borderColor={isDragActive ? `${colors[kind]}.500` : 'gray.200'}
        background={isDragActive ? `${colors[kind]}.50` : 'gray.50'}
        rounded="lg"
        outline="none"
        flex="1 1 50%"
        minH="180px"
        height="100%"
      >
        <input {...getInputProps()} />

        {isDragActive ? (
          <Stack alignItems="center" textAlign="center">
            <Stack spacing="3">
              <Heading size="sm">Drop here</Heading>
              <Text fontSize="sm" color="gray.600">
                Drop your .CSV file in this area to upload.
              </Text>
            </Stack>
          </Stack>
        ) : file ? (
          <SelectedFile
            columns={columnLabels}
            rows={entries}
            file={file}
            onReset={() => {
              setFiles([])
              setEntries([])
            }}
          />
        ) : submitting ? (
          <Stack alignItems="center" textAlign="center">
            <CircularProgress color={`${colors[kind]}.500`} size="8" thickness="5px" isIndeterminate />
            <Stack spacing="3">
              <Heading size="sm">Uploading...</Heading>
              <Text fontSize="xs">
                Importing {uniqIds.length} {pluralize(identifierName, uniqIds.length)}
              </Text>
            </Stack>
          </Stack>
        ) : (
          <Stack alignItems="center" spacing={5} fontWeight="medium" textAlign="center">
            <CircleIcon icon={IconUpload} iconSize={6} padding={2.5} colorScheme={colors[kind]} />

            <Text fontSize="xs" color="gray.500">
              Drop your .CSV file in this area to upload, or
            </Text>
            <Button size="sm" variant="outline" bg="white" tabIndex={-1} onClick={open}>
              Choose a CSV file
            </Button>
          </Stack>
        )}
      </Flex>

      {identifiers.length === 0 && files.length > 0 && entries.length > 0 && (
        <Stack
          alignItems="center"
          color="orange.800"
          bg="orange.50"
          p="8"
          borderColor="orange.200"
          borderWidth="1px"
          rounded="lg"
        >
          <IconAlertTriangle size={24} />
          <Text fontSize="sm">
            Your file did not contain any valid {pluralize(identifierName, 2)}. Please upload a CSV with a column
            containing {pluralize(identifierName, 2)}.
          </Text>
        </Stack>
      )}

      {uniqIds.length > 0 && (
        <Card flex="0 1 auto" minH="100px" padding={0}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            bg="gray.50"
            py={2.5}
            px={4}
            borderBottom="1px solid"
            borderColor="gray.100"
            roundedTop="md"
          >
            <Text fontSize="sm" fontWeight="medium">
              {typeof identifierColumn === 'number' ? columnLabels[identifierColumn] : 'Column'}
            </Text>

            <Checkbox size="md" isChecked={hasHeader} onChange={(e) => setHasHeader(e.target.checked)}>
              <Text color="gray.500">First row as header</Text>
            </Checkbox>
          </Flex>
          <VirtualList
            maxH="calc(100% - 42px)"
            items={uniqIds}
            estimateSize={() => 42}
            renderItem={(item, index) => {
              return (
                <Flex
                  key={`${item}-${index}`}
                  w="100%"
                  borderBottomColor="gray.100"
                  borderBottomStyle="solid"
                  borderBottomWidth={uniqIds.length === index + 1 ? '0' : '1px'}
                  alignItems="center"
                  flex="1"
                  _hover={{ bg: 'gray.50' }}
                >
                  <Flex fontSize="sm" gap={4} py={2.5} px={4}>
                    <Text
                      color="gray.400"
                      css={{ fontVariantNumeric: 'tabular-nums' }}
                      textAlign="right"
                      minWidth="22px"
                    >
                      {(index + 1).toLocaleString()}
                    </Text>
                    <Text color="gray.600" fontWeight="medium" fontFamily="mono">
                      {item}
                    </Text>
                  </Flex>
                </Flex>
              )
            }}
          />
        </Card>
      )}

      <HStack justifyContent="space-between" mt="auto">
        {uniqIds.length > 0 && (
          <Text fontSize="sm" fontWeight="medium">
            Matched {format(uniqIds.length)} {pluralize(identifierName, uniqIds.length)} out of {format(entries.length)}{' '}
            entries in CSV.
            {dupes > 0 && ` ${dupes.toLocaleString()} ${pluralize('duplicate', dupes)} found.`}
          </Text>
        )}

        {props.import.static_list_id ? (
          <>
            <input type="hidden" name="import[static_list_id]" value={props.import.static_list_id} />
            <Button
              size="sm"
              colorScheme={colors[kind]}
              ml="auto"
              type="submit"
              form="import-form"
              isDisabled={uniqIds.length === 0}
              isLoading={submitting}
            >
              Start import
            </Button>
          </>
        ) : (
          <Button
            size="sm"
            colorScheme={colors[kind]}
            ml="auto"
            isDisabled={uniqIds.length === 0 || submitting}
            onClick={disclosure.onOpen}
          >
            Continue
          </Button>
        )}
      </HStack>

      <Modal {...disclosure} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalHeader fontSize="md">Create list from import</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                name="import[list_name]"
                size="sm"
                isDisabled={submitting}
                isRequired
                placeholder="List Name"
                defaultValue={suggestedName}
                value={name ?? suggestedName ?? ''}
                onChange={(e) => setName(e.target.value)}
              />
              <FormHelperText>What should we name this list?</FormHelperText>
            </FormControl>
          </ModalBody>
          <ModalFooter justifyContent="flex-end" gap={3}>
            <Button size="sm" variant="outline" onClick={disclosure.onClose}>
              Cancel
            </Button>
            <Button
              size="sm"
              colorScheme={colors[kind]}
              leftIcon={<IconUpload size="14" />}
              type="submit"
              form="import-form"
              isLoading={submitting}
              isDisabled={uniqIds.length === 0 || submitting}
            >
              Import to List
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
