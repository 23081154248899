import {
  Box,
  Button,
  Circle,
  Divider,
  Heading,
  HStack,
  Icon,
  Img,
  Input,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SkeletonText,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconCheck, IconConfetti, IconCopy, IconLink, IconMessage2, IconStar, IconUserPlus } from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'
import { Project } from '../../../../types/Project'
import { useData } from '../../../data/use-data'
import { GrayCard } from '../../../ui/Card'
import { BuildingIcon } from '../../../ui/icons'
import { PricingForm } from '../../../ui/PricingForm'
import { projectPath } from '../../../ui/ProjectsContext'
import { useCopyToClipboard } from '../../../ui/useCopyToClipboard'
import { konfetti } from '../../welcome/components/konfetti'
import img from '../assets/billing-upgrade.png'

interface Props {
  children: React.ReactNode
}

export interface ReferralCode {
  code: string
}

export interface Redemption {
  id: number
  referral_code_id: number
  project: Project
  status: 'pending' | 'valid'
  credit_type: null
}

export function ReferralBanner() {
  const code = useData<{ referral_code: ReferralCode; redemptions: Redemption[] }>(
    'ref-code',
    projectPath('/settings/billing/referral-code')
  )

  const [referral, setReferral] = React.useState<Redemption | null>(null)

  if (!code.data?.redemptions?.length) return null

  const needsConfirmation = code.data.redemptions.filter(
    (r) => r.status === 'valid' && r.credit_type === null && r.project
  )
  if (needsConfirmation.length === 0) return null

  return (
    <>
      {referral && (
        <CreditCheckoutModal
          redemption={referral}
          onClose={() => {
            setReferral(null)
            code.refetch()
          }}
        />
      )}

      <Stack>
        {needsConfirmation.map((r) => (
          <HStack bg="blue.50" key={r.id} rounded="lg" p="4" justifyContent={'center'} alignItems={'center'}>
            <HStack spacing={'2'}>
              <Heading size="sm" fontWeight={'semibold'}>
                🥳 Congrats!
              </Heading>
              <Text fontSize={'md'}>
                Your referral to <strong>{r.project.slug}</strong> has been approved!
              </Text>
            </HStack>
            <Button colorScheme={'purple'} size="sm" onClick={() => setReferral(r)}>
              Activate Credits
            </Button>
          </HStack>
        ))}
      </Stack>
    </>
  )
}

function CreditCheckoutModal(props: { redemption: Redemption; onClose: () => void }) {
  const [loading, setLoading] = React.useState(false)

  useEffect(() => {
    setTimeout(() => {
      konfetti()
      setTimeout(() => konfetti(), 500)
    }, 250)
  }, [])

  const confirm = useCallback(
    (type: 'accounts' | 'member') => {
      setLoading(true)
      post(projectPath('/settings/billing/referral-code/confirm'), {
        referral_redemption: { credit_type: type, id: props.redemption.id }
      })
        .then(() => {
          props.onClose()
          toast.message('🥳 Woo!', {
            description: 'Your credits have been activated.'
          })
        })
        .catch((_e) => {
          toast.error('Error', {
            description: 'Something went wrong. Please try again.'
          })
        })
        .finally(() => setLoading(false))
    },
    [props]
  )

  return (
    <Modal isOpen onClose={props.onClose}>
      <ModalOverlay backdropFilter="blur(8px)" />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p="0" w="100%" fontSize={'sm'}>
          <Img src={img} w="100%" />
          <Stack p="8" spacing="8" px="12">
            <Heading size="lg" fontWeight={'semibold'} textAlign="center">
              🥳 Activate Your Credits
            </Heading>

            <Text color="gray.500">
              You have been approved for a free account upgrade! Please choose how to activate your credits.
            </Text>

            <Stack>
              <Heading size="xs">Options</Heading>
              <List pb="4">
                <ListItem>
                  <ListIcon as={IconUserPlus} />1 Extra Workspace Member
                </ListItem>
                <ListItem>
                  <ListIcon as={BuildingIcon} />
                  250 Extra Accounts
                </ListItem>
              </List>
              <Stack spacing="4">
                <Stack w="100%">
                  <Button
                    colorScheme={'blue'}
                    onClick={() => confirm('member')}
                    leftIcon={<IconUserPlus size="18" />}
                    isLoading={loading}
                  >
                    Workspace Member
                  </Button>
                  <Button
                    colorScheme={'purple'}
                    onClick={() => confirm('accounts')}
                    leftIcon={<BuildingIcon />}
                    isLoading={loading}
                  >
                    Account Pack
                  </Button>
                </Stack>

                <Divider />
                <Button
                  variant={'ghost'}
                  leftIcon={<IconConfetti size="18" />}
                  colorScheme={'purple'}
                  onClick={() => konfetti()}
                >
                  Konfetti!
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export function ReferralModal({ disclosure }: { disclosure: ReturnType<typeof useDisclosure> }) {
  const [showPricingForm, setShowPricingForm] = useState(false)
  const code = useData<{ referral_code: ReferralCode }>('ref-code', projectPath('/settings/billing/referral-code'))
  const url = useMemo(
    () => `https://app.getkoala.com/signup?referral_code=${code.data?.referral_code.code}`,
    [code.data]
  )
  const { copied, copy } = useCopyToClipboard()

  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} size="2xl">
      <ModalOverlay backdropFilter="blur(8px)" />
      <ModalContent>
        <ModalCloseButton />

        <ModalBody p="0" w="100%" fontSize={'sm'}>
          <Img src={img} w="100%" maxH="220px" objectFit="cover" />
          <Stack p="8" spacing="8" px="12">
            <Stack>
              <Heading size="lg" fontWeight={'semibold'}>
                Koala Referral Program
              </Heading>
              <Text color="gray.500">
                Refer Koala to another company, help them get their data flowing — we'll give you extra credits!
              </Text>
            </Stack>

            <Divider />

            <Stack>
              <Stack>
                <Heading size="md" fontWeight={'semibold'}>
                  How it works:
                </Heading>
                <Text color="gray.500">
                  You can unlock more free Accounts or Workspace Members by referring another company to use Koala.
                </Text>
              </Stack>

              <HStack
                gap="12"
                as={GrayCard}
                borderWidth="1px"
                p="8"
                justifyContent={'baseline'}
                alignItems="baseline"
                flexWrap={'wrap'}
              >
                <Stack flex="1" justifyContent={'center'} alignItems="center" textAlign={'center'}>
                  <Circle bg="purple.500" color="white" size="10" fontSize="lg" fontWeight={'semibold'}>
                    <IconMessage2 size="16" />
                  </Circle>
                  <Stack spacing="0">
                    <Heading fontWeight={'semibold'} size="sm">
                      Invite others
                    </Heading>
                    <Text fontSize="xs">Share this link with your friends</Text>
                  </Stack>
                </Stack>

                <Stack flex="1" justifyContent={'center'} alignItems="center" textAlign={'center'}>
                  <Circle bg="purple.500" color="white" size="10" fontSize="lg" fontWeight={'semibold'}>
                    <IconUserPlus size="16" />
                  </Circle>
                  <Stack spacing="0">
                    <Heading size="sm" fontWeight={'semibold'}>
                      They sign up
                    </Heading>
                    <Text fontSize="xs">They instrument their website</Text>
                  </Stack>
                </Stack>

                <Stack flex="1" justifyContent={'center'} alignItems="center" textAlign={'center'}>
                  <Circle bg="green.400" color="white" size="10" fontSize="lg" fontWeight={'semibold'}>
                    <IconStar size="16" />
                  </Circle>
                  <Stack spacing="0">
                    <Heading size="sm" fontWeight={'semibold'}>
                      You get credits
                    </Heading>
                    <Text fontSize="xs">Apply towards more Accounts or Seats</Text>
                  </Stack>
                </Stack>
              </HStack>
            </Stack>

            <Stack
              bg="purple.50"
              w="100%"
              p="6"
              rounded="md"
              spacing="4"
              borderWidth={'2px'}
              borderStyle="dotted"
              borderColor={'purple.200'}
            >
              <HStack>
                <Circle as={IconLink} p="1" rounded="full" size="5" color="white" bg="purple.500" />
                <Heading size="xs">Your Referral Link</Heading>
              </HStack>
              <HStack justifyContent={'space-between'} w="100%">
                {code.isLoading && <SkeletonText noOfLines={1} w="100%" />}
                {code.data && <Input colorScheme={'purple'} type="text" readOnly value={url} bg="white" />}
                <Button
                  aria-label="Copy"
                  colorScheme={'purple'}
                  onClick={() => copy(url)}
                  leftIcon={<Icon as={copied ? IconCheck : IconCopy} boxSize={4} />}
                  isLoading={code.isLoading}
                  minW="24"
                >
                  {copied ? 'Copied' : 'Copy'}
                </Button>
              </HStack>
              <Text fontSize="xs">
                Share the code <strong>{code.data?.referral_code?.code}</strong> with your friends to unlock an extra
                pack of 250 Accounts or 1 extra Workspace Member.
              </Text>
            </Stack>

            <Stack color="gray.600">
              <Heading size="xs">Notes:</Heading>
              <List>
                <ListItem>
                  * Referral redemptions are only valid after the referred company has{' '}
                  <strong>instrumented their website</strong>.
                </ListItem>
                <ListItem>
                  * Redemptions are limited to <strong>one per company</strong>, and require a{' '}
                  <strong>work email</strong>.
                </ListItem>
                <ListItem>
                  * You'll receive an email where you can choose how to activate your credits upon redemption.
                </ListItem>
              </List>
            </Stack>

            <Divider />

            <Button
              alignSelf="center"
              margin="auto"
              colorScheme={'purple'}
              variant="ghost"
              onClick={() => {
                setShowPricingForm(true)
              }}
            >
              Got questions? Chat with us
            </Button>

            {showPricingForm && (
              <PricingForm
                onClose={() => {
                  setShowPricingForm(false)
                }}
              />
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export function ReferralProgramModal(props: Props) {
  const disclosure = useDisclosure()

  return (
    <>
      <Box w="100%" flex={1} onClick={() => disclosure.onOpen()}>
        {props.children}
      </Box>
      {disclosure.isOpen && <ReferralModal disclosure={disclosure} />}
    </>
  )
}
