import { Box, HStack, Icon, Text } from '@chakra-ui/react'
import { IconCircleDashed, IconLineDashed } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { OmnisearchCompany, useCompanySearch } from '../data/use-company-search'
import { ComboboxWithSearch } from './ComboboxWithSearch'
import CompanyAvatar from './CompanyAvatar'

export interface PartialCompany extends OmnisearchCompany {}

interface CompanySelectorProps {
  selectedCompany?: PartialCompany | null
  onChange: (company: PartialCompany | null) => void
  showClearButton?: boolean
}

export function CompanySelector({ selectedCompany, showClearButton, onChange }: CompanySelectorProps) {
  const [inputValue, setInputValue] = useState<string | undefined>()
  const [query] = useDebounce(inputValue, 300)
  const { data, isLoading } = useCompanySearch(query || '*')

  const companies = useMemo(() => {
    return data?.companies ?? []
  }, [data])

  return (
    <ComboboxWithSearch
      items={companies}
      selectedItem={selectedCompany || null}
      onChange={onChange}
      onInputValueChange={setInputValue}
      isLoading={isLoading}
      filterItem={(c, val) => c.name?.toLowerCase().includes(val) || c.domain?.toLowerCase().includes(val) || false}
      itemToString={(item) => item?.name ?? item?.domain ?? ''}
      itemRenderer={CompanyRenderer}
      selectButtonRenderer={CompanyRenderer}
      showClearButton={showClearButton}
    />
  )
}

interface CompanyRendererProps {
  item: PartialCompany | null
  isSelected?: boolean
  isToggleButton?: boolean
}

function CompanyRenderer(props: CompanyRendererProps) {
  const company = props.item

  if (!company) {
    return (
      <HStack spacing={2.5}>
        <Icon as={IconCircleDashed} color="gray.300" boxSize={6} />
        <Text fontSize="sm" fontWeight="medium" color="gray.400">
          Select a company
        </Text>
      </HStack>
    )
  }

  return (
    <HStack spacing={2.5}>
      <CompanyAvatar
        size={props.isToggleButton ? 'xs' : 'sm'}
        domain={company.domain}
        rounded="full"
        {...(company === null
          ? {
              backgroundColor: 'transparent',
              fallbackIcon: <IconLineDashed size={20} />
            }
          : {
              name: company.name || company.domain
            })}
      />
      <Box>
        <Text fontSize="sm" fontWeight="medium">
          {company.name || company.domain || 'Anonymous'}
        </Text>
        {!props.isToggleButton &&
          (company.name ? (
            <Text fontSize="xs" color="gray.500">
              {company.domain}
            </Text>
          ) : null)}
      </Box>
    </HStack>
  )
}
