import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import {
  IconAlertCircle,
  IconArrowDown,
  IconArrowUp,
  IconCheck,
  IconChevronDown,
  IconChevronRight,
  IconCircle,
  IconCircleCheckFilled,
  IconCircleX,
  IconExternalLink,
  IconFilter,
  IconFilterX,
  IconFolderCheck,
  IconMail,
  IconQuestionMark,
  IconX
} from '@tabler/icons-react'
import { castArray, flatten, groupBy, isEmpty, orderBy, pickBy, startCase } from 'lodash'
import React, { useMemo } from 'react'
import router from '../../../lib/router'
import { AutoOutboundContext, AutoOutboundRule } from '../../../types/AutoOutbound'
import { PageMeta } from '../../../types/PageMeta'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Card } from '../../ui/Card'
import CircleIcon from '../../ui/CircleIcon'
import { HoverCard } from '../../ui/HoverCard'
import { IntentBolt, IntentBoltCircle } from '../../ui/IntentBolt'
import { JSONTree } from '../../ui/json-tree'
import MultipleSelectInput from '../../ui/MultipleSelectInput'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import SelectInput from '../../ui/SelectInput'
import { TableFooter } from '../../ui/TableFooter'
import { TextEllipsis } from '../../ui/text-ellipsis'
import { TimeAgo } from '../../ui/TimeAgo'
import { useCurrentUser } from '../../ui/UserContext'
import { PartialVisitor, VisitorSelector } from '../../ui/VisitorSelector'
import { TimelineItem } from '../accounts/components/TimelineItem'
import { getItemDisplay } from '../accounts/facets/categories'
import { BarGraph } from '../analytics/components/BarChart'
import DateRangePicker from '../analytics/components/DateRangePicker'
import { mergeParams } from '../icps/types'
import { SignalType } from '../kql_definitions/components/SignalType'
import { KqlDefinition } from '../kql_definitions/types'
import { automationsPath } from '../notifications/lib/path-helper'
import { profilePath } from '../profiles/lib/path'
import { autoOutboundLogsPath, autoOutboundPath, autoOutboundRulePath } from './lib/path-helpers'

const statusColors = {
  executed: 'green.500',
  failed: 'red.500',
  eligible: 'orange.500',
  ineligible: 'gray.600'
}

const statuses = [
  { label: 'All', value: undefined },
  { label: 'Synced', value: 'executed' },
  { label: 'Failed', value: 'failed' },
  { label: 'Pending', value: 'eligible' },
  { label: 'Filtered', value: 'ineligible' }
]

const emailTypes = [
  { label: 'Anonymous', value: 'anonymous' },
  { label: 'Personal emails', value: 'personal' },
  { label: 'Student emails', value: 'academic' },
  { label: 'Work emails', value: 'work' }
]

export interface AutoOutboundLog {
  id: number
  profile_id: string
  rule_id: string
  rule: AutoOutboundRule
  executed_at: string
  triggered_at?: string | null
  status: string
  context: AutoOutboundContext
}

interface StatTrend {
  total: number
  previous: number
  change: number
  series: Array<{ timestamp: string; value: number }>
}

interface Props {
  logs: AutoOutboundLog[]
  rules: Pick<AutoOutboundRule, 'id' | 'name'>[]
  period?: string
  page_meta: PageMeta
  stats: {
    _error?: { retry: Boolean }
    all: StatTrend
    executed: StatTrend
    failed: StatTrend
    eligible: StatTrend
    ineligible: StatTrend
  }
  profile?: PartialVisitor | null
  intent_signals: Record<string, KqlDefinition>
}

export default function Logs(props: Props) {
  const [period, setPeriod] = React.useState(props.period)
  const [selectedVisitor, setSelectedVisitor] = React.useState<PartialVisitor | null>(props.profile || null)

  const rules = useMemo(() => [{ name: 'All rules', id: '' }].concat(props.rules), [props.rules])

  const params = new URLSearchParams(window.location.search)
  const selectedEmailTypes = (params.get('email_types') ? params.get('email_types')!.split(',') : []).filter(Boolean)
  const mode = params.get('mode')

  const updatePeriod = React.useCallback(async (period: string | undefined) => {
    if (period) {
      setPeriod(period)

      const path = mergeParams(window.location.toString(), { page: '1', period })
      router.visit(path)
    }
  }, [])

  const onSelectVisitor = React.useCallback((visitor: PartialVisitor | null) => {
    setSelectedVisitor(visitor?.id ? visitor : null)
    const path = mergeParams(window.location.toString(), { page: '1', profile_id: visitor?.id || undefined })
    router.visit(path)
  }, [])

  return (
    <PageLayout size="full">
      <Breadcrumb
        paths={[
          { path: automationsPath('/overview'), title: 'Automations' },
          { path: autoOutboundPath(), title: 'Auto Outbound' },
          { path: autoOutboundLogsPath(), title: 'Logs' }
        ]}
      />

      <PageTitle>Auto Outbound History</PageTitle>

      <Flex alignItems="center" gap={3} flexWrap="wrap">
        <FormControl flex="0 1 auto" minW="150px" width="auto" size="sm">
          <FormLabel>Status</FormLabel>
          <SelectInput
            variant="outline"
            size="md"
            items={statuses}
            selectedItem={statuses.find((s) => s.value === (params.get('status') || undefined)) || null}
            onSelectedItemChange={({ selectedItem }) => {
              const status = selectedItem?.value
              const url = mergeParams(window.location.toString(), { status })
              router.visit(url)
            }}
            itemToString={(item) => item?.label ?? item?.value ?? ''}
          />
        </FormControl>
        <FormControl flex="0 1 auto" minW="120px" width="auto" size="sm">
          <FormLabel>Date range</FormLabel>
          <DateRangePicker
            size="md"
            period={period}
            onChange={updatePeriod}
            popoverProps={{ placement: 'bottom', isLazy: true }}
            footer={
              <Box paddingX={4} paddingBottom={3}>
                <Box fontSize="xs" fontStyle="italic" color="gray.500">
                  All times are in UTC
                </Box>
              </Box>
            }
          />
        </FormControl>
        <FormControl flex="0 1 auto" minW="300px" width="auto" size="sm">
          <FormLabel>Person</FormLabel>
          <VisitorSelector selectedVisitor={selectedVisitor} onChange={onSelectVisitor} showClearButton />
        </FormControl>
        <FormControl flex="0 1 auto" minW="150px" width="auto" size="sm">
          <FormLabel>Rule</FormLabel>
          <SelectInput
            variant="outline"
            size="md"
            placeholder="All rules"
            items={rules}
            selectedItem={rules.find((r) => r.id === (params.get('rule_id') || '')) || null}
            onSelectedItemChange={({ selectedItem }) => {
              const rule_id = selectedItem?.id || undefined
              const url = mergeParams(window.location.toString(), { rule_id })
              router.visit(url)
            }}
            itemToString={(item) => item?.name || item?.id || 'Untitled rule'}
          />
        </FormControl>

        <FormControl flex="0 1 auto" minW="300px" width="auto" size="sm">
          <FormLabel>Include</FormLabel>
          <MultipleSelectInput
            placeholder="All identities"
            items={emailTypes}
            itemToString={(item) => item?.label || item?.value || ''}
            initialSelectedItems={
              selectedEmailTypes.length === 0
                ? []
                : emailTypes.filter((type) => selectedEmailTypes.includes(type.value!))
            }
            onSelectedItemsChange={({ selectedItems }) => {
              const included = selectedItems
                ?.map((item) => item.value)
                ?.filter(Boolean)
                ?.join(',')
              if (included) {
                const url = mergeParams(window.location.toString(), { email_types: included })
                router.visit(url)
              } else {
                const url = mergeParams(window.location.toString(), { email_types: undefined })
                router.visit(url)
              }
            }}
            variant="outline"
          />
        </FormControl>
      </Flex>

      <StatsCard stats={props.stats} />

      <LogsTable
        logs={props.logs}
        mode={mode}
        intent_signals={props.intent_signals}
        onSelectVisitor={onSelectVisitor}
      />

      <TableFooter
        pageMeta={props.page_meta}
        page={props.page_meta.current_page}
        nextPath={mergeParams(window.location.toString(), {
          page: (props.page_meta.current_page + 1).toString()
        })}
        prevPath={mergeParams(window.location.toString(), {
          page: (props.page_meta.current_page - 1).toString()
        })}
        sticky
      />
    </PageLayout>
  )
}

interface LogsTableProps {
  logs: AutoOutboundLog[]
  ruleId?: string
  mode?: string | null
  intent_signals: Record<string, KqlDefinition>
  onSelectVisitor?: (visitor: PartialVisitor) => void
}

export function LogsTable({ logs, mode, ruleId, intent_signals, onSelectVisitor }: LogsTableProps) {
  const user = useCurrentUser()

  // If in grouped mode... group the logs!
  const grouped = useMemo(() => {
    if (mode === 'grouped') {
      return groupBy(logs, 'profile_id')
    }
  }, [logs, mode])

  const displayLogs = useMemo(() => {
    if (mode === 'grouped' && grouped) {
      return orderBy(
        Object.values(grouped).map((logs) => logs[0]),
        ['executed_at'],
        ['desc']
      )
    } else {
      return logs
    }
  }, [logs, grouped, mode])

  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            {mode === 'grouped' && <Th padding={0} />}
            <Th paddingLeft={mode !== 'grouped' ? 0 : undefined}>Status</Th>
            <Th>Person</Th>
            {!ruleId && <Th>Rule</Th>}
            <Th>Signal</Th>
            <Th>Checks</Th>
            <Th>Result</Th>
            <Th>Time</Th>
            {user.isInternalUser && <Th>Full Context</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {displayLogs.map((log) => (
            <LogRows
              key={log.id}
              log={log}
              ruleId={ruleId}
              definitions={intent_signals}
              grouped={grouped?.[log.profile_id]}
              mode={mode}
              onSelectVisitor={onSelectVisitor}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

function StatsCard(props: { stats?: Props['stats'] }) {
  const error = props.stats?._error
  return (
    <Card p={0}>
      <Grid
        width="100%"
        templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(auto-fit, minmax(280px, 1fr))']}
        gap={2}
      >
        <StatWithTrend label="Total Runs" trend={props.stats?.all} error={error} />
        <StatWithTrend label="Filtered" trend={props.stats?.ineligible} error={error} />
        <StatWithTrend label="Pending" trend={props.stats?.eligible} error={error} />
        <StatWithTrend label="Failed" trend={props.stats?.failed} error={error} />
        <StatWithTrend label="Synced" trend={props.stats?.executed} error={error} />
      </Grid>
      {props.stats?._error && (
        <Flex marginTop="auto" justifyContent="space-between">
          <Stack p="1">
            <HStack spacing="1" textAlign="center" width="100%">
              <Icon as={IconAlertCircle} color="yellow.500" />
              <Text fontSize="xs">Failed to load stats</Text>
            </HStack>
          </Stack>
        </Flex>
      )}
    </Card>
  )
}
interface StatWithTrendProps {
  label: string
  trend?: StatTrend
  error?: Props['stats']['_error']
}

function StatWithTrend(props: StatWithTrendProps) {
  return (
    <Flex flex="1 1 0%" gap={2} direction="column" justifyContent="space-between" px={3} py={3}>
      <Stack spacing={1.5}>
        <Heading size="xs" fontWeight="normal" color="gray.600">
          {props.label}
        </Heading>
        {!props.error && (
          <Text fontSize="xl" fontWeight="semibold" lineHeight={1}>
            {(props.trend?.total || 0).toLocaleString()}
          </Text>
        )}

        {!!props.trend?.change && (
          <Flex alignItems="center" gap={1}>
            <Flex alignItems="center" gap={0.5} color={props.trend.change > 0 ? 'green.500' : 'red.500'}>
              {props.trend.change > 0 ? <IconArrowUp size={14} /> : <IconArrowDown size={14} />}
              <Text fontSize="sm" lineHeight={1}>
                {Math.abs(Math.round(10 * props.trend.change) / 10).toLocaleString()}%
              </Text>
            </Flex>
            <Text fontSize="xs" lineHeight={1} color="gray.600">
              from prev period
            </Text>
          </Flex>
        )}
      </Stack>
      {!props.error && (
        <Box marginTop="auto">
          <BarGraph
            label={props.label}
            data={props.trend?.series ?? []}
            height={40}
            xDataKey="timestamp"
            yDataKeys={['value']}
            // manually specify the y domain so the bars aren't full height when there's no data
            yDomain={!props.trend?.total ? [0, 100] : undefined}
            xAxis={false}
            yAxis={false}
            minPointSize={1}
            colorScheme="purple"
          />
        </Box>
      )}
    </Flex>
  )
}

interface LogRowsProps {
  log: Props['logs'][0]
  definitions: Record<string, KqlDefinition>
  grouped?: Props['logs']
  ruleId?: string
  mode?: string | null
  onSelectVisitor?: (visitor: PartialVisitor) => void
}

function LogRows({ log, definitions, grouped, ruleId, mode, onSelectVisitor }: LogRowsProps) {
  const disclosure = useDisclosure()

  // have to filter the first one since we already show it!
  const previous = grouped?.filter((_prevLog, index) => index > 0)

  return (
    <>
      <LogRow
        log={log}
        definitions={definitions}
        onSelectVisitor={onSelectVisitor}
        isOpen={disclosure.isOpen}
        mode={mode}
        ruleId={ruleId}
        onToggle={disclosure.onToggle}
      />
      {mode === 'grouped' &&
        previous &&
        disclosure.isOpen &&
        previous.map((prevLog) => (
          <LogRow
            key={prevLog.id}
            log={prevLog}
            ruleId={ruleId}
            definitions={definitions}
            onSelectVisitor={onSelectVisitor}
            mode={mode}
          />
        ))}
    </>
  )
}

interface LogRowProps {
  log: Props['logs'][0]
  definitions: Record<string, KqlDefinition>
  onSelectVisitor?: (visitor: PartialVisitor) => void
  mode?: string | null
  ruleId?: string
  isOpen?: boolean
  onToggle?: () => void
}

function LogRow({ log, mode, ruleId, onSelectVisitor, onToggle, isOpen, definitions }: LogRowProps) {
  const failedCheck = log.context.checks?.find((check) => !log.context.rules[check]?.eligible)
  const info = useMemo(() => {
    if (failedCheck) {
      const deets = log.context.rules[failedCheck]?.info || {}

      if (Array.isArray(deets)) {
        if (deets.length === 2 && typeof deets[0] === 'boolean') {
          return deets[1] || 'Missing'
        } else {
          return deets
        }
      } else if (typeof deets === 'object') {
        return Object.entries(deets).reduce((acc, [key, value]: [string, any]) => {
          if (Array.isArray(value) && value.length === 2) {
            if (!value[0]) {
              acc[key] = value[1] || 'Missing'
            }
          } else {
            acc[key] = value
          }
          return acc
        }, {})
      }
    }
  }, [failedCheck, log.context.rules])

  const signals = flatten(log.context?.rules?.intent?.info?.signals ?? [])
  const latest: any = orderBy(signals, ['triggered_at'], ['desc'])?.[0]

  const user = useCurrentUser()

  return (
    <Tr>
      {mode === 'grouped' &&
        (onToggle ? (
          <Td width="1px" padding={0}>
            <IconButton
              aria-label="Toggle row"
              size="xs"
              variant="ghost"
              icon={<Icon as={isOpen ? IconChevronDown : IconChevronRight} boxSize={4} />}
              onClick={onToggle}
            />
          </Td>
        ) : (
          <Td width="1px" padding={0} />
        ))}
      <Td width="1px">
        <HStack color={statusColors[log.status]}>
          <StatusCell status={log.status} />
          <Text fontSize="sm" fontWeight="medium">
            {log.status === 'ineligible' ? (failedCheck ? startCase(failedCheck) : 'Filtered') : startCase(log.status)}
          </Text>
        </HStack>
      </Td>
      <Td>
        <Box
          position="relative"
          paddingRight="40px"
          paddingY={1.5}
          css={{ '&:hover .filter-button': { visibility: 'visible', opacity: 1 } }}
        >
          <Link
            flex="none"
            display="inline-flex"
            fontWeight="normal"
            href={profilePath({
              id: log.profile_id
            })}
            isExternal
            css={{ '&:hover .show-on-hover': { visibility: 'visible', opacity: 1 } }}
          >
            <TextEllipsis tooltip maxW="340">
              {log.context.email ?? log.profile_id}
            </TextEllipsis>
            <Icon
              className="show-on-hover"
              as={IconExternalLink}
              boxSize={4}
              marginLeft={1}
              visibility="hidden"
              opacity={0}
            />
          </Link>

          {onSelectVisitor && (
            <IconButton
              aria-label="Filter by visitor"
              size="sm"
              variant="outline"
              className="filter-button"
              icon={<Icon as={IconFilter} boxSize={3.5} />}
              position="absolute"
              bg="white"
              zIndex={1}
              right={0}
              top={0}
              bottom={0}
              margin="auto"
              visibility="hidden"
              opacity={0}
              transition="all 150ms ease-in-out"
              onClick={() => onSelectVisitor({ id: log.profile_id, email: log.context.email })}
            />
          )}
        </Box>
      </Td>
      {!ruleId && (
        <Td width="1px">
          {log.rule && (
            <Link fontWeight="normal" href={autoOutboundRulePath(log.rule.id)}>
              {log.rule.name}
            </Link>
          )}
        </Td>
      )}
      <Td width="1px">
        {latest && (
          <HStack lineHeight="20px">
            <SignalType
              label={latest.kql_definition_name}
              signalType={definitions[latest.kql_definition_id]?.signal_type}
              marginRight={1}
              compact
              isTruncated
              fontSize="sm"
              fontWeight="normal"
              flexShrink={1}
              minW="60px"
            />
            <IntentBolt count={signals.length} variant="subtle" />
          </HStack>
        )}
      </Td>
      <Td width="1px">
        <HoverCard trigger="hover" hoverContent={<CheckResults log={log} signal={latest} />}>
          <Flex gap={1.5}>
            {(log.context?.checks ?? []).map((check) => (
              <StepResultIcon key={check} eligible={log.context?.rules?.[check]?.eligible} boxSize={5} />
            ))}
          </Flex>
        </HoverCard>
      </Td>
      <Td width="1px">
        <Box width="300px" overflow="auto" fontSize="sm" color="gray.700">
          {log.status === 'failed' && log.context.errors && log.context.errors.length > 0 && (
            <Stack lineHeight="20px" spacing={0} color="red.500">
              {log.context.errors.map((error, index) => (
                <TextEllipsis key={error + index} tooltip maxW="300px" fontWeight="normal">
                  {error}
                </TextEllipsis>
              ))}
            </Stack>
          )}
          {log.status === 'ineligible' && failedCheck && (
            <Stack lineHeight="20px" spacing={0}>
              <Text maxWidth="100%" whiteSpace="initial">
                {getReason(failedCheck, false, log.context?.rules?.[failedCheck]?.info)}
              </Text>
              {!isEmpty(info) && !log.context?.rules?.[failedCheck]?.info?.reason && <JSONTree data={pickBy(info)} />}
            </Stack>
          )}
        </Box>
      </Td>
      <Td width="1px">
        <TimeAgo time={log.executed_at} format="MM/DD/YYYY HH:mm" />
      </Td>
      {user.isInternalUser && (
        <Td width="1px">
          <Box width="315px" overflow="auto">
            <JSONTree data={{ context: log.context }} />
          </Box>
        </Td>
      )}
    </Tr>
  )
}

interface CheckResultsProps {
  log: Props['logs'][0]
  signal?: {
    kql_definition_name: string
  }
}

function CheckResults({ log, signal }: CheckResultsProps) {
  const sequence = log.context?.rules?.outreach?.info?.destination_sequences?.routed_sequences
  const owner = log.context?.rules?.routing?.info
  const checks = log.context?.checks ?? []
  const errors = log.context?.errors ?? []

  return (
    <Stack spacing={0} fontSize="sm" padding={2}>
      {signal?.kql_definition_name && (
        <TimelineItem icon={<IntentBoltCircle />} isFirst>
          <Text lineHeight="18px" fontWeight="normal" isTruncated>
            {signal.kql_definition_name}
          </Text>
        </TimelineItem>
      )}
      {checks.map((check, index) => (
        <TimelineItem
          key={check + index}
          icon={<StepResultIcon eligible={log.context?.rules?.[check]?.eligible} boxSize={5} />}
          isFirst={index === 0 && !signal?.kql_definition_name}
          isLast={index === checks.length - 1 && !(sequence && sequence.length > 0)}
        >
          <Stack lineHeight="20px" spacing={1}>
            <Text fontWeight="normal" isTruncated>
              {startCase(check)} Step
            </Text>
            {!log.context?.rules?.[check]?.eligible && (
              <Text fontSize="sm" color="gray.500">
                {getReason(check, log.context?.rules?.[check]?.eligible, log.context?.rules?.[check]?.info)}
              </Text>
            )}
          </Stack>
        </TimelineItem>
      ))}
      {sequence && sequence.length > 0 && (
        <TimelineItem
          icon={<CircleIcon icon={IconMail} color="purple.600" bg="purple.100" padding={1} iconSize={3.5} />}
          isLast={errors.length === 0}
        >
          <HStack lineHeight="20px">
            <Text fontWeight="normal">Sequence {sequence[0]?.sequence_id}</Text>
            <Text>(sent from {owner})</Text>
          </HStack>
        </TimelineItem>
      )}
      {errors.map((error, index) => (
        <TimelineItem
          key={error + index}
          icon={<StepResultIcon eligible={false} boxSize={5} />}
          isLast={index === errors.length - 1}
        >
          <Stack lineHeight="20px" spacing={1}>
            <Text fontWeight="normal" isTruncated>
              {error}
            </Text>
          </Stack>
        </TimelineItem>
      ))}
    </Stack>
  )
}

const operators = {
  _and: 'all',
  _or: 'any',
  _not: 'none'
}

function getReason(check: string, eligible?: null | boolean, info?: any) {
  if (typeof eligible !== 'boolean') {
    return 'This step was not executed because prior steps did not pass.'
  }

  if (info?.diff?.account?.length || info?.diff?.profile?.length) {
    const accountDiffs = (info.diff.account ?? []).map((d) => {
      if (['_and', '_or', '_not'].includes(d.key)) {
        return `Account is ${JSON.stringify(d.actual)}, but expected ${
          operators[d.key]
        } of these conditions: ${JSON.stringify(d.condition)}`
      }

      const item = getItemDisplay(d.key, [], 'account')

      let actual = castArray(d.actual)
      if (actual.length === 1) {
        actual = actual[0]
      }

      return `${item.label} is ${JSON.stringify(actual)}, but expected ${JSON.stringify(d.condition)}`
    })

    const profileDiffs = (info.diff.profile ?? []).map((d) => {
      if (['_and', '_or', '_not'].includes(d.key)) {
        return `Visitor is ${JSON.stringify(d.actual)}, but expected ${
          operators[d.key]
        } of these conditions: ${JSON.stringify(d.condition)}`
      }

      const item = getItemDisplay(d.key, [], 'profile')

      let actual = castArray(d.actual)
      if (actual.length === 1) {
        actual = actual[0]
      }

      return `${item.label} is ${JSON.stringify(actual)}, but expected ${JSON.stringify(d.condition)}`
    })

    const reasons = [].concat(...accountDiffs, ...profileDiffs)
    if (reasons.length) {
      return reasons.join('. \n')
    }
  }

  const reason = Array.isArray(info?.reason) ? info.reason.join(', ') : info?.reason
  if (reason) {
    return reason
  }

  switch (check) {
    case 'cadence':
      return 'Already outbounded within the configured time frame.'
    case 'intent':
      return 'No intent signals matched the Auto Outbound configuration.'
    case 'crm':
      return 'One or more of your CRM conditions were not met.'
    case 'routing':
      return 'No routing rules matched.'
    case 'qualification':
      return 'Account or contact conditions were not met.'
    default:
      return ''
  }
}

function StepResultIcon(props: { eligible: boolean | undefined; boxSize?: number }) {
  switch (props.eligible) {
    case true:
      return <Icon as={IconCircleCheckFilled} boxSize={props.boxSize || 18} color="green.500" />
    case false:
      return <Icon as={IconCircleX} boxSize={props.boxSize || 18} color="red.500" />
    default:
      return <Icon as={IconCircle} boxSize={props.boxSize || 18} color="gray.300" />
  }
}

function StatusCell(props: { status: string }) {
  switch (props.status) {
    case 'executed':
      return <CircleIcon icon={IconCheck} iconSize={4} size={6} bg="green.500" color="white" />
    case 'failed':
      return <CircleIcon icon={IconX} iconSize={4} size={6} bg="red.500" color="white" />
    case 'eligible':
      return <CircleIcon icon={IconFolderCheck} iconSize={4} size={6} colorScheme="orange" />
    case 'ineligible':
      return <CircleIcon icon={IconFilterX} iconSize={5} size={6} bg="transparent" color="gray.600" />
    default:
      return <CircleIcon icon={IconQuestionMark} iconSize={4} size={6} colorScheme="gray" />
  }
}
