import { Button, Heading, HStack, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { IconSettings, IconSparkles } from '@tabler/icons-react'
import { uniqBy } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Prospect } from '../../data/use-prospects'
import { projectPath } from '../../ui/ProjectsContext'
import { PersonaTabsProps } from '../profiles/components/AutoProspectCard'
import { Persona, PersonaBuilderModal } from './personas'
import { ProspectsTable } from './prospects-table'

interface StreamedProspect {
  id?: string
  first_name?: string
  last_name?: string
  full_name?: string
  title?: string
  seniority?: string
  location?: string
  city?: string
  state?: string
  country?: string
  country_code?: string
  function?: string
  rank?: string
  reason?: string
  linkedin_url?: string
  highlight?: object
  email?: string
}

export function AIPersonaTab(props: PersonaTabsProps) {
  const controllerRef = useRef<AbortController>()
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState<string[]>([])
  const [ragProspects, setRagProspects] = useState<Prospect[]>([])

  const [localPersona, setLocalPersona] = useState<Persona>(props.persona)
  const disc = useDisclosure()

  const lastUpdateTime = useRef(0)
  const previousTableElementsRef = useRef<StreamedProspect[]>([])
  const [, forceUpdate] = useState({})

  const onPreview = useCallback(() => {
    setIsLoading(true)

    controllerRef.current?.abort()
    controllerRef.current = new AbortController()

    const path = projectPath(`/personas/${props.persona.id}/ai-prospects?domain=${props.domain}`)

    fetchEventSource(path, {
      openWhenHidden: true,
      signal: controllerRef.current.signal,
      onopen: async (res) => {
        setMessages([])

        if (res.ok && res.status === 200) {
          console.log('Connection made ', res)
        } else if (res.status >= 400 && res.status < 500 && res.status !== 429) {
          console.log('Client side error ', res)
        }
      },
      onmessage: (e) => {
        if (e.event === 'data') {
          setMessages((prev) => [...prev, e.data])
        }

        if (e.event === 'rag') {
          setRagProspects(JSON.parse(e.data))
        }
      },
      onerror: () => {
        setIsLoading(false)
        console.log('Error occurred')
      },
      onclose: () => {
        setIsLoading(false)
        console.log('Connection closed')
      }
    })
  }, [props.domain, props.persona.id])

  useEffect(() => {
    if (controllerRef.current) {
      return
    }

    onPreview()
  }, [onPreview])

  // Force update every second to ensure the memo updates
  useEffect(() => {
    const timer = setInterval(() => forceUpdate({}), 250)
    return () => clearInterval(timer)
  }, [])

  const tableElements = useMemo(() => {
    const now = Date.now()
    if (now - lastUpdateTime.current < 250) {
      return previousTableElementsRef.current // Return the previous value if less than a second has passed
    }
    lastUpdateTime.current = now

    const text = messages.join('')
    const [_preamble, ...rawProspects] = text.split('---')

    const mapped = rawProspects
      .map((rawProspect) => {
        const prospect: StreamedProspect = {
          email: 'tbd'
        }

        rawProspect
          .trim()
          .replace(/- /g, '')
          .split('\n')
          .forEach((line) => {
            if (line.includes('id:')) {
              prospect.id = line.replace('id:', '').trim().replace(/\.$/, '').replace(/"/g, '')
            }

            if (line.includes('[LinkedIn]')) {
              const fullName = line.match(/\*\*(.*)\*\*/)?.[1] ?? ''

              prospect.first_name = fullName.split(' ')[0]
              prospect.last_name = fullName.split(' ')[1]
              prospect.full_name = fullName

              prospect.linkedin_url = (line.match(/\(([^)]+)\)/)?.[1] ?? '')
                .replace('http://', 'https://')
                .replace('www.', '')
            }

            if (line.includes('title:')) {
              prospect.title = line.replace('title:', '').trim().replace(/\.$/, '')
            }

            if (line.includes('function:')) {
              prospect.function = line.replace('function:', '').trim().replace(/\.$/, '')
            }

            if (line.includes('seniority:')) {
              prospect.seniority = line.replace('seniority:', '').trim().replace(/\.$/, '')
            }

            if (line.includes('location:')) {
              prospect.location = line.replace('location:', '').trim().replace(/\.$/, '')
              prospect.city = prospect.location?.split(',')?.[0]
              prospect.state = prospect.location?.split(',')?.[1]?.trim()
              prospect.country = prospect.location?.split(',')?.[2]?.trim()
            }

            if (line.includes('highlight:')) {
              try {
                const rawHighlight = line.replace('highlight:', '').trim().replace(/\.$/, '')
                const highlight = JSON.parse(rawHighlight)
                prospect.highlight = highlight
              } catch (err) {
                // This is fine, the highlights are loaded on the fly
              }
            }

            if (line.includes('rank:')) {
              prospect.rank = line
                .replace('rank:', '')
                .trim()
                .replace(/'/g, '')
                .replace(/\.$/, '')
                .replace(/[*]/g, '')
                .toLowerCase()
                .trim()
            }

            if (line.includes('reason:')) {
              prospect.reason = line.replace('reason:', '').trim().replace(/\.$/, '')
            }
          })

        // attempt to merge the base prospect with the streamed prospect
        // so we can get the email address, id, and other goodies required for
        // actions
        const base = ragProspects.find((p) => {
          return p.id === prospect.id
        })

        return {
          ...base,
          ...prospect,
          email: base?.email
        }
      })
      .filter((p) => !!p.linkedin_url && !!p.id)

    const result = uniqBy(mapped, (p) => p.id) as StreamedProspect[]
    previousTableElementsRef.current = result // Store the new value for future reference
    return result
  }, [messages, ragProspects])

  return (
    <Stack position={'relative'}>
      {isLoading && (
        <HStack p="4" w="100%" bg="gray.50" px="6">
          <Spinner size={'sm'} />
          <Text>
            Searching for prospects who match your <b>{localPersona.name}</b> persona
          </Text>
        </HStack>
      )}
      {tableElements.length > 0 && (
        <ProspectsTable
          persona={localPersona}
          account={props.account}
          context="account_page"
          apps={props.apps}
          prospects={tableElements.map((p) => {
            return {
              ...p
            } as unknown as Prospect
          })}
          isLoading={isLoading}
          domain={props.domain}
        />
      )}
      {tableElements.length === 0 && !isLoading && (
        <Stack p="4" w="100%" spacing="2">
          <Heading size="xs">No prospects found</Heading>
          <Stack spacing="0.5">
            <Text fontSize={'sm'} color="gray.500">
              We couldn't find anyone who matches your <b>{localPersona.name}</b> persona. You may want to consider
              broadening your persona definition, or refining your question in the AI Chat.
            </Text>
          </Stack>
        </Stack>
      )}

      <HStack justifyContent={'flex-end'} w="100%" alignItems={'center'}>
        <Button
          disabled={isLoading}
          size="xs"
          leftIcon={<IconSettings size="12" />}
          onClick={disc.onToggle}
          variant="ghost"
        >
          Customize
        </Button>

        <Button
          disabled={isLoading}
          size="xs"
          colorScheme={'purple'}
          leftIcon={<IconSparkles size="12" />}
          onClick={() => props.onProspect(props.persona)}
          variant="ghost"
        >
          Prospect with AI
        </Button>
      </HStack>

      <PersonaBuilderModal
        persona={localPersona}
        isOpen={disc.isOpen}
        onClose={disc.onClose}
        onSave={(updated) => {
          onPreview()
          setLocalPersona(updated)
          props.onChange(updated)
        }}
      />
    </Stack>
  )
}
