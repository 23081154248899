import { Box, HStack, StackProps, Text } from '@chakra-ui/react'
import { Icon as TablerIcon } from '@tabler/icons-react'
import React from 'react'
import SquareIcon from '../../../ui/SquareIcon'
import { signalTypes } from './triggers'

type Props = StackProps & {
  label: string
  description?: string | React.ReactNode
  signalType?: string
  colorScheme?: string
  icon?: TablerIcon | React.FC
  compact?: boolean
}

export function SignalType(props: Props) {
  const { compact, colorScheme, signalType, icon, label, description, children, ...rest } = props
  const signal = signalTypes.find((s) => s.kind === signalType)
  const iconOrFallback = icon || signal?.icon

  return (
    <HStack
      spacing={compact ? 2 : 3}
      justifyContent="flex-start"
      alignItems={compact ? 'center' : 'flex-start'}
      textAlign="left"
      role="group"
      fontSize="sm"
      lineHeight="base"
      fontWeight="medium"
      {...rest}
    >
      {iconOrFallback && (
        <SquareIcon
          icon={iconOrFallback}
          colorScheme={colorScheme || signal?.colorScheme}
          padding={compact ? 1 : 2}
          iconSize={compact ? 3.5 : 5}
          rounded={compact ? 'md' : 'lg'}
        />
      )}
      <Box flex="1" minWidth="60px">
        <Text fontWeight="inherit" isTruncated>
          {label}
        </Text>
        {!compact && (
          <Box fontSize="xs" fontWeight="normal" color="gray.500">
            {description}
          </Box>
        )}
      </Box>
      {props.children}
    </HStack>
  )
}
