import { Button, CloseButton, HStack, Link, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import createPersistedState from 'use-persisted-state'
import { projectPath } from './ProjectsContext'
import { RootCssVar } from './RootCssVar'
import useLocation from './useLocation'

export interface Trial {
  project_id: string
  trial_end_at: string
}

// use-persisted-state relies on `global` being defined,
// but setting `global` in vite.config.js causes chakra-ui
// to fail to render certain styles.
window.global = window

declare global {
  interface Window {
    global: Window
  }
}

function lastSeenState(projectId: string) {
  return createPersistedState(`lastSeen_${projectId}`)
}

function dimissState(projectId: string) {
  return createPersistedState(`dismiss_${projectId}`)
}

export const TrialMessage = (props: { trial: Trial; dismissible?: boolean }) => {
  const location = useLocation()
  const { trial, dismissible } = props
  const isDismissible = dismissible ?? true

  const [lastSeen, setLastSeen] = lastSeenState(trial.project_id)(null)
  const [dismissed, setDismissed] = dimissState(trial.project_id)(false)
  const [visible, setVisible] = React.useState(true)

  const trialEnd = dayjs(trial.trial_end_at)
  const expired = trialEnd.isBefore(dayjs())

  useEffect(() => {
    const shouldSeeBannerAgain = dayjs(lastSeen as string).isBefore(dayjs().subtract(1, 'day'))
    if (shouldSeeBannerAgain) {
      setVisible(true)
    }
  }, [lastSeen])

  useEffect(() => {
    if (!lastSeen || visible) {
      setLastSeen(new Date().toISOString())
    }
  }, [setLastSeen, lastSeen, visible])

  useEffect(() => {
    const isCloseToEndOfTrial = trialEnd && trialEnd.isBefore(dayjs().add(2, 'week'))
    const trialHasEnded = trialEnd.isBefore(dayjs())

    if (dismissed || !isCloseToEndOfTrial || trialHasEnded) {
      setVisible(false)
    }

    if (isCloseToEndOfTrial && !dismissed) {
      setVisible(true)
    }
  }, [setVisible, trialEnd, dismissed])

  if (location.pathname.includes('/settings/billing') || location.pathname.includes('/settings/plans')) {
    return null
  }

  if (!visible && isDismissible) {
    return null
  }

  return (
    <>
      <RootCssVar property="--trial-banner" value="40px" />

      <HStack
        bg={expired ? 'orange.50' : 'blue.50'}
        color={expired ? 'orange.900' : 'blue.900'}
        borderColor={expired ? 'orange.400' : 'blue.400'}
        justifyContent="space-between"
        py="2"
        px="4"
      >
        <HStack flex="1" justifyContent="center">
          {!expired && (
            <Text fontSize={'sm'} as="span">
              Your free trial ends <strong>{trialEnd.format('MMM D, YYYY')}</strong>.
            </Text>
          )}

          {expired && (
            <Text fontSize={'sm'} as="span">
              Your trial ended <strong>{trialEnd.format('MMM D, YYYY')}</strong>. Enter a payment method or manage your
              plan.
            </Text>
          )}

          <Button as={Link} size="xs" colorScheme={expired ? 'orange' : 'blue'} href={projectPath('/settings/billing')}>
            Manage plan
          </Button>
        </HStack>

        {!expired && isDismissible && (
          <CloseButton
            onClick={() => {
              setDismissed(true)
            }}
          />
        )}
      </HStack>
    </>
  )
}
