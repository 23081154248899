import { SlackAlert } from '../types'

export function audienceName(alert: SlackAlert): string {
  if (alert.account_view_id) {
    return alert.account_view?.name || '[Unknown list]'
  } else if (alert.space_id) {
    return alert.space?.name || '[Unknown space]'
  } else {
    return 'Everyone'
  }
}
