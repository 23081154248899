import {
  Button,
  ButtonProps,
  Center,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Img,
  Select,
  Spinner,
  Stack,
  Tag,
  Text
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import React, { useCallback, useMemo, useState } from 'react'
import { useApp } from '../../../../data/use-app'
import { useAppDep } from '../../../../data/use-app-dep'
import { LightBgCard } from '../../../../ui/Card'
import { PopupConnectDialog } from '../../../apps/components/ConnectOauthAppDialog'
import { ReconnectionRequiredWarning } from '../../../apps/components/ReconnectionRequiredWarning'
import { SalesforceDeps } from '../../../apps/salesforce/show'
import { KqlDefinition } from '../../../kql_definitions/types'
import { FollowRule } from '../../../notifications'
import { channelLogos } from '../delivery-setup'
import { ActionSchema, useActionSchema } from '../slack-message-builder/use-action-schema'
import { AddToCampaign } from './add-to-campaign'
import { CreateRecord } from './create-record'
import { CreateTask } from './create-task'
import { ImportAccount } from './import-account'
import { ImportContact } from './import-contact'
import { ImportLead } from './import-lead'
import { SalesforceFieldMapping } from './salesforce-field-mapper'

interface SalesforceSetupProps {
  delivery_rules?: FollowRule['delivery_rules']
  targetType?: 'Profile' | 'Account'
  colorScheme?: ButtonProps['colorScheme']
  actions?: {
    import_account?: boolean
    import_contact?: boolean
    import_lead?: boolean
    add_to_campaign?: boolean
    create_task?: boolean
    create_record?: boolean
  }

  skipBatchFrequency?: boolean
  suggestedMappings?: {
    company?: SalesforceFieldMapping[]
    contact?: SalesforceFieldMapping[]
  }
  kqlDefinitions?: KqlDefinition[]
  setSaveDisabled?: (value) => void
}

export interface SalesforceActionProps {
  deps: SalesforceDeps
  actionSchema?: ActionSchema
  refetchDeps: () => void
  loadingDeps: boolean
  delivery_rules: FollowRule['delivery_rules']
  setDeliveryRules: (rules: FollowRule['delivery_rules']) => void
  suggestedMappings?: SalesforceFieldMapping[]
}

function WrapTask(props: { children: React.ReactNode; name: React.ReactNode; enabled?: boolean; beta?: boolean }) {
  const [visible, setVisible] = React.useState(false)

  return (
    <LightBgCard p="0" bg="white">
      <HStack
        justifyContent={'space-between'}
        onClick={() => setVisible(!visible)}
        cursor="pointer"
        py={2}
        px="5"
        pr="2"
      >
        <HStack fontWeight={'semibold'} fontSize="sm">
          {props.name}
          {props.enabled && (
            <Tag colorScheme={'purple'} size="sm">
              Enabled
            </Tag>
          )}
          {props.beta && !props.enabled && (
            <Tag colorScheme={'purple'} size="sm">
              Beta
            </Tag>
          )}
        </HStack>
        <IconButton
          aria-label="Toggle"
          variant="ghost"
          size="sm"
          icon={visible ? <IconChevronUp size="14" /> : <IconChevronDown size="14" />}
        />
      </HStack>
      {visible && <Divider />}
      <Collapse in={visible}>
        <Flex w="100%" px="6" py="4">
          {props.children}
        </Flex>
      </Collapse>
    </LightBgCard>
  )
}

export function SalesforceSetup(props: SalesforceSetupProps) {
  const salesforce = useApp<SalesforceDeps>('Salesforce')
  const { schema } = useActionSchema()
  const disconnected = useMemo(() => !salesforce.data?.connected, [salesforce.data])
  const invalid = useMemo(() => !salesforce.data?.valid, [salesforce.data])
  const [deliveryRules, setDeliveryRules] = useState(props.delivery_rules)

  const {
    data: depData,
    isFetching: loadingDepData,
    refetch: refetchDepsData
  } = useAppDep<'uncached_deps', SalesforceDeps>('Salesforce', 'uncached_deps')

  const onConnected = useCallback(() => {
    salesforce.refetch()
  }, [salesforce])

  const actions = useMemo(() => {
    if (props.actions) {
      return props.actions
    }

    return {
      import_account: true,
      import_contact: true,
      import_lead: true,
      add_to_campaign: true,
      create_task: true,
      create_record: true
    }
  }, [props.actions])

  if (salesforce.isLoading || !depData) {
    props.setSaveDisabled?.(true)
    return <Spinner />
  }

  if (salesforce.data && disconnected) {
    return (
      <Center w="100%">
        <Flex py="8">
          {salesforce.data && disconnected && (
            <PopupConnectDialog app_id={'salesforce'} onConnected={onConnected}>
              {({ onStart }) => (
                <Stack spacing="4">
                  <Stack spacing="0">
                    <Center>
                      <Heading size="sm">Connect your Salesforce Account</Heading>
                    </Center>
                    <Center>
                      <Text fontSize="sm" color="gray.600">
                        Please connect your Salesforce account in order to get started.
                      </Text>
                    </Center>
                  </Stack>
                  <Center>
                    <Button
                      leftIcon={<Img w="4" src={channelLogos.salesforce} />}
                      size="sm"
                      variant={'outline'}
                      onClick={onStart}
                      colorScheme={'purple'}
                    >
                      Connect Salesforce
                    </Button>
                  </Center>
                </Stack>
              )}
            </PopupConnectDialog>
          )}
        </Flex>
      </Center>
    )
  }

  if (!salesforce.data || !depData) {
    return null
  }

  props.setSaveDisabled?.(false)
  return (
    <Stack spacing="8" py="8">
      <Stack spacing="8">
        {invalid && <ReconnectionRequiredWarning appTitle={'Salesforce'} variant="short" />}
        {actions.import_account && (
          <WrapTask name={<Text>Import Account</Text>} enabled={deliveryRules?.salesforce?.import_account?.enabled}>
            <ImportAccount
              deps={depData?.data.uncached_deps}
              refetchDeps={refetchDepsData}
              actionSchema={schema}
              loadingDeps={loadingDepData}
              delivery_rules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              suggestedMappings={props.suggestedMappings?.company}
            />
          </WrapTask>
        )}
        {actions.import_contact && (
          <WrapTask name={<Text>Import Contact</Text>} enabled={deliveryRules?.salesforce?.import_contact?.enabled}>
            <ImportContact
              deps={depData?.data.uncached_deps}
              refetchDeps={refetchDepsData}
              loadingDeps={loadingDepData}
              actionSchema={schema}
              delivery_rules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              targetType={props.targetType}
              suggestedMappings={props.suggestedMappings?.contact}
            />
          </WrapTask>
        )}
        {actions.import_lead && (
          <WrapTask name={<Text>Import Lead</Text>} enabled={deliveryRules?.salesforce?.import_lead?.enabled}>
            <ImportLead
              deps={depData?.data.uncached_deps}
              refetchDeps={refetchDepsData}
              loadingDeps={loadingDepData}
              delivery_rules={deliveryRules}
              actionSchema={schema}
              setDeliveryRules={setDeliveryRules}
              targetType={props.targetType}
              suggestedMappings={props.suggestedMappings?.contact}
            />
          </WrapTask>
        )}
        {actions.add_to_campaign && (
          <WrapTask name={<Text>Add to Campaign</Text>} enabled={deliveryRules?.salesforce?.add_to_campaign?.enabled}>
            <AddToCampaign
              deps={depData?.data.uncached_deps}
              refetchDeps={refetchDepsData}
              loadingDeps={loadingDepData}
              delivery_rules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
              kqlDefinitions={props.kqlDefinitions}
            />
          </WrapTask>
        )}
        {actions.create_task && (
          <WrapTask name={<Text>Create Task</Text>} enabled={deliveryRules?.salesforce?.create_task?.enabled}>
            <CreateTask
              deps={depData?.data.uncached_deps}
              refetchDeps={refetchDepsData}
              loadingDeps={loadingDepData}
              delivery_rules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
            />
          </WrapTask>
        )}
        {actions.create_record && (
          <WrapTask name={<Text>Create Record</Text>} enabled={deliveryRules?.salesforce?.create_record?.enabled} beta>
            <CreateRecord
              refetchDeps={refetchDepsData}
              loadingDeps={loadingDepData}
              deps={depData?.data.uncached_deps}
              actionSchema={schema}
              delivery_rules={deliveryRules}
              setDeliveryRules={setDeliveryRules}
            />
          </WrapTask>
        )}
      </Stack>

      {props.delivery_rules?.salesforce && !props.skipBatchFrequency && (
        <>
          <Divider />
          <FormControl>
            <FormLabel>Batch Frequency</FormLabel>
            <Select
              size="sm"
              name="follow_rule[delivery_rules][salesforce][trigger]"
              defaultValue={props.delivery_rules.salesforce.trigger ?? 'every hour'}
            >
              <option value={'every 15 minutes'}>Every 15 minutes</option>
              <option value={'every 30 minutes'}>Every 30 minutes</option>
              <option value={'every hour'}>Every hour</option>
            </Select>
            <FormHelperText>Define how often you'd like to batch your Salesforce operations.</FormHelperText>
          </FormControl>
        </>
      )}
    </Stack>
  )
}
