import {
  AvatarGroup,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  ModalProps,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconBookmark, IconDotsVertical, IconEdit, IconTrash, IconUsers } from '@tabler/icons-react'
import * as React from 'react'
import { pluralize } from '../../../lib/pluralize'
import { AccountView } from '../../../types/AccountView'
import { User } from '../../../types/Invite'
import Avatar from '../../ui/Avatar'
import { LightBgCard } from '../../ui/Card'
import { DeleteConfirmation } from '../../ui/DeleteConfirmation'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { usePermission } from '../../ui/PermissionsContext'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'

interface Team {
  id: string
  name: string
  emails: string[]
  members?: TeamMember[]
  account_views?: AccountView[]
}

interface TeamMember extends User {}

interface IndexProps {
  teams: Team[]
}

export default function Index(props: IndexProps) {
  const { hasPermission: canManageMembers } = usePermission({ on: 'project', action: 'can_manage_members' })

  return (
    <PageLayout size="sm">
      <Flex pb="4">
        <SettingsBreadCrumb paths={[{ path: projectPath('/settings/teams'), title: 'Teams' }]} />
      </Flex>

      <SettingsHeader border={'none'}>
        <HStack justifyContent="space-between">
          <Box>
            <PageTitle>Teams</PageTitle>
            <PageDescription>Create teams to manage territory or role-specific settings.</PageDescription>
          </Box>
          {canManageMembers && (
            <Button
              as={Link}
              href={projectPath('/settings/teams/new')}
              marginLeft="auto"
              alignSelf="center"
              size="sm"
              flex="none"
              colorScheme="purple"
            >
              Create team
            </Button>
          )}
        </HStack>
      </SettingsHeader>

      {props.teams.length > 0 ? (
        <Stack spacing="4">
          {props.teams.map((team) => (
            <LightBgCard key={team.id} rounded="lg">
              <TeamRow team={team} canManageMembers={canManageMembers} />
            </LightBgCard>
          ))}
        </Stack>
      ) : (
        <Center paddingY={10}>
          <Text fontSize="sm" color="gray.600">
            No teams have been created yet. Create a team to customize territory or role-specific rules.
          </Text>
        </Center>
      )}
    </PageLayout>
  )
}

interface TeamRowProps {
  canManageMembers: boolean
  team: Team
}

function TeamRow({ canManageMembers, team }: TeamRowProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Grid
      gap={6}
      alignItems="center"
      gridTemplateColumns="minmax(150px, 8fr) minmax(140px, 2fr) minmax(100px, 1fr)"
      minWidth={0}
    >
      <Flex
        alignSelf="stretch"
        as={Link}
        href={projectPath(`/settings/teams/${team.id}`)}
        alignItems="center"
        gap={2}
        _hover={{
          textDecoration: 'none',
          color: 'purple.500'
        }}
      >
        <Stack>
          <Heading size="sm" fontWeight={'semibold'}>
            {team.name}
          </Heading>
          <HStack fontWeight={'normal'}>
            <HStack spacing={1}>
              <IconUsers size={16} />
              <Text fontSize="sm">{pluralize(team.emails?.length, 'Member', 'Members')}</Text>
            </HStack>
            <HStack spacing={1}>
              <IconBookmark size={16} />
              <Text fontSize="sm">{pluralize(team.account_views?.length, 'List', 'Lists')}</Text>
            </HStack>
          </HStack>
        </Stack>
      </Flex>
      {team.emails?.length ? (
        <AvatarGroup size="sm" max={3}>
          {team.emails?.map((email) => {
            const member = team.members?.find((m) => m.email === email)
            if (member) {
              return (
                <Avatar
                  key={member.email}
                  src={member.image}
                  name={member.name || member.email}
                  borderWidth="2px"
                  boxSizing="content-box"
                />
              )
            } else {
              return (
                <Avatar
                  key={email}
                  name={email}
                  borderWidth="2px"
                  color="gray.400"
                  backgroundColor="white"
                  border="1px dashed"
                  borderColor="gray.200"
                />
              )
            }
          })}
        </AvatarGroup>
      ) : (
        <Text fontSize="sm" color="gray.600">
          No members yet
        </Text>
      )}
      {canManageMembers && (
        <Box textAlign="right">
          <Menu placement="bottom-end">
            <MenuButton size="xs" as={IconButton} icon={<IconDotsVertical size={16} />} variant="ghost" />
            <MenuList fontSize="sm">
              <MenuItem
                as={Link}
                href={projectPath(`/settings/teams/${team.id}`)}
                icon={<IconEdit size="16" />}
                onClick={() => {}}
              >
                Manage Settings…
              </MenuItem>
              <MenuItem icon={<IconTrash size="16" />} color="red.500" onClick={onOpen}>
                Delete…
              </MenuItem>
            </MenuList>
          </Menu>
          <DeleteTeam team={team} isOpen={isOpen} onClose={onClose} />
        </Box>
      )}
    </Grid>
  )
}

interface DeleteTeamProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  team: Team
}

function DeleteTeam(props: DeleteTeamProps) {
  return (
    <DeleteConfirmation
      title={`Delete "${props.team.name}" Team?`}
      isOpen={props.isOpen}
      onClose={props.onClose}
      deletePath={projectPath(`/settings/teams/${props.team.id}`)}
      confirmLabel="Delete team"
    >
      <Text fontSize="sm" color="gray.700">
        This will delete the "{props.team.name}" team and all of its settings. However, members in this team will still
        be a part of the workspace.
      </Text>
    </DeleteConfirmation>
  )
}
