import { Button, ButtonProps, Circle, Link, useBreakpointValue } from '@chakra-ui/react'
import React, { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { useMountedState } from 'react-use'
import { ProfilesProps } from '..'
import { concurrentGET } from '../../../../lib/api'
import { projectPath } from '../../../ui/ProjectsContext'
import { SegmentedControl } from '../../../ui/SegmentedControl'
import { mergeParams } from '../../icps/types'

interface Props {
  kind?: 'account' | 'profile'
}

interface LiveButtonProps extends ButtonProps {
  path?: string
  isActive?: boolean
}

export const LiveButton = forwardRef(function LiveButton(
  props: LiveButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const { path = projectPath(`/visitors/live${location.search}`), isActive, ...rest } = props
  const mounted = useMountedState()

  const [totalCount, setTotalCount] = React.useState<number>()
  const hasOnline = useMemo(() => totalCount ?? 0 > 0, [totalCount])

  // refetch live count when the url changes, but dont include things like column changes
  const fetchPath = useMemo(() => {
    return mergeParams(path || projectPath(`/visitors/live${location.search}`), {
      columns: undefined,
      sort_by: undefined,
      page: '1',
      page_size: '0'
    })
  }, [path])

  const fetchingRef = React.useRef(false)
  const refetch = useCallback(async () => {
    if (fetchingRef.current || document.hidden || !mounted()) {
      return
    }

    fetchingRef.current = true

    return concurrentGET<ProfilesProps>(fetchPath).then((res) => {
      if (!mounted()) return
      fetchingRef.current = false
      setTotalCount(res.total_count ?? res.page_meta?.total_count)
    })
  }, [fetchPath, mounted])

  useEffect(() => {
    refetch()
  }, [refetch])

  const buttonActive = isActive ?? location.pathname.endsWith('/live')

  return (
    <Button
      ref={ref}
      as={Link}
      href={path}
      isActive={buttonActive}
      leftIcon={hasOnline && !buttonActive ? <Circle size="2" bg="green.300" /> : undefined}
      {...rest}
      // Hide live counts until we update the algorithm for the old live list
      // rightIcon={
      //   hasOnline ? <Text fontSize="xx-small">{totalCount}</Text> : <Spinner size="xs" colorScheme={'green'} />
      // }
    >
      Live
    </Button>
  )
})

export const VisitorTabs = (props: Props) => {
  // use a modal if we are on small screens/mobile
  const isSmall = useBreakpointValue({ base: true, sm: false, md: false })
  if (isSmall) {
    return null
  }

  if (props.kind === 'account') {
    return (
      <SegmentedControl
        size="sm"
        display={['grid', 'flex']}
        width={['100%', 'auto']}
        gridTemplateColumns="1fr 1px 1fr 1px 1fr"
        marginBottom={['4', '0']}
      >
        <Button
          isActive={location.pathname.endsWith('/accounts')}
          as={Link}
          href={projectPath(`/accounts${location.search}`)}
        >
          Explore
        </Button>
        <Button
          isActive={location.pathname.endsWith('/feed')}
          as={Link}
          href={projectPath(`/accounts/feed${location.search}`)}
        >
          Feed
        </Button>
        <LiveButton />
      </SegmentedControl>
    )
  }

  return (
    <SegmentedControl
      size="sm"
      display={['grid', 'flex']}
      width={['100%', 'auto']}
      gridTemplateColumns="1fr 1px 1fr 1px 1fr"
      marginBottom={['4', '0']}
    >
      <Button
        isActive={location.pathname.endsWith('/visitors')}
        as={Link}
        href={projectPath(`/visitors${location.search}`)}
      >
        Explore
      </Button>
      <Button
        isActive={location.pathname.endsWith('/feed')}
        as={Link}
        href={projectPath(`/visitors/feed${location.search}`)}
      >
        Feed
      </Button>
      <LiveButton />
    </SegmentedControl>
  )
}
