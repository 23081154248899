import { AlternateEmailsList } from '@app/components/ui/AlternateEmailsList'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  StackProps,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { IconArrowLeft } from '@tabler/icons-react'
import React, { useRef, useState, useEffect } from 'react'
import { AuthenticityToken } from '../../ui/AuthenticityToken'
import Avatar from '../../ui/Avatar'
import { User } from '../../ui/UserContext'

interface FieldGroupProps extends StackProps {
  title?: string
}

export const FieldGroup = (props: FieldGroupProps) => {
  const { title, children, ...flexProps } = props
  return (
    <Stack direction={{ base: 'column', md: 'row' }} spacing="6" py="4" {...flexProps}>
      <Box minW="3xs">
        {title && (
          <Heading as="h2" fontSize="lg" flexShrink={0}>
            {title}
          </Heading>
        )}
      </Box>
      {children}
    </Stack>
  )
}

interface ShowProps {
  user: User & { can_change_credentials: boolean }
  errors?: any[]
}

export default function Show(props: ShowProps) {
  const user = props.user

  return (
    <Box px={{ base: '4', md: '10' }} py="8" mx="auto" background="white" minH="100%">
      <Button
        alignSelf="flex-start"
        leftIcon={<Icon as={IconArrowLeft} boxSize={3.5} />}
        iconSpacing={1.5}
        variant="link"
        size="xs"
        flex="none"
        color="gray.500"
        as="a"
        href="/"
        marginBottom={4}
      >
        Back
      </Button>

      <Stack spacing="4" divider={<StackDivider />}>
        <Heading size="lg" as="h1" paddingBottom="4">
          Your Account
        </Heading>

        <ChangePersonalInfoForm user={props.user} errors={props.errors} />

        {user.can_change_credentials && (
          <FieldGroup title="Password">
            <Stack width="full" spacing="6">
              <Box width="100%">
                <ChangePasswordForm />
              </Box>

              <Text pt="4" fontSize={'sm'}>
                Forgot your password? <Link href={'/me/reset-link'}>Set a brand new password here.</Link>
              </Text>
            </Stack>
          </FieldGroup>
        )}

        <FieldGroup title="Profile Photo">
          <Stack direction="row" spacing="6" align="center" width="full">
            <Avatar size="lg" name={user.name || user.email} src={user.image} />
            <VStack alignItems="flex-start" spacing="sm">
              {user.provider ? (
                <Text>Your avatar is set from your connected Google account.</Text>
              ) : (
                <Text>When you log in via Google your avatar will be set from your connected Google account.</Text>
              )}
            </VStack>
          </Stack>
        </FieldGroup>
      </Stack>
    </Box>
  )
}
function ChangePersonalInfoForm(props) {
  const { user, errors } = props
  const [altEmail, setAltEmail] = useState('')

  const [emailChanged, setEmailChanged] = useState(false)
  const [altEmailChanged, setAltEmailChanged] = useState(false)
  const [nameChanged, setNameChanged] = useState(false)
  const [_name, setName] = useState(user.name)
  const [_email, setEmail] = useState(user.email)

  const altEmailDupe = React.useMemo(() => {
    return props.user.alternate_emails.map((e) => e.email).includes(altEmail)
  }, [altEmail, props.user.alternate_emails])

  const [submitting, setSubmitting] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [currentPassword, setCurrentPassword] = useState('')
  const initialRef = useRef(null)

  useEffect(() => {
    if (submitting) {
      if (emailChanged) {
        onOpen()
      } else {
        console.log('submitting?')
        const form = document.getElementById('update_personal_info') as HTMLFormElement
        if (form) {
          form.submit()
        }
      }
    } else {
      onClose()
    }
  }, [submitting, emailChanged, onOpen, onClose])

  return (
    <>
      <Stack>
        {errors &&
          errors.map((message, index) => {
            return (
              <Alert status="error" key={index}>
                <AlertIcon />
                <Stack spacing="-1">
                  <AlertTitle>Validation Error</AlertTitle>
                  <AlertDescription>{message}</AlertDescription>
                </Stack>
              </Alert>
            )
          })}
      </Stack>
      <form id="update_personal_info" action="/me" method="POST" onSubmit={() => setSubmitting(true)}>
        <AuthenticityToken />
        <input type="hidden" name="_method" value="patch" />

        <FieldGroup title="Personal Info">
          <VStack width="full" spacing="6" alignItems="flex-start">
            <FormControl id="name">
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                defaultValue={user.name}
                name="user[name]"
                onChange={(e) => {
                  setNameChanged(e.target.value !== user.name)
                  setName(e.target.value)
                }}
              />
            </FormControl>

            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                defaultValue={user.email}
                name="user[email]"
                isDisabled={!user.can_change_credentials}
                onChange={(e) => {
                  setEmailChanged(e.target.value !== user.email)
                  setEmail(e.target.value)
                }}
              />
              <FormHelperText>
                {user.can_change_credentials
                  ? "You'll be logged out once you update your email. You will have to confirm your old email before using it to login."
                  : 'Changing your email is not possible when using SAML SSO.'}
              </FormHelperText>
            </FormControl>

            {user.can_change_credentials && (
              <>
                <Divider />
                <AlternateEmailsList user={user} showHeader={true} showPrimaryEmail={false} allowDeletion={true} />
                <FormControl>
                  <FormHelperText mb={2}>
                    Optionally add an alternate email, if you have another email used for your Salesforce or HubSpot
                    account.
                  </FormHelperText>
                  <Input
                    placeholder={'New alternate email...'}
                    type="email"
                    name="user[alternate_emails_attributes][][email]"
                    onChange={(e) => {
                      setAltEmailChanged(true)
                      setAltEmail(e.target.value)
                    }}
                  />
                </FormControl>
              </>
            )}
            <HStack>
              <input type="hidden" name="user[current_password]" value={currentPassword} />
              <Button
                onClick={() => setSubmitting(true)}
                colorScheme="purple"
                size="sm"
                isDisabled={altEmailDupe || (!nameChanged && !emailChanged && !altEmailChanged)}
                isLoading={submitting}
              >
                Save Changes
              </Button>
              {altEmailDupe && (
                <Text fontSize={'xs'} color={'red.500'}>
                  Duplicate alternate email
                </Text>
              )}
              <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Confirm your password</ModalHeader>
                  <ModalBody pb={6}>
                    <FormControl id="current_password">
                      <FormLabel>Current Password</FormLabel>
                      <Input
                        ref={initialRef}
                        type="password"
                        autoComplete="off"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                      <FormHelperText mb={2}>
                        Using Google OAuth or a Magic Link without a password?{' '}
                        <Link href={'/me/reset-link'}>Set a brand new password here.</Link>
                      </FormHelperText>
                    </FormControl>
                  </ModalBody>

                  <ModalFooter>
                    <Button onClick={() => setSubmitting(false)} size="sm" variant="outline">
                      Cancel
                    </Button>
                    <Button
                      colorScheme="purple"
                      ml={3}
                      size="sm"
                      type="submit"
                      form="update_personal_info"
                      isDisabled={!currentPassword}
                    >
                      Confirm
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </HStack>
          </VStack>
        </FieldGroup>
      </form>
    </>
  )
}

function ChangePasswordForm() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = React.useRef<HTMLInputElement | null>(null)

  return (
    <>
      <Button size="sm" onClick={onOpen}>
        Change Password
      </Button>

      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change password</ModalHeader>
          <ModalBody pb={6}>
            <form id="update_password" action="/me/password" method="post">
              <input type="hidden" name="_method" value="put" />
              <AuthenticityToken />

              <FormControl id="current_password">
                <FormLabel>Current Password</FormLabel>
                <Input name="user[current_password]" ref={initialRef} type="password" autoComplete="off" />
              </FormControl>

              <FormControl mt={4} id="password">
                <FormLabel>New password</FormLabel>
                <Input name="user[password]" type="password" autoComplete="off" minLength={6} />
              </FormControl>
            </form>
            <Text pt="4" fontSize={'sm'}>
              Using Google OAuth or a Magic Link without a password?{' '}
              <Link href={'/me/reset-link'}>Set a brand new password here.</Link>
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} size="sm" variant="outline">
              Cancel
            </Button>
            <Button colorScheme="purple" ml={3} size="sm" type="submit" form="update_password">
              Change Password
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
