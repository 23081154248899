import { Badge, Box, Center, Flex, Heading, HStack, Link, Stack, Text } from '@chakra-ui/react'
import { IconArrowRight } from '@tabler/icons-react'
import React from 'react'
import router from '../../../lib/router'
import { Account } from '../../../types/Account'
import { Apps } from '../../../types/App'
import { Company } from '../../../types/Profile'
import { CompanySelector } from '../../ui/CompanySelector'
import { AiSparklesIcon } from '../../ui/icons'
import PageLayout from '../../ui/PageLayout'
import { projectPath } from '../../ui/ProjectsContext'
import { mergeParams } from '../icps/types'
import { useAutoProspectingEntitlements } from '../prospects/entitlements'
import { Persona } from '../prospects/personas'
import { ExploreProspectsView } from './components/ExploreProspectsView'
import { ProspectorTitle } from './components/ProspectorHeader'

interface Props {
  account: Account
  company: Company
  personas: Persona[]
  saved_prospects_count?: number
  apps: Apps
  selected_persona: Persona | null
}

export default function Show(props: Props) {
  const { autoProspectingEnabled } = useAutoProspectingEntitlements()
  if (!autoProspectingEnabled) {
    return (
      <PageLayout>
        <Center as={Stack} spacing={4} paddingY={10} paddingX={8}>
          <ProspectorTitle />
          <Text fontSize="md" color="gray.500" maxW="600px" textAlign="center">
            Looks like you found something new we're cooking up. Stay tuned!
          </Text>
        </Center>
      </PageLayout>
    )
  }

  return (
    <PageLayout flush gap={0} maxH="100%">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={4}
        paddingX={[4, 4, 6]}
        paddingY={4}
        borderBottom="1px solid"
        borderColor="gray.200"
        position="relative"
      >
        <HStack spacing={2}>
          <AiSparklesIcon color="accent.500" boxSize={5} />
          <HStack>
            <Heading size="sm" fontWeight="semibold">
              Koala Prospector
            </Heading>
            <Badge colorScheme="purple">Beta</Badge>
          </HStack>
        </HStack>

        <Box flex="none" minWidth="260px" maxWidth="300px">
          <CompanySelector
            onChange={(company) => {
              if (company?.domain && company.domain !== 'None') {
                const path = mergeParams(window.location.toString(), { domain: company.domain })
                router.visit(path)
              }
            }}
            selectedCompany={props.company}
          />
        </Box>

        <Link
          href={projectPath(`/accounts/${props.company.domain}`)}
          color="gray.400"
          fontSize="sm"
          fontWeight="medium"
          display="inline-flex"
          alignItems="center"
          gap={1.5}
        >
          View Account
          <IconArrowRight size={15} />
        </Link>
      </Flex>

      <ExploreProspectsView
        account={props.account}
        company={props.company}
        personas={props.personas}
        selectedPersona={props.selected_persona}
        savedProspectsCount={props.saved_prospects_count}
        apps={props.apps}
      />
    </PageLayout>
  )
}
