import {
  Badge,
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconExternalLink, IconFileText, IconUsers } from '@tabler/icons-react'
import { uniqBy } from 'lodash'
import React, { useCallback, useEffect, useMemo } from 'react'
import { toast } from 'sonner'
import { concurrentGET, del, post } from '../../../lib/api'
import { AccountView } from '../../../types/AccountView'
import { Apps } from '../../../types/App'
import { useFacets } from '../../data/use-facets'
import { LightBgCard } from '../../ui/Card'
import { CheckboxCard } from '../../ui/CheckboxCard'
import CircleIcon from '../../ui/CircleIcon'
import { BuildingIcon } from '../../ui/icons'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import { FacetFilters } from '../accounts'
import { FilterPreview } from '../accounts/components/FilterPreview'
import { SearchBar } from '../accounts/facets/search-bar'
import { accountViewPath, customViewsPath } from '../account_views/lib/list-paths'
import { AccountList } from '../icps/icp/account-list'
import { HighlightedAccount } from '../icps/types'
import { DigestSubscription, SubscribibleView } from '../welcome/rep/rep-audiences'
import { KoalaWeeklyDrawer } from './koala_weekly'

interface Props {
  views: AccountView[]
  apps: Apps
  my_accounts_filters?: FacetFilters
  subscriptions: DigestSubscription[]
}

function SubscriptionsDigest(props: Props & { onUpdate: () => void }) {
  const views = useMemo(() => {
    return (
      props.views
        // remove `My Accounts`, as it's handled separately
        .filter((v) => v.view_type !== 'my_accounts')
    )
  }, [props.views])

  const [selected, setSelected] = React.useState<SubscribibleView[]>(
    props.views
      .filter((v) => props.subscriptions.some((s) => s.account_view_id === v.id))
      .filter((v) => v.view_type !== 'my_accounts')
  )
  const [saving, setSaving] = React.useState(false)

  const [filter, setFilter] = React.useState('')
  const filteredViews = useMemo(() => {
    return views.filter((v) => v.name.toLowerCase().includes(filter.toLowerCase()))
  }, [filter, views])

  useMemo(() => {
    setSelected(views.filter((v) => props.subscriptions.some((s) => s.account_view_id === v.id)))
  }, [views, setSelected, props.subscriptions])

  const subscribe = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      setSaving(true)

      const views = selected.map((view) => {
        return { account_view_id: view.id, kind: 'koala_weekly' }
      })

      post(projectPath('/subscriptions'), {
        digest: {
          kind: 'koala_weekly',
          subscriptions: views
        }
      })
        .then(() => {
          toast.success('Subscribed!', {
            description: 'You will receive weekly updates'
          })
          props.onUpdate()
        })
        .catch((e) => {
          toast.error('Failed to subscribe', {
            description: 'Something went wrong ' + e.message
          })
        })
        .finally(() => {
          setSaving(false)
        })
    },
    [selected, props]
  )

  return (
    <Stack w="100%" as={'form'} onSubmit={subscribe} spacing="6">
      <HStack justifyContent="space-between">
        <Stack>
          <Flex gap={2} alignItems="baseline">
            <Heading size="sm">Lists</Heading>
            <Text fontSize="sm">
              {uniqBy(selected, 'id').length > 0 && `(${uniqBy(selected, 'id').length} of ${views.length} selected)`}
            </Text>
          </Flex>
          <Text fontSize="sm" color="gray.500">
            Select the account and contact lists you want to receive weekly updates for.
          </Text>
        </Stack>
        <HStack spacing="4">
          <Button flex="none" type="submit" colorScheme={'purple'} isLoading={saving} size="sm">
            Save
          </Button>
        </HStack>
      </HStack>

      <SearchBar size="sm" value={filter} onChange={(e) => setFilter(e)} collapsible={false} />

      <Stack overflow={'auto'} maxH="800" divider={<Divider />}>
        {filteredViews.map((view) => (
          <CheckboxCard
            key={view.id + 'checkboxcard'}
            isChecked={selected.includes(view)}
            bg="white"
            _hover={{
              bg: 'gray.50'
            }}
            onChange={(e) => {
              if (e.target.checked) {
                setSelected([...selected, view])
              } else {
                setSelected(selected.filter((v) => v.id !== view.id))
              }
            }}
          >
            <Flex width="100%" justifyContent="space-between" alignItems="center">
              <HStack spacing={3} paddingX={1}>
                <CircleIcon
                  icon={view.kind === 'profile' ? IconUsers : BuildingIcon}
                  colorScheme={view.kind === 'profile' ? 'blue' : 'purple'}
                  iconSize={5}
                  padding={2}
                />
                <Stack spacing="0">
                  <HStack spacing="1">
                    <Text fontSize={'sm'} fontWeight={'semibold'}>
                      {view.name}
                    </Text>
                    <IconButton
                      as={Link}
                      href={accountViewPath(view)}
                      isExternal
                      variant={'ghost'}
                      aria-label={view.name}
                      icon={<IconExternalLink size={14} />}
                      size="xs"
                    />
                  </HStack>
                  <Text fontSize={'xs'} color="gray.500">
                    Created by {view.created_by_user?.name}
                  </Text>
                </Stack>
              </HStack>
              <Badge colorScheme={'gray'} px="2" rounded="full">
                {view.counts?.all}
              </Badge>
            </Flex>
          </CheckboxCard>
        ))}
      </Stack>

      {views.length < 1 && (
        <Stack bg="gray.50" p="4" spacing="1" borderLeftWidth={'thick'}>
          <Heading size="sm" fontWeight={'semibold'}>
            No audiences yet
          </Heading>
          <Text fontSize="sm">
            It looks like your team has not created any audiences yet.{' '}
            <Link color="purple.500" href={customViewsPath()}>
              Create one here.
            </Link>
          </Text>
        </Stack>
      )}
    </Stack>
  )
}

function MyAccounts(props: Props & { onUpdate: () => void }) {
  const [loading, setLoading] = React.useState(false)
  const [accounts, setAccounts] = React.useState<HighlightedAccount[]>([])
  const [loadingMine, setIsLoadingMine] = React.useState(false)

  const subscription = useMemo(() => {
    return props.views.find(
      (v) => v.view_type === 'my_accounts' && props.subscriptions.some((s) => s.account_view_id === v.id)
    )
  }, [props.subscriptions, props.views])

  const [subscribed, setSubscribed] = React.useState<boolean>(!!subscription)

  useEffect(() => {
    setSubscribed(!!subscription)
  }, [subscription])

  const safeFilters = useMemo(() => {
    let asObject = props.my_accounts_filters ?? {}
    if (asObject['_or']) {
      asObject = asObject['_or'][0]
    }

    return asObject
  }, [props.my_accounts_filters])

  const facets = useFacets({
    facet_filters: safeFilters
  })

  useEffect(() => {
    const query = facets.queryString
    const path = projectPath(`/accounts${query}`)

    if (query.length === 0) {
      setAccounts([])
      return
    }

    setLoading(true)
    concurrentGET<{ accounts: HighlightedAccount[] }>(path + '&page_size=5').then((res) => {
      setAccounts(res.accounts)
      setLoading(false)
    })
  }, [facets.queryString])

  const updateFilters = useCallback(() => {
    setIsLoadingMine(true)

    post(accountViewPath({ slug: 'mine' }) + facets.queryString).then(() => {
      setIsLoadingMine(false)

      toast.success('Filters updated', {
        description: 'Your filters have been confirmed.'
      })

      props.onUpdate()
    })

    if (subscribed) {
      post(projectPath('/subscriptions/my-accounts'))
    } else {
      del(projectPath('/subscriptions/my-accounts'))
    }
  }, [facets.queryString, props, subscribed])

  return (
    <Stack w="100%" spacing="8">
      <HStack justifyContent="space-between">
        <Checkbox
          rounded="lg"
          size="lg"
          w="100%"
          isChecked={subscribed}
          onChange={(e) => setSubscribed(e.target.checked)}
        >
          <Stack spacing="0.5" pl="2">
            <Heading size="sm">My Accounts</Heading>
            <Text fontSize="sm" color="gray.500">
              Select new filters that correspond to the accounts you own
            </Text>
          </Stack>
        </Checkbox>

        <Button
          flex="none"
          colorScheme={'purple'}
          size="sm"
          onClick={updateFilters}
          isLoading={loadingMine}
          disabled={facets.queryString.length === 0 || accounts.length === 0}
        >
          {facets.queryString.length === 0 || accounts.length === 0 ? 'Select a filter' : 'Save'}
        </Button>
      </HStack>

      <Stack w="100%" spacing="4">
        {loading && <Spinner />}
        <FilterPreview
          {...facets}
          canClearFilters={false}
          kind="account"
          apps={props.apps}
          shouldShowIntentFilters={false}
          shouldShowCsvUploadFilters={false}
          range={undefined}
        />

        {accounts.length === 0 && (
          <Flex py="8" w="100%">
            <Stack bg="gray.50" p="4" spacing="1" borderLeftWidth={'thick'} w="100%">
              <HStack justifyContent={'space-between'}>
                <Stack spacing="0">
                  <Heading size="xs">No accounts found</Heading>
                  <Text fontSize="sm" color="gray.500">
                    It looks like your filters did not match any accounts. Try adjusting your filters.
                  </Text>
                </Stack>
                <Button variant="outline" size="sm" onClick={() => facets.clearFilters()}>
                  Clear Filters
                </Button>
              </HStack>
            </Stack>
          </Flex>
        )}

        <AccountList compact useExternalLinks accounts={accounts} columns={[]} showActions={false} />
      </Stack>
    </Stack>
  )
}

export default function Index(props: Props) {
  const [preview, setPreview] = React.useState(false)
  const [saved, setSaved] = React.useState(false)
  const [state, setState] = React.useState<Props>(props)

  const onUpdate = useCallback(() => {
    concurrentGET<Props>(projectPath('/subscriptions.json')).then((res) => {
      setState(res)
      setSaved(true)
    })
  }, [])

  return (
    <PageLayout size="sm">
      <SettingsBreadCrumb rootPath={{ path: projectPath('/koala-weekly'), title: 'Koala Weekly' }} offscreen />

      <Drawer isOpen={preview} onClose={() => setPreview(false)} size="xl">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>Koala Weekly</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            <KoalaWeeklyDrawer />
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Stack spacing="8" pt="0">
        <HStack justifyContent={'space-between'} alignItems="flex-start">
          <Stack flex="2">
            <PageTitle>Koala Weekly Subscriptions</PageTitle>
            <PageDescription>
              Receive a weekly digest of the hottest accounts in various audiences. Select any relevant audiences, and
              we'll include them in your digest.
            </PageDescription>
          </Stack>
          <Flex flex="1" justifyContent={'flex-end'}>
            {(state.subscriptions.length > 0 || saved) && (
              <Button
                colorScheme={'purple'}
                leftIcon={<IconFileText size="14" />}
                variant="ghost"
                size="sm"
                onClick={() => {
                  setPreview(!preview)
                }}
              >
                Preview Email
              </Button>
            )}
          </Flex>
        </HStack>

        <LightBgCard>
          <MyAccounts {...state} onUpdate={onUpdate} />
        </LightBgCard>

        <LightBgCard>
          <SubscriptionsDigest {...state} onUpdate={onUpdate} />
        </LightBgCard>
      </Stack>
    </PageLayout>
  )
}
