import {
  Box,
  Button,
  Collapse,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { IconArrowRight, IconChevronDown, IconChevronRight, IconEdit, IconRefresh, IconX } from '@tabler/icons-react'
import { keyBy, set } from 'lodash'
import { nanoid } from 'nanoid'
import React, { useCallback, useMemo, useState } from 'react'
import { useAppDep } from '../../../../data/use-app-dep'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import { JSONTree } from '../../../../ui/json-tree'
import { useCurrentUser } from '../../../../ui/UserContext'
import { ActionField } from '../../../actions/components/action-field'
import { SalesforceDeps, SalesforceField } from '../../../apps/salesforce/show'
import { channelLogos } from '../delivery-setup'
import { Ingredient, variableFieldOptionsByType } from '../slack-message-builder/ingredient-selector'
import { ActionSchema } from '../slack-message-builder/use-action-schema'
import { NotificationVariables, useNotificationVariables } from '../slack-message-builder/use-notification-variables'

interface FieldMapperProps {
  actionsSchema: ActionSchema
  deps: SalesforceDeps
  refetchDeps: () => void
  loadingDeps: boolean

  type: 'contact' | 'account' | 'lead' | string
  namespace?: string
  mappings?: SalesforceFieldMapping[]
  suggestions?: SalesforceFieldMapping[]
}

interface PropertyEntryProps {
  isLoadingDeps?: boolean
  actionSchema: ActionSchema
  layout: SalesforceField[]
  variables: NotificationVariables
  namespace?: string
  id?: string
  koalaField?: string
  type?: string
  salesforceField?: string
  mode?: 'mapped' | 'hardcoded'
  value?: string
  koalaOptions: Array<{
    key: string
    label: string
    humanLabel: string
  }>
  onChange: (koala: string | undefined, salesforce: string | undefined) => void
}

interface FieldPreviewProps {
  item: SalesforceField | null
  selectedItem?: SalesforceField | null
}

export function FieldPreview(props: FieldPreviewProps) {
  const item = props.item

  if (!item) {
    return (
      <HStack flex="1" fontSize={'sm'}>
        <Img src={channelLogos.salesforce} w="4" />
        <Text color="gray.600">Select a field in Salesforce</Text>
      </HStack>
    )
  }

  return (
    <HStack flex="1" fontSize={'sm'}>
      <Img src={channelLogos.salesforce} w="4" />
      <Text>{item.label}</Text>
    </HStack>
  )
}

export function LoadingFieldPreview(_props: FieldPreviewProps) {
  return (
    <HStack flex="1" fontSize={'sm'}>
      <Img src={channelLogos.salesforce} w="4" />
      <Skeleton width="100%" height="4" />
    </HStack>
  )
}

function PropertyEntry(props: PropertyEntryProps) {
  const [selected, setSelected] = useState<SalesforceField | null>(
    props.layout.find((l) => l.name === props.salesforceField) ?? null
  )

  const [selectedKoala, setSelectedKoala] = useState<Ingredient | null>(
    props.koalaOptions.find((o) => o.key === props.koalaField) ?? null
  )

  const { salesforceField, koalaField, onChange } = props

  const onKoalaChange = useCallback(
    (koala: Ingredient | null) => {
      setSelectedKoala(koala)
      onChange(koala?.key, salesforceField)
    },
    [onChange, salesforceField]
  )

  const hasOptions = useMemo(() => {
    return (selected && selected.picklistValues && selected.picklistValues.length > 0) || selected?.type === 'boolean'
  }, [selected])

  const inputType = useMemo(() => {
    if (selected?.type === 'number' || selected?.type === 'double' || selected?.type === 'currency') {
      return 'number'
    }

    if (selected?.type === 'date') {
      return 'date'
    }

    if (selected?.type === 'datetime') {
      return 'datetime-local'
    }

    if (selected?.type === 'boolean') {
      return 'text'
    }

    if (selected?.type === 'url') {
      return 'url'
    }

    if (hasOptions) {
      return 'text'
    }

    return 'text'
  }, [selected, hasOptions])

  const onSalesforceChange = useCallback(
    (salesforce: SalesforceField | null) => {
      setSelected(salesforce)
      onChange(koalaField, salesforce?.name)
    },
    [onChange, koalaField]
  )

  const pattern = useMemo(() => {
    if (selected?.type === 'boolean') {
      return 'true|false'
    }

    if (hasOptions) {
      return selected?.picklistValues.map((o) => o.value).join('|')
    } else {
      return '.*'
    }
  }, [selected, hasOptions])

  const [hardcodedValue, setHardcodedValue] = useState<string>(props.value ?? props.koalaField ?? '')

  return (
    <Flex w="100%">
      {selected && (
        <>
          {selectedKoala && props.mode === 'mapped' && (
            <input type="hidden" name={`${props.namespace}[fields][][koala]`} value={selectedKoala.key} />
          )}
          {props.mode === 'hardcoded' && hardcodedValue && (
            <input type="hidden" name={`${props.namespace}[fields][][koala]`} value={hardcodedValue} />
          )}
          <input type="hidden" name={`${props.namespace}[fields][][salesforce]`} value={selected.name} />
          <input type="hidden" name={`${props.namespace}[fields][][id]`} value={props.id} />
          <input type="hidden" name={`${props.namespace}[fields][][mode]`} value={props.mode} />
        </>
      )}

      <HStack w="100%">
        {props.mode === 'hardcoded' && (
          <InputGroup>
            <InputLeftAddon>
              <Icon as={IconEdit} />
            </InputLeftAddon>
            <Input
              w="calc(100% - 32px)"
              name={`${props.namespace}[fields][][value]`}
              placeholder={`Enter a hardcoded ${selected?.type === 'number' ? 'number' : 'value'}`}
              borderLeft="none"
              value={hardcodedValue}
              onChange={(e) => setHardcodedValue(e.target.value)}
              fontSize={'sm'}
              required
              list={`options-${selected?.name}`}
              pattern={pattern}
              type={inputType}
            />
            {hasOptions && (
              <datalist id={`options-${selected?.name}`}>
                {selected?.picklistValues?.map((o) => (
                  <option key={o.value} value={o.value} />
                ))}
                {selected?.type === 'boolean' && (
                  <>
                    <option key="true">true</option>
                    <option key="false">false</option>
                  </>
                )}
              </datalist>
            )}
          </InputGroup>
        )}

        {(props.mode === 'mapped' || !props.mode) && (
          <ActionField
            schema={props.actionSchema}
            selectedItem={selectedKoala}
            type={props.type ?? 'account'}
            onChange={(item) => {
              const selected = props.koalaOptions.find((o) => o.key === item.key) ?? null
              onKoalaChange(selected)
            }}
          />
        )}

        <Icon as={IconArrowRight} color="gray.400" boxSize={4} />

        <ComboboxWithSearch
          items={props.layout}
          selectedItem={selected}
          onChange={onSalesforceChange}
          filterItem={(a, val) => a.label.toLowerCase().includes(val)}
          itemToString={(item) => item?.label ?? ''}
          itemRenderer={props.isLoadingDeps ? LoadingFieldPreview : FieldPreview}
          selectButtonRenderer={props.isLoadingDeps ? LoadingFieldPreview : FieldPreview}
        />
      </HStack>
    </Flex>
  )
}

function toNestedObject(array: Array<{ key: string; humanLabel: string }>) {
  const result = {}

  array.forEach((item) => {
    const path = item.key
    set(result, path, item.humanLabel)
  })

  return result
}

export interface SalesforceFieldMapping {
  id?: string
  koala?: string
  salesforce?: string
  mode?: 'mapped' | 'hardcoded'
  value?: string
}

export function SalesforceFieldMapper(props: FieldMapperProps) {
  const variables = useNotificationVariables()

  const sobject = useMemo(
    () => props.deps.sobjects.find((s) => s.name.toLowerCase() === props.type.toLowerCase()),
    [props.type, props.deps.sobjects]
  )

  const { data } = useAppDep<'object_fields', SalesforceField[]>('Salesforce', 'object_fields', {
    sobject: sobject?.name ?? '',
    writable: true
  })

  const layout = useMemo(() => {
    if (props.type === 'lead') {
      return props.deps.lead_layout
    } else if (props.type === 'contact') {
      return props.deps.contact_layout
    } else if (props.type === 'account') {
      return props.deps.account_layout
    } else if (sobject) {
      return data?.data?.object_fields ?? []
    } else {
      return []
    }
  }, [props.deps, props.type, sobject, data])

  const [mappings, setMappings] = useState<SalesforceFieldMapping[]>(props.mappings ?? [])

  const shouldShowSuggestions = useMemo(
    () => props.suggestions && props.suggestions.length > 0 && mappings.length === 0,
    [props.suggestions, mappings]
  )

  const options = useMemo(() => {
    const byType = variableFieldOptionsByType(variables)

    if (props.type === 'contact' || props.type === 'lead') {
      return (byType['visitor'] ?? []).concat(byType['signal'] ?? [])
    }

    if (props.type === 'account') {
      return (byType['company'] ?? []).concat(byType['account'] ?? []).concat(byType['signal'] ?? [])
    }

    return Object.values(byType).flat()
  }, [variables, props.type])

  const user = useCurrentUser()
  const debug = useDisclosure()

  return (
    <Stack fontSize={'sm'} spacing="4">
      <Stack spacing="1">
        <Heading size="xs">Field Mappings</Heading>
        <Text color="gray.500">Define how each Koala field maps to a Salesforce field.</Text>
      </Stack>
      <Stack spacing={2.5}>
        {variables.isLoading ? (
          <SkeletonText noOfLines={Math.max(mappings.length, 2)} />
        ) : (
          <>
            {mappings.map((mapping) => {
              return (
                <HStack key={mapping.id}>
                  <PropertyEntry
                    actionSchema={props.actionsSchema}
                    id={mapping.id}
                    namespace={props.namespace}
                    variables={variables}
                    isLoadingDeps={props.loadingDeps}
                    layout={layout}
                    koalaOptions={options}
                    koalaField={mapping.koala}
                    mode={mapping.mode ?? 'mapped'}
                    value={mapping.value}
                    salesforceField={mapping.salesforce}
                    type={props.type}
                    onChange={(koala, salesforce) => {
                      setMappings((prev) =>
                        prev.map((m) => {
                          if (m.id === mapping.id) {
                            return { ...m, koala, salesforce }
                          } else {
                            return m
                          }
                        })
                      )
                    }}
                  />
                  <IconButton
                    aria-label="Remove Field Mapping"
                    size="xs"
                    onClick={() => {
                      setMappings((prev) => prev.filter((m) => m.id !== mapping.id))
                    }}
                    variant="ghost"
                    color="gray.400"
                    _hover={{ color: 'gray.800' }}
                    icon={<IconX size={14} />}
                  />
                </HStack>
              )
            })}
            <Stack
              bg={shouldShowSuggestions ? 'gray.50' : undefined}
              p={shouldShowSuggestions ? '4' : undefined}
              borderWidth={shouldShowSuggestions ? '1px' : undefined}
              borderColor={shouldShowSuggestions ? 'gray.400' : undefined}
              rounded="md"
              paddingTop={2}
            >
              {shouldShowSuggestions && (
                <Stack spacing="0.5" pt="2">
                  <Heading size="xs">No Fields Selected</Heading>
                  <Text color="gray.500">You don't have any fields selected.</Text>
                </Stack>
              )}
              <HStack>
                {shouldShowSuggestions && (
                  <>
                    <Button
                      size="xs"
                      colorScheme={'purple'}
                      px="2"
                      onClick={() => {
                        setMappings(props.suggestions ?? [])
                      }}
                    >
                      Use suggested fields
                    </Button>
                  </>
                )}

                <Flex justifyContent={'flex-end'} gap="2">
                  <Menu>
                    <MenuButton size="xs" variant={'outline'} as={Button} rightIcon={<IconChevronDown size="12" />}>
                      Add {shouldShowSuggestions && 'Individual'} Field
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        icon={<Icon as={IconArrowRight} />}
                        onClick={() => {
                          setMappings([...mappings, { id: nanoid(), mode: 'mapped' }])
                        }}
                      >
                        Mapped Field
                      </MenuItem>
                      <MenuItem
                        icon={<Icon as={IconEdit} />}
                        onClick={() => {
                          setMappings([...mappings, { id: nanoid(), mode: 'hardcoded' }])
                        }}
                      >
                        Hardcoded Field
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Tooltip label="Refresh the list of properties from Salesforce" placement="top">
                    <Button
                      variant={'outline'}
                      as={Button}
                      size="xs"
                      leftIcon={<IconRefresh size="12" />}
                      onClick={props.refetchDeps}
                      isLoading={props.loadingDeps}
                    >
                      Refresh Properties
                    </Button>
                  </Tooltip>
                </Flex>
              </HStack>
            </Stack>
          </>
        )}
      </Stack>

      {/* debug panel for admins to inspect all variables + layout */}
      {user.isInternalUser && (
        <Box>
          <Divider />
          <HStack onClick={debug.onToggle} cursor="pointer" paddingY={3}>
            <Icon as={debug.isOpen ? IconChevronDown : IconChevronRight} boxSize={3.5} />
            <Heading size="xs">Debug (Internal Only)</Heading>
          </HStack>
          <Collapse in={debug.isOpen}>
            <Box paddingY={2}>
              <HStack alignItems="flex-start">
                <Box flex="1 1 50%">
                  <Text fontSize="sm">Variables</Text>
                  <JSONTree data={toNestedObject(options)} />
                </Box>
                <Box flex="1 1 50%">
                  <Text fontSize="sm">{props.type} Schema</Text>
                  <JSONTree data={keyBy(layout, 'name')} />
                </Box>
              </HStack>
            </Box>
          </Collapse>
        </Box>
      )}
    </Stack>
  )
}
