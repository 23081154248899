import {
  Box,
  Flex,
  HStack,
  HTMLChakraProps,
  Icon,
  Link,
  Skeleton,
  Table,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainer,
  TagLabel,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import { Icon as TablerIcon, IconMail, IconPlus, IconUser } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React, { MouseEvent, useMemo } from 'react'
import { Account } from '../../../../types/Account'
import { App } from '../../../../types/App'
import { Crm } from '../../../../types/Crm'
import { ProfileRecord } from '../../../../types/Profile'
import { Project } from '../../../../types/Project'
import { FacetParams } from '../../../data/use-facets'
import { UrlFilterParams } from '../../../data/use-url-filters'
import { ColumnInfo, ColumnSelectorDropdown, defaultAccountColumns } from '../../../ui/ColumnSelector'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { useCurrentProject } from '../../../ui/ProjectsContext'
import { TimeAgo } from '../../../ui/TimeAgo'
import { blocked, RedactedAccountCell, useEntitlements } from '../../../ui/useEntitlements'
import { AccountCTA } from '../../accounts/components/AccountCTA'
import { AccountTrends } from '../../accounts/components/AccountTrends'
import { IntentSummary } from '../../accounts/components/Intent/IntentSummary'
import { getItemDisplay } from '../../accounts/facets/categories'
import { accountPath } from '../../accounts/lib/account-path'
import { IntentSignalCell } from '../../profiles/components/profile-feed'
import { HighlightedAccount } from '../types'
import { Breakdown, LetterGrade } from './breakdown'
import { useMedia } from 'react-use'
import { Iconify } from '../../../ui/Iconify'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { useOverflow } from '../../../ui/useOverflow'
import { AllEntitlements } from '../../billing/show'
import { TrendCell } from './trend-cell'
import ms from 'ms'
import { flatGet } from '../../../../lib/flatGet'
import { SortableHeader } from '../../../ui/Table'
import { BubbleTag } from '../../../ui/BubbleTag'

export interface Column {
  id: string
  Title: React.FC<HTMLChakraProps<'th'>>
  Cell: React.FC<{ account: HighlightedAccount } & HTMLChakraProps<'td'>>
}

const defaultColumnKeys = (project?: Project) => {
  if (project?.scoring_enabled) {
    return ['FitScore', 'IntentScore', 'Trend', 'Visitors', 'LastVisit', 'KQL']
  } else {
    return ['IntentScore', 'Trend', 'Visitors', 'LastVisit', 'KQL']
  }
}

export type AccountSortParam =
  | 'overall_grade'
  | 'fit_grade'
  | 'intent_grade'
  | 'intent_score'
  | 'intent_trend'
  | 'page_views'
  | 'focus_time'
  | 'visitors'
  | 'last_seen'
  | 'latest_intent'

function getRangeHeader(range) {
  switch (range) {
    case 'day':
      return '24h'
    case 'week':
      return '7d'
    default:
      return '30d'
  }
}

const rowHover = {
  bg: [undefined, 'gray.50']
}

const columnRenderers = {
  FitScore: {
    Td: (props: CellRendererProps) => {
      const account = props.account
      return (
        <LinkCell href={accountPath(account)} isExternal={props.isExternal}>
          {!!account.fit_grade && (
            <HStack>
              <LetterGrade
                value={account.fit_grade}
                label={account.fit_grade_letter}
                fontSize="md"
                textAlign="center"
              />
              <Breakdown domain={account.domain} />
            </HStack>
          )}
        </LinkCell>
      )
    }
  },

  IntentScore: {
    Td: (props: CellRendererProps) => {
      const account = props.account
      return (
        <LinkCell isExternal={props.isExternal} href={accountPath(account)}>
          <Box flex="none" display="flex" overflow="hidden">
            <IntentSummary accountId={account.id} intent={account.intent} />
          </Box>
        </LinkCell>
      )
    }
  },

  Trend: {
    Td: (props: CellRendererProps) => {
      const account = props.account
      return (
        <LinkCell href={accountPath(account)} isExternal={props.isExternal}>
          <AccountTrends account={account} />
        </LinkCell>
      )
    }
  },

  Visitors: {
    Th: ({ columnId, columnTitle, columnIcon, columnType, intentRange, ...props }: HeaderRendererProps) => {
      return (
        <SortableHeader
          info={`The number of active visitors that are known or anonymous in the past ${intentRange}`}
          {...props}
        >
          Visitors ({getRangeHeader(intentRange)})
        </SortableHeader>
      )
    },
    Td: (props: CellRendererProps) => {
      const account = props.account
      const intentRange = props.intentRange ?? 'week'
      const total = account.visitor_stats?.visitors?.[intentRange] ?? 0
      const known = account.visitor_stats?.identified?.[intentRange] ?? 0
      const anons = Math.max(0, total - known)

      return (
        <LinkCell href={accountPath(account)} isExternal={props.isExternal} role="group">
          {account.visitor_stats && (
            <HStack spacing={4}>
              {known > 0 && (
                <Tooltip
                  label={`${known.toLocaleString()} identified ${pluralize(
                    'visitor',
                    known
                  )} seen in the past ${intentRange}`}
                  hasArrow
                  arrowSize={6}
                >
                  <HStack spacing={1}>
                    <Icon as={IconMail} boxSize={4} color="gray.400" />
                    <Text fontWeight="medium">{known.toLocaleString()}</Text>
                  </HStack>
                </Tooltip>
              )}
              {anons > 0 && (
                <Tooltip
                  label={`${anons.toLocaleString()} anonymous ${pluralize(
                    'visitor',
                    anons
                  )} seen in the past ${intentRange}`}
                  hasArrow
                  arrowSize={6}
                >
                  <HStack spacing={1}>
                    <Icon as={IconUser} boxSize={4} color="gray.400" />
                    <Text>{anons.toLocaleString()}</Text>
                  </HStack>
                </Tooltip>
              )}
              {!known && !anons && <Text color="gray.400">—</Text>}
            </HStack>
          )}
        </LinkCell>
      )
    }
  },

  LastVisit: {
    Td: (props: CellRendererProps) => {
      const account = props.account

      return (
        <LinkCell href={accountPath(account)} isExternal={props.isExternal}>
          <TimeAgo time={account.last_seen_at} fallback="—" />
        </LinkCell>
      )
    }
  },

  KQL: {
    Td: (props: CellRendererProps) => {
      const account = props.account
      const sortedKQLs = account.latest_intent_signals ?? []

      return (
        <LinkCell isExternal={props.isExternal} href={accountPath(account)}>
          <IntentSignalCell feed={sortedKQLs} />
        </LinkCell>
      )
    }
  },

  'company.linkedin_url': {
    Td: (props: CellRendererProps) => {
      const account = props.account
      const linkedinUrl = account.company?.linkedin_url

      return (
        <Td width="1px">
          {linkedinUrl && (
            <RedactedAccountCell showLock={false} element={account} entitlements={props.entitlements}>
              <Link variant="dotted" href={linkedinUrl} isExternal>
                {linkedinUrl.split('/').reverse()[0]}
              </Link>
            </RedactedAccountCell>
          )}
        </Td>
      )
    }
  },
  focus_time_trend: {
    Th: (props: HeaderRendererProps) => {
      return <SortableHeader isNumeric>Session Time ({getRangeHeader(props.intentRange)})</SortableHeader>
    },
    Td: ({ account, intentRange }: CellRendererProps) => {
      return (
        <LinkCell href={accountPath(account)} isNumeric>
          {Boolean(account.focus_time_trend?.[intentRange]?.current?.value) && (
            <TrendCell stats={account.focus_time_trend} format={(val) => (val ? ms(val) : '—')} range={intentRange} />
          )}
        </LinkCell>
      )
    }
  },
  page_views_trend: {
    Th: (props: HeaderRendererProps) => {
      return (
        <SortableHeader columnKey={props.columnKey} onRemoveColumn={props.onRemoveColumn}>
          Page Views ({getRangeHeader(props.intentRange)})
        </SortableHeader>
      )
    },
    Td: ({ account, intentRange }: CellRendererProps) => {
      return (
        <LinkCell href={accountPath(account)} isNumeric>
          {account.page_views_trend && (
            <TrendCell
              stats={account.page_views_trend}
              format={(val) => val?.toLocaleString() || ''}
              range={intentRange}
            />
          )}
        </LinkCell>
      )
    }
  }
}

interface HeaderRendererProps extends TableColumnHeaderProps {
  columnId: string
  columnKey: string
  columnTitle: string
  columnIcon?: TablerIcon | string
  columnType?: string
  intentRange: 'day' | 'week' | 'month'
  sortBy?: string
  currentSort?: string
  onSortChange?: (sortBy: string | undefined) => void
  onRemoveColumn?: (column: string) => void
  onFilterColumn?: (column: string) => void
}

function HeaderRenderer(props: HeaderRendererProps) {
  const ThRenderer = columnRenderers[props.columnId]?.Th
  if (ThRenderer) {
    return <ThRenderer {...props} />
  }

  const { columnId, columnKey, columnTitle, columnIcon, columnType, intentRange, ...rest } = props
  const isNumeric = !!columnType && ['float', 'long', 'number', 'double'].includes(columnType)
  const sortable = rest.sortBy || (!!columnType && !['object', 'nested', 'binary'].includes(columnType))
  const sortBy = sortable ? rest.sortBy || columnKey : undefined

  return (
    <SortableHeader columnKey={columnKey} isNumeric={isNumeric} {...rest} sortBy={sortBy}>
      <Flex gap={1} alignItems="center" isTruncated>
        {columnIcon && <Iconify icon={columnIcon} size={15} flex="none" />}
        <TextEllipsis maxW="100%" tooltip>
          {columnTitle}
        </TextEllipsis>
      </Flex>
    </SortableHeader>
  )
}

interface CellRendererProps {
  columnId: string
  columnKey: string
  columnTitle?: string
  columnIcon?: TablerIcon | string
  columnType?: string
  isExternal?: boolean
  isLoading?: boolean
  intentRange: 'day' | 'week' | 'month'
  facetParams?: FacetParams | UrlFilterParams
  account: HighlightedAccount
  entitlements?: AllEntitlements
}

function CellRenderer(props: CellRendererProps) {
  const { columnKey, columnType, account, isLoading } = props
  const isNumeric = !!columnType && ['float', 'long', 'number', 'double'].includes(columnType)
  const isDate = columnType === 'date'

  if (isLoading) {
    return (
      <LinkCell
        minW="140px"
        maxW="300px"
        href={accountPath(props.account)}
        isExternal={props.isExternal}
        isNumeric={isNumeric}
      >
        <Skeleton height="16px" rounded="base" startColor="gray.50" endColor="gray.200" />
      </LinkCell>
    )
  }

  const CustomRenderer = columnRenderers[props.columnId]?.Td
  if (CustomRenderer) {
    return <CustomRenderer {...props} />
  }

  const value = flatGet(account, columnKey)

  return (
    <LinkCell minW="140px" maxW="300px" href={accountPath(account)} isExternal={props.isExternal} isNumeric={isNumeric}>
      <RedactedAccountCell showLock={false} element={account} entitlements={props.entitlements}>
        {isDate ? (
          <TimeAgo time={value} mode="full" />
        ) : typeof value === 'string' ? (
          value
        ) : Array.isArray(value) ? (
          <Flex flex="1 1 auto" gap={1} mx={-1.5} isTruncated>
            {value
              .filter((v) => ['string', 'boolean', 'number'].includes(typeof v) && v !== '')
              .sort()
              .map((item, index) => (
                <BubbleTag
                  key={JSON.stringify({ item, index })}
                  title={item?.toString()}
                  variant="subtleBorder"
                  value={item}
                >
                  <TagLabel isTruncated minWidth="10px" maxWidth="180px">
                    {item.toString()}
                  </TagLabel>
                </BubbleTag>
              ))}
          </Flex>
        ) : typeof value === 'number' && isNumeric ? (
          value.toLocaleString()
        ) : value === null || value === undefined ? null : (
          JSON.stringify(value)
        )}
      </RedactedAccountCell>
    </LinkCell>
  )
}

interface Props {
  accounts: HighlightedAccount[] | ProfileRecord[]
  range?: 'day' | 'week' | 'month' | 'all' | 'any'
  extraColumns?: Column[]
  columns?: Array<string>
  apps?: App[]
  crm?: Crm
  compact?: boolean
  sticky?: boolean
  onSortChange?: (sort_param: string | null | undefined) => void
  sortingBy?: string
  facetParams?: FacetParams | UrlFilterParams
  onClaim?: (acc: Account) => void
  onRefresh?: () => void
  canAddColumns?: boolean
  loadingColumns?: string[]
  onColumnChange?: (columns: any[]) => void
  onColumnRemove?: (column: string) => void
  onFilterColumn?: (column: string) => void
  useExternalLinks?: boolean
  noGrays?: boolean
  showActions?: boolean
}

function rangeDefault(range) {
  switch (range) {
    case 'day':
    case 'week':
    case 'month':
      return range
    case 'all':
    case 'any':
      return 'month'
    default:
      return 'week'
  }
}

export function AccountList(props: Props) {
  const project = useCurrentProject()
  const entitlements = useEntitlements()

  const apps = props.apps
  const accounts = props.accounts as HighlightedAccount[]
  const intentRange = rangeDefault(props.range)

  const columnsToDisplay = useMemo(() => {
    const cols = props.columns ?? defaultColumnKeys(project)
    return cols.filter((col) => {
      // dont show fit score column unless enabled for their workspace
      if (col === 'FitScore' && entitlements?.icp_scoring === false) {
        return false
      }

      if (col === 'auto_icp_account_score.fit_grade_letter' && entitlements?.icp_scoring === false) {
        return false
      }

      return true
    })
  }, [project, props.columns, entitlements])

  const displayedColumns: ColumnInfo[] = useMemo(() => {
    return columnsToDisplay.map((column) => {
      return (
        defaultAccountColumns.find((c) => c.id === column || c.key === column) ||
        getItemDisplay(column, apps || [], 'account')
      )
    })
  }, [columnsToDisplay, apps])

  const largeEnoughScreen = useMedia('(min-width: 768px) and (min-height: 600px)')
  const stickyColumn = !props.compact && props.sticky !== false && largeEnoughScreen
  const facetMappings = props.facetParams?.facetMappings ?? {}

  const { scrollRef, overflowLeft } = useOverflow()

  return (
    <TableContainer
      ref={scrollRef}
      position="relative"
      className={overflowLeft ? 'scrolled' : undefined}
      fontSize="sm"
      w="100%"
    >
      <Table variant="bordered" size="sm" w="100%" height="1px">
        {!props.compact && (
          <Thead>
            <Tr height="100%">
              <Th className={stickyColumn ? 'sticky-column' : undefined} paddingLeft={[0, 3]} height="38px">
                Company
              </Th>

              {displayedColumns.map((column) => (
                <HeaderRenderer
                  key={column.key + ':header'}
                  columnId={column.id || column.key}
                  columnKey={column.key}
                  columnTitle={column.label}
                  columnIcon={column.icon}
                  columnType={column.type || facetMappings[column.key]?.type}
                  intentRange={intentRange}
                  sortBy={column.sortBy}
                  currentSort={props.sortingBy}
                  onSortChange={props.onSortChange}
                  onRemoveColumn={props.onColumnRemove}
                  onFilterColumn={props.onFilterColumn}
                  px={[2, 3]}
                />
              ))}

              {props.extraColumns?.map((c) => (
                <c.Title key={c.id} />
              ))}

              {props.canAddColumns && (
                <Th color="gray.500" _hover={{ bg: 'gray.50', color: 'gray.600' }}>
                  <ColumnSelectorDropdown
                    audienceKind="account"
                    apps={apps}
                    selectedColumns={props.columns}
                    onChange={props.onColumnChange}
                  >
                    <Flex
                      as="button"
                      type="button"
                      alignItems="center"
                      width="100%"
                      height="100%"
                      gap={1}
                      fontSize="13px"
                      fontWeight="medium"
                    >
                      <Icon as={IconPlus} boxSize={4} />
                      Add column
                    </Flex>
                  </ColumnSelectorDropdown>
                </Th>
              )}

              {props.showActions !== false && <Th />}
            </Tr>
          </Thead>
        )}
        <Tbody bg="white">
          {accounts.map((account) => {
            return (
              <Tr
                {...blocked(props.noGrays ? undefined : entitlements, account)}
                key={account.domain}
                role="group"
                _hover={rowHover}
              >
                {account.company ? (
                  <LinkCell
                    href={accountPath(account)}
                    minW="290px"
                    maxW="290px"
                    isExternal={props.useExternalLinks}
                    paddingLeft={[0, 3]}
                    className={stickyColumn ? 'sticky-column' : undefined}
                  >
                    <RedactedAccountCell
                      element={account}
                      entitlements={props.noGrays ? undefined : entitlements}
                      flexProps={{
                        gap: 2
                      }}
                    >
                      <HStack spacing={2}>
                        <CompanyAvatar size="xs" name={account.company.name} domain={account.company.domain} />

                        <Text fontSize="15px" minWidth="100px" lineHeight="1.2" fontWeight="semibold" isTruncated>
                          {account.company.name || account.domain}
                        </Text>
                      </HStack>
                    </RedactedAccountCell>
                  </LinkCell>
                ) : (
                  <Td
                    position={stickyColumn ? 'sticky' : undefined}
                    left={0}
                    zIndex={1}
                    bg="white"
                    paddingLeft={[0, 3]}
                  ></Td>
                )}

                {displayedColumns.map((column) => {
                  return (
                    <CellRenderer
                      key={column.key + ':' + account.id}
                      columnId={column.id || column.key}
                      columnKey={column.key}
                      columnTitle={column.label}
                      columnIcon={column.icon}
                      columnType={column.type || facetMappings[column.key]?.type}
                      isLoading={props.loadingColumns?.includes(column.key)}
                      account={account}
                      facetParams={props.facetParams}
                      intentRange={intentRange}
                      entitlements={props.noGrays ? undefined : entitlements}
                      isExternal={props.useExternalLinks}
                    />
                  )
                })}

                {props.extraColumns?.map((c) => {
                  return <c.Cell key={c.id + account.id} account={account} />
                })}

                {props.canAddColumns && (
                  <Td>
                    <Box minW="200px" />
                  </Td>
                )}

                {props.showActions !== false && (
                  <Td width="1px" height="1px">
                    <Flex justifyContent="flex-end">
                      {account.domain && (
                        <AccountCTA
                          domain={account.domain}
                          onClaim={props.onClaim}
                          onRefresh={() => {
                            props.onRefresh?.()
                          }}
                        />
                      )}
                    </Flex>
                  </Td>
                )}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

interface LinkCellProps extends TableCellProps {
  href: string
  isExternal?: boolean
}

function LinkCell({
  children,
  href,
  isExternal,
  paddingLeft,
  isNumeric,
  ...props
}: React.PropsWithChildren<LinkCellProps>) {
  const ignoreChildLinks = React.useCallback((event: MouseEvent<HTMLElement>) => {
    if (event.target === event.currentTarget) {
      return true
    }

    const targetAnchor = (event.target as HTMLElement).closest('a')
    if (targetAnchor !== event.currentTarget && event.currentTarget.contains(targetAnchor)) {
      event.preventDefault()
    }

    if (event.defaultPrevented) {
      return false
    }
  }, [])

  return (
    <Td height="100%" width="1px" minW="140px" maxW="300px" padding={0} isNumeric={isNumeric} {...props}>
      <Box
        as="a"
        target={isExternal ? '_blank' : undefined}
        href={href}
        cursor="pointer"
        display="flex"
        alignItems="center"
        width="100%"
        height="100%"
        textDecoration="none"
        onClick={ignoreChildLinks}
      >
        <Box width="100%" px={[2, 3]} py={2} paddingLeft={paddingLeft} isTruncated>
          {children}
        </Box>
      </Box>
    </Td>
  )
}
