import { Box, Button, Heading, HStack, Spinner, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { IconSearch, IconSettings, IconSparkles } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { usePersonaProspects } from '../../data/use-prospects'
import { TableFooter } from '../../ui/TableFooter'
import { PersonaTabsProps } from '../profiles/components/AutoProspectCard'
import { Persona, PersonaBuilderModal } from './personas'
import { ProspectsTable } from './prospects-table'

export function FilterPersonaTab(props: PersonaTabsProps) {
  const [seed, setSeed] = useState(1)
  const [page, setPage] = useState(1)

  const personaQuery = usePersonaProspects(props.domain, props.persona.id!, { page, seed })
  const [localProspects, setLocalProspects] = useState(personaQuery.data?.prospects ?? [])
  const [pageMeta, setPageMeta] = useState(personaQuery.data?.page_meta)

  const [localPersona, setLocalPersona] = useState<Persona>(props.persona)
  const disc = useDisclosure()

  useEffect(() => {
    if (personaQuery.data && !personaQuery.isLoading && !personaQuery.isFetching) {
      setLocalProspects(personaQuery.data?.prospects ?? [])
      setPageMeta(personaQuery.data?.page_meta)
    }
  }, [personaQuery.data, personaQuery.isLoading, personaQuery.isFetching])

  return (
    <Stack>
      {personaQuery.isLoading && page === 1 && (
        <HStack p="4" w="100%" bg="gray.50" px="6">
          <Spinner size={'sm'} />
          <Text>
            Searching for prospects who match your <b>{localPersona.name}</b> persona
          </Text>
        </HStack>
      )}

      {localProspects.length > 0 && (
        <Box position={'relative'}>
          {personaQuery.isLoading && page > 1 && (
            <Box position={'absolute'} right="2" top="0">
              <Spinner size={'sm'} />
            </Box>
          )}
          <ProspectsTable
            persona={localPersona}
            account={props.account}
            context="account_page"
            apps={props.apps}
            prospects={localProspects.map((p) => {
              const highlight = personaQuery.data?.highlights?.[p.external_id]?.['job_title.analyzed']?.[0]

              if (highlight && typeof highlight === 'string') {
                const textHiglight = (
                  <Text
                    style={{ whiteSpace: 'nowrap' }}
                    sx={{
                      em: {
                        color: 'purple.500',
                        fontWeight: 'semibold'
                      },
                      textTransform: 'capitalize'
                    }}
                    dangerouslySetInnerHTML={{
                      __html: highlight
                    }}
                  />
                )

                p.highlight = {
                  title_keywords: highlight.replace(/<em>/g, '').replace(/<\/em>/g, '')
                }

                if (localPersona.filters?.job_title_levels?.values?.length) {
                  p.highlight.job_title_levels = p.seniority
                }
                if (localPersona.filters?.job_title_role?.values?.length) {
                  p.highlight.job_title_roles = p.departments.join(', ')
                }
                if (localPersona.filters?.location_country?.values?.length) {
                  p.highlight.location = p.country
                }
                p.reason = textHiglight
              }

              return p
            })}
            isLoading={false}
            domain={props.domain}
          />
          {pageMeta && (
            <TableFooter
              isLoading={personaQuery.isLoading || personaQuery.isFetching}
              page={page}
              scrollToTop={false}
              setPage={(page) => {
                setPage(page)
              }}
              pageMeta={pageMeta}
            />
          )}
        </Box>
      )}

      {personaQuery.data?.prospects?.length === 0 && !personaQuery.isLoading && (
        <Stack p="4" w="100%" spacing="2">
          <Heading size="xs">No prospects found</Heading>
          <Stack spacing="0.5">
            <Text fontSize={'sm'} color="gray.500">
              We couldn't find anyone who matches your <b>{localPersona.name}</b> persona. You may want to consider
              broadening your persona definition, or refining your question in the AI Chat.
            </Text>
          </Stack>
        </Stack>
      )}

      <HStack justifyContent={'flex-end'} w="100%" alignItems={'center'}>
        <Button size="xs" leftIcon={<IconSettings size="12" />} iconSpacing={1} onClick={disc.onToggle} variant="ghost">
          Customize
        </Button>

        <Button
          size="xs"
          colorScheme={'purple'}
          leftIcon={<IconSparkles size="12" />}
          iconSpacing={1}
          onClick={() => props.onProspect(props.persona)}
          variant="ghost"
        >
          Prospect with AI
        </Button>

        <Button
          size="xs"
          leftIcon={<IconSearch size="12" />}
          iconSpacing={1}
          onClick={() => props.onSearch(props.persona)}
          variant="ghost"
        >
          Explore
        </Button>
      </HStack>

      <PersonaBuilderModal
        persona={localPersona}
        isOpen={disc.isOpen}
        onClose={disc.onClose}
        onSave={(updated) => {
          props.onChange(updated)
          setSeed((s) => s + 1)
          setLocalPersona(updated)
        }}
      />
    </Stack>
  )
}
