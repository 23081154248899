import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { IconExclamationMark } from '@tabler/icons-react'
import ms from 'ms'
import React from 'react'
import { ReferralBanner } from '../pages/billing/components/referral-program'
import CircleIcon from './CircleIcon'
import { usePermission } from './PermissionsContext'
import { projectPath, useCurrentProject } from './ProjectsContext'
import createPersistedState from './usePersistedState'
import { TrialMessage } from './useTrial'

export default function BillingBanners() {
  const project = useCurrentProject()

  if (!project) {
    return null
  }

  return (
    <Box>
      {project && <ReferralBanner />}
      {project && <SubscriptionPastDueBanner />}
      {project && project.trialing && project.trial_ends_at && (
        <TrialMessage
          trial={{
            project_id: project.id,
            trial_end_at: project.trial_ends_at
          }}
        />
      )}
    </Box>
  )
}

const useDismissedModal = createPersistedState<boolean>('unpaid-modal-dismissed', { expiresIn: ms('1 day') })

export function SubscriptionPastDueBanner() {
  const project = useCurrentProject()
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })

  const [dismissed, setDismissed] = useDismissedModal(false)

  if (window.location.pathname.match('settings/(billing|plans)$')) {
    return null
  }

  if (!project?.koala_subscription?.past_due) {
    return null
  }

  if (dismissed || !canEditProject) {
    return (
      <Box fontSize="sm" bg="red.100" color="red.900" py="3">
        <HStack
          alignItems="flex-start"
          spacing={3}
          maxW="min(100%, var(--max-container-width))"
          px={[4, 4, 6, 8]}
          mx="auto"
        >
          <Circle flex="none" bg="red.600" color="red.100" p="0.5" mt={1.5}>
            <IconExclamationMark size={14} />
          </Circle>
          <Flex flex="1 1 auto" gap={2} alignItems="center" flexWrap="wrap">
            <Box display="inline" pr={4} py={1}>
              <Text display="inline" fontWeight="semibold">
                Your subscription is past due.
              </Text>{' '}
              <Text display="inline">If left unpaid, your workspace will be downgraded to a Free plan.</Text>{' '}
              {!canEditProject && (
                <Text display="inline">Ask your workspace admin to update your billing information.</Text>
              )}
            </Box>
            {canEditProject && (
              <Button
                size="sm"
                colorScheme="red"
                as={Link}
                flex="none"
                href={projectPath('/settings/billing')}
                isExternal
              >
                Update your billing info
              </Button>
            )}
          </Flex>
        </HStack>
      </Box>
    )
  }

  return (
    <Modal
      size="md"
      isOpen={!dismissed}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onClose={() => setDismissed(true)}
      blockScrollOnMount
      preserveScrollBarGap
      isCentered
    >
      <ModalOverlay backdropFilter="blur(4px) grayscale(0.8)" />
      <ModalContent rounded="2xl">
        <ModalBody pt={10} pb={4} px={8}>
          <Stack spacing={4} alignItems="center">
            <CircleIcon flex="none" icon={IconExclamationMark} color="red.600" bg="red.100" iconSize={8} />
            <Heading size="md" fontWeight="semibold">
              Your subscription is past due
            </Heading>
            <Text textAlign="center" fontSize="sm" color="gray.600">
              Update your billing information to continue using Koala without disruption.
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter gap={3} flexWrap="wrap" p={6}>
          <Button
            as={Link}
            size="lg"
            rounded="lg"
            fontSize="15px"
            width="full"
            variant="solid"
            colorScheme="purple"
            href={projectPath('/settings/billing')}
          >
            Update billing information
          </Button>
          <Button size="lg" rounded="lg" fontSize="15px" width="full" onClick={() => setDismissed(true)}>
            Dismiss until tomorrow
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
