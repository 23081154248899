import { Box, Button, Circle, Flex, ScaleFade } from '@chakra-ui/react'
import { IconArrowBarToUp, IconArrowDown, IconArrowUp, IconRadar } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { uniqBy } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useMountedState, useWindowScroll } from 'react-use'
import { ProfilesProps } from '..'
import { concurrentGET } from '../../../../lib/api'
import { pluralize } from '../../../../lib/pluralize'
import EmptyState from '../../../ui/EmptyState'
import useLatestRef from '../../../ui/useLatestRef'
import useLocation from '../../../ui/useLocation'
import { mergeParams } from '../../icps/types'
import { HighlightedProfile, ProfileList, ProfileListProps } from './profile-list'

export const isOnline = (profile: HighlightedProfile, minutes = 10) => {
  const lastSeen = dayjs(profile.last_seen_at)
  return lastSeen.isAfter(dayjs().subtract(minutes, 'minutes'))
}

interface ProfileLiveListProps extends ProfileListProps {
  total_count: number
  paused?: boolean
  onChangeMode?: (mode: 'playing' | 'paused') => void
}

export function ProfileLiveList({ paused, onChangeMode, ...props }: ProfileLiveListProps) {
  const mounted = useMountedState()
  const [profiles, setProfiles] = React.useState<HighlightedProfile[]>(props.profiles)
  const [buffered, setBuffered] = React.useState<HighlightedProfile[]>([])
  const perPage = 25

  const maxRows = useMemo(() => (paused ? 100 : 25), [paused])

  const [loadingMore, setLoadingMore] = React.useState(false)
  const [total, setTotal] = React.useState<number>(props.total_count)

  const loc = useLocation()
  const fetchingRef = React.useRef(false)

  const refetch = React.useCallback(async () => {
    if (fetchingRef.current) {
      return
    }

    fetchingRef.current = true

    const loc = window.location
    const search = loc.search
    const location = loc.toString()

    return concurrentGET<ProfilesProps>(
      mergeParams(location, {
        page_size: perPage.toString()
      })
    ).then((res) => {
      if (!mounted()) {
        return
      }

      fetchingRef.current = false

      // Skip updates if the search has changed while waiting for this response
      if (search !== window.location.search) {
        return
      }

      if (!res || !res.profiles) {
        return
      }

      setTotal(res.total_count ?? res.page_meta?.total_count)

      const newProfiles = res.profiles.filter((profile) => {
        return !profiles.some((p) => p.id === profile.id)
      })

      const oldProfiles = res.profiles.filter((profile) => {
        return profiles.some((p) => p.id === profile.id)
      })

      // update existing profiles in place
      setProfiles((profiles) => {
        return profiles
          .map((profile) => {
            const existing = oldProfiles.find((p) => p.id === profile.id)

            if (existing) {
              return existing
            } else {
              return profile
            }
          })
          .slice(0, maxRows)
      })

      // add new profiles to the front of the buffer, to respect new ordering
      setBuffered((buff) => uniqBy([...newProfiles, ...buff], 'id'))
    })
  }, [mounted, profiles, maxRows])

  const refetchRef = useLatestRef(refetch)

  useEffect(() => {
    let timeout = setTimeout(async function ref() {
      await refetchRef.current()
      timeout = setTimeout(ref, 10_000)
    }, 10_000)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc])

  // reset all state when new profiles are passed in
  useEffect(() => {
    setProfiles(props.profiles)
    setBuffered([])
    setLoadingMore(false)
    setTotal(props.total_count)
  }, [props.profiles, props.total_count])

  const online = useMemo(() => uniqBy(profiles, 'id').slice(0, maxRows), [profiles, maxRows])

  const loadMore = React.useCallback(async () => {
    setLoadingMore(true)
    onChangeMode?.('paused')

    return concurrentGET<ProfilesProps>(
      mergeParams(location.toString(), {
        per_page: perPage.toString(),
        offset: (online.length + buffered.length).toString()
      })
    ).then((res) => {
      if (mounted()) {
        setProfiles((profiles) => uniqBy([...profiles, ...res.profiles], 'id'))
        setLoadingMore(false)
      }
    })
  }, [mounted, onChangeMode, online, buffered])

  const hasMore = useMemo(() => online.length + buffered.length < total, [online, buffered, total])
  useEffect(() => {
    if (!paused && buffered.length > 0) {
      setProfiles((profiles) => uniqBy([...buffered, ...profiles], 'id').slice(0, maxRows))
      setBuffered([])
    }
  }, [paused, buffered, maxRows])

  const scrollToTop = React.useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])

  const showBuffered = React.useCallback(() => {
    scrollToTop()

    setTimeout(() => {
      if (mounted()) {
        setProfiles((profiles) => uniqBy([...buffered, ...profiles], 'id').slice(0, maxRows))
        setBuffered([])
      }
    })
  }, [mounted, buffered, maxRows, scrollToTop])

  const { y: scrollY } = useWindowScroll()

  return (
    <Box position="relative">
      {buffered.length > 0 && (
        <Flex
          justifyContent={'center'}
          w="100%"
          position={scrollY > 0 ? 'sticky' : 'absolute'}
          top={scrollY > 0 ? '72px' : undefined}
          zIndex={1}
          bg="linear-gradient(#ffffffa6, white);"
          h="60px"
          transition="opacity 150ms cubic-bezier(0.4, 0, 0.2, 1)"
        >
          <Button
            rounded="full"
            size="sm"
            colorScheme={'blue'}
            fontSize={'xs'}
            variant={'solid'}
            leftIcon={<IconArrowUp size={15} />}
            iconSpacing={1}
            onClick={showBuffered}
          >
            {pluralize(buffered.length, 'update', 'updates')}
          </Button>
        </Flex>
      )}

      {online.length === 0 && (
        <EmptyState
          size="md"
          heading="Waiting for new sessions..."
          description="You can try changing your filters if you wanna see a broader stream of sessions."
          icon={IconRadar}
        />
      )}

      {online.length > 0 && (
        <ProfileList
          profiles={online as unknown as HighlightedProfile[]}
          range={'day'}
          crm={props.crm}
          isLive
          columns={['Company', 'FitScore', 'Online', 'SessionTime', 'Activity']}
        />
      )}
      {hasMore && online.length > 0 && (
        <Flex justifyContent={'center'} pt="8">
          <Button
            isLoading={loadingMore}
            onClick={() => loadMore()}
            size="sm"
            rounded="full"
            fontSize={'xs'}
            leftIcon={<IconArrowDown size={14} />}
            iconSpacing={1}
          >
            Load more
          </Button>
        </Flex>
      )}

      <Box position="fixed" bottom={10} right={10}>
        <ScaleFade initialScale={0.9} in={scrollY > 150}>
          <Circle
            padding={3}
            bg="gray.500"
            cursor="pointer"
            opacity={0.5}
            _hover={{ opacity: 0.8 }}
            transition="opacity 150ms cubic-bezier(0.4, 0, 0.2, 1)"
            onClick={scrollToTop}
          >
            <IconArrowBarToUp size={24} color="white" />
          </Circle>
        </ScaleFade>
      </Box>
    </Box>
  )
}
