import { Heading, Link, Stack, HStack } from '@chakra-ui/react'
import React from 'react'
import { Breadcrumb, BreadcrumbPath } from '../../ui/Breadcrumb'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'

interface Props {}

export function AdminBreadcrumb(props: { paths: BreadcrumbPath[] }) {
  return <Breadcrumb paths={[{ path: '/admin', title: 'Admin' }].concat(props.paths)} />
}

export default function AdminDashboard(_props: Props) {
  return (
    <PageLayout>
      <PageTitle>Admin Dashboard</PageTitle>

      <HStack alignItems="top" spacing="40px">
        <Stack>
          <Heading size="xs" fontWeight="medium">
            Company Data
          </Heading>
          <Stack spacing={0.5} fontSize="sm" alignItems="flex-start">
            <Link color="purple.500" href="/admin/kdb">
              Companies
            </Link>
            <Link color="purple.500" href="/admin/suggestions">
              Suggested Edits
            </Link>
            <Link color="purple.500" href="/admin/data-tasks">
              Data Tasks
            </Link>
          </Stack>
        </Stack>

        <Stack>
          <Heading size="xs" fontWeight="medium">
            Reports
          </Heading>
          <Stack spacing={0.5} fontSize="sm" alignItems="flex-start">
            <Link color="purple.500" href="/admin/reports/event-volume">
              Event Volume
            </Link>
            <Link color="purple.500" href="/admin/syncs">
              Integration Syncs
            </Link>
            <Link color="purple.500" href="/admin/reports/emails">
              Emails
            </Link>
            <Link color="purple.500" href="/admin/reports/activities">
              Activities
            </Link>
            <Link color="purple.500" href="/admin/ip_geo">
              IP Lookup
            </Link>
            <Link color="purple.500" href="/admin/intent-playground">
              Intent Playground
            </Link>
            <Link color="purple.500" href="/admin/reports/integrations">
              Integrations
            </Link>
          </Stack>
        </Stack>

        <Stack>
          <Heading size="xs" fontWeight="medium">
            Billing
          </Heading>
          <Stack spacing={0.5} fontSize="sm" alignItems="flex-start">
            <Link color="purple.500" href="/admin/subscriptions">
              Subscriptions
            </Link>
          </Stack>
        </Stack>

        <Stack>
          <Heading size="xs" fontWeight="medium">
            GDPR
          </Heading>
          <Stack spacing={0.5} fontSize="sm" alignItems="flex-start">
            <Link color="purple.500" href="/admin/subject-data-deletion-request">
              Deletion Requests
            </Link>
          </Stack>
        </Stack>

        <Stack>
          <Heading size="xs" fontWeight="medium">
            Sensitive operations
          </Heading>
          <Stack spacing={0.5} fontSize="sm" alignItems="flex-start">
            <Link color="purple.500" href="/admin/operations-firewall">
              Operations Firewall
            </Link>
          </Stack>
          <Stack spacing={0.5} fontSize="sm" alignItems="flex-start">
            <Link color="purple.500" href="/admin/prompts-db">
              Prompts DB
            </Link>
          </Stack>
        </Stack>

        <Stack>
          <Heading size="xs" fontWeight="medium">
            Playground
          </Heading>
          <Stack spacing={0.5} fontSize="sm" alignItems="flex-start">
            <Link color="purple.500" href="/admin/account-summary-playground">
              Account Summarization
            </Link>
          </Stack>
        </Stack>
      </HStack>
    </PageLayout>
  )
}
