import {
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  TableColumnHeaderProps,
  Text,
  Th
} from '@chakra-ui/react'
import { IconArrowDown, IconArrowUp, IconCircleCheckFilled, IconFilter, IconSelector, IconX } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { HelpTooltip } from './HelpTooltip'

interface SortableHeaderProps extends TableColumnHeaderProps {
  info?: string
  sortBy?: string
  currentSort?: string
  columnKey?: string
  onSortChange?: (sortBy: string | undefined) => void
  onRemoveColumn?: (column: string) => void
  onFilterColumn?: (column: string) => void
}

export function SortableHeader({
  info,
  sortBy,
  columnKey,
  currentSort,
  onSortChange,
  onRemoveColumn,
  onFilterColumn,
  children,
  ...props
}: SortableHeaderProps) {
  const sortable = sortBy && typeof onSortChange === 'function'
  const removable = columnKey && typeof onRemoveColumn === 'function'
  const filterable = columnKey && typeof onFilterColumn === 'function'
  const [sortingBy, sortDirection] = currentSort?.split(':') ?? []
  const isSorted = sortingBy && sortingBy === sortBy
  const SortIcon = isSorted ? (sortDirection === 'asc' ? IconArrowUp : IconArrowDown) : IconSelector

  const onSortAsc = useCallback(() => {
    if (isSorted && sortDirection === 'asc') {
      onSortChange?.(undefined)
    } else {
      onSortChange?.(`${sortBy}:asc`)
    }
  }, [sortBy, isSorted, sortDirection, onSortChange])

  const onSortDesc = useCallback(() => {
    if (isSorted && sortDirection === 'desc') {
      onSortChange?.(undefined)
    } else {
      onSortChange?.(`${sortBy}:desc`)
    }
  }, [sortBy, isSorted, sortDirection, onSortChange])

  if (sortable || removable) {
    return (
      <Th {...props} _hover={{ bg: 'gray.50', '& .hover-tooltip': { opacity: 0.6 } }} cursor="pointer" px={0}>
        <Menu isLazy lazyBehavior="keepMounted" offset={[4, 6]} autoSelect={false}>
          <MenuButton type="button" width="full" height="100%" px={3} fontWeight="inherit">
            <Flex width="100%" height="100%" alignItems="center" justifyContent="space-between" gap={1.5}>
              <Flex alignItems="center" gap={1}>
                <Text as="span" lineHeight={1.2} color={isSorted ? 'gray.800' : 'inherit'}>
                  {children}
                </Text>
                {info && (
                  <HelpTooltip
                    className="hover-tooltip"
                    opacity={0}
                    transition="opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
                    variant="info"
                  >
                    {info}
                  </HelpTooltip>
                )}
              </Flex>
              {sortable && (
                <Icon
                  as={SortIcon}
                  boxSize={3.5}
                  color={isSorted ? 'color.800' : 'gray.500'}
                  transition="color 200ms cubic-bezier(0.4, 0, 0.2, 1)"
                />
              )}
            </Flex>
          </MenuButton>
          <Portal>
            <MenuList>
              {sortable && (
                <>
                  <MenuItem icon={<IconArrowUp size={16} />} iconSpacing={1.5} onClick={onSortAsc}>
                    <Flex alignItems="center" gap={2} justifyContent="space-between">
                      Sort ascending
                      {isSorted && sortDirection === 'asc' && (
                        <Icon as={IconCircleCheckFilled} boxSize={4} color="purple.500" />
                      )}
                    </Flex>
                  </MenuItem>
                  <MenuItem icon={<IconArrowDown size={16} />} iconSpacing={1.5} onClick={onSortDesc}>
                    <Flex alignItems="center" gap={2} justifyContent="space-between">
                      Sort descending
                      {isSorted && sortDirection === 'desc' && (
                        <Icon as={IconCircleCheckFilled} boxSize={4} color="purple.500" />
                      )}
                    </Flex>
                  </MenuItem>
                </>
              )}

              {filterable && sortable && <MenuDivider />}

              {filterable && (
                <MenuItem icon={<IconFilter size={16} />} iconSpacing={1.5} onClick={() => onFilterColumn(columnKey)}>
                  Filter column
                </MenuItem>
              )}

              {removable && (sortable || filterable) && <MenuDivider />}

              {removable && (
                <MenuItem icon={<IconX size={14} />} iconSpacing={1.5} onClick={() => onRemoveColumn(columnKey)}>
                  Remove column
                </MenuItem>
              )}
            </MenuList>
          </Portal>
        </Menu>
      </Th>
    )
  }

  return <Th {...props}>{children}</Th>
}
