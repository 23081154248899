import {
  Box,
  Divider,
  Flex,
  FlexProps,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  Text,
  usePopper,
  UsePopperProps
} from '@chakra-ui/react'
import { IconChevronDown, IconX } from '@tabler/icons-react'
import { useCombobox, UseComboboxActions } from 'downshift'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { VirtualizerOptions } from '@tanstack/react-virtual'
import { SearchIcon } from './icons/SearchIcon'
import { VirtualList } from './VirtualList'

export type ComboboxWithSearchProps<T = any> =
  | {
      items: T[]
      selectedItem: T | null
      placeholder?: string
      hideSearchBar?: boolean
      footer?: React.ReactNode | ((combobox: UseComboboxActions<T>) => React.ReactNode)
      isLoading?: boolean
      isReadOnly?: boolean
      showClearButton?: boolean
      onChange: (selectedItem: T | null) => void
      onInputValueChange?: (value: string | undefined) => void
      filterItem?: (item: T, search: string) => boolean
      itemToString?: (item: T | null) => string
      itemRenderer?: (item: {
        item: T
        isHighlighted?: boolean
        selectedItem?: T | null
        isSelected: boolean
      }) => React.ReactNode
      triggerProps?: FlexProps
      popoverProps?: FlexProps
      popperOptions?: UsePopperProps
      selectButtonRenderer?: (item: {
        item: T | null
        isSelected: boolean
        isToggleButton: boolean
        isLoading?: boolean
      }) => React.ReactNode
    } & (
      | {
          virtual: true
          estimateSize: VirtualizerOptions<any, any>['estimateSize']
        }
      | {
          virtual?: false
          estimateSize?: never
        }
    )

export function ComboboxWithSearch<T = any>({
  items,
  selectedItem,
  hideSearchBar,
  isLoading,
  isReadOnly,
  placeholder,
  footer,
  onChange,
  onInputValueChange,
  filterItem = (item, val) => itemToString(item).toLowerCase().includes(val),
  itemToString = (item) => String(item),
  itemRenderer = ({ item }) => <Text fontSize="sm">{itemToString(item)}</Text>,
  selectButtonRenderer = ({ item }) => (
    <Text fontSize="sm">{item ? itemToString(item) : placeholder || 'Select something'}</Text>
  ),
  virtual,
  triggerProps,
  popperOptions,
  popoverProps,
  estimateSize,
  showClearButton
}: ComboboxWithSearchProps<T>) {
  const { popperRef, referenceRef } = usePopper({
    matchWidth: true,
    strategy: 'fixed',
    offset: [0, 5],
    ...popperOptions
  })
  const inputRef = useRef<HTMLInputElement>(null)
  const [displayItems, setDisplayItems] = useState(items)

  useEffect(() => {
    setDisplayItems(items)
  }, [items])

  const combobox = useCombobox({
    items: displayItems,
    selectedItem,
    itemToString,
    onInputValueChange: ({ inputValue }) => {
      if (typeof onInputValueChange === 'function') {
        onInputValueChange(inputValue)
      } else {
        const val = inputValue?.toLowerCase() ?? ''
        setDisplayItems(items.filter((item) => !val || filterItem(item, val)))
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      onChange(selectedItem || null)
    },
    onIsOpenChange: ({ isOpen }) => {
      if (isOpen) {
        inputRef.current?.focus()
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            // dont close the menu, dont set the input value, dont change the selected item on blur!
            isOpen: state.isOpen,
            selectedItem: state.selectedItem,
            inputValue: state.inputValue
          }
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.FunctionOpenMenu:
        case useCombobox.stateChangeTypes.FunctionToggleMenu:
        case useCombobox.stateChangeTypes.ControlledPropUpdatedSelectedItem:
        case useCombobox.stateChangeTypes.ToggleButtonClick:
          return {
            ...changes,
            isOpen: isReadOnly ? false : changes.isOpen,
            inputValue: '' // don't add the item string as input value
          }
        default:
          return changes // otherwise business as usual
      }
    }
  })

  const containerRef = useRef<HTMLDivElement>(null)
  const closeMenu = combobox.closeMenu

  // Handle clicks outside the combobox
  const handleClickOutside = useCallback(
    (event) => {
      const container = containerRef.current

      if (container?.contains(event.target)) {
        return
      } else {
        closeMenu()
      }
    },
    [closeMenu]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <div ref={containerRef} style={{ width: '100%', position: 'relative' }}>
      <Flex
        as="button"
        type="button"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        textAlign="left"
        borderRadius="md"
        border="1px solid"
        borderColor="gray.200"
        bg="white"
        // TODO make consumers who specify renderers be responsible this stuff. (same in the item renderer)
        py={2}
        px={3}
        gap={2}
        role="group"
        cursor={isReadOnly ? 'initial' : 'pointer'}
        _hover={
          isReadOnly
            ? undefined
            : {
                shadow: 'sm'
              }
        }
        {...triggerProps}
        {...combobox.getToggleButtonProps({ ref: referenceRef, tabIndex: 0 })}
      >
        {selectButtonRenderer({ item: combobox.selectedItem, isSelected: true, isToggleButton: true, isLoading })}
        <Flex gap={1} marginLeft="auto">
          {!isReadOnly && showClearButton && combobox.selectedItem && (
            <Icon
              as={IconX}
              flex="none"
              color="gray.500"
              boxSize={4}
              _hover={{ color: 'gray.800' }}
              onClick={(e) => {
                e.stopPropagation()
                combobox.selectItem(null as any)
              }}
            />
          )}
          <Icon
            as={IconChevronDown}
            flex="none"
            color={combobox.isOpen ? 'gray.800' : 'gray.500'}
            boxSize={3.5}
            _groupHover={{ color: isReadOnly ? 'gray.500' : 'gray.800' }}
          />
        </Flex>
      </Flex>
      <Flex
        ref={popperRef}
        display="flex !important"
        direction="column"
        width="100%"
        visibility={combobox.isOpen ? 'visible' : 'hidden'}
        pointerEvents={combobox.isOpen ? 'auto' : 'none'}
        background="white"
        shadow="lg"
        rounded="md"
        borderWidth="1px"
        borderColor="gray.200"
        zIndex="popover"
        {...popoverProps}
      >
        <Box {...(!hideSearchBar && { py: 0.5, pl: 1 })}>
          <InputGroup
            size="sm"
            {...combobox.getComboboxProps()}
            {...(hideSearchBar && {
              visibility: 'hidden',
              position: 'absolute',
              pointerEvents: 'none'
            })}
          >
            <InputLeftElement width="8" pointerEvents="none" color="gray.400">
              <SearchIcon boxSize={4} />
            </InputLeftElement>
            <Input
              {...combobox.getInputProps({ ref: inputRef })}
              size="sm"
              width="100%"
              background="white"
              outline="none"
              border="none"
              roundedBottom={0}
              focusBorderColor="transparent"
              placeholder="Search…"
            />
            {isLoading && (
              <InputRightElement>
                <Spinner color="gray.400" thickness="1.5px" size="sm" />
              </InputRightElement>
            )}
          </InputGroup>
        </Box>
        {!hideSearchBar && <Divider />}
        <List
          {...combobox.getMenuProps()}
          width="100%"
          display="flex"
          flexDir="column"
          alignItems="center"
          fontSize="sm"
          w="100%"
          maxH="300px"
          padding={1}
          overflowY="auto"
          scrollBehavior="smooth"
          overscrollBehavior="contain"
        >
          {combobox.isOpen && virtual && (
            <VirtualList
              items={displayItems}
              estimateSize={estimateSize}
              maxH="300px"
              renderItem={(item, index) => {
                return (
                  <Flex
                    key={index}
                    as="li"
                    width="100%"
                    p={2}
                    background={combobox.highlightedIndex === index ? 'gray.100' : 'transparent'}
                    cursor="pointer"
                    rounded="md"
                    {...combobox.getItemProps({ item, index })}
                  >
                    {itemRenderer({
                      item,
                      isHighlighted: combobox.highlightedIndex === index,
                      selectedItem: combobox.selectedItem,
                      isSelected: combobox.selectedItem === item
                    })}
                  </Flex>
                )
              }}
            />
          )}
          {combobox.isOpen &&
            !virtual &&
            displayItems.map((item, index) => (
              <Flex
                key={index}
                as="li"
                width="100%"
                p={2}
                background={combobox.highlightedIndex === index ? 'gray.100' : 'transparent'}
                cursor="pointer"
                rounded="md"
                {...combobox.getItemProps({ item, index })}
              >
                {itemRenderer({
                  item,
                  isHighlighted: combobox.highlightedIndex === index,
                  selectedItem: combobox.selectedItem,
                  isSelected: combobox.selectedItem === item
                })}
              </Flex>
            ))}
          {combobox.isOpen && displayItems.length === 0 && (
            <ListItem fontStyle="italic" color="gray.500" py={4}>
              No items {combobox.inputValue ? `matching "${combobox.inputValue}"` : 'found'}
            </ListItem>
          )}
        </List>
        {typeof footer === 'function' ? footer(combobox) : footer}
      </Flex>
    </div>
  )
}
