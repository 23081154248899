import { HStack, Link, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { IconBriefcase } from '@tabler/icons-react'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import React from 'react'
import type { Account } from '../../../../../types/Account'
import { DetailsCard } from '../../../../ui/Card'
import { CardHeading } from '../../../../ui/CardHeading'
import { CrunchbaseIcon, LinkedinBoxIcon, TwitterIcon } from '../../../../ui/icons'
import { StackedField } from '../../../../ui/StackedField'

interface Props {
  account: Account
}

export function CompanyDetailsCard(props: Props) {
  const overflow = useDisclosure()
  const company = props.account.clearbit || props.account.company
  const geo = uniq(compact([company.geo?.city, company.geo?.stateCode, company.geo?.country])).join(', ')
  const sector = company.category?.sector
  const industry = company.category?.industry
  const employees = company.metrics?.employeesRange
  const description = company.description
  const revenue = company.metrics?.estimatedAnnualRevenue
  const founded = company.founded_year

  // Skip rendering if there's nothing to show!
  if (!(description || geo || revenue || sector || industry || employees || founded)) {
    return null
  }

  const twitterHandle = company.twitter?.handle
  const linkedinHandle = company.linkedin?.handle
  const crunchbaseHandle = company.crunchbase?.handle

  const searchURL = `https://linkedin.com/search/results/companies/?keywords=${company.name}`
  const linkedin = linkedinHandle
    ? `https://linkedin.com/company/${linkedinHandle.replace(/(linkedin\.com\/|company\/)/gi, '')}`
    : searchURL

  return (
    <DetailsCard>
      {company.clearbit_id ? (
        <CardHeading icon="https://logo.clearbit.com/clearbit.com">Clearbit</CardHeading>
      ) : (
        <CardHeading icon={IconBriefcase}>Company Details</CardHeading>
      )}

      <Stack spacing={5}>
        {description && (
          <StackedField label="Description" cursor="pointer" onClick={overflow.onToggle} copyable={false}>
            <Text noOfLines={overflow.isOpen ? undefined : 2}>{description}</Text>
          </StackedField>
        )}

        {geo && <StackedField label="Location">{geo}</StackedField>}
        {revenue && <StackedField label="Revenue">{revenue}</StackedField>}
        {sector && <StackedField label="Sector">{sector}</StackedField>}
        {industry && <StackedField label="Industry">{industry}</StackedField>}
        {employees && <StackedField label="Number of Employees">{employees}</StackedField>}
        {founded && <StackedField label="Founded">{founded}</StackedField>}

        {(linkedin || twitterHandle || crunchbaseHandle) && (
          <StackedField label="Social" copyable={false}>
            <HStack>
              {linkedin && (
                <Link flex="none" href={linkedin} isExternal color="linkedin.700">
                  <LinkedinBoxIcon size={24} />
                </Link>
              )}
              {twitterHandle && (
                <Link flex="none" href={`https://twitter.com/${twitterHandle}`} isExternal color="black">
                  <TwitterIcon size={22} />
                </Link>
              )}
              {crunchbaseHandle && (
                <Link flex="none" href={`https://crunchbase.com/${crunchbaseHandle}`} isExternal color="#146AFF">
                  <CrunchbaseIcon size={24} />
                </Link>
              )}
            </HStack>
          </StackedField>
        )}
      </Stack>
    </DetailsCard>
  )
}
