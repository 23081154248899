import {
  Box,
  Button,
  Center,
  Circle,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  LinkBox,
  LinkOverlay,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
  Progress,
  Spinner,
  Square,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IconChevronRight, IconExclamationMark, IconHelp } from '@tabler/icons-react'
import { format as numberShorthand } from 'friendly-numbers'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { InlineWidget } from 'react-calendly'
import { useMountedState } from 'react-use'
import { concurrentCachedGET } from '../../../lib/api'
import { formatDate } from '../../../lib/dayjs'
import router from '../../../lib/router'
import { LineGraph } from '../../pages/analytics/components/LineGraph'
import { TimeseriesData } from '../../pages/analytics/components/timeseries'
import { CheckoutModal } from '../../pages/billing/components/checkout'
import { ReferralModal } from '../../pages/billing/components/referral-program'
import { billingMetricsPath } from '../../pages/billing/lib/path-helpers'
import { friendlyNumber, KoalaSubscription } from '../../pages/billing/show'
import { EntitlementsV2, Usage } from '../../pages/billing/v2'
import GrayAccountContext from '../GrayAccountsContext'
import { BuildingIcon } from '../icons'
import { usePermission } from '../PermissionsContext'
import { PricingForm } from '../PricingForm'
import { projectPath, useCurrentProject } from '../ProjectsContext'
import { RootCssVar } from '../RootCssVar'
import { useCurrentUser } from '../UserContext'

interface Props {
  subscription: KoalaSubscription
  entitlements?: EntitlementsV2
  usage?: Usage
  variant?: 'top-nav' | 'sidebar'
  popoverProps?: PopoverProps
}

type SubscriptionWithAccounts = {
  koala_subscription: KoalaSubscription
  entitlements?: EntitlementsV2
  usage?: Usage
}

export function AccountsBanner(props: Pick<Props, 'variant' | 'popoverProps'>) {
  const project = useCurrentProject()
  const mounted = useMountedState()
  const [completeSub, setCompleteSub] = useState<SubscriptionWithAccounts>()

  useEffect(() => {
    concurrentCachedGET<SubscriptionWithAccounts>(billingMetricsPath())
      .then((data) => {
        if (mounted()) {
          setCompleteSub(data)
        }
      })
      .catch(() => {})
  }, [project, mounted])

  if (!completeSub || !completeSub.koala_subscription) {
    return null
  }

  return (
    <AccountBannerComponent
      variant={props.variant}
      popoverProps={props.popoverProps}
      usage={completeSub.usage}
      entitlements={completeSub.entitlements}
      subscription={completeSub?.koala_subscription}
    />
  )
}

function AccountBannerComponent(props: Props) {
  const disclosure = useDisclosure()
  const referralDisclosure = useDisclosure()
  const { setGrayAccountReason, setIsGrayAccountVisible } = useContext(GrayAccountContext)
  const { hasPermission: canEditProject } = usePermission({ on: 'project', action: 'can_edit' })
  const [showModal, setShowModal] = useState(false)
  const { metrics, entitlements, plan, version } = props.subscription
  const { usage } = props

  const isV2 = useMemo(() => version === 'v2', [version])
  const isV1 = useMemo(() => version === 'v1', [version])

  const billableMetric = useMemo(() => (isV1 ? 'maa' : 'accounts'), [isV1])

  const count = useMemo(() => {
    if (!entitlements?.accounts && !entitlements?.maa) {
      return null
    }

    return billableMetric === 'accounts' ? usage?.accounts ?? 0 : metrics?.maa?.count ?? 0
  }, [metrics, usage, billableMetric, entitlements])

  const limit = useMemo(() => {
    if (!entitlements?.accounts && !entitlements?.maa) {
      return null
    }

    return billableMetric === 'accounts' ? entitlements?.accounts ?? 1 : entitlements?.maa ?? 1
  }, [entitlements, billableMetric])

  const progressPct = useMemo(() => {
    if (count === null || limit === null) {
      return null
    }

    return Math.round((count / limit) * 100)
  }, [count, limit])

  useEffect(() => {
    if (isV1) {
      return
    }

    if (isV2 && props.subscription?.plan !== 'free') {
      return
    }

    if (progressPct === null) {
      setGrayAccountReason(undefined)
      return
    }

    if (progressPct >= 100) {
      setIsGrayAccountVisible(true)
      setGrayAccountReason('limited')
    } else {
      setIsGrayAccountVisible(false)
      setGrayAccountReason(undefined)
    }
  }, [progressPct, setGrayAccountReason, setIsGrayAccountVisible, isV1, isV2, props.subscription.plan])

  if (!metrics && !usage) {
    return null
  }

  if (!entitlements?.maa && !entitlements?.accounts) {
    return null
  }

  if (progressPct === null || limit === null || count === null) {
    return null
  }

  // Don't show banner when user is on a paid plan and has not reached a threshold of their Account limit
  if (plan !== 'free' && progressPct < 75) {
    return null
  }

  // For v1 plans, hide the banner when there are 0 accounts
  if (isV1 && count === 0) {
    return null
  }

  if (isV2 && props.subscription.plan?.includes('team')) {
    return null
  }

  const colorScheme = progressPct >= 100 ? 'red' : progressPct >= 90 ? 'orange' : 'purple'

  return (
    <>
      {showModal && props.subscription && (
        <CheckoutModal
          subscription={props.subscription}
          onClose={() => {
            setShowModal(false)
          }}
        />
      )}

      <ReferralModal disclosure={referralDisclosure} />

      <Popover size="lg" lazyBehavior="unmount" isLazy trigger="hover" {...props.popoverProps} {...disclosure}>
        <PopoverTrigger>
          <Stack
            cursor={canEditProject ? 'pointer' : undefined}
            display={['none', 'flex']}
            spacing={props.variant === 'sidebar' ? 2 : 1}
            bg="white"
            py="2"
            px="2.5"
            rounded="md"
            shadow="base"
            onClick={() => {
              if (canEditProject) {
                setShowModal(true)
              }
            }}
          >
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
              fontSize={props.variant === 'sidebar' ? '13px' : 'xs'}
              fontWeight="medium"
              lineHeight={props.variant === 'sidebar' ? 1.5 : '16px'}
            >
              {billableMetric === 'maa' ? (
                <HStack width="100%" flex="none" justifyContent="space-between">
                  <HStack spacing={1}>
                    <Text whiteSpace="nowrap">
                      {friendlyNumber(count ?? 0)} / {numberShorthand(limit, { formattedDecimals: 2 })} MAA
                    </Text>
                    <Icon as={IconHelp} boxSize={3.5} color="gray.500" />
                  </HStack>
                  <Text>{progressPct}%</Text>
                </HStack>
              ) : (
                <HStack width="100%" flex="none" justifyContent="space-between">
                  <HStack spacing={1}>
                    <Text>Accounts</Text>
                    <Icon as={IconHelp} boxSize={3.5} color="gray.500" />
                  </HStack>
                  <Text whiteSpace="nowrap">
                    {friendlyNumber(count ?? 0)}{' '}
                    <Text display="inline" color="gray.500" as="span">
                      / {numberShorthand(limit, { formattedDecimals: 2 })}
                    </Text>
                  </Text>
                </HStack>
              )}
            </Flex>
            <Flex w="100%" flex="1">
              <Progress
                w="100%"
                minW="160px"
                bg="gray.200"
                value={count ?? 0}
                max={limit}
                height={props.variant === 'sidebar' ? '4px' : '3px'}
                rounded="full"
                colorScheme={colorScheme}
              />
            </Flex>
          </Stack>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width="350px" maxWidth="90vw" border="none" shadow="xl" rounded="xl">
            <PopoverBody bg="white" padding={0} rounded="xl" overflow="hidden" borderColor="gray.200">
              <AccountsHoverCard
                {...props}
                onClose={disclosure.onClose}
                setShowModal={setShowModal}
                onRefer={referralDisclosure.onOpen}
              />
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    </>
  )
}

interface AccountsHoverCardProps extends Props {
  onClose: () => void
  setShowModal: (show: boolean) => void
  onRefer: () => void
}

function AccountsHoverCard(props: AccountsHoverCardProps) {
  const { version } = props.subscription
  const setShowModal = props.setShowModal

  const isV1 = useMemo(() => version === 'v1', [version])
  const billableMetric = useMemo(() => (isV1 ? 'maa' : 'accounts'), [isV1])

  return (
    <Stack fontSize="sm" p="6" spacing="4" bgGradient="linear(purple.100 0%, white 120px)">
      <HStack spacing={3}>
        <Square rounded="lg" p={2} bg="purple.600">
          <BuildingIcon color="white" size={20} />
        </Square>
        <Stack spacing="0.5">
          <Heading fontWeight="semibold" size="sm">
            {billableMetric === 'maa' ? 'Monthly Active Accounts' : 'Koala Accounts'}
          </Heading>
          {billableMetric === 'maa' && (
            <HStack fontSize="xs" color="gray.600">
              <Text>{formatDate(props.subscription.current_period_start)}</Text>
              <Text>-</Text>
              <Text>{formatDate(props.subscription.current_period_end)}</Text>
            </HStack>
          )}
        </Stack>
      </HStack>

      {billableMetric === 'maa' && (
        <>
          <Text>MAA is the number of Active Accounts in the current billing period. </Text>
          <Stack spacing={4}>
            <Stack
              role="group"
              cursor="pointer"
              border="1px solid"
              borderColor="gray.200"
              rounded="xl"
              p={4}
              onClick={() => {
                props.onClose()
                setShowModal(true)
              }}
            >
              <HStack justifyContent="space-between">
                <Text color="purple.600" fontWeight="semibold">
                  Upgrade your plan
                </Text>
                <Icon
                  as={IconChevronRight}
                  boxSize={4}
                  color="gray.400"
                  _groupHover={{ color: 'purple.600', transform: 'translateX(1px)' }}
                  transition="all 150ms ease-in-out"
                />
              </HStack>
              <Text>Upgrade your workspace to increase your monthly active accounts</Text>
            </Stack>
          </Stack>
        </>
      )}

      {billableMetric === 'accounts' && (
        <>
          <Text>Koala Accounts is the number of Accounts that you choose to track in Koala. </Text>
          <Stack spacing={4}>
            <LinkBox
              as={Stack}
              role="group"
              cursor="pointer"
              border="1px solid"
              borderColor="gray.200"
              rounded="xl"
              p={4}
            >
              <HStack justifyContent="space-between">
                <Text color="purple.600" fontWeight="semibold">
                  <LinkOverlay
                    href={projectPath('/settings/excluded-accounts')}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      props.onClose()
                      router.visit(projectPath('/settings/excluded-accounts'))
                    }}
                  >
                    Hide accounts
                  </LinkOverlay>
                </Text>
                <Icon
                  as={IconChevronRight}
                  boxSize={4}
                  color="gray.400"
                  _groupHover={{ color: 'purple.600', transform: 'translateX(1px)' }}
                  transition="all 150ms ease-in-out"
                />
              </HStack>
              <Text>Hide accounts that are out of your ICP to focus on the most valuable ones</Text>
            </LinkBox>
            <Stack
              role="group"
              cursor="pointer"
              border="1px solid"
              borderColor="gray.200"
              rounded="xl"
              p={4}
              onClick={props.onRefer}
            >
              <HStack justifyContent="space-between">
                <Text color="purple.600" fontWeight="semibold">
                  Refer a company
                </Text>
                <Icon
                  as={IconChevronRight}
                  boxSize={4}
                  color="gray.400"
                  _groupHover={{ color: 'purple.600', transform: 'translateX(1px)' }}
                  transition="all 150ms ease-in-out"
                />
              </HStack>
              <Text>Each referral will get you 250 more Koala Accounts</Text>
            </Stack>
            <Stack
              role="group"
              cursor="pointer"
              border="1px solid"
              borderColor="gray.200"
              rounded="xl"
              p={4}
              onClick={() => {
                props.onClose()
                setShowModal(true)
              }}
            >
              <HStack justifyContent="space-between">
                <Text color="purple.600" fontWeight="semibold">
                  Upgrade your plan
                </Text>
                <Icon
                  as={IconChevronRight}
                  boxSize={4}
                  color="gray.400"
                  _groupHover={{ color: 'purple.600', transform: 'translateX(1px)' }}
                  transition="all 150ms ease-in-out"
                />
              </HStack>
              <Text>Upgrade your workspace to access more accounts</Text>
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  )
}

export function MAAProgressionChart(props: { sub: KoalaSubscription }) {
  const entitlements = props.sub.entitlements
  const metrics = props.sub.metrics
  const maaProgression = metrics?.maa?.progression

  const timeseries: TimeseriesData[] = useMemo(() => {
    if (!maaProgression || !metrics?.maa || !entitlements?.maa) {
      return []
    }

    return maaProgression.map((entry) => {
      return {
        timestamp: entry.date,
        value: entry.maa
      }
    })
  }, [maaProgression, metrics, entitlements])

  const invisibleCuttoff: number = useMemo(() => {
    if (!entitlements?.maa || entitlements.maa === null) {
      return 0
    }

    const invisible = timeseries.filter((entry) => entry.value > entitlements.maa!).length
    const lenght = timeseries.length

    return parseInt(((invisible / lenght) * 100).toFixed(2))
  }, [timeseries, entitlements])

  if (!metrics || !metrics.maa || !entitlements?.maa) {
    return null
  }

  return (
    <Stack position="relative" w="100%">
      <Box
        position={'absolute'}
        right="10px"
        w={`${invisibleCuttoff}%`}
        h="60px"
        bg="white"
        border="1px solid gray"
        style={{
          mixBlendMode: 'saturation',
          pointerEvents: 'none',
          zIndex: 1
        }}
      />
      <LineGraph
        period="month"
        label="Monthly Active Accounts"
        data={timeseries}
        height={60}
        xAxis={false}
        yAxis={false}
      />
      <HStack fontSize={'xs'} spacing="1" justifyContent={'space-between'} pr="4">
        <Heading size="xs" fontWeight={'normal'}>
          {metrics.maa.count} MAA
        </Heading>
        <HStack color="gray.600">
          <Text>{formatDate(props.sub.current_period_start)}</Text>
          <Text>-</Text>
          <Text>{formatDate(props.sub.current_period_end)}</Text>
        </HStack>
      </HStack>
    </Stack>
  )
}

export function openUpgradeFlow(upgrade_to?: 'pro' | 'starter' | 'business') {
  window.dispatchEvent(
    new CustomEvent('openupgradeflow', {
      detail: {
        upgrade_to
      }
    })
  )
}

export function openPricingForm() {
  window.dispatchEvent(new Event('openpricingform'))
}

export function PricingFormFlow() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    const onOpenPricingForm = () => {
      onOpen()
    }

    window.addEventListener('openpricingform', onOpenPricingForm)

    return () => {
      window.removeEventListener('openpricingform', onOpenPricingForm)
    }
  }, [onOpen])

  if (!isOpen) return null

  return <PricingForm onClose={onClose} />
}

export function BTFlow() {
  const user = useCurrentUser()

  return (
    <InlineWidget
      url="https://calendly.com/nick-koala/30min"
      prefill={{
        email: user.email,
        name: user.name
      }}
    />
  )
}

export function UpgradeFlow() {
  const project = useCurrentProject()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [completeSub, setCompleteSub] = useState<SubscriptionWithAccounts>()
  const [isLoading, setIsLoading] = useState(false)
  const [upgradeTo, setUpgradeTo] = useState<'pro' | 'starter' | undefined>()

  useEffect(() => {
    setIsLoading(true)
    concurrentCachedGET<SubscriptionWithAccounts>(billingMetricsPath())
      .then((data) => {
        if (data.koala_subscription) {
          setCompleteSub(data)
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false)
      })
  }, [project])

  useEffect(() => {
    const onOpenUpgradeFlow = (e) => {
      e = e as CustomEvent
      const upgradeTo = e.detail?.upgrade_to
      setUpgradeTo(upgradeTo)
      onOpen()
    }

    window.addEventListener('openupgradeflow', onOpenUpgradeFlow)

    return () => {
      window.removeEventListener('openupgradeflow', onOpenUpgradeFlow)
    }
  }, [onOpen])

  if (!isOpen) {
    return null
  }

  if (isLoading) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Center minH="250px">
              <Spinner />
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  }

  if (!completeSub) {
    return null
  }

  // all paths lead to v3 plans
  if (completeSub?.koala_subscription) {
    return <CheckoutModal onClose={onClose} subscription={completeSub.koala_subscription} upgrade_to={upgradeTo} />
  }

  return null
}

export function GrayAccountsBanner() {
  const { isGrayAccountVisible, grayAccountReason } = useContext(GrayAccountContext)
  const project = useCurrentProject()
  const isV1 = useMemo(() => project?.koala_subscription?.version === 'v1', [project])
  const isV2 = useMemo(() => project?.koala_subscription?.version === 'v2', [project])
  const isV3 = useMemo(() => project?.koala_subscription?.version === 'v3', [project])
  const isPastDue = useMemo(() => isV2 && project?.koala_subscription?.past_due, [project, isV2])

  if (isPastDue) {
    return null
  }

  if (!isGrayAccountVisible) {
    return null
  }

  if (!isV1 && grayAccountReason === undefined) {
    return null
  }

  if (isV1 && !grayAccountReason) {
    return null
  }

  return (
    <Box fontSize="sm" bg="orange.100" color="orange.900" py="2">
      <RootCssVar property="--banner-height" value="40px" />

      <HStack
        alignItems="flex-start"
        spacing={3}
        maxW="min(100%, var(--max-container-width))"
        px={[4, 4, 6, 8]}
        mx="auto"
      >
        <Circle flex="none" bg="orange.600" color="orange.100" p="0.5" mt={0.5}>
          <IconExclamationMark size={14} />
        </Circle>
        <Flex flex="1 1 auto" gap={3} alignItems="center" justifyContent="space-between" flexWrap="wrap">
          {(isV2 || isV3) && (
            <Box display="inline">
              <Text display="inline" fontWeight="semibold">
                You've reached your account limit.
              </Text>{' '}
              Unlock more by hiding unwanted accounts or upgrading your plan.
            </Box>
          )}
          {isV1 && grayAccountReason === 'limited' && (
            <Text>
              You've reached your Account limit. You can unlock more Accounts and longer retention by upgrading your
              plan.
            </Text>
          )}

          {isV1 && grayAccountReason === 'retention' && (
            <Text>
              You're seeing limited data retention for some of your accounts. This is because the data is outside of the
              data retention period for your plan. You can unlock a longer retention by upgrading your plan.
            </Text>
          )}
          <HStack flex="none" spacing={3}>
            {isV2 && (
              <Link flex="none" fontSize="xs" href={projectPath('/settings/excluded-accounts')} isExternal>
                Hide Accounts
              </Link>
            )}
            <Button
              flex="none"
              size="xs"
              bg="white"
              onClick={() => {
                openUpgradeFlow()
              }}
            >
              Upgrade Plan
            </Button>
          </HStack>
        </Flex>
      </HStack>
    </Box>
  )
}
