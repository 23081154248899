import { Box, Divider, FormControl, FormHelperText, Stack, Switch, Text } from '@chakra-ui/react'
import { nanoid } from 'nanoid'
import React, { useMemo } from 'react'
import { SalesforceFieldMapper, SalesforceFieldMapping } from './salesforce-field-mapper'
import { SalesforceActionProps } from './salesforce-setup'

export function ImportContact(
  props: SalesforceActionProps & {
    targetType?: 'Profile' | 'Account'
  }
) {
  const [enabled, setEnabled] = React.useState(Boolean(props.delivery_rules?.salesforce?.import_contact?.enabled))
  const sfFieldNames = useMemo(() => props.deps.contact_layout.map((f) => f.name), [props.deps.contact_layout])

  const isPersisted = useMemo(
    () => !!props.delivery_rules?.salesforce?.import_contact?.fields?.length,
    [props.delivery_rules?.salesforce?.import_contact]
  )

  const suggestions: SalesforceFieldMapping[] = useMemo(() => {
    if (props.suggestedMappings) {
      return props.suggestedMappings
    }

    return [
      {
        id: nanoid(),
        koala: 'visitor.email',
        salesforce: 'Email'
      },
      {
        id: nanoid(),
        koala: 'visitor.first_name',
        salesforce: 'FirstName'
      },
      {
        id: nanoid(),
        koala: 'visitor.last_name',
        salesforce: 'LastName'
      },
      {
        id: nanoid(),
        koala: 'visitor.title',
        salesforce: 'Title'
      },
      {
        id: nanoid(),
        koala: 'visitor.geo.city',
        salesforce: 'MailingCity'
      },
      {
        id: nanoid(),
        koala: 'visitor.geo.state',
        salesforce: 'MailingState'
      },
      {
        id: nanoid(),
        koala: 'visitor.geo.country',
        salesforce: 'MailingCountry'
      }
    ].filter((s) => s.salesforce && sfFieldNames.includes(s.salesforce))
  }, [sfFieldNames, props.suggestedMappings])

  return (
    <Stack w="100%">
      <FormControl>
        <Switch
          disabled={props.targetType === 'Account'}
          isChecked={enabled}
          onChange={(e) => {
            setEnabled(e.target.checked)
            props.setDeliveryRules({
              ...props.delivery_rules,
              salesforce: {
                ...props.delivery_rules?.salesforce,
                import_contact: {
                  ...props.delivery_rules?.salesforce?.import_contact,
                  enabled: e.target.checked
                }
              }
            })
          }}
          size="sm"
          fontSize={'sm'}
          fontWeight="semibold"
          colorScheme={'purple'}
          value={enabled ? 'true' : 'false'}
          name="follow_rule[delivery_rules][salesforce][import_contact][enabled]"
        >
          Enable
        </Switch>
        <FormHelperText>
          Import the current Visitor as a Contact in Salesforce if it doesn't already exist. (Requires an identified
          visitor.)
        </FormHelperText>
      </FormControl>

      {props.targetType === 'Account' && (
        <Box bg="orange.50" p="4" rounded="md" borderWidth="thin" borderColor={'orange.500'}>
          <Text fontSize={'sm'}>
            <strong>Note:</strong> Your Action is set up to target Accounts. You can only import Contacts when targeting
            Visitors in your Action.
          </Text>
        </Box>
      )}

      {enabled && props.actionSchema && (
        <Stack spacing={'8'}>
          <Divider />
          <SalesforceFieldMapper
            actionsSchema={props.actionSchema}
            loadingDeps={props.loadingDeps}
            refetchDeps={props.refetchDeps}
            namespace="follow_rule[delivery_rules][salesforce][import_contact]"
            type="contact"
            mappings={props.delivery_rules?.salesforce?.import_contact?.fields ?? (isPersisted ? [] : suggestions)}
            suggestions={suggestions}
            deps={props.deps}
          />
        </Stack>
      )}
    </Stack>
  )
}
