import { Box, ButtonProps, Flex, List, ListItem, Text } from '@chakra-ui/react'
import { useSelect } from 'downshift'
import React, { useCallback } from 'react'

type Item = { key: string; label: string; icon: React.ElementType }

interface Props {
  onEditOperator?: (operator: string) => void
  isEditable?: boolean
  children: React.ReactNode
  items?: Item[]
  colorScheme: ButtonProps['colorScheme']
}

const DEFAULT_ITEMS = [
  { key: 'all', label: 'is' },
  { key: 'not', label: 'is not' }
]

function Operator({ children, onEditOperator, isEditable, ...props }: Props) {
  const colorScheme = props.colorScheme ?? 'purple'
  const items = props.items || DEFAULT_ITEMS

  const handleEditOperator = useCallback(
    (operator) => {
      onEditOperator?.(operator)
    },
    [onEditOperator]
  )

  const isTriggerable = isEditable && onEditOperator

  const { isOpen, getItemProps, getMenuProps, getToggleButtonProps, highlightedIndex } = useSelect({
    items,
    itemToString: (item: any) => item.key,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        handleEditOperator(selectedItem.key)
      }
    }
  })

  return (
    <Box as="span" display="block" position="relative" alignSelf="center" userSelect="none">
      <Flex
        as="span"
        justifyContent="center"
        alignItems="center"
        rounded="md"
        pointerEvents="none"
        {...(isTriggerable && {
          paddingX: 1.5,
          borderWidth: '1px',
          cursor: 'pointer',
          pointerEvents: 'unset',
          _hover: {
            '& > p': { opacity: 1 },
            background: `${colorScheme}.100`
          },
          _active: {
            '& > p': { opacity: 1 },
            background: `${colorScheme}.100`
          }
        })}
        {...getToggleButtonProps()}
      >
        <Text as="span" fontWeight="medium" fontSize="sm" opacity={isTriggerable ? 0.8 : 0.6}>
          {children}
        </Text>
      </Flex>

      <List
        display="flex"
        flexDirection="column"
        minWidth="100px"
        maxWidth="400px"
        maxHeight="400px"
        overflowY="auto"
        rounded="md"
        boxShadow="lg"
        color="gray.800"
        zIndex={1500}
        position="absolute"
        background="white"
        visibility={isOpen ? 'visible' : 'hidden'}
        marginTop={1}
        border="1px solid"
        borderColor={isOpen ? 'gray.200' : 'transparent'}
        outline="none"
        {...getMenuProps()}
      >
        {isOpen &&
          items.map((item, index) => {
            return (
              <ListItem
                key={index}
                {...getItemProps({ item, index })}
                display="flex"
                alignItems={'center'}
                backgroundColor={highlightedIndex === index ? `${colorScheme}.50` : undefined}
                _hover={{ backgroundColor: `${colorScheme}.50` }}
                _focus={{ outline: 'none', backgroundColor: `${colorScheme}.50` }}
                _active={{ backgroundColor: `${colorScheme}.50` }}
                cursor="pointer"
                paddingY={2}
                paddingLeft={3}
                paddingRight={6}
                borderRadius={4}
                minWidth="100%"
                gap={2}
              >
                <Text isTruncated title={item.label} fontSize="sm">
                  {item.label}
                </Text>
              </ListItem>
            )
          })}
      </List>
    </Box>
  )
}

export default Operator
