import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  LinkProps,
  List,
  ListIcon,
  ListItem,
  Skeleton,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure
} from '@chakra-ui/react'
import {
  Icon as TablerIcon,
  IconChevronDown,
  IconChevronRight,
  IconInbox,
  IconLock,
  IconPlus,
  IconPointFilled,
  IconSettings,
  IconTrendingUp,
  IconUsers
} from '@tabler/icons-react'
import groupBy from 'lodash/groupBy'
import merge from 'lodash/merge'
import React, { PropsWithChildren, useCallback, useMemo } from 'react'
import createPersistedState from 'use-persisted-state'
import { AccountView } from '../../../../types/AccountView'
import { useAccountViews } from '../../../data/use-account-views'
import { BuildingIcon } from '../../../ui/icons'
import PageLayout from '../../../ui/PageLayout'
import { projectPath } from '../../../ui/ProjectsContext'
import { useLayoutMode } from '../../../ui/useLayoutMode'
import { NewListModal } from '../../account_views/components/NewListModal'

const ogPadding = [4, 5, 6]
const newPadding = [2, 4]

export function ListContainer(props: BoxProps) {
  const layoutMode = useLayoutMode()
  return <PageLayout flush paddingX={ogPadding} paddingY={layoutMode === 'new' ? newPadding : ogPadding} {...props} />
}

interface ListsNavContextProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  // whether or not the sidebar will render offscreen (and requires toggle), i.e. on mobile
  offScreen?: boolean
}

export const ListsNavContext = React.createContext<ListsNavContextProps>({
  isOpen: true,
  onOpen: () => {},
  onClose: () => {},
  offScreen: false
})

export function useListsNav() {
  return React.useContext(ListsNavContext)
}

interface UIState {
  open?: {
    [key: string]: boolean | undefined
  }
}

const defaultState = {
  open: {
    traits: undefined,
    salesforce: undefined,
    hubspot: undefined,
    outreach: undefined
  }
}

const useListsUI = createPersistedState<UIState>('lists-ui')

function useUIState() {
  const [state, setState] = useListsUI(defaultState)

  const upsertState = useCallback(
    (newState: Partial<UIState>) => {
      setState((oldState) => merge({}, oldState, newState))
    },
    [setState]
  )

  return [state || defaultState, upsertState] as const
}

interface ListsNavProps {
  path?: string
  listSlug?: string
}

const emptyArray = []

export function ListsNav({ path, listSlug }: ListsNavProps) {
  const lists = useAccountViews()
  const newListModal = useDisclosure()
  const navContext = useListsNav()

  // figure out the list type from the current path
  const selected = useMemo(() => {
    if (!listSlug) return undefined

    const data = lists.data?.account_views ?? []
    return data.find((list) => list.slug === listSlug || (list.view_type === 'my_accounts' && listSlug === 'mine'))
  }, [lists.data?.account_views, listSlug])

  const listKind = useMemo(() => {
    if (selected) {
      return selected.kind
    }

    return path?.includes('/accounts') || path?.includes('/views/mine') || path?.includes('/views/trending')
      ? 'account'
      : path?.includes('/visitors')
        ? 'profile'
        : undefined
  }, [path, selected])

  const grouped = useMemo(() => {
    const data = lists.data?.account_views ?? []
    const filtered = listKind
      ? data.filter((list) => list.view_type !== 'my_accounts' && list.kind === listKind)
      : data.filter((list) => list.view_type !== 'my_accounts')
    return groupBy(filtered, (list) => (list.ownership === 'team' ? list.team?.name : list.ownership))
  }, [lists.data?.account_views, listKind])

  const personal = grouped['private'] ?? emptyArray
  const shared = grouped['shared'] ?? emptyArray

  const menu = (
    <Flex direction="column" minHeight="100%" gap={1.5} paddingTop={[4, 4, 5]} px={[3, 3, 4]}>
      {listKind === 'account' ? (
        <>
          <SubnavLink href={projectPath('/accounts')} icon={<BuildingIcon size={18} />}>
            All Accounts
          </SubnavLink>

          <SubnavLink
            href={projectPath('/views/mine')}
            icon={<IconInbox size={18} />}
            isActive={location.pathname.includes('/views/mine')}
          >
            My Accounts
          </SubnavLink>

          <SubnavLink
            href={projectPath('/views/trending')}
            icon={<IconTrendingUp size={18} />}
            isActive={location.pathname.includes('/views/trending')}
          >
            Trending Accounts
          </SubnavLink>
        </>
      ) : (
        <SubnavLink href={projectPath('/visitors')} icon={<IconUsers size={18} />}>
          All Visitors
        </SubnavLink>
      )}

      {personal.length > 0 && <Section title="My Lists" items={personal} isLoading={lists.isLoading} />}

      {Object.keys(grouped)
        .filter((k) => !['private', 'shared'].includes(k))
        .map((key) => (
          <Section
            key={grouped[key]?.[0]?.team?.id || key}
            title={key.replace(/\s+team/gi, '') + ' Team'}
            items={grouped[key] ?? []}
            isLoading={lists.isLoading}
            permalink={
              grouped[key]?.[0]?.team?.id ? projectPath(`/settings/teams/${grouped[key]?.[0]?.team?.id}`) : undefined
            }
          />
        ))}

      {shared.length > 0 && (
        <Section title="Shared Lists" items={shared} isLoading={lists.isLoading} permalink={projectPath(`/views`)} />
      )}

      <Box marginTop="auto" paddingTop={4} paddingBottom={6} position="sticky" bottom={0} bg="white">
        <NewListModal {...newListModal} />

        <Button
          as={Link}
          href={projectPath(`/views/new`)}
          onClick={(e) => {
            // prevent the link from navigating
            // unless opening in a new tab
            if (e.ctrlKey || e.metaKey || e.shiftKey) {
              return true
            }

            newListModal.onOpen()
            e.preventDefault()
            return false
          }}
          width="full"
          rounded="lg"
          variant="outline"
          leftIcon={<Icon as={IconPlus} boxSize={4} color="purple.600" />}
          iconSpacing={1}
        >
          Create List
        </Button>
      </Box>
    </Flex>
  )

  // use a modal if we are on small screens/mobile
  const isSmall = useBreakpointValue({ base: true, sm: true, md: false })
  if (isSmall) {
    return (
      <Drawer {...navContext} placement="bottom" preserveScrollBarGap>
        <DrawerOverlay />
        <DrawerContent margin={0} maxHeight="80vh" roundedBottom={0}>
          <DrawerBody paddingX={0} paddingBottom={0}>
            {menu}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    )
  }

  return (
    <Box
      flex="none"
      width={['100vw', '240px', '250px', '260px', '270px']}
      maxWidth="100vw"
      gap={1.5}
      bg="white"
      borderRight="1px solid"
      borderColor="gray.200"
      height="100%"
      overflow="auto"
      color="gray.700"
    >
      {menu}
    </Box>
  )
}

interface SectionProps {
  isLoading?: boolean
  title: string
  items: AccountView[]
  permalink?: string
}

function Section({ title, items, permalink, isLoading }: SectionProps) {
  const isSmall = useBreakpointValue({ base: true, sm: true, md: false })
  const [ui, setUI] = useUIState()

  const menu = useDisclosure({
    defaultIsOpen: ui.open?.[title] ?? false,
    onOpen: () => {
      setUI({ open: { [title]: true } })
    },
    onClose: () => {
      setUI({ open: { [title]: false } })
    }
  })

  return (
    <Box>
      <SubnavHeader expandable isOpen={menu.isOpen} onClick={menu.onToggle}>
        <HStack width="100%" justifyContent="space-between">
          <Box as="span">{title}</Box>
          {permalink && (
            <IconButton
              as="a"
              href={permalink}
              onClick={(e) => {
                e.stopPropagation()
              }}
              aria-label="Configure"
              size="xs"
              variant="ghost"
              icon={<IconSettings size={16} />}
              opacity={isSmall && menu.isOpen ? 1 : 0}
              _groupHover={isSmall ? undefined : { opacity: 1 }}
              transition={isSmall ? undefined : 'opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'}
            />
          )}
        </HStack>
      </SubnavHeader>

      <Collapse in={menu.isOpen} animateOpacity>
        {isLoading ? (
          <Box paddingX={2}>
            <Stack spacing={4}>
              <Skeleton height="14px" width="180px" />
              <Skeleton height="14px" width="180px" />
              <Skeleton height="14px" width="180px" />
            </Stack>
          </Box>
        ) : (
          <List fontSize={['md', 'sm']} spacing={1}>
            {items.map((list) => (
              <ListLink key={list.id} icon={list.ownership === 'private' ? IconLock : undefined} list={list} />
            ))}
          </List>
        )}
      </Collapse>
    </Box>
  )
}

interface SubnavHeaderProps extends BoxProps {
  expandable?: boolean
  isOpen?: boolean
  icon?: React.ReactNode
}

function SubnavHeader({ children, expandable, isOpen, icon, ...props }: PropsWithChildren<SubnavHeaderProps>) {
  const isSmall = useBreakpointValue({ base: true, sm: true, md: false })

  return (
    <HStack
      spacing={1.5}
      cursor={expandable ? 'pointer' : undefined}
      userSelect="none"
      role="group"
      fontSize={['md', 'sm']}
      fontWeight="medium"
      // avoid requiring two clicks on mobile
      _hover={isSmall ? undefined : { bg: 'purple.50', color: 'purple.600' }}
      paddingY={1}
      paddingX={2}
      marginX={-1.5}
      rounded="md"
      {...props}
    >
      {expandable && (
        <Box as="span" display="inline-flex" alignItems="center" justifyContent="center" flex="none" boxSize="18px">
          <Icon as={isOpen ? IconChevronDown : IconChevronRight} boxSize={3.5} />
        </Box>
      )}
      {icon && (
        <Box as="span" display="inline-flex" alignItems="center" justifyContent="center" flex="none" boxSize="18px">
          {icon}
        </Box>
      )}
      <Box as="span" flex="1" width="100%">
        {children}
      </Box>
    </HStack>
  )
}

interface SubnavLinkProps extends LinkProps {
  isActive?: boolean
  icon?: React.ReactNode
}

function SubnavLink({ isActive, children, icon, ...props }: React.PropsWithChildren<SubnavLinkProps>) {
  isActive =
    isActive ??
    (location.pathname === props.href ||
      location.pathname === props.href + '/feed' ||
      location.pathname === props.href + '/live')

  return (
    <Link
      display="flex"
      gap={1.5}
      justifyContent="flex-start"
      alignItems="center"
      color={isActive ? 'purple.600' : 'gray.600'}
      fontSize={['md', 'sm']}
      fontWeight={isActive ? 'semibold' : undefined}
      style={{ textDecoration: 'none' }}
      _hover={props.href || props.onClick ? { bg: 'purple.50', color: 'purple.600' } : undefined}
      cursor={props.href || props.onClick ? 'pointer' : 'unset'}
      paddingY={1}
      paddingX={2}
      marginX={-1.5}
      rounded="md"
      {...props}
    >
      <Box as="span" display="inline-flex" alignItems="center" justifyContent="center" flex="none" boxSize="18px">
        {icon}
      </Box>
      <Box as="span" flex="1" width="100%" isTruncated>
        {children}
      </Box>
    </Link>
  )
}

function ListLink({ list, icon, isActive }: { list: AccountView; icon?: TablerIcon; isActive?: boolean }) {
  const href = projectPath(`/views/${list.slug}`)
  isActive =
    isActive ??
    (location.pathname === href || location.pathname === href + '/feed' || location.pathname === href + '/live')

  return (
    <ListItem
      display="flex"
      alignItems="center"
      paddingLeft={6}
      paddingY={1}
      as="a"
      cursor="pointer"
      title={list.name}
      href={href}
      fontWeight="medium"
      color={isActive ? 'purple.600' : 'gray.500'}
      _hover={{ color: isActive ? 'purple.500' : 'gray.900' }}
    >
      <ListIcon
        as={icon || IconPointFilled}
        color={isActive ? 'purple.600' : icon ? 'gray.500' : 'gray.300'}
        boxSize={3.5}
        marginInlineEnd={1}
        marginInlineStart="1px"
      />
      <Text isTruncated>{list.name}</Text>
    </ListItem>
  )
}
