import { useEffect, useState } from 'react'
import { toast } from 'sonner'

declare global {
  interface Window {
    notice?: string
    flashError?: string
    flashWarning?: string
  }
}

export interface FlashMessages {
  notice?: string
  error?: string
  warning?: string
}

interface Props {
  flash?: FlashMessages
}

export default function Notices({ flash }: Props) {
  const [notice, setNotice] = useState<string | undefined>(flash?.notice)
  const [error, setError] = useState<string | undefined>(flash?.error)
  const [warning, setWarning] = useState<string | undefined>(flash?.warning)

  useEffect(() => {
    if (!flash) {
      return
    }

    setNotice(flash.notice)
    setError(flash.error)
    setWarning(flash.warning)
  }, [flash])

  useEffect(() => {
    if (!notice) {
      return
    }

    toast.success('Success!', {
      description: notice
    })

    window.notice = undefined
    setNotice(undefined)
  }, [notice])

  useEffect(() => {
    if (!error) {
      return
    }

    toast.error('Error!', {
      description: error
    })

    window.flashError = undefined
    setError(undefined)
  }, [error])

  useEffect(() => {
    if (!warning) {
      return
    }

    toast(warning)

    window.flashWarning = undefined
    setWarning(undefined)
  }, [warning])

  return null
}
