import {
  Button,
  Flex,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  Table,
  TableContainer,
  TabList,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { IconActivity, IconArchive, IconArrowUpLeft, IconDotsVertical, IconUsers } from '@tabler/icons-react'
import { format } from 'friendly-numbers'
import React from 'react'
import { toast } from 'sonner'
import { get } from '../../../lib/api'
import { PageMeta } from '../../../types/PageMeta'
import { BuildingIcon } from '../../ui/icons'
import PageDescription from '../../ui/PageDescription'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import { SegmentedControl } from '../../ui/SegmentedControl'
import { SettingsBreadCrumb } from '../../ui/SettingsBreadCrumb'
import SettingsHeader from '../../ui/SettingsHeader'
import { TableFooter } from '../../ui/TableFooter'
import TabLink from '../../ui/TabLink'
import { TimeAgo } from '../../ui/TimeAgo'
import { formList, formsPath } from './path-helpers'

export interface AutoTrackedForm {
  page_path: string
  count: number
  views: number
  last_submission: string
}

interface Filters {
  range: 'day' | 'week' | 'month'
  ignored?: boolean
}
interface Props {
  rollup: Array<AutoTrackedForm>
  page_meta: PageMeta
  total_ignored: number
  total: number
  filters: Filters
}

export const ActionsCell: React.FC<{ form: AutoTrackedForm; filters: Filters }> = (props) => {
  return (
    <>
      <Menu>
        {props.children || (
          <MenuButton
            as={IconButton}
            aria-label="Actions"
            icon={<IconDotsVertical size={16} />}
            variant="ghost"
            size="xs"
          />
        )}
        <MenuList>
          <MenuItem
            icon={<IconActivity size={16} />}
            as={Link}
            href={formsPath(`/auto-tracked?path=${props.form.page_path}`)}
            _focus={{
              boxShadow: 'unset',
              background: 'gray.100'
            }}
            style={{
              textDecoration: 'none'
            }}
          >
            Submissions
          </MenuItem>
          <MenuDivider />
          {!props.filters.ignored && (
            <MenuItem
              icon={<IconArchive size={16} />}
              as={Link}
              _focus={{
                boxShadow: 'unset',
                background: 'gray.100'
              }}
              onClick={(e) => {
                const path = e.currentTarget.getAttribute('href')!
                get(path)
                  .then(() => {
                    toast.success('Form ignored')
                  })
                  .catch(() => {
                    toast.error('Failed to ignore form')
                  })
              }}
              href={formsPath(`/auto-tracked/ignore?path=${props.form.page_path}`)}
              style={{
                textDecoration: 'none'
              }}
            >
              Ignore
            </MenuItem>
          )}
          {props.filters.ignored && (
            <MenuItem
              icon={<IconArrowUpLeft size={16} />}
              as={Link}
              style={{
                textDecoration: 'none'
              }}
              _focus={{
                boxShadow: 'unset',
                background: 'gray.100'
              }}
              onClick={(e) => {
                const path = e.currentTarget.getAttribute('href')!
                get(path)
                  .then(() => {
                    toast.success('Form restored')
                  })
                  .catch(() => {
                    toast.error('Failed to restore form')
                  })
              }}
              href={formsPath(`/auto-tracked/restore?path=${props.form.page_path}`)}
            >
              Restore
            </MenuItem>
          )}
          <MenuDivider />
          <MenuItem
            icon={<IconUsers size={16} />}
            as={Link}
            href={formList(props.form.page_path, 'profiles')}
            _focus={{
              boxShadow: 'unset',
              background: 'gray.100'
            }}
            style={{
              textDecoration: 'none'
            }}
          >
            <Text>Track as Contact List</Text>
          </MenuItem>
          <MenuItem
            icon={<BuildingIcon size={16} />}
            as={Link}
            _focus={{
              boxShadow: 'unset',
              background: 'gray.100'
            }}
            href={formList(props.form.page_path, 'accounts')}
            style={{
              textDecoration: 'none'
            }}
          >
            <Text>Track as Account List</Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  )
}

export default function Reports(props: Props) {
  const range = props.filters.range

  return (
    <PageLayout size="full">
      <SettingsBreadCrumb paths={[{ title: 'Forms', path: projectPath('/forms/reports') }]} />

      <SettingsHeader borderBottomWidth={0} paddingBottom={0}>
        <HStack justifyContent="space-between" alignItems="flex-start">
          <Stack>
            <PageTitle>Forms</PageTitle>
            <PageDescription>Manage and view autotracked forms.</PageDescription>
          </Stack>
          <SegmentedControl size="sm">
            <Button isActive={range === 'day'} as={Link} href={formsPath('/reports?range=day')}>
              Day
            </Button>
            <Button isActive={range === 'week'} as={Link} href={formsPath('/reports?range=week')}>
              Week
            </Button>
            <Button isActive={range === 'month'} as={Link} href={formsPath('/reports?range=month')}>
              Month
            </Button>
          </SegmentedControl>
        </HStack>

        <Tabs size="sm" marginTop={6}>
          <TabList>
            <TabLink href={formsPath(`/reports?range=${props.filters.range}`)} isSelected={!props.filters.ignored}>
              Tracked ({props.total})
            </TabLink>
            <TabLink
              isSelected={props.filters.ignored}
              href={formsPath(`/reports?ignored=true&range=${props.filters.range}`)}
            >
              Ignored ({props.total_ignored})
            </TabLink>
          </TabList>
        </Tabs>
      </SettingsHeader>

      {props.rollup.length === 0 && (
        <Text fontSize="sm" color="gray.500">
          No submissions for the selected time period.
        </Text>
      )}

      {props.rollup.length > 0 && (
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th isNumeric>Page Views</Th>
                <Th isNumeric>Submissions</Th>
                <Th isNumeric>Conversion</Th>
                <Th>Last Submitted</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {props.rollup.map((form) => {
                let formattedConversionRate = '--'
                let conversion = 0
                let conversionColor = 'gray.800'

                if (form.views > 0) {
                  conversion = Math.min((form.count * 100) / form.views, 100)
                  formattedConversionRate = `${format(conversion)}%`
                  if (conversion >= 5) {
                    conversionColor = 'green.500'
                  } else {
                    conversionColor = 'orange.500'
                  }
                }

                return (
                  <Tr key={form.page_path} role="group" _hover={{ bg: 'gray.50' }}>
                    <Td isTruncated maxW="400px">
                      <Flex flex={1}>
                        <Link
                          overflow={'hidden'}
                          textOverflow="ellipsis"
                          whiteSpace={'nowrap'}
                          maxW="320px"
                          href={formsPath(`/auto-tracked?path=${form.page_path}&range=${props.filters.range}`)}
                        >
                          {form.page_path}
                        </Link>
                      </Flex>
                    </Td>
                    <Td isNumeric fontSize={'xs'}>
                      <Link href={formsPath(`/auto-tracked?path=${form.page_path}`)}>{format(form.views)}</Link>
                    </Td>
                    <Td isNumeric color="purple.500" fontWeight={'semibold'}>
                      <Link href={formsPath(`/auto-tracked?path=${form.page_path}`)}>{format(form.count)}</Link>
                    </Td>
                    <Td isNumeric color={conversionColor} fontWeight="semibold" fontSize={'xs'}>
                      <Link href={formsPath(`/auto-tracked?path=${form.page_path}`)}>{formattedConversionRate}</Link>
                    </Td>
                    <Td color="gray.500">
                      <Link href={formsPath(`/auto-tracked?path=${form.page_path}`)}>
                        <TimeAgo time={form.last_submission} />
                      </Link>
                    </Td>
                    <Td textAlign={'center'}>
                      <ActionsCell form={form} filters={props.filters} />
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      {props.rollup.length > 0 && (
        <TableFooter
          pageMeta={props.page_meta}
          page={props.page_meta.current_page}
          prevPath={formsPath(`reports?page=${props.page_meta.prev_page}&range=${range}`)}
          nextPath={formsPath(`reports?page=${props.page_meta.next_page}&range=${range}`)}
          sticky
        />
      )}
    </PageLayout>
  )
}
