import {
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Img,
  Input,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SkeletonText,
  Stack,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { IconArrowRight, IconChevronDown, IconEdit, IconRefresh } from '@tabler/icons-react'
import { orderBy } from 'lodash'
import { nanoid } from 'nanoid'
import React, { useMemo, useState } from 'react'
import { ComboboxWithSearch } from '../../../../ui/ComboboxWithSearch'
import { TextEllipsis } from '../../../../ui/text-ellipsis'
import { ActionField } from '../../../actions/components/action-field'
import { HubspotDeps, HubspotProp } from '../../../apps/hubspot/show'
import { channelLogos } from '../delivery-setup'
import { Ingredient, variableFieldOptionsByType } from '../slack-message-builder/ingredient-selector'
import { ActionSchema } from '../slack-message-builder/use-action-schema'
import { NotificationVariables, useNotificationVariables } from '../slack-message-builder/use-notification-variables'

interface FieldMapperProps {
  actionSchema: ActionSchema
  deps: HubspotDeps
  type: 'contact' | 'account'
  namespace?: string
  mappings?: HubspotFieldMapping[]
  refetch?: () => void
  loadingDeps?: boolean
}

interface PropertyEntryProps {
  actionSchema: ActionSchema
  layout: HubspotProp[]
  variables: NotificationVariables
  namespace?: string
  id?: string
  type: string
  koalaField?: string
  hubspotField?: string
  koalaOptions: Array<{
    key: string
    label: string
    humanLabel: string
  }>
  mode: 'mapped' | 'hardcoded'
  value?: string
}

interface FieldPreviewProps {
  item: HubspotProp | null
  selectedItem?: HubspotProp | null
}

function FieldPreview(props: FieldPreviewProps) {
  const item = props.item
  const readonly = item?.readonly

  if (!item) {
    return (
      <HStack flex="1" fontSize={'sm'}>
        <Img src={channelLogos.hubspot} w="4" />
        <Text fontSize="sm" fontWeight={'semibold'}>
          Select a field in HubSpot *
        </Text>
      </HStack>
    )
  }

  return (
    <Tooltip
      label={readonly ? 'Read only fields cannot be used in mapping, as you cannot write to them' : undefined}
      placement="right"
    >
      <HStack flex="1" fontSize={'sm'}>
        <Img src={channelLogos.hubspot} w="3" />
        <TextEllipsis maxW={'60'} color={readonly ? 'gray.600' : undefined} fontSize="xs" tooltip>
          {readonly && '(Read only) '}
          {item.label}
        </TextEllipsis>
      </HStack>
    </Tooltip>
  )
}

function PropertyEntry(props: PropertyEntryProps) {
  const [selected, setSelected] = useState<HubspotProp | null>(
    props.layout.find((l) => l.name === props.hubspotField) ?? null
  )

  const [selectedKoala, setSelectedKoala] = useState<Ingredient | null>(
    props.koalaOptions.find((o) => o.key === props.koalaField) ?? null
  )

  const orderedLayout = useMemo(() => {
    let layout = props.layout.filter((l) => !l.hidden)

    // sort alphabetically
    layout = orderBy(layout, (l) => l.label.toLowerCase(), 'asc')

    // then push read only fields to the bottom
    layout = orderBy(layout, (l) => l.readonly, 'asc')

    return layout
  }, [props.layout])

  const hasOptions = useMemo(() => {
    return selected && selected.options && selected.options.length
  }, [selected])

  const inputType = useMemo(() => {
    if (selected?.type === 'number') {
      return 'number'
    }

    if (selected?.field_type === 'date') {
      return 'date'
    }

    if (selected?.type === 'datetime') {
      return 'datetime-local'
    }

    if (hasOptions) {
      return 'text'
    }

    return 'text'
  }, [selected, hasOptions])

  const [hardcodedValue, setHardcodedValue] = useState<string>(props.value ?? props.koalaField ?? '')

  return (
    <Flex w="100%">
      {selected && (
        <>
          {props.mode === 'mapped' && selectedKoala && (
            <input type="hidden" name={`${props.namespace}[fields][][koala]`} value={selectedKoala.key} />
          )}
          {props.mode === 'hardcoded' && hardcodedValue && (
            <input type="hidden" name={`${props.namespace}[fields][][koala]`} value={hardcodedValue} />
          )}
          <input type="hidden" name={`${props.namespace}[fields][][hubspot]`} value={selected.name} />
          <input type="hidden" name={`${props.namespace}[fields][][id]`} value={props.id} />
          <input type="hidden" name={`${props.namespace}[fields][][mode]`} value={props.mode} />
        </>
      )}
      <Stack w="100%">
        <HStack w="100%">
          {(props.mode === 'mapped' || !props.mode) && (
            <ActionField
              schema={props.actionSchema}
              selectedItem={selectedKoala}
              type={props.type ?? 'company'}
              onChange={(item) => {
                const selected = props.koalaOptions.find((o) => o.key === item.key) ?? null
                setSelectedKoala(selected)
              }}
            />
          )}

          {props.mode === 'hardcoded' && (
            <InputGroup>
              <InputLeftAddon>
                <Icon as={IconEdit} />
              </InputLeftAddon>
              <Input
                w="calc(100% - 32px)"
                name={`${props.namespace}[fields][][value]`}
                placeholder={`Enter a hardcoded ${selected?.type === 'number' ? 'number' : 'value'}`}
                borderLeft="none"
                value={hardcodedValue}
                onChange={(e) => setHardcodedValue(e.target.value)}
                fontSize={'sm'}
                required
                list={`options-${selected?.name}`}
                pattern={hasOptions ? selected?.options.map((o) => o.value).join('|') : '.*'}
                type={inputType}
              />
              {hasOptions && (
                <datalist id={`options-${selected?.name}`}>
                  {selected?.options?.map((o) => (
                    <option key={o.value} value={o.value} />
                  ))}
                </datalist>
              )}
            </InputGroup>
          )}

          <ComboboxWithSearch
            items={orderedLayout}
            selectedItem={selected}
            onChange={(item) => {
              const readonly = item && item.readonly
              if (readonly) {
                return false
              }

              setSelected(item)
            }}
            filterItem={(a, val) => a.label.toLowerCase().includes(val)}
            itemToString={(item) => item?.label ?? ''}
            itemRenderer={FieldPreview}
            selectButtonRenderer={FieldPreview}
          />
        </HStack>
      </Stack>
    </Flex>
  )
}

export interface HubspotFieldMapping {
  id?: string
  koala?: string
  hubspot?: string
  mode?: 'mapped' | 'hardcoded'
  value?: string
}

export function FieldMapper(props: FieldMapperProps) {
  const variables = useNotificationVariables()

  const layout = useMemo(() => {
    const opts = props.type === 'contact' ? props.deps.contact_layout : props.deps.company_layout
    return opts?.props.filter((o) => !o.hidden) ?? []
  }, [props.deps, props.type])

  const [mappings, setMappings] = useState<HubspotFieldMapping[]>(props.mappings ?? [])

  const options = useMemo(() => {
    const byType = variableFieldOptionsByType(variables)

    if (props.type === 'contact') {
      return (byType['visitor'] ?? []).concat(byType['signal'] ?? [])
    }

    return (byType['company'] ?? []).concat(byType['account'] ?? []).concat(byType['signal'] ?? [])
  }, [variables, props.type])

  return (
    <Stack fontSize={'sm'} spacing="4">
      <Stack spacing="1">
        <Heading size="xs">Field Mappings</Heading>
        <Text color="gray.500">Define how each Koala field maps to a HubSpot field.</Text>
      </Stack>
      <Stack>
        {variables.isLoading && <SkeletonText noOfLines={Math.max(mappings.length, 2)} />}
        {!variables.isLoading &&
          mappings.map((mapping) => {
            return (
              <HStack key={mapping.id}>
                <PropertyEntry
                  actionSchema={props.actionSchema}
                  id={mapping.id}
                  namespace={props.namespace}
                  variables={variables}
                  layout={layout}
                  koalaOptions={options}
                  type={props.type}
                  koalaField={mapping.koala}
                  hubspotField={mapping.hubspot}
                  mode={mapping.mode ?? 'mapped'}
                  value={mapping.value}
                />
                <Button
                  size="sm"
                  onClick={() => {
                    setMappings(mappings.filter((m) => m.id !== mapping.id))
                  }}
                  variant="ghost"
                  fontSize={'sm'}
                >
                  &times;
                </Button>
              </HStack>
            )
          })}
        <Flex justifyContent={'flex-end'} gap="2">
          <Tooltip label="Refresh the list of properties from HubSpot" placement="top">
            <Button
              variant={'outline'}
              as={Button}
              size="xs"
              leftIcon={<IconRefresh size="12" />}
              onClick={props.refetch}
              isLoading={props.loadingDeps}
            >
              Refresh Properties
            </Button>
          </Tooltip>
          <Menu>
            <MenuButton size="xs" variant={'outline'} as={Button} rightIcon={<IconChevronDown size="12" />}>
              Add field
            </MenuButton>
            <MenuList>
              <MenuItem
                icon={<Icon as={IconArrowRight} />}
                onClick={() => {
                  setMappings([...mappings, { id: nanoid(), mode: 'mapped' }])
                }}
              >
                Mapped Field
              </MenuItem>
              <MenuItem
                icon={<Icon as={IconEdit} />}
                onClick={() => {
                  setMappings([...mappings, { id: nanoid(), mode: 'hardcoded' }])
                }}
              >
                Hardcoded Field
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Stack>
    </Stack>
  )
}
