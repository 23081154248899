import {
  Box,
  BoxProps,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tooltip,
  TooltipProps
} from '@chakra-ui/react'
import { IconHelp, IconInfoCircle } from '@tabler/icons-react'
import React from 'react'

interface HelpTooltipProps extends BoxProps {
  size?: string | number
  color?: string
  mode?: 'tooltip' | 'popover'
  variant?: 'help' | 'info'
  trigger?: React.ReactNode
  placement?: TooltipProps['placement']
}

export function HelpTooltip({
  children,
  mode = 'tooltip',
  variant = 'help',
  trigger,
  size,
  color,
  placement,
  ...props
}: React.PropsWithChildren<HelpTooltipProps>) {
  if (typeof children === 'string' && mode === 'tooltip') {
    return (
      <Tooltip label={children} placement={placement}>
        <Box
          cursor="default"
          display="inline-flex"
          opacity={0.6}
          _hover={{ opacity: 1 }}
          transition="opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
          {...props}
        >
          <Icon as={variant === 'info' ? IconInfoCircle : IconHelp} boxSize={size || 4} color={color || 'gray.500'} />
        </Box>
      </Tooltip>
    )
  }

  return (
    <Popover trigger="hover" isLazy lazyBehavior="unmount" placement={placement}>
      <PopoverTrigger>
        <Box
          cursor="default"
          display="inline-flex"
          verticalAlign="text-bottom"
          marginX={1}
          opacity={0.6}
          _hover={{ opacity: 1 }}
          transition="opacity 0.2s ease-in-out"
          {...props}
        >
          {trigger || (
            <Icon as={variant === 'info' ? IconInfoCircle : IconHelp} boxSize={size || 4} color={color || 'gray.600'} />
          )}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody fontSize="sm" fontWeight="normal" padding={4}>
            {children}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
