import { useQuery, UseQueryOptions } from 'react-query'
import { concurrentCachedGET } from '../../lib/api'

export interface OmnisearchCompany {
  domain: string
  name?: string
  logo?: string
}

interface Data {
  companies: OmnisearchCompany[]
}

export function useCompanySearch(query: string | undefined, opts?: UseQueryOptions<Data>) {
  const path = `/web/companies/autocomplete?query=${query}&all=1`

  return useQuery<Data>(['omnisearch', { query }], () => concurrentCachedGET<any>(path), {
    ...opts,
    enabled: opts?.enabled !== false && Boolean(query)
  })
}
