import { Box } from '@chakra-ui/react'
import React from 'react'
import { Account } from '../../../../../types/Account'
import { VisitorsBreakdown } from '../VisitorsBreakdown'

interface Props {
  account: Account
}

export function Visitors(props: Props) {
  return (
    <Box position="sticky" top={0} minHeight={['100vh', 'auto']}>
      <VisitorsBreakdown {...props.account} />
    </Box>
  )
}
