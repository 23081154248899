import {
  Box,
  Circle,
  Flex,
  HStack,
  Icon,
  Link,
  Progress,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { IconArrowRight, IconArrowUpRight } from '@tabler/icons-react'
import throttle from 'lodash/throttle'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { statusColor, statusLabel } from '.'
import { subscribeToChannel, SubscriptionEmitter } from '../../../channels/generic_channel'
import { concurrentGET } from '../../../lib/api'
import { ImportList } from '../../../types/Imports'
import { PageMeta } from '../../../types/PageMeta'
import { ProfileRecord } from '../../../types/Profile'
import { Breadcrumb } from '../../ui/Breadcrumb'
import { Card } from '../../ui/Card'
import CompanyAvatar from '../../ui/CompanyAvatar'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath, useCurrentProject } from '../../ui/ProjectsContext'
import { TableFooter } from '../../ui/TableFooter'
import { TimeAgo } from '../../ui/TimeAgo'
import { TopBarContent } from '../../ui/TopBarContext'
import useLatestRef from '../../ui/useLatestRef'
import { accountPath } from '../accounts/lib/account-path'
import { mergeParams } from '../icps/types'
import { ImportType } from './components/ImportType'

interface Props {
  import_list: ImportList
  done: ProfileRecord[]
  failed: string[]
  page_meta: PageMeta
}

export default function Show(props: Props) {
  const [importList, setImportList] = useState(props.import_list)
  const [items, setItems] = useState(props.done)
  const [pageMeta, setPageMeta] = useState(props.page_meta)

  const location = useLatestRef(window.location.toString())
  const subscription = useRef<SubscriptionEmitter>()

  const refresh = React.useCallback(() => {
    concurrentGET<Props>(projectPath(`/imports/${importList.id}.json`)).then((data) => {
      setImportList((importList) => ({
        ...importList,
        ...data.import_list,
        total_complete: Math.max(data.import_list.total_complete ?? 0, importList.total_complete)
      }))
      setItems(data.done)
      setPageMeta(data.page_meta)
    })
  }, [importList.id])

  const debouncedRefresh = useMemo(() => throttle(refresh, 3_000), [refresh])

  const onUpdate = React.useCallback(
    (update: { action: string; total_complete?: number }) => {
      if (update.total_complete) {
        setImportList((importList) => ({
          ...importList,
          total_complete: Math.max(update.total_complete ?? 0, importList.total_complete)
        }))
      }
      debouncedRefresh()
    },
    [debouncedRefresh]
  )

  const project = useCurrentProject()

  useEffect(() => {
    setImportList((importList) => ({
      ...importList,
      ...props.import_list,
      total_complete: Math.max(props.import_list.total_complete ?? 0, importList.total_complete)
    }))
    setItems(props.done)
    setPageMeta(props.page_meta)
  }, [props])

  useEffect(() => {
    if (!project) {
      return
    }

    if (subscription.current) {
      return
    }

    subscription.current = subscribeToChannel({
      channel: 'ImportListsChannel',
      id: importList.id,
      project_slug: project.slug
    })

    subscription.current?.on('received', onUpdate)

    return () => {
      subscription.current?.off('received', onUpdate)
      subscription.current?.unsubscribe()
      subscription.current = undefined
      debouncedRefresh.cancel()
    }
  }, [importList.id, onUpdate, project, debouncedRefresh])

  return (
    <PageLayout>
      <PageTitle skipRendering>Import | {importList.name}</PageTitle>

      <TopBarContent>
        <Flex w="100%" justifyContent="space-between" alignItems="center" gap={4} pr={4}>
          <Flex flex="1 1 auto" alignItems="center" gap={3}>
            <Breadcrumb
              paths={[
                { path: projectPath('/imports'), title: 'Imports' },
                { path: projectPath(`/imports/${importList.slug}`), title: importList.name }
              ]}
            />

            <ImportType kind={importList.kind} />
          </Flex>

          {importList.account_view && (
            <Link href={projectPath(`/views/${importList.account_view.slug}`)} variant="dotted" fontSize="sm">
              View List
              <IconArrowUpRight size={15} />
            </Link>
          )}
          {importList.static_list_id && (
            <Link href={projectPath(`/lists/${importList.static_list_id}`)} variant="dotted" fontSize="sm">
              View List
              <IconArrowUpRight size={15} />
            </Link>
          )}
        </Flex>
      </TopBarContent>

      <Stack spacing="8">
        <Card px={4} py={4}>
          <Stack spacing={2.5}>
            <Flex fontSize="sm" fontWeight="medium" alignItems="center" justifyContent="space-between">
              <Text>
                Import {statusLabel(importList.status)}{' '}
                <Text as="span" color="gray.500" fontWeight="normal">
                  (<TimeAgo time={importList.status === 'done' ? importList.updated_at : importList.created_at} />)
                </Text>
              </Text>

              <Text css={{ fontVariantNumeric: 'tabular-nums' }}>
                {new Intl.NumberFormat('en-US').format(importList.total_complete)}{' '}
                <Text as="span" color="gray.400">
                  / {new Intl.NumberFormat('en-US').format(importList.total_items)}
                </Text>
              </Text>
            </Flex>
            <Progress
              size="xs"
              value={importList.total_complete}
              max={importList.total_items}
              rounded="md"
              isIndeterminate={!importList.total_complete && ['pending', 'started'].includes(importList.status)}
              hasStripe={importList.status === 'started'}
              colorScheme={statusColor(importList.status)}
            />
          </Stack>
        </Card>

        {items.length > 0 && (
          <Box>
            <Tabs size="sm" variant="line" colorScheme="gray">
              <TabList marginBottom="-1px">
                <Tab gap={1}>
                  Completed
                  <Text color="gray.400">
                    ({new Intl.NumberFormat('en-US').format(importList.counts?.completed || 0)})
                  </Text>
                </Tab>
                <Tab gap={1}>
                  Failed
                  <Text color="gray.400">
                    ({new Intl.NumberFormat('en-US').format(importList.counts?.failed || 0)})
                  </Text>
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel p={0}>
                  {importList.kind === 'accounts' ? <AccountsTable items={items} /> : <ProfilesTable items={items} />}
                </TabPanel>
                <TabPanel p={0}>
                  <FailedTable items={props.failed} />
                </TabPanel>
              </TabPanels>
            </Tabs>

            <TableFooter
              pageMeta={pageMeta}
              page={pageMeta.current_page}
              nextPath={mergeParams(location.current, {
                page: pageMeta.next_page?.toString()
              })}
              prevPath={mergeParams(location.current, {
                page: pageMeta.prev_page?.toString()
              })}
            />
          </Box>
        )}
      </Stack>
    </PageLayout>
  )
}

function FailedTable(props) {
  const items = props.items

  return (
    <TableContainer>
      <Table size="sm" height="1px" width="100%">
        <Thead>
          <Tr>
            <Th height="38px">Domain</Th>
          </Tr>
        </Thead>

        <Tbody bg="white">
          {items.map((item, index) => (
            <Tr
              key={item + index}
              role="group"
              _hover={{
                bg: 'gray.50'
              }}
            >
              <Td height="43px">{item}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

function AccountsTable(props) {
  const items = props.items

  return (
    <TableContainer>
      <Table variant="bordered" size="sm" height="1px" width="100%">
        <Thead>
          <Tr>
            <Th height="38px">Company</Th>
            <Th>Domain</Th>
          </Tr>
        </Thead>

        <Tbody bg="white">
          {items.map((item) => (
            <Tr
              key={item.id}
              role="group"
              _hover={{
                bg: 'gray.50'
              }}
            >
              <Td>
                <HStack paddingY={1}>
                  <CompanyAvatar size="tiny" name={item.company?.name} domain={item.company?.domain} />
                  <Link href={accountPath(item)} isExternal>
                    {item.company?.name || item.company?.domain}
                  </Link>
                </HStack>
              </Td>

              <Td>
                <Link href={item.company?.domain ? `https://${item.company?.domain}` : ''} variant="dotted" isExternal>
                  {item.company?.domain}
                  <IconArrowUpRight size={14} />
                </Link>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

function ProfilesTable(props) {
  const items = props.items

  return (
    <TableContainer>
      <Table variant="bordered" size="sm" w="100%" h="1px">
        <Thead>
          <Tr>
            <Th height="38px">Name</Th>
            <Th>Email</Th>
            <Th>Title</Th>
            <Th>Company</Th>
          </Tr>
        </Thead>

        <Tbody bg="white">
          {items.map((item) => (
            <Tr
              key={item.id}
              role="group"
              _hover={{
                bg: 'gray.50'
              }}
            >
              <Td isTruncated maxW="200px">
                <Stack spacing={0.5} flex="1" alignItems="flex-start">
                  {item.name || item.display_name ? (
                    <Text fontSize="sm" fontWeight="medium" isTruncated maxWidth="100%">
                      {item.name || item.display_name}
                    </Text>
                  ) : (
                    <Text fontSize="sm" color="gray.400">
                      &mdash;
                    </Text>
                  )}
                </Stack>
              </Td>
              <Td>
                <Text fontWeight="medium">{item.email}</Text>
              </Td>
              <Td>
                <Text color={item.title ? 'gray.600' : 'gray.400'}>{item.title || '—'}</Text>
              </Td>
              <Td>
                <Box width="200px">
                  <Flex
                    as="a"
                    href={accountPath(item)}
                    target="_blank"
                    display="inline-flex"
                    alignItems="center"
                    gap={1}
                    width="auto"
                    maxW="100%"
                    overflow="hidden"
                    rounded="md"
                    paddingLeft={1}
                    paddingRight={1.5}
                    paddingY={1}
                    marginX={-1}
                    title={item.company?.name || item.company?.domain}
                    _hover={{
                      background: 'white',
                      shadow: 'sm',
                      '& .hover-icon': {
                        display: 'flex',
                        opacity: 1
                      }
                    }}
                  >
                    <CompanyAvatar size="tiny" rounded="base" name={item.company?.name} domain={item.company?.domain} />
                    <Text flex="1 1 auto" maxWidth="100%" fontSize="sm" fontWeight="medium" isTruncated>
                      {item.company?.name || item.company?.domain}
                    </Text>
                    <Circle flex="none" className="hover-icon" display="none" opacity={0} size="18px" bg="gray.100">
                      <Icon as={IconArrowRight} boxSize={3.5} color="gray.600" />
                    </Circle>
                  </Flex>
                </Box>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
