import React from 'react'
import { Account } from '@app/types/Account'
import { useAccountEvents } from '@app/components/data/use-account-events'
import { JSONTree } from '@app/components/ui/json-tree'
import { TimeAgo } from '@app/components/ui/TimeAgo'
import { DetailsCard } from '@app/components/ui/Card'
import CompanyAvatar from '@app/components/ui/CompanyAvatar'
import { AccountEvent } from '@app/types/AccountEvent'

import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Skeleton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  IconButton,
  Stack,
  Text
} from '@chakra-ui/react'

import { IconSearch } from '@tabler/icons-react'

interface Props {
  account: Account
}

export function AccountEvents(props: Props) {
  const { data: events, isLoading } = useAccountEvents({
    accountId: props.account.domain
  })

  if (isLoading) {
    return (
      <Skeleton>
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
      </Skeleton>
    )
  }

  return (
    <DetailsCard>
      <TableContainer>
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Source</Th>
              <Th>Event</Th>
              <Th>Event Type</Th>
              <Th>Timestamp</Th>
              <Th>Properties</Th>
            </Tr>
          </Thead>
          <Tbody>
            {events?.data?.map((event) => (
              <Tr key={event.id}>
                <Td>
                  <EventSourceDisplay source={event.source} />
                </Td>
                <Td>
                  <HydrateEventName event={event} />
                </Td>
                <Td>{event.event_type}</Td>
                <Td>
                  <TimeAgo time={event.timestamp} />
                </Td>
                <Td>
                  <Popover>
                    <PopoverTrigger>
                      <IconButton icon={<IconSearch size="15" />} size="xs" variant="ghost" aria-label="See More" />
                    </PopoverTrigger>
                    <PopoverContent maxW="600px" minW={{ base: '100%', lg: 'max-content' }}>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Stack px="2" pr="4" spacing="3" fontWeight={'normal'} fontSize="xs">
                          <JSONTree data={event.properties} />
                        </Stack>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </DetailsCard>
  )
}

export function EventSourceDisplay({ source }: { source: string }) {
  return <>{source === 'g2' ? <CompanyAvatar size="20px" name="G2" domain="g2.com" /> : source}</>
}

export function HydrateEventName({ event }: { event: AccountEvent }) {
  if (!event.metadata?.event_excerpt) {
    return <Text>{event.event}</Text>
  }

  const event_excerpt = event.metadata?.event_excerpt

  return (
    <>
      {event_excerpt.length > 0 ? (
        <Text>
          {event.event}: {event_excerpt}
        </Text>
      ) : (
        <Text>{event.event}</Text>
      )}
    </>
  )
}
