import { Stack, Heading } from '@chakra-ui/react'

import React from 'react'

import { AdminBreadcrumb } from '../..'
import PageLayout from '../../../../ui/PageLayout'
import PageTitle from '../../../../ui/PageTitle'

import { LineGraph } from '../../../analytics/components/LineGraph'

export default function Show(props: any) {
  return (
    <PageLayout>
      <AdminBreadcrumb paths={[{ path: '/admin/reports/event-volume', title: 'Event Volume' }]} />
      <PageTitle>Event Volume</PageTitle>
      <Stack spacing="12">
        <Heading size="md">Page Views</Heading>
        <LineGraph label="Total" period={'quarter'} data={props.pvs} />
        <Heading size="md">Custom Events</Heading>
        <LineGraph label="Total" period={'quarter'} data={props.evs} />
        <Heading size="md">Form Submissions</Heading>
        <LineGraph label="Total" period={'quarter'} data={props.fss} />
        <Heading size="md">KQL Events</Heading>
        <LineGraph label="Total" period={'quarter'} data={props.kql} />
        <Heading size="md">Auto Outbound Logs</Heading>
        <LineGraph label="Total" period={'quarter'} data={props.aol} />
      </Stack>
    </PageLayout>
  )
}
