import { Box, Flex, Input, InputProps, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import React, { ChangeEvent, ClipboardEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'

interface TagsInputProps {
  initialTags?: string[]
  onChange: (tags: string[]) => void
  onRemove?: (tag: string) => void
  colorScheme?: string
  placeholder?: string
  inputProps?: InputProps
}

interface SortableTagProps {
  tag: string
  onRemove: () => void
  colorScheme: string
}

const SortableTag: React.FC<SortableTagProps> = ({ tag, onRemove, colorScheme }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: tag })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <Tag
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      size="sm"
      variant="subtle"
      colorScheme={colorScheme}
      tabIndex={0}
      mx={0.5}
      mt={0.5}
      mb={1}
      cursor="move"
    >
      <TagLabel>{tag}</TagLabel>
      <TagCloseButton
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          onRemove()
        }}
        cursor="pointer"
        onMouseDown={(e) => {
          e.stopPropagation()
        }}
        onTouchStart={(e) => {
          e.stopPropagation()
        }}
      />
    </Tag>
  )
}

export const TagsInput: React.FC<TagsInputProps> = ({
  initialTags = [],
  onChange,
  onRemove,
  colorScheme = 'purple',
  placeholder,
  inputProps = {}
}) => {
  const [tags, setTags] = useState<string[]>(initialTags)
  const [inputValue, setInputValue] = useState<string>('')
  const [activeId, setActiveId] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  useEffect(() => {
    setTags(initialTags)
  }, [initialTags])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const addTag = (tag: string) => {
    if (tag.trim() && !tags.includes(tag.trim())) {
      const newTags = [...tags, tag.trim()]
      setTags(newTags)
      onChange(newTags)
    }
  }

  const handleInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab' || e.key === ',') {
      e.preventDefault()
      if (inputValue.trim()) {
        addTag(inputValue.trim())
        setInputValue('')
      }
    }
  }

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const pastedText = e.clipboardData.getData('text')
    const pastedTags = pastedText
      .split(/[,\n]/)
      .map((tag) => tag.trim())
      .filter(Boolean)
    const newTags = [...tags, ...pastedTags.filter((tag) => !tags.includes(tag))]
    setTags(newTags)
    onChange(newTags)
    setInputValue('')
  }

  const removeTag = (index: number) => {
    const newTags = tags.filter((_, i) => i !== index)
    setTags(newTags)
    onRemove?.(tags[index])
    onChange(newTags)
  }

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string)
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      setTags((items) => {
        const oldIndex = items.indexOf(active.id as string)
        const newIndex = items.indexOf(over?.id as string)

        const newTags = arrayMove(items, oldIndex, newIndex)
        onChange(newTags)
        return newTags
      })
    }
    setActiveId(null)
  }

  return (
    <Box border="1px solid" borderColor="gray.300" borderRadius="md" p={1.5} w="100%">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={tags} strategy={horizontalListSortingStrategy}>
          <Flex minHeight="20px" alignItems="flex-start" flexWrap="wrap">
            {tags.map((tag, index) => (
              <SortableTag key={tag} tag={tag} onRemove={() => removeTag(index)} colorScheme={colorScheme} />
            ))}
          </Flex>
        </SortableContext>
        <DragOverlay>
          {activeId ? (
            <Tag size="sm" variant="subtle" colorScheme={colorScheme} opacity="0.8">
              <TagLabel>{activeId}</TagLabel>
              <TagCloseButton isDisabled />
            </Tag>
          ) : null}
        </DragOverlay>
      </DndContext>
      <Input
        ref={inputRef}
        size="sm"
        variant="unstyled"
        w="100%"
        mt={2}
        px={1.5}
        fontSize="13px"
        fontWeight="medium"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        onPaste={handlePaste}
        placeholder={placeholder ?? 'Add a tag'}
        onBlur={() => {
          if (inputValue.trim()) {
            addTag(inputValue.trim())
            setInputValue('')
          }
        }}
        backgroundColor={inputValue.trim() ? `${colorScheme}.100` : 'transparent'}
        {...inputProps}
      />
    </Box>
  )
}

export default TagsInput
