import { Badge, HStack, IconButton, StackProps, Text, UseDisclosureReturn } from '@chakra-ui/react'
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { Iconify, IconifyProps } from './Iconify'

export interface CardHeadingProps extends StackProps {
  icon: IconifyProps['icon']
  count?: number
  disclosure?: UseDisclosureReturn
}

export function CardHeading({ children, count, disclosure, icon, ...rest }: CardHeadingProps) {
  // This makes the entire header clickable, but doesn't bubble the click if a sub-link was clicked
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!disclosure && !rest.onClick) {
        return true
      }

      const targetAnchor = (event.target as HTMLElement).closest('a')
      if (targetAnchor !== event.currentTarget && event.currentTarget.contains(targetAnchor)) {
        return true
      }

      if (!event.defaultPrevented) {
        disclosure?.onToggle()
      }

      rest.onClick?.(event as React.MouseEvent<HTMLDivElement>)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rest.onClick, disclosure?.onToggle]
  )

  return (
    <HStack
      spacing={3}
      marginRight={disclosure ? -2 : undefined}
      cursor={disclosure || rest.onClick ? 'pointer' : undefined}
      {...rest}
      onClick={onClick}
    >
      <HStack flex="1" spacing={2} fontSize="sm" fontWeight="semibold">
        <Iconify icon={icon} size={20} />
        {typeof children === 'string' ? <Text>{children}</Text> : children}
        {typeof count === 'number' && (
          <Badge variant="pill" flex="none" fontWeight="medium">
            {count.toLocaleString()}
          </Badge>
        )}
      </HStack>
      {disclosure && (
        <IconButton
          aria-label="Expand/collapse"
          variant="ghost"
          color="gray.500"
          _hover={{ color: 'gray.700' }}
          icon={disclosure.isOpen ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />}
          size="xs"
        />
      )}
    </HStack>
  )
}
