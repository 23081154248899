import {
  Button,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Link,
  Stack,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { IconArrowRight, IconExternalLink, IconRefresh } from '@tabler/icons-react'
import React, { useCallback, useMemo } from 'react'
import { toast } from 'sonner'
import type { Account, CRMMatch } from '../../../../types/Account'
import type { App } from '../../../../types/App'
import { AppActions } from '../../../../types/AppActions'
import { DetailsCard } from '../../../ui/Card'
import { CardHeading } from '../../../ui/CardHeading'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { projectPath } from '../../../ui/ProjectsContext'
import { StackedField } from '../../../ui/StackedField'
import { TimeAgo } from '../../../ui/TimeAgo'
import useFuzzyCrmMatches from '../../../ui/useFuzzyCrmMatches'
import { useCurrentUser } from '../../../ui/UserContext'
import { humanize } from '../facets/filter-cloud'
import { AssignCrmOwnerModal } from './AssignCrmOwnerModal'
import { CRMDebugPanel } from './CrmProperties'
import { useUIState } from './DetailsV2/useUIState'

interface HubSpotCardProps {
  app?: App
  account?: Account
  actions?: AppActions
  match?: CRMMatch
  crmEntity?: CRMMatch['crm_entity']
  initiallyCollapsed?: boolean
  onRequestImport?: (app: App) => void
}

export function HubSpotCard(props: HubSpotCardProps) {
  const user = useCurrentUser()
  const [ui, setUI] = useUIState()
  const claimDisclosure = useDisclosure()
  const cardDisclosure = useDisclosure({
    defaultIsOpen: ui.open?.hubspot ?? !props.initiallyCollapsed,
    onOpen: () => {
      setUI({ open: { hubspot: true } })
    },
    onClose: () => {
      setUI({ open: { hubspot: false } })
    }
  })

  const [lastRefreshed, setLastRefreshed] = React.useState<Date | null>(null)
  const onRefetch = useCallback(() => {
    toast(
      'HubSpot company details updated. Please note that it may take a few moments for the changes to take effect on your searches and filters'
    )
  }, [])

  const crmMatches = useFuzzyCrmMatches({
    account: props.account,
    app: props.app,
    fuzzy: false
  })

  const crmEntity = useMemo(() => {
    return crmMatches.mainCrmMatch?.crm_entity
  }, [crmMatches])

  const match = useMemo(() => {
    return crmMatches.mainCrmMatch
  }, [crmMatches])

  const refresh = useCallback(
    (callRefetchCallback = true) => {
      crmMatches
        .refetch({
          shouldReset: false,
          hardRefresh: true
        })
        ?.then(() => {
          setLastRefreshed(new Date())
          if (callRefetchCallback) {
            onRefetch()
          }
        })
    },
    [crmMatches, onRefetch]
  )

  return (
    <DetailsCard>
      <CardHeading icon="https://cdn.cdnlogo.com/logos/h/24/hubspot.svg" disclosure={cardDisclosure}>
        <HStack spacing="1" justifyContent="space-between">
          <Text>HubSpot Company</Text>
          {props.app?.connected && (
            <Tooltip label="Refresh HubSpot record" placement="top" hasArrow arrowSize={6}>
              <IconButton
                variant="ghost"
                size="xs"
                aria-label="Refresh"
                icon={<IconRefresh size={16} />}
                color="gray.500"
                _hover={{ color: 'purple.600' }}
                isLoading={crmMatches.isLoading}
                onClick={(e) => {
                  e.preventDefault()
                  refresh()
                }}
              />
            </Tooltip>
          )}
          {crmEntity?.permalink && (
            <Tooltip label="View in HubSpot" placement="top" hasArrow arrowSize={6}>
              <IconButton
                aria-label="View in HubSpot"
                as={Link}
                href={crmEntity.permalink}
                isExternal
                size="xs"
                variant="link"
                icon={<IconExternalLink size={16} />}
                color="gray.500"
                _hover={{ color: 'purple.600' }}
                onClick={() => {
                  window.ko?.track('Hubspot Visit Action', {
                    app: 'hubspot',
                    company: crmEntity
                  })
                }}
              />
            </Tooltip>
          )}
        </HStack>
      </CardHeading>
      {!cardDisclosure.isOpen ? null : crmEntity ? (
        <Stack spacing={4}>
          <Grid templateColumns="repeat(2, 1fr)" gap={4}>
            <StackedField label="Name">{crmEntity.name}</StackedField>
            {crmEntity.account_type && <StackedField label="Type">{humanize(crmEntity.account_type)}</StackedField>}
            {crmEntity.payload?.lifecyclestage && (
              <StackedField label="Lifecycle Stage">{humanize(crmEntity.payload.lifecyclestage)}</StackedField>
            )}
            <StackedField label="Account Owner">{crmEntity.account_owner}</StackedField>
            <StackedField label="Deal Stage">{crmEntity.stage_name?.join(',')}</StackedField>
            <StackedField label="Last Modified">
              <TimeAgo time={crmEntity.external_last_modified_at} mode="calendar" />
            </StackedField>

            <StackedField label="Last Successful Sync">
              <TimeAgo time={lastRefreshed ?? crmEntity.last_finished_integration_sync?.stopped_at} mode="calendar" />
              {(lastRefreshed || crmEntity.last_finished_integration_sync) && (
                <HelpTooltip>
                  <Stack>
                    <Heading size="xs">Last Successful Sync</Heading>
                    <Text>
                      Koala last pulled your HubSpot account for changes{' '}
                      <TimeAgo time={lastRefreshed ?? crmEntity.last_finished_integration_sync?.started_at} />.
                    </Text>
                  </Stack>
                </HelpTooltip>
              )}
            </StackedField>
          </Grid>
          {props.actions?.hs_claim_in_crm?.enabled && match && user.name != match?.crm_entity.account_owner && (
            <>
              <Button variant="outline" width="full" size="sm" onClick={claimDisclosure.onOpen}>
                Claim in HubSpot…
              </Button>
              <AssignCrmOwnerModal
                {...claimDisclosure}
                app={props.app}
                account={match}
                appModule="Apps::Hubspot::App"
                onSuccess={() => {
                  refresh(false)
                }}
              />
            </>
          )}

          <CRMDebugPanel crmEntity={crmEntity} strongMatches={crmMatches.strongMatches} />
        </Stack>
      ) : props.app?.connected ? (
        <Stack>
          <Text fontSize="xs" color="gray.500">
            No companies linked yet
          </Text>
          {props.actions?.hs_import_accounts?.enabled && props.onRequestImport ? (
            <Button
              variant="outline"
              width="full"
              size="sm"
              onClick={() => {
                window.ko?.track('Hubspot Import Action', {
                  app: 'hubspot',
                  company: props.crmEntity
                })
                props.onRequestImport?.(props.app!)
              }}
            >
              Create company…
            </Button>
          ) : // TODO stuff for enabling the write action
          null}
        </Stack>
      ) : (
        <>
          <Text fontSize="xs" color="gray.600">
            Connect the HubSpot integration to see details from HubSpot or sync data from Koala.
          </Text>
          <Button
            alignSelf="flex-start"
            size="sm"
            variant="outline"
            as="a"
            href={projectPath(`/apps/hubspot?return_to=${encodeURIComponent(window.location.pathname)}`)}
            iconSpacing={1}
            rightIcon={<Icon as={IconArrowRight} boxSize={4} />}
          >
            Connect
          </Button>
        </>
      )}
    </DetailsCard>
  )
}
