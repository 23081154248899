import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Link,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Tag,
  Text
} from '@chakra-ui/react'
import { IconCheck, IconExternalLink } from '@tabler/icons-react'
import React, { useMemo, useState } from 'react'
import { BTFlow } from '../../ui/billing-banners/accounts-banner'
import { useCurrentProject } from '../../ui/ProjectsContext'
import { SegmentedControl } from '../../ui/SegmentedControl'
import { billingCheckoutPath, billingManagePath } from './lib/path-helpers'
import { friendlyNumber, KoalaSubscription, Plan } from './show'
import { freePlanLimits, teamPrices } from './v2'

interface PlanCardProps {
  title: string
  discount?: number
  subtitle?: React.ReactNode
  subline?: React.ReactNode
  description?: React.ReactNode
  cta?: React.ReactNode
  isCurrent?: boolean
  features?: React.ReactNode
  isTrialing?: boolean
  onCTAClicked?: () => void
  disabled?: boolean
}

export const PlanCard = (props: PlanCardProps) => {
  return (
    <Flex
      flex="1"
      direction="column"
      w="100%"
      borderWidth="1px"
      rounded="md"
      position="relative"
      shadow="sm"
      borderColor="gray.200"
      justifyContent="space-between"
      flexWrap="wrap"
      minW="282px"
    >
      <Stack spacing={4} p="6">
        <HStack>
          <Heading size="lg">{props.title} </Heading>
          {props.discount ? (
            <Tag colorScheme="green" variant="subtle" lineHeight={1}>
              {props.discount}% off
            </Tag>
          ) : null}
        </HStack>

        <Text pb="4" h="80px" fontSize="sm" color="gray.600">
          {props.description}
        </Text>

        <Stack>
          {props.subtitle}
          <Flex fontSize="xs" color="gray.500">
            {props.subline}
          </Flex>
        </Stack>

        {props.isCurrent ? (
          <Button variant="solid" colorScheme="gray" isDisabled cursor="auto">
            Your current plan
          </Button>
        ) : (
          <Button
            onClick={props.onCTAClicked}
            variant="solid"
            colorScheme={props.title === 'Free' ? undefined : 'purple'}
            isDisabled={props.disabled}
          >
            {props.cta}
          </Button>
        )}

        {props.features}
      </Stack>

      <Center py={4} borderTop="1px solid" borderColor="gray.100">
        <Link variant="dotted" fontSize="sm" fontWeight="medium" href="https://getkoala.com/pricing" isExternal>
          Compare all features
          <IconExternalLink size={14} />
        </Link>
      </Center>
    </Flex>
  )
}

export interface PlanData {
  title: string
  subtitle: React.ReactNode
  annualSubtitle?: React.ReactNode
  description: string
  costPerUser?: number | 'custom'
  basePrice?: number | 'custom'
  annualMonthlyPrice?: number | 'custom'
  includedSeats?: number
  features: string[]
}

export const plansV1: Record<'free' | 'team' | 'business', PlanData> = {
  free: {
    title: 'Free',
    subtitle: 'Free forever',
    description: 'For individual sales reps.',
    costPerUser: 0,
    basePrice: 0,
    features: [
      'Up to 500 Monthly Active Accounts',
      'See 7 days of historical intent',
      'Read from HubSpot, Salesforce, and Outreach',
      'Enrich IP data with Clearbit Reveal',
      'Capture pageviews, session time, and create custom signals',
      'Real-time Slack notifications',
      'Account Scoring',
      'Weekly Email digests'
    ]
  },
  team: {
    title: 'Team',
    subtitle: '30-day free trial · 5 seats included · then $15 per seat/month',
    description: 'For SDRs, AEs, and small teams looking to find the high-intent accounts',
    includedSeats: 5,
    costPerUser: 15,
    basePrice: 299,
    features: [
      'Up to 2,500 Monthly Active Accounts',
      'See 30 days of historical intent',
      '5 seats included (extra seats: $15 per user per month)'
    ]
  },
  business: {
    title: 'Business',
    subtitle: 'Billed yearly · 5 seats included',
    description: 'For teams that want to standardize their workflows with Koala intent data',
    includedSeats: 5,
    costPerUser: 15,
    basePrice: 415,
    features: [
      'Custom Monthly Active Accounts',
      'See 90 days of historical intent',
      'Custom seats',
      'Webhooks and CRM Automation',
      'Salesforce Canvas App',
      'Content Reports'
    ]
  }
}

export const plansV2: Record<'free' | 'team' | 'business', PlanData> = {
  free: {
    title: 'Free',
    subtitle: 'Free forever',
    description: 'For early stage companies figuring out Product Market Fit.',
    costPerUser: 0,
    basePrice: 0,
    features: [
      `Up to ${freePlanLimits.accounts} Koala Accounts`,
      `Up to ${freePlanLimits.members} Workspace Members`,
      `Up to ${friendlyNumber(freePlanLimits.events)} Events`,
      `See ${freePlanLimits.retention} days of historical intent`,
      'Read from HubSpot, Salesforce, and Outreach',
      'Enrich IP data with Clearbit Reveal',
      'Capture pageviews, session time, and create custom signals',
      'Real-time Slack notifications',
      'Account Scoring',
      'Weekly Email digests'
    ]
  },
  team: {
    title: 'Team',
    subtitle: '3 seats included · then $15 per seat/month',
    description: 'For Series A or B companies looking to find the high-intent accounts',
    includedSeats: 3,
    costPerUser: 15,
    basePrice: 175,
    features: [
      `Unlimited Koala Accounts. ${friendlyNumber(teamPrices.account_pack.included)} included.`,
      `Unlimited Workspace Members ${friendlyNumber(teamPrices.member.included)} included.`,
      `Unlimited Events ${friendlyNumber(teamPrices.event_pack.included)} included.`,
      'See 30 days of historical intent'
    ]
  },
  business: {
    title: 'Business',
    subtitle: 'Billed yearly · 5 seats included',
    description: 'For teams that want to standardize their workflows with Koala intent data',
    includedSeats: 5,
    costPerUser: 15,
    basePrice: 415,
    features: [
      'Custom limits on Koala Accounts, Workspace Members, or Events',
      'See 90+ days of historical intent',
      'CRM Automation',
      'Salesforce Canvas App',
      'Content Reports'
    ]
  }
}

export const plansV3: Record<'free' | 'pro' | 'starter' | 'starter_plus' | 'business', PlanData> = {
  free: {
    title: 'Free',
    subtitle: 'Free forever',
    description: 'For early stage companies figuring out Product Market Fit.',
    costPerUser: 0,
    basePrice: 0,
    features: [
      `Up to ${freePlanLimits.accounts} Koala Accounts`,
      `Up to ${freePlanLimits.members} Workspace Members`,
      `Up to ${friendlyNumber(freePlanLimits.events)} Events`,
      `See ${freePlanLimits.retention} days of historical intent`,
      `Up to 250 Slack Alerts per month`,
      'Read from HubSpot or Salesforce',
      'Enrich IP data with Clearbit Reveal',
      'Capture pageviews, session time, and create custom signals',
      'Real-time Slack Alerts',
      'Weekly Email digests',
      'Web Analytics (past 30 days)'
    ]
  },
  starter: {
    title: 'Starter',
    subtitle: '3 seats included · $30 per seat/month',
    annualSubtitle: '3 seats included · $300 per seat/year',
    description: 'Follow your intent signals and get notified in Slack when accounts are showing intent',
    includedSeats: 3,
    costPerUser: 30,
    basePrice: 500,
    annualMonthlyPrice: 350,
    features: [
      'Includes 3 seats',
      'Unlimited Accounts',
      'Unlimited Slack Alerts',
      'See 90+ days of historical intent',
      'Actions trial credits',
      'Auto Outbound trial credits',
      'Web Analytics (past 30 days)',
      '10k Clearbit Reveal Credits per month'
    ]
  },
  starter_plus: {
    title: 'Starter Plus',
    subtitle: '3 seats included · $300 per seat/year',
    annualSubtitle: '3 seats included · $300 per seat/year',
    description: 'Follow your intent signals and get notified in Slack when accounts are showing intent',
    includedSeats: 3,
    costPerUser: 30,
    annualMonthlyPrice: 750,
    features: [
      'Includes 3 seats',
      'Unlimited Accounts',
      'Unlimited Slack Alerts',
      'See 90+ days of historical intent',
      '10k Actions credits',
      'Auto Outbound trial credits',
      'Web Analytics (past 30 days)',
      '10k Clearbit Reveal Credits per month'
    ]
  },
  pro: {
    title: 'Pro',
    subtitle: '10 seats included · $30 per seat/month',
    annualSubtitle: '10 seats included · $300 per seat/year',
    description: 'Unlock Automated Outbound, Actions and scoring that is custom to your business.',
    includedSeats: 10,
    costPerUser: 30,
    basePrice: 1000,
    annualMonthlyPrice: 800,
    features: [
      'Includes 10 seats',
      '1k Automated Outbound credits',
      '10k Actions credits',
      'Custom Fit Scoring',
      'Custom Intent Scoring',
      'Web Analytics (Custom reports)',
      'CSV Exports',
      'Pipeline Influence Reporting',
      'Content Reports',
      '25k Clearbit Reveal Credits per month'
    ]
  },
  business: {
    title: 'Business',
    subtitle: 'Billed yearly · custom seats',
    description: 'For teams that want to standardize their workflows with Koala intent data',
    includedSeats: 10,
    costPerUser: 'custom',
    basePrice: 'custom',
    annualMonthlyPrice: 'custom',
    features: [
      'Includes 10 seats',
      'See 90+ days of historical intent',
      'Custom limits for Actions',
      'Custom limits for Auto Outbound',
      'Custom Fit Scoring',
      'Custom Intent Scoring',
      'Web Analytics (Custom reports)',
      'CSV Exports',
      'Pipeline Influence Reporting',
      'Content Reports',
      'Custom Clearbit Reveal Credits per month'
    ]
  }
}

interface Props {
  previouslyTrialed?: boolean
  isTrialing: boolean
  currentPlan?: Plan
  koalaSubscription: KoalaSubscription
}

function calculatePercentOff(regularPrice, discountPrice) {
  return Math.round(((regularPrice - discountPrice) / regularPrice) * 100)
}

export function PricingTable(props: Props) {
  const project = useCurrentProject()
  const [showPricingForm, setShowPricingForm] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [priceVariation, setPriceVariation] = useState('annual')

  const showStarterPlus = useMemo(() => props.currentPlan === 'starter_plus', [props.currentPlan])
  const showPro = useMemo(() => props.currentPlan === 'pro', [props.currentPlan])

  const version = 'v3'
  const displayPlans = plansV3

  return (
    <>
      <Modal isOpen={isRedirecting} onClose={() => setIsRedirecting(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Redirecting...</ModalHeader>
          <ModalBody>
            <Center as={Stack} spacing="4">
              <Spinner />
              <Flex>
                <Heading size="xs">Redirecting you to Stripe to finish your transaction</Heading>
              </Flex>
            </Center>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      {showPricingForm && (
        <Modal isOpen={showPricingForm} onClose={() => setShowPricingForm(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <BTFlow />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      <Stack alignItems="center" pt={8}>
        <Text fontSize="md" fontWeight="semibold">
          Save up to 30% with annual billing
        </Text>
        <Box width="auto">
          <SegmentedControl size="sm">
            <Button width="140px" isActive={priceVariation === 'annual'} onClick={() => setPriceVariation('annual')}>
              Yearly{' '}
              <Tag size="sm" colorScheme="green" variant="subtle" ml="2" px={1}>
                -30%
              </Tag>
            </Button>
            <Button width="140px" isActive={priceVariation === 'monthly'} onClick={() => setPriceVariation('monthly')}>
              Monthly
            </Button>
          </SegmentedControl>
        </Box>
      </Stack>

      <Flex w="100%" alignItems="stretch" gap="5" flexWrap="wrap" justifyContent="stretch" pt="4">
        <PlanCard
          isCurrent={props.currentPlan === 'free'}
          isTrialing={props.isTrialing}
          title={displayPlans.free.title}
          subtitle={
            <Stack spacing={1}>
              <Text fontSize="xs" fontWeight="semibold" color="gray.500">
                Free forever
              </Text>
              <Flex>
                <Heading as="span" letterSpacing="tight" size="xl">
                  <Text as="span" fontSize=".72em" lineHeight={1.42} verticalAlign="top">
                    $
                  </Text>
                  0
                </Heading>
              </Flex>
            </Stack>
          }
          description={displayPlans.free.description}
          disabled={props.currentPlan === 'business'}
          cta="Downgrade"
          subline={<>&nbsp;</>}
          onCTAClicked={() => {
            // @ts-ignore I just want to redirect!
            window.location = billingManagePath()
          }}
          features={
            <Stack pt="4">
              <Heading size="xs">Features:</Heading>
              <List fontSize="sm" spacing="3" pt="4">
                {displayPlans.free.features.map((feature) => (
                  <ListItem key={feature} display="flex">
                    <ListIcon as={IconCheck} boxSize={4} color="purple.500" marginY={0.5} />
                    {feature}
                  </ListItem>
                ))}
              </List>
            </Stack>
          }
        />

        <PlanCard
          isCurrent={props.currentPlan === 'starter'}
          isTrialing={props.isTrialing}
          title={plansV3.starter.title}
          discount={
            priceVariation === 'annual'
              ? calculatePercentOff(plansV3.starter.basePrice, plansV3.starter.annualMonthlyPrice)
              : undefined
          }
          subtitle={
            <Stack spacing={1}>
              <Text fontSize="xs" fontWeight="semibold" color="gray.500">
                From
              </Text>
              <Flex>
                <Heading as="span" letterSpacing="tight" size="xl">
                  <Text as="span" fontSize=".72em" lineHeight={1.42} verticalAlign="top">
                    $
                  </Text>
                  {priceVariation === 'annual' ? plansV3.starter.annualMonthlyPrice : plansV3.starter.basePrice}
                </Heading>
                <Text
                  as="span"
                  alignSelf="flex-end"
                  fontSize="xs"
                  fontWeight="medium"
                  lineHeight={1.33}
                  marginLeft="2"
                  transform={`translateY(-6px)`}
                  color="gray.500"
                >
                  per month
                  {priceVariation === 'annual' && (
                    <>
                      <br />
                      billed yearly
                    </>
                  )}
                </Text>
              </Flex>
            </Stack>
          }
          subline={priceVariation === 'annual' ? plansV3.starter.annualSubtitle : plansV3.starter.subtitle}
          description={plansV3.starter.description}
          cta={
            props.currentPlan === 'free' &&
            !props.isTrialing &&
            !props.previouslyTrialed &&
            props.koalaSubscription.trial_available
              ? 'Try Starter for Free'
              : props.currentPlan === 'pro' || props.currentPlan === 'starter_plus'
              ? 'Downgrade to Starter'
              : 'Upgrade to Starter'
          }
          disabled={props.currentPlan === 'business'}
          onCTAClicked={() => {
            setIsRedirecting(true)
            setTimeout(() => {
              if (props.currentPlan === 'starter') {
                // @ts-ignore I just want to redirect!
                window.location = billingManagePath()
              } else {
                // @ts-ignore I just want to redirect!
                window.location = billingCheckoutPath(`?plan=starter&price=${priceVariation}&version=${version}`)
              }
            })
          }}
          features={
            <Stack pt="4">
              <Heading size="xs">Everything in Free, plus:</Heading>
              <List fontSize="sm" spacing="3" pt="4">
                {plansV3.starter.features.map((feature) => (
                  <ListItem key={feature} display="flex">
                    <ListIcon as={IconCheck} boxSize={4} color="purple.500" marginY={0.5} />
                    {feature}
                  </ListItem>
                ))}
              </List>
            </Stack>
          }
        />

        {showStarterPlus && (
          <PlanCard
            isCurrent={props.currentPlan === 'starter_plus'}
            isTrialing={props.isTrialing}
            title={plansV3.starter_plus.title}
            discount={undefined}
            subtitle={
              <Stack spacing={1}>
                <Text fontSize="xs" fontWeight="semibold" color="gray.500">
                  From
                </Text>
                <Flex>
                  <Heading as="span" letterSpacing="tight" size="xl">
                    <Text as="span" fontSize=".72em" lineHeight={1.42} verticalAlign="top">
                      $
                    </Text>
                    {plansV3.starter_plus.annualMonthlyPrice}
                  </Heading>
                  <Text
                    as="span"
                    alignSelf="flex-end"
                    fontSize="xs"
                    fontWeight="medium"
                    lineHeight={1.33}
                    marginLeft="2"
                    transform={`translateY(-6px)`}
                    color="gray.500"
                  >
                    per month
                    <>
                      <br />
                      billed yearly
                    </>
                  </Text>
                </Flex>
              </Stack>
            }
            subline={plansV3.starter_plus.annualSubtitle}
            description={plansV3.starter_plus.description}
            cta={
              !props.isTrialing && !props.previouslyTrialed && props.koalaSubscription.trial_available
                ? 'Try Starter Plus for Free'
                : props.currentPlan === 'pro'
                ? 'Downgrade to Starter Plus'
                : 'Upgrade to Starter Plus'
            }
            disabled={props.currentPlan === 'business'}
            onCTAClicked={() => {
              setShowPricingForm(true)
            }}
            features={
              <Stack pt="4">
                <Heading size="xs">Everything in Starter, plus:</Heading>
                <List fontSize="sm" spacing="3" pt="4">
                  {plansV3.starter_plus.features.map((feature) => (
                    <ListItem key={feature} display="flex">
                      <ListIcon as={IconCheck} boxSize={4} color="purple.500" marginY={0.5} />
                      {feature}
                    </ListItem>
                  ))}
                </List>
              </Stack>
            }
          />
        )}

        {showPro && (
          <PlanCard
            isCurrent={props.currentPlan === 'pro'}
            isTrialing={props.isTrialing}
            title={plansV3.pro.title}
            discount={
              priceVariation === 'annual'
                ? calculatePercentOff(plansV3.pro.basePrice, plansV3.pro.annualMonthlyPrice)
                : undefined
            }
            subtitle={
              <Stack spacing={1}>
                <Text fontSize="xs" fontWeight="semibold" color="gray.500">
                  From
                </Text>
                <Flex>
                  <Heading as="span" letterSpacing="tight" size="xl">
                    <Text as="span" fontSize=".72em" lineHeight={1.42} verticalAlign="top">
                      $
                    </Text>
                    {priceVariation === 'annual' ? plansV3.pro.annualMonthlyPrice : plansV3.pro.basePrice}
                  </Heading>
                  <Text
                    as="span"
                    alignSelf="flex-end"
                    fontSize="xs"
                    fontWeight="medium"
                    lineHeight={1.33}
                    marginLeft="2"
                    transform={`translateY(-6px)`}
                    color="gray.500"
                  >
                    per month
                    {priceVariation === 'annual' && (
                      <>
                        <br />
                        billed yearly
                      </>
                    )}
                  </Text>
                </Flex>
              </Stack>
            }
            subline={priceVariation === 'annual' ? plansV3.starter.annualSubtitle : plansV3.starter.subtitle}
            disabled={props.currentPlan === 'business'}
            description={plansV3.pro.description}
            cta={
              (props.currentPlan === 'free' || props.currentPlan === 'starter') &&
              !props.isTrialing &&
              !props.previouslyTrialed &&
              props.koalaSubscription.trial_available
                ? 'Try Pro for Free'
                : props.currentPlan === 'starter'
                ? 'Switch to Pro'
                : 'Upgrade to Pro'
            }
            onCTAClicked={() => {
              setIsRedirecting(true)
              setTimeout(() => {
                if (props.currentPlan === 'pro') {
                  // @ts-ignore I just want to redirect!
                  window.location = billingManagePath()
                } else {
                  // @ts-ignore I just want to redirect!
                  window.location = billingCheckoutPath(`?plan=pro&price=${priceVariation}&version=${version}`)
                }
              })
            }}
            features={
              <Stack pt="4">
                <Heading size="xs">Everything in Starter, plus:</Heading>
                <List fontSize="sm" spacing="3" pt="4">
                  {plansV3.pro.features.map((feature) => (
                    <ListItem key={feature} display="flex">
                      <ListIcon as={IconCheck} boxSize={4} color="purple.500" marginY={0.5} />
                      {feature}
                    </ListItem>
                  ))}
                </List>
              </Stack>
            }
          />
        )}

        <PlanCard
          isCurrent={props.currentPlan === 'business'}
          isTrialing={props.isTrialing}
          title={displayPlans.business.title}
          subtitle={
            <Stack spacing={1}>
              <Text fontSize="xs" fontWeight="semibold" color="gray.500">
                &nbsp;
              </Text>
              <HStack spacing="1" alignItems="baseline">
                <Heading size="xl" fontWeight={'semibold'}>
                  Custom
                </Heading>
              </HStack>
            </Stack>
          }
          subline={displayPlans.business.subtitle}
          description={displayPlans.business.description}
          cta="Let's Talk"
          onCTAClicked={() => {
            window.ko?.track('Business Plan Contact Form Opened', {
              projectSlug: project?.slug
            })
            setShowPricingForm(true)
          }}
          features={
            <Stack pt="4">
              <Heading size="xs">Everything in {showPro ? 'Pro' : 'Starter'}, plus:</Heading>
              <List fontSize="sm" spacing="3" pt="2">
                {displayPlans.business.features.map((feature) => (
                  <ListItem key={feature} display="flex">
                    <ListIcon as={IconCheck} boxSize={4} color="purple.500" marginY={0.5} />
                    {feature}
                  </ListItem>
                ))}
              </List>
            </Stack>
          }
        />
      </Flex>
    </>
  )
}
