import { Button, Heading, HStack, Stack, Text } from '@chakra-ui/react'
import { IconArrowRight, IconMail } from '@tabler/icons-react'
import React, { useCallback, useMemo } from 'react'
import { toast } from 'sonner'
import { post } from '../../../../lib/api'
import { FacetFilters } from '../../accounts'
import { accountViewPath } from '../../account_views/lib/list-paths'
import { AccountList } from '../../icps/icp/account-list'
import { HighlightedAccount } from '../../icps/types'
import { HeroImage } from '../components/HeroImage'
import { Step } from '../components/SetupProject'
import { StepProps } from '../rep_onboarding'
import img from './my-accounts.png'

export function RepMyAccounts(
  props: StepProps & { accounts: HighlightedAccount[]; my_accounts_filters?: FacetFilters }
) {
  const [saving, setSaving] = React.useState(false)

  const [confirmed, setConfirmed] = React.useState(false)

  const safeFilters = useMemo(() => {
    let asObject = props.my_accounts_filters ?? {}
    if (asObject['_or']) {
      asObject = asObject['_or'][0]
    }

    return asObject
  }, [props.my_accounts_filters])

  const saveMine = useCallback(() => {
    setSaving(true)

    post(accountViewPath({ slug: 'mine' }), {
      facets: safeFilters
    })
      .then(() => {
        setConfirmed(true)
      })
      .catch(() => {
        toast.error('Could not save your accounts')
      })
      .finally(() => {
        setSaving(false)
      })
  }, [safeFilters])

  return (
    <Step
      cardProps={{ maxW: 800 }}
      {...props}
      onBack={
        confirmed
          ? () => {
              setConfirmed(false)
            }
          : props.onBack
      }
    >
      <HeroImage src={img} h="180px" />
      {!confirmed && (
        <Stack p="8" spacing="8" w="100%" pt="0">
          <Heading size="md">Do these accounts from your CRM belong to you?</Heading>

          <AccountList compact useExternalLinks accounts={props.accounts} showActions={false} />

          <HStack w="100%">
            <Button w="100%" variant={'outline'} onClick={props.onSkip} disabled={saving}>
              No
            </Button>
            <Button w="100%" colorScheme={'purple'} onClick={saveMine} isLoading={saving}>
              Yes
            </Button>
          </HStack>
        </Stack>
      )}
      {confirmed && (
        <Stack p="8" spacing="8" w="100%" pt="0">
          <Heading size="md" fontWeight={'semibold'}>
            🎉 Nice! Your accounts have been set up.
          </Heading>
          <Stack>
            <Text>
              Now let's get you set up with <strong>Koala Weekly</strong> emails.
            </Text>
            <HStack bg="gray.50" p="8" borderWidth="1px" rounded="md" spacing="4">
              <IconMail size="32" />
              <Text>
                You'll be able to subscribe to Weekly Emails containing the hottest accounts from a variety of
                Audiences.
              </Text>
            </HStack>
          </Stack>

          <Button colorScheme={'purple'} onClick={props.onContinue} rightIcon={<IconArrowRight size="18" />}>
            Continue
          </Button>
        </Stack>
      )}
    </Step>
  )
}
