import { Box, Heading, HStack, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React from 'react'
import type { PageMeta } from '../../../types/PageMeta'
import type { Company } from '../../../types/Profile'
import { UrlFilterParams, useUrlFilters } from '../../data/use-url-filters'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { BuildingIcon } from '../../ui/icons'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { TableFooter } from '../../ui/TableFooter'
import { FilterPreview } from '../accounts/components/FilterPreview'

interface Props {
  companies: Company[]
  page_meta: PageMeta
}

export default function Index(props: Props) {
  const facets: UrlFilterParams = useUrlFilters({
    initialRange: null,
    facetCloudPath: '/companies/facet-cloud'
  })

  return (
    <PageLayout size="full">
      <PageTitle skipRendering>Companies</PageTitle>

      <Stack>
        <HStack flex="1 1 100%" width="100%" justifyContent="space-between" spacing={3}>
          <HStack spacing={3}>
            <BuildingIcon boxSize={5} />
            <Heading display="inline-flex" gap={4} alignItems="baseline" size="md">
              Companies{'  '}
              <Box whiteSpace="nowrap">
                <Text as="span">{props.page_meta.total_count.toLocaleString()}</Text>
              </Box>
            </Heading>
          </HStack>
        </HStack>

        <FilterPreview
          {...facets}
          range={undefined}
          kind="account"
          facetValuesPath="/companies/facet-values"
          shouldShowICPFilters={false}
          shouldShowIntentFilters={false}
          shouldShowCsvUploadFilters={false}
          shouldShowUserAttributeFilters={false}
        />
      </Stack>

      <TableContainer fontSize="sm" w="100%">
        <Table size="md" w="100%" height="1px">
          <Thead>
            <Tr height="100%">
              <Th isTruncated>Company</Th>
              <Th isTruncated>Industry</Th>
              <Th>Employees</Th>
              <Th>Est. Revenue</Th>
              <Th>Country</Th>
            </Tr>
          </Thead>
          <Tbody bg="white">
            {props.companies.map((company) => (
              <Tr key={company.id}>
                <Td isTruncated>
                  <HStack spacing={4}>
                    <CompanyAvatar size="40px" name={company.name} domain={company.domain} />

                    <Stack spacing={1} minW="100px" lineHeight="1.2">
                      <Text fontSize="md" fontWeight="semibold" textOverflow="ellipsis" overflow="hidden">
                        {company.name}
                      </Text>
                      <Text fontSize="sm" color="gray.500" textOverflow="ellipsis" overflow="hidden">
                        {company.domain}
                      </Text>
                    </Stack>
                  </HStack>
                </Td>

                <Td isTruncated>{company.category?.industry}</Td>

                <Td>{company.metrics?.employeesRange}</Td>

                <Td>{company.metrics?.estimatedAnnualRevenue}</Td>

                <Td>{company.geo?.country}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <TableFooter
        word="company"
        pageMeta={props.page_meta}
        page={(facets.page ?? 1) as number}
        setPage={facets.setPage}
      />
    </PageLayout>
  )
}
