import {
  Box,
  Button,
  Circle,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  Tooltip,
  Spinner,
  useBreakpointValue,
  useTheme
} from '@chakra-ui/react'
import {
  IconArrowRight,
  IconBolt,
  IconClick,
  IconFileText,
  IconGitMerge,
  IconLink,
  IconMail,
  IconSelector,
  IconWorld
} from '@tabler/icons-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  AccountTrait,
  FormSubmissionEntry,
  IdentifyEntry,
  KQLEventEntry,
  PageViewEntry,
  ProfileEventEntry,
  ProfileTrait,
  Session,
  TimelineEntry
} from '..'
import { concurrentCachedGET } from '../../../../lib/api'
import { duration } from '../../../../lib/duration'
import { pluralize } from '../../../../lib/pluralize'
import { LightBgCard } from '../../../ui/Card'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { FeedStoryActor } from '../../../ui/feed/FeedStoryActor'
import { JSONTree } from '../../../ui/json-tree'
import { MiddotDivider } from '../../../ui/Middot'
import { StackedField } from '../../../ui/StackedField'
import { TextEllipsis } from '../../../ui/text-ellipsis'
import { TimeAgo } from '../../../ui/TimeAgo'
import { RedactedAccountCell, RedactedText, useEntitlements } from '../../../ui/useEntitlements'
import { VirtualList } from '../../../ui/VirtualList'
import { accountPath } from '../../accounts/lib/account-path'
import { sessionTimelinePath } from '../lib/path-helpers'
import { useEventDetails } from '../../../data/use-event-details'
import AISummaryButton, { useAISummary, AISummaryContent } from '@app/components/ui/AISummaryButton'
import { projectPath } from '@app/components/ui/ProjectsContext'
import { useCurrentUser } from '../../../ui/UserContext'
import HoverContext from './HoverContext'
import Time from './Time'
import { deepEqual } from 'fast-equals'
import partition from 'lodash/partition'

export type Entry =
  | PageViewEntry
  | FormSubmissionEntry
  | IdentifyEntry
  | KQLEventEntry
  | ProfileEventEntry
  | AccountTrait
  | ProfileTrait

function hostAndPath(props: { url?: string; path?: string; minimal_search?: string }) {
  try {
    if (props.url) {
      const url = new URL(props.url)
      // use minimal search to show things like utms, click ids, but not all query params
      const search = props.minimal_search ? `?${props.minimal_search}` : ''
      return `${url.hostname}${url.pathname}${search}`
    }

    return props.path ?? null
  } catch {
    return null
  }
}

function Pv(props: PageViewEntry) {
  const longForm = useBreakpointValue({ base: false, sm: true })

  return (
    <HStack spacing="4" fontSize="xs" w="100%">
      <Text flex="none">
        <Time time={props.visit_start} />
      </Text>
      <HStack spacing={1} flex="1" w="120px">
        <Circle color="gray.500" size={5} alignSelf="center" flex="none">
          <Icon as={IconWorld} boxSize={4} marginX={0.25} />
        </Circle>

        <TextEllipsis maxW="800px" tooltip flex="1">
          <RedactedText redacted={props.redacted}>{hostAndPath(props)}</RedactedText>
        </TextEllipsis>
      </HStack>

      {props.focus_time && (
        <Text>
          {props.focus_time >= 5_000 ? duration(props.focus_time, { long: longForm ?? true }) : 'a few seconds'}
        </Text>
      )}

      <HoverContext
        title={
          <HStack>
            <Circle color="gray.500" size={5} alignSelf="center" flex="none">
              <Icon as={IconWorld} boxSize={4} marginX={0.25} />
            </Circle>

            <TextEllipsis maxW="800px" tooltip flex="1">
              <RedactedText redacted={props.redacted}>
                <TextEllipsis maxW="300px" tooltip flex="1">
                  {props.title}
                </TextEllipsis>
              </RedactedText>
            </TextEllipsis>
          </HStack>
        }
      >
        <>
          <StackedField label="Path" fontWeight={'normal'}>
            {props.path}
          </StackedField>

          {props.utm &&
            Object.entries(props.utm).map(([key, value]) => (
              <StackedField key={key} label={`UTM ${key}`} fontWeight={'normal'}>
                {value}
              </StackedField>
            ))}

          <StackedField label="Referrer" fontWeight={'normal'}>
            {props.referrer && (
              <Link
                fontWeight={'normal'}
                href={props.referrer}
                isExternal
                whiteSpace={'pre-wrap'}
                wordBreak="break-all"
              >
                {props.referrer}
              </Link>
            )}
          </StackedField>

          <StackedField label="Visit Start" fontWeight={'normal'}>
            <Time time={props.visit_start} />
          </StackedField>

          <StackedField label="Active Session Time" fontWeight={'normal'}>
            {props.focus_time ? <Text>{duration(props.focus_time, { long: true })}</Text> : <Text>--</Text>}
          </StackedField>

          <StackedField label="Full URL">
            <Link fontWeight={'normal'} href={props.url} isExternal whiteSpace={'pre-wrap'} wordBreak="break-all">
              {props.url}
            </Link>
          </StackedField>
        </>
      </HoverContext>
    </HStack>
  )
}

function FormSub(props: FormSubmissionEntry) {
  return (
    <HStack spacing="4" fontSize="xs" w="100%">
      <Text flex="none">
        <Time time={props.created_at} />
      </Text>
      <HStack spacing={1} flex="1" w="120px">
        <Circle color="gray.600" alignSelf="center" size={5} flex="none">
          <Icon as={IconFileText} boxSize={4} />
        </Circle>
        <TextEllipsis maxW="800px" tooltip flex="1" fontWeight="semibold">
          Submitted form on{' '}
          <RedactedText redacted={props.redacted}>{hostAndPath({ url: props.page_url })}</RedactedText>
        </TextEllipsis>
      </HStack>
      <HoverContext
        title={
          <HStack>
            <Circle color="gray.500" size={5} alignSelf="center" flex="none">
              <Icon as={IconFileText} boxSize={4} />
            </Circle>

            <TextEllipsis maxW="800px" tooltip flex="1">
              <RedactedText redacted={props.redacted}>
                <TextEllipsis maxW="300px" tooltip flex="1">
                  Submitted form on {props.page_path}
                </TextEllipsis>
              </RedactedText>
            </TextEllipsis>
          </HStack>
        }
      >
        <>
          <StackedField label="Path" fontWeight={'normal'}>
            {props.page_path}
          </StackedField>

          <StackedField label="Form Data" fontWeight={'normal'}>
            <Box ml="-4" mt="-2">
              <JSONTree data={props.form_data} />
            </Box>
          </StackedField>

          {props.utm &&
            Object.entries(props.utm).map(([key, value]) => (
              <StackedField key={key} label={`UTM ${key}`} fontWeight={'normal'}>
                {value}
              </StackedField>
            ))}

          <StackedField label="Referrer" fontWeight={'normal'}>
            {props.referrer && (
              <Link
                fontWeight={'normal'}
                href={props.referrer}
                isExternal
                whiteSpace={'pre-wrap'}
                wordBreak="break-all"
              >
                {props.referrer}
              </Link>
            )}
          </StackedField>

          <StackedField label="Submitted" fontWeight={'normal'}>
            <Time time={props.created_at} />
          </StackedField>

          <StackedField label="Full URL">
            <Link fontWeight={'normal'} href={props.page_url} isExternal whiteSpace={'pre-wrap'} wordBreak="break-all">
              {props.page_url}
            </Link>
          </StackedField>
        </>
      </HoverContext>
    </HStack>
  )
}

function Trait(props: ProfileTrait | AccountTrait) {
  return (
    <HStack spacing="4" fontSize="xs" w="100%">
      <Text flex="none">
        <Time time={props.updated_at} />
      </Text>
      <HStack spacing={1} flex="none" w="120px">
        <Circle color="gray.500" alignSelf="center" size={5} flex="none">
          <Icon as={IconGitMerge} boxSize={4} />
        </Circle>
        <Text fontWeight={'semibold'}>Trait Update</Text>
        <HStack flex="1">
          <Text fontWeight="semibold" color="gray.500">
            {props.name}
          </Text>
          {props.redacted && <RedactedText>......</RedactedText>}
          {!props.redacted && !deepEqual(props.first_value, props.current_value) && (
            <>
              <Text>{JSON.stringify(props.first_value ?? null)}</Text>
              <Icon as={IconArrowRight} boxSize={2.5} />
            </>
          )}
          <Text>{JSON.stringify(props.current_value ?? null)}</Text>
        </HStack>
      </HStack>
    </HStack>
  )
}

function Evt(props: ProfileEventEntry) {
  return (
    <HStack spacing="4" fontSize="xs" w="100%">
      <Text flex="none">
        <Time time={props.sent_at} />
      </Text>
      <HStack spacing={1} flex="1">
        <Circle color="gray.500" alignSelf="center" size={5} flex="none">
          <Icon as={IconClick} boxSize={4} />
        </Circle>
        <Text fontWeight="semibold">
          <RedactedText redacted={props.redacted}>{props.event}</RedactedText>
        </Text>
      </HStack>

      <HoverContext
        title={
          <HStack>
            <Circle color="gray.500" size={5} alignSelf="center" flex="none">
              <Icon as={IconClick} boxSize={4} />
            </Circle>

            <TextEllipsis maxW="800px" tooltip flex="1">
              <RedactedText redacted={props.redacted}>{props.event}</RedactedText>
            </TextEllipsis>
          </HStack>
        }
      >
        <>
          <StackedField label="Type" fontWeight={'normal'}>
            track
          </StackedField>

          {props.data?.properties && (
            <StackedField label={'Properties'} fontWeight={'normal'}>
              <Box ml="-4" mt="-2">
                <JSONTree data={props.data.properties} />
              </Box>
            </StackedField>
          )}

          <StackedField label="Sent At" fontWeight={'normal'}>
            <Time time={props.sent_at} />
          </StackedField>
        </>
      </HoverContext>
    </HStack>
  )
}

function Kql(props: KQLEventEntry) {
  const [isOpen, setIsOpen] = useState(false)
  const eventDetails = useEventDetails<any>('kql_event', props.id, isOpen)

  return (
    <HStack spacing="4" fontSize="xs" w="100%">
      <Text flex="none">
        <Time time={props.triggered_at} />
      </Text>

      <HStack spacing={1} flex="1" overflow="hidden">
        <Circle alignSelf="center" size={5} flex="none">
          <Icon as={IconBolt} fill="currentColor" strokeWidth="1.5" boxSize="18px" color="purple.500" />
        </Circle>
        <TextEllipsis maxW="100%" tooltip flex="1" fontWeight="semibold" color="purple.500">
          <RedactedText redacted={props.redacted}>{props.context.definition.name}</RedactedText>
        </TextEllipsis>
      </HStack>

      <HoverContext
        onOpen={() => {
          setIsOpen(true)
        }}
        title={
          <HStack>
            <Circle alignSelf="center" size={5} flex="none">
              <Icon as={IconBolt} fill="currentColor" strokeWidth="1.5" boxSize="18px" color="purple.500" />
            </Circle>
            <Text>{props.context.definition.name ?? 'Intent Signal'}</Text>
          </HStack>
        }
      >
        <>
          <StackedField label="Signal Type" fontWeight={'normal'}>
            {props.context.definition.signal_type}
          </StackedField>

          {eventDetails.isLoading ? (
            <Spinner />
          ) : (
            <Stack spacing={2}>
              <StackedField label="Signal Matches" fontWeight={'normal'}>
                <JSONTree data={eventDetails?.data?.signal_matches || []} />
              </StackedField>
            </Stack>
          )}

          <StackedField label="Triggered At" fontWeight={'normal'}>
            <Time time={props.triggered_at} />
          </StackedField>
        </>
      </HoverContext>
    </HStack>
  )
}

export function SessionActor(
  props: Session & {
    hideTimestamps?: boolean
    hideCompany?: boolean
    compact?: boolean
    redacted?: boolean
    showPreview?: boolean
    onShowMore?: () => void
  }
) {
  if (props.profile) {
    const { compact, ...rest } = props
    return (
      <Box p={compact ? undefined : [4, 4, 4, 5]}>
        <FeedStoryActor
          scopedTo={props.hideCompany ? 'account' : undefined}
          session={rest}
          showingMore={props.showPreview}
          onShowMore={props.onShowMore}
          hideTimestamps={props.hideTimestamps}
        />
      </Box>
    )
  }

  if (props.company) {
    return <CompanyActorDetails {...props} company={props.company} />
  }

  return null
}

function Summary(props: { remaining: number; onShowMore: () => void; isLoadingMore: boolean }) {
  return (
    <Tooltip label="Click to show the full timeline">
      <Button
        leftIcon={<IconSelector size={14} />}
        variant="unstyled"
        color="gray.500"
        _hover={{ color: 'gray.700' }}
        size="xs"
        iconSpacing={1}
        w="100%"
        h="100%"
        display="flex"
        alignItems="center"
        onClick={props.onShowMore}
        isLoading={props.isLoadingMore}
      >
        Show {props.remaining} more…
      </Button>
    </Tooltip>
  )
}

interface SessionPreviewProps {
  items: TimelineEntry[]
  remaining: number
  redacted?: boolean
  hasMore?: boolean
  onShowMore: () => void
  isLoadingMore: boolean
}

export function SessionPreview(props: SessionPreviewProps) {
  if (props.items.length === 0) {
    return null
  }

  const min = Math.min(7, props.items.length)

  return (
    <Stack
      borderColor="gray.200"
      borderTopWidth="1px"
      divider={<Divider />}
      spacing="0"
      fontSize="sm"
      role="group"
      bg="white"
      overflow="hidden"
    >
      <VirtualList
        maxH={36 * min}
        items={props.items}
        estimateSize={() => 36}
        containerStyles={{ paddingTop: '8px', paddingBottom: '8px' }}
        renderItem={(entry) => {
          return (
            <Stack
              w="100%"
              key={`${entry.type}-${entry.id}`}
              justifyContent={'center'}
              bg={entry.search_highlight ? 'yellow.50' : undefined}
              minH="36px"
              _hover={{
                bg: 'gray.50'
              }}
              overflow="auto"
              fontSize="xs"
            >
              <Flex w="100%" h="100%" px={[4, 4, 4, 5]}>
                {entry.type === 'PageView' && <Pv {...entry} redacted={props.redacted} />}
                {entry.type === 'KqlEvent' && <Kql {...entry} redacted={props.redacted} />}
                {entry.type === 'Event' && <Evt {...entry} redacted={props.redacted} />}
                {entry.type === 'FormSubmission' && <FormSub {...entry} redacted={props.redacted} />}
                {entry.type === 'ProfileTrait' && <Trait {...entry} redacted={props.redacted} />}
                {entry.type === 'AccountTrait' && <Trait {...entry} redacted={props.redacted} />}
                {entry.type === 'Summary' && props.hasMore && (
                  <Summary
                    remaining={props.remaining}
                    onShowMore={props.onShowMore}
                    isLoadingMore={props.isLoadingMore}
                  />
                )}
              </Flex>
            </Stack>
          )
        }}
      />
    </Stack>
  )
}

export function MaterializedSession(
  props: Session & {
    redacted?: boolean
    hideTimestamps?: boolean
    hideCompany?: boolean
    hideIntent?: boolean
    onShowMore?: () => void
  }
) {
  const timeline = useMemo(() => props.highlights ?? [], [props.highlights])
  const hasMore = useMemo(() => props.num_interactions > timeline.length, [props.num_interactions, timeline.length])
  const withItems = useMemo(() => {
    if (!hasMore || props.kind === 'Account') {
      return timeline
    }

    const firstTwo = timeline.slice(0, 2)
    const rest = timeline.slice(2, timeline.length)

    const expand = {
      id: 'expand',
      type: 'Summary'
    }

    return [...firstTwo, expand, ...rest] as TimelineEntry[]
  }, [timeline, hasMore, props.kind])

  const [isUnlocking, setIsUnlocking] = useState(false)
  const [fullTimeline, setFullTimeline] = useState<TimelineEntry[]>([])
  const entitlements = useEntitlements()

  const entries = useMemo(() => {
    if (fullTimeline.length > 0) {
      return fullTimeline
    }

    return withItems
  }, [withItems, fullTimeline])

  const onUnlock = useCallback(() => {
    const path = sessionTimelinePath(props.id, props.kind)
    setIsUnlocking(true)
    concurrentCachedGET<{ timeline: TimelineEntry[] }>(path).then((res) => {
      setIsUnlocking(false)
      setFullTimeline(res.timeline)
    })
  }, [props.id, props.kind])

  const [showPreview, setShowPreview] = useState(typeof props.hideIntent === 'boolean' ? !props.hideIntent : true)

  useEffect(() => {
    if (typeof props.hideIntent !== 'boolean') {
      return
    }
    setShowPreview(!props.hideIntent)
  }, [props.hideIntent])

  const togglePreview = useCallback(() => {
    if (props.onShowMore) {
      props.onShowMore()
    } else {
      setShowPreview((prev) => !prev)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onShowMore])

  const trafficSource = useMemo(() => {
    if (props.sources?.length) {
      return props.sources[0]
    }
  }, [props.sources])

  const theme = useTheme()

  const user = useCurrentUser()
  const [showAiSummary, setShowAiSummary] = useState(false)

  const { messages, eventSource: startAiSummary } = useAISummary({
    path: projectPath(`/daily_profile_feeds/${props.profile_id}/ai-summary?date=${props.last_touched_at}`)
  })

  return (
    <LightBgCard w="100%" bg="white" p={0} borderColor="gray.50" shadow="base">
      {trafficSource?.source_label && (
        <Box
          fontSize="xs"
          bg={`${trafficSource.source in theme.colors ? trafficSource.source : 'gray'}.100`}
          paddingY={2}
          paddingX={5}
          roundedTop="md"
          marginX="-1px"
          marginTop="-1px"
        >
          <Flex justifyContent="space-between" alignItems="center" gap={3}>
            <HStack flex="none" spacing={2}>
              <CompanyAvatar
                size="16px"
                src={`/web/companies/${trafficSource.source_label}/favicon`}
                fallbackIcon={
                  trafficSource.minimal_search?.includes('utm_medium=email') ? (
                    <IconMail size={16} />
                  ) : (
                    <IconLink size={16} />
                  )
                }
                background="transparent"
              />
              {['ref', 'utm_source'].includes(trafficSource.param) ? (
                <Text>Came from {trafficSource.source_label}</Text>
              ) : (
                <Text>Clicked a {trafficSource.source_label} ad</Text>
              )}
            </HStack>
            {trafficSource.minimal_search && (
              <Text fontFamily="mono" color="gray.600" isTruncated>
                {trafficSource.minimal_search}
              </Text>
            )}
          </Flex>
        </Box>
      )}

      {props.redacted ? (
        <Box p={[4, 4, 4, 5]}>
          <RedactedAccountCell
            entitlements={entitlements}
            showLock
            type="Visitor"
            element={{
              last_seen_at: props.last_touched_at,
              limited: true
            }}
            flexProps={{ gap: 4 }}
          >
            <Text>Redacted</Text>
          </RedactedAccountCell>
        </Box>
      ) : (
        <SessionActor
          {...props}
          hideTimestamps={props.hideTimestamps}
          hideCompany={props.hideCompany}
          showPreview={showPreview}
          onShowMore={togglePreview}
        />
      )}

      {showPreview && withItems.length > 0 && !showAiSummary && (
        <SessionPreview
          items={entries}
          remaining={props.num_interactions - timeline.length}
          redacted={props.redacted}
          hasMore={hasMore}
          onShowMore={onUnlock}
          isLoadingMore={isUnlocking}
        />
      )}

      {props.profile_id && props.last_touched_at && user.isInternalUser && (
        <>
          <AISummaryContent messages={messages} isOpen={showAiSummary} />
          <AISummaryButton
            aria-label="Summarize with AI"
            leftText="Summarize with AI"
            onValueChange={(checked) => {
              if (checked) {
                startAiSummary()
              }

              setShowAiSummary(checked)
            }}
          />
        </>
      )}
    </LightBgCard>
  )
}

export function CompanyActorDetails(props: Session & { company: NonNullable<Session['company']> }) {
  const [known, anons] = React.useMemo(() => {
    if (!props.session_profiles) {
      return [[], []]
    }

    return partition(props.session_profiles, (p) => p.name || p.email)
  }, [props.session_profiles])

  const description = React.useMemo(() => {
    const highlights = known.slice(0, 3)
    const otherKnown = known.slice(3)
    const allOthers = otherKnown.concat(anons)
    const shortNames = highlights.map((k) => k.display_name?.split(' ')?.[0] || k.email).join(', ')
    const fullNames = highlights.map((k) => k.display_name || k.email).join(', ')

    if (fullNames && !allOthers.length) {
      return fullNames
    }

    if (shortNames && allOthers.length) {
      return `${shortNames} and ${pluralize(allOthers.length, 'other visitor', 'others')}`
    }

    if (allOthers.length) {
      return pluralize(allOthers.length, 'anonymous visitor', 'anonymous visitors')
    }
  }, [known, anons])

  return (
    <Stack spacing="0">
      <Link href={accountPath({ company: props.company })}>
        <Heading size="xs">{props.company.name || props.company.domain}</Heading>
      </Link>
      {description && (
        <Text color="gray.600" fontSize="xs">
          {description}
        </Text>
      )}
      <HStack color="gray.600" fontSize="xs" spacing={1} divider={<MiddotDivider />}>
        <Text>
          <TimeAgo time={props.last_touched_at} />
        </Text>
      </HStack>
    </Stack>
  )
}
