import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import calendar from 'dayjs/plugin/calendar'
import duration, { DurationUnitType } from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import minMax from 'dayjs/plugin/minMax'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'
import weekday from 'dayjs/plugin/weekday'

dayjs.extend(advancedFormat)
dayjs.extend(calendar)
dayjs.extend(duration)
dayjs.extend(isBetween)
dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(minMax)
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(updateLocale)
dayjs.extend(weekday)
dayjs.extend(quarterOfYear)

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    m: '1min',
    mm: '%dmin',
    h: '1h',
    hh: '%dh',
    d: '1 day',
    dd: '%d days',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy'
  }
})

export function formatDate(date: number | string | Date | dayjs.Dayjs, format = 'MMM D, YYYY') {
  return dayjs(date).format(format)
}

export function getSmallestUnit(ms) {
  const d = dayjs.duration(ms, 'milliseconds')

  // Calculate the duration in seconds, minutes, and hours
  const units: DurationUnitType[] = ['months', 'weeks', 'days', 'hours', 'minutes', 'seconds']
  return units.find((unit) => d.as(unit) % 1 === 0)
}

export default dayjs
