import { CRMMatch } from '../../../types/Account'
import { Company, DateTime } from '../../../types/Profile'

export interface Trend {
  trends: { day: string; value: number }[]

  day?: {
    current: {
      value: number
    }
  }

  week?: {
    current: {
      value: number
    }
  }

  month?: {
    current: {
      value: number
    }
  }
}

interface Stats {
  month: number
  week: number
  day: number
}

export interface AutoICPAccountScore {
  fit_grade: number
  account_id: string
  intent_grade: number
  overall_grade: number
  fit_grade_letter: 'A' | 'B' | 'C' | 'D' | 'F'
  intent_score_level: 'Very High' | 'High' | 'Medium' | 'Low' | 'None'
}

export type IntentTrend = 'Surging' | 'Heating' | 'Neutral' | 'Cooling' | 'New'

interface KqlEventInput {
  type: 'KqlEvent'
  kql_definition_id: string
  // the raw count of this kql
  count: number
  // the log scale of the count
  log: number
  // the weighted score of the input
  weighted: number
}

interface PageViewInput {
  type: 'PageView'
  // the raw count of pageviews
  count: number
  // the log scale of the count
  log: number
  // the weighted score of the input
  weighted: number
}

interface FocusTimeInput {
  type: 'FocusTime'
  // the raw count (in seconds) of focus time
  count: number
  // the log scale of the count
  log: number
  // the weighted score of the input
  weighted: number
}

interface IntentScoreSummary {
  type: 'KqlEvent' | 'PageView' | 'FocusTime'
  // the raw sum of this input, for focus time it's the number of seconds
  count: number
  weighted: number
  // for KQL events, we can see how many of each KQL fed into the score
  itemized?: null | Array<{
    id: string
    name?: string
    count: number
  }>
}

export interface IntentScore {
  max: number
  score: number
  date: string
  score_label?: string
  trend_7d?: IntentTrend
  trend_14d?: IntentTrend
  last_scored_at: string
  summary: IntentScoreSummary[]
  debug?: {
    inputs?: Array<KqlEventInput | PageViewInput | FocusTimeInput>
    recent_inputs?: Array<KqlEventInput | PageViewInput | FocusTimeInput>
  }
  trend: Array<{
    date: string
    score: number
  }>
}

export interface HighlightedAccount {
  id: string
  intent?: IntentScore
  fit_grade?: number
  fit_grade_letter?: string
  fit_score?: number
  intent_score?: number
  intent_grade?: number
  overall_grade?: number
  company_id: string
  company: Company
  domain: string
  focus_time_trend?: Trend
  page_views_trend?: Trend
  visitor_stats?: {
    identified: Stats
    visitors: Stats
  }
  first_seen_at?: string
  last_seen_at?: string
  crm_accounts: CRMMatch[]
  limited?: boolean
  fuzzy_company_match?: boolean
  latest_intent_signals?: Array<{
    id: string
    name: string
    last_triggered_at: DateTime
  }>
}

export function mergeParams(url: string, params: Record<string, string | undefined>): string {
  const parsed = new URL(url, location.toString())
  const search = parsed.searchParams

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      search.set(key, value)
    }

    if (value === undefined) {
      search.delete(key)
    }
  })

  const query = search.toString()
  if (query) {
    return `${parsed.pathname}?${decodeURIComponent(query)}`
  } else {
    return parsed.pathname
  }
}
