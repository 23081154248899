import { Badge, Box, Flex, IconButton, ScaleFade, Text } from '@chakra-ui/react'
import { IconX } from '@tabler/icons-react'
import React from 'react'

interface BulkActionBarProps {
  show?: boolean
  selectionCount: number
  onRemoveSelection?: () => void
}

export function BulkActionBar(props: React.PropsWithChildren<BulkActionBarProps>) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      position="sticky"
      bottom="32px"
      left={0}
      right={0}
      pointerEvents="none"
      zIndex={10}
    >
      <ScaleFade in={props.show !== false && props.selectionCount > 0}>
        <Box
          display="flex"
          gap={3}
          alignItems="center"
          zIndex="sticky"
          bg="white"
          fontSize="sm"
          rounded="lg"
          paddingX={4}
          paddingY={2}
          pointerEvents="auto"
          boxShadow="rgba(28, 40, 64, 0.06) 0px 0px 0px 1px inset, rgba(28, 40, 64, 0.12) 0px 4px 8px -4px, rgba(28, 40, 64, 0.16) 0px 4px 12px -2px"
        >
          <Flex alignItems="center" gap={1}>
            <Badge colorScheme="purple" variant="solid" rounded="md">
              {props.selectionCount}
            </Badge>
            <Text>selected</Text>
          </Flex>
          {props.children}
          {props.onRemoveSelection && (
            <IconButton
              aria-label="Remove selection"
              variant="ghost"
              size="xs"
              icon={<IconX size={16} />}
              onClick={props.onRemoveSelection}
            />
          )}
        </Box>
      </ScaleFade>
    </Box>
  )
}
