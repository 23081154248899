import { useQuery } from 'react-query'
import { concurrentGET } from '../../lib/api'
import { FacetMapping } from '../pages/accounts'
import { projectPath, useCurrentProject } from '../ui/ProjectsContext'

interface FacetCloudResponse {
  mappings: FacetMapping[]
}

// get the field mappings for the current workspace, for a given index
export function useFieldMappings(facetCloudPath = '/accounts/facet-cloud') {
  const project = useCurrentProject()
  const path = projectPath(facetCloudPath)

  return useQuery<FacetMapping[]>(
    ['facet-cloud', { projectId: project?.id, path }],
    () => concurrentGET<FacetCloudResponse>(path).then((res) => res.mappings || []),
    { enabled: Boolean(project?.id) }
  )
}
