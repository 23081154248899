import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Spinner,
  Stack,
  Table,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import {
  Icon as TablerIcon,
  IconChevronDown,
  IconChevronRight,
  IconCrosshair,
  IconHelp,
  IconListSearch
} from '@tabler/icons-react'
import React, { FormEvent, Fragment, MouseEvent, PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { AccountView } from '../../../types/AccountView'
import { Apps } from '../../../types/App'
import { Crm } from '../../../types/Crm'
import EmptyState from '../../ui/EmptyState'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { FacetFilters } from '../accounts'
import { FilterPreview } from '../accounts/components/FilterPreview'
import DrawnArrowUpperLeft from '../account_views/components/ArrowUp'
import { mergeParams } from '../icps/types'
import { useFacets } from '../../data/use-facets'
import { deepEqual } from 'fast-equals'
import { get, post, put } from '../../../lib/api'
import { useMountedState } from 'react-use'
import { toast } from 'sonner'
import { Opportunity } from '../../../types/Opportunity'
import { accountPath } from '../accounts/lib/account-path'
import CompanyAvatar from '../../ui/CompanyAvatar'
import { TimeAgo } from '../../ui/TimeAgo'
import { ColumnInfo, defaultAccountColumns } from '../../ui/ColumnSelector'
import { getItemDisplay } from '../accounts/facets/categories'
import { flatGet } from '../../../lib/flatGet'
import { IntentChart } from '../accounts/components/Intent/IntentSummary'
import { AccountTrends } from '../accounts/components/AccountTrends'
import pluralize from 'pluralize'
import { Iconify } from '../../ui/Iconify'
import { TextEllipsis } from '../../ui/text-ellipsis'
import { MCHeader } from './Header'
import { AuthenticityToken } from '../../ui/AuthenticityToken'

const columns = ['amount', 'close_date', 'intent', 'Trend', 'Visitors', 'LastVisit']

const columnRenderers = {
  amount: {
    Td: ({ data }) => (
      <Td width="100px" isNumeric>
        {data.amount ? `$${Number(data.amount).toLocaleString()}` : '-'}
      </Td>
    )
  },
  close_date: {
    Th: () => <Th width="120px">Close Date</Th>,
    Td: ({ data }) => (
      <Td width="120px">
        <TimeAgo time={data.close_date} format="MMM D, YYYY" />
      </Td>
    )
  },
  intent: {
    Th: () => <Th width="170px">Intent</Th>,
    Td: (props: CellRendererProps) => {
      return (
        <Td width="170px">
          <Box flex="none" display="flex" overflow="hidden">
            <IntentChart intent={props.data.account?.intent} />
          </Box>
        </Td>
      )
    }
  },
  Trend: {
    Th: () => <Th width="90px">Trend</Th>,
    Td: (props: CellRendererProps) => {
      const account = props.data.account!
      return (
        <Td width="90px">
          <AccountTrends account={account} showAllTrends />
        </Td>
      )
    }
  },

  Visitors: {
    Th: () => <Th>Visitors (30d)</Th>,
    Td: (props: CellRendererProps) => {
      const account = props.data.account
      const intentRange = 'month'

      return (
        <Td role="group">
          {account.visitor_stats && (
            <HStack>
              <Stack spacing="0.5">
                <Text>{pluralize('visitor', account.visitor_stats?.visitors?.[intentRange] ?? 0, true)}</Text>
                {account.visitor_stats?.identified?.[intentRange] > 0 && (
                  <Text color="gray.500" fontSize="sm">
                    {account.visitor_stats?.identified?.[intentRange]} known
                  </Text>
                )}
              </Stack>

              <Flex
                visibility="hidden"
                color="gray.500"
                _groupHover={{
                  visibility: 'visible'
                }}
                onClick={(e) => e.preventDefault()}
              >
                <Tooltip
                  label={`${account.visitor_stats?.identified?.[intentRange]} active users identified with an email out of ${account.visitor_stats?.visitors?.[intentRange]} total users this ${intentRange}`}
                >
                  <IconHelp size={15} />
                </Tooltip>
              </Flex>
            </HStack>
          )}
        </Td>
      )
    }
  },
  LastVisit: {
    Td: (props: CellRendererProps) => {
      return (
        <Td>
          <TimeAgo time={props.data.account?.last_seen_at} />
        </Td>
      )
    }
  }
}

const rowHover = {
  bg: [undefined, 'gray.50']
}

type OpportunityWithAccount = Opportunity & { account: NonNullable<Opportunity['account']> }

interface Props {
  account_view: AccountView
  opportunities: OpportunityWithAccount[]
  facet_filters?: FacetFilters
  sort_by?: string
  counts: Record<string, number>
  apps: Apps
  crm?: Crm
  permissions: Record<'can_edit' | 'can_create' | 'can_destroy', boolean>
  persisted: boolean
  stages: string[]
}

export default function MissionControl({
  crm,
  facet_filters,
  permissions,
  persisted,
  sort_by,
  stages,
  ...props
}: Props) {
  const [accountView, setAccountView] = React.useState(props.account_view)
  const [opportunities, setOpportunities] = React.useState<OpportunityWithAccount[]>(props.opportunities)
  const [counts, setCounts] = React.useState<Record<string, number>>(props.counts || {})
  const [submitting, setSubmitting] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [loadingStage, setLoadingStage] = React.useState<Record<string, boolean>>({})
  const mounted = useMountedState()

  useEffect(() => {
    setAccountView(props.account_view)
  }, [props.account_view])

  useEffect(() => {
    setOpportunities(props.opportunities)
    setCounts(props.counts || {})
    setLoading(false)
  }, [props.opportunities, props.counts])

  const facets = useFacets({
    facet_filters: facet_filters,
    range: null,
    sortBy: accountView.filters.sort_by || sort_by,
    facetCloudPath: '/accounts/facet-cloud?seen=0&range=all&no_defaults=1',
    onFilterChange: (appliedFilters) => {
      if (!deepEqual(appliedFilters, facet_filters)) {
        const body = {
          facets: appliedFilters
        }

        setLoading(true)

        post<Props>(window.location.href, body)
          .then((response) => {
            if (mounted()) {
              setOpportunities(response.opportunities || [])
              setCounts(response.counts || {})
              setLoading(false)
            }
          })
          .catch((error) => {
            console.error(error)

            if (mounted()) {
              setLoading(false)
            }
          })
      }
    }
  })

  const setFacetFilters = facets.setFacetFilters
  useEffect(() => {
    if (facet_filters) {
      setFacetFilters(facet_filters)
    }
  }, [setFacetFilters, facet_filters])

  const resetFilters = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      setSubmitting(true)

      post<Pick<Props, 'account_view'>>(window.location.pathname + '/reset' + window.location.search, {})
        .then((response) => {
          if (mounted()) {
            setFacetFilters({})
            setAccountView(response.account_view)
            setOpportunities([])
            setCounts({})
            setSubmitting(false)
          }
        })
        .catch((error) => {
          console.error(error)

          if (mounted()) {
            setSubmitting(false)
          }
        })
    },
    [mounted, setFacetFilters]
  )

  const saveChanges = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      setSubmitting(true)

      const body = { facets: facets.facetFilters }
      put<Pick<Props, 'account_view'>>(window.location.href, body)
        .then((response) => {
          if (mounted()) {
            setAccountView(response.account_view)
            toast.success('Territory filters saved successfully!')
            setSubmitting(false)
          }
        })
        .catch((error) => {
          toast.error('Failed to save territory filters')
          console.error(error)

          if (mounted()) {
            setSubmitting(false)
          }
        })
    },
    [mounted, facets.facetFilters]
  )

  const discardChanges = useCallback(() => {
    setFacetFilters(accountView.filters.facets || {})
  }, [setFacetFilters, accountView])

  const apps = useMemo(() => Object.values(props.apps), [props.apps])
  const canSaveChanges = permissions?.can_create ?? false
  const hasFilters = facets.isFiltering
  const isDirty = useMemo(
    () => !deepEqual(accountView.filters.facets || {}, facets.facetFilters),
    [accountView.filters.facets, facets.facetFilters]
  )

  const grouped = useMemo(() => {
    const grouping: Record<string, OpportunityWithAccount[]> = {}

    for (const opp of opportunities) {
      const stage = opp.stage_name
      if (!stage) {
        continue
      }

      if (!grouping[stage]) {
        grouping[stage] = []
      }

      grouping[stage].push(opp)
    }

    return grouping
  }, [opportunities])

  const allStages = useMemo(() => {
    // include any stages that are not in the stages list
    const remaining = Object.keys(grouped).filter((stage) => !stages.includes(stage))

    // combine them all
    return [...new Set(stages.concat(remaining))]
  }, [stages, grouped])

  const displayedColumns: ColumnInfo[] = useMemo(() => {
    return columns.map((column) => {
      return (
        defaultAccountColumns.find((c) => c.id === column || c.key === column) ||
        getItemDisplay(column, apps || [], 'account')
      )
    })
  }, [apps])

  const loadMore = useCallback(
    (stage, offset) => {
      setLoadingStage((prev) => ({ ...prev, [stage]: true }))

      const params = new URLSearchParams(window.location.search)

      const path = mergeParams(window.location.pathname + '/opportunities' + facets.queryString, {
        stage,
        offset,
        user: params.get('user') || undefined
      })
      get<Pick<Props, 'opportunities'>>(path)
        .then((response) => {
          if (!mounted()) return
          setOpportunities((opps) => opps.concat(response.opportunities))
        })
        .finally(() => {
          if (!mounted()) return
          setLoadingStage((prev) => ({ ...prev, [stage]: false }))
        })
    },
    [mounted, facets.queryString]
  )

  const columnCount = 1 + columns.length

  return (
    <PageLayout size="full" flush gap={0}>
      <PageTitle skipRendering>My Pipeline</PageTitle>

      <MCHeader viewId="pipeline" apps={props.apps} />

      <Stack paddingX={4} paddingBottom={8} spacing={0}>
        <Flex justifyContent="space-between" alignItems="flex-start" gap={4} paddingY={4}>
          <FilterPreview
            {...facets}
            kind="account"
            facetValuesPath="/accounts/facet-values?seen=0&no_defaults=1&no_range=1"
            apps={props.apps}
            canClearFilters={false}
            shouldShowLastSeenFilter={false}
          />

          <Flex gap={2} alignItems="center">
            {canSaveChanges && isDirty ? (
              <>
                <Button
                  flex="none"
                  variant="ghost"
                  size="sm"
                  color="gray.500"
                  _hover={{ color: 'gray.700' }}
                  onClick={discardChanges}
                >
                  Discard changes
                </Button>

                <form onSubmit={saveChanges}>
                  <AuthenticityToken />
                  <input type="hidden" name="_method" value="PUT" />
                  <Button
                    flex="none"
                    colorScheme="purple"
                    type="submit"
                    size="sm"
                    isDisabled={!canSaveChanges || !hasFilters}
                    isLoading={submitting}
                  >
                    Save
                  </Button>
                </form>
              </>
            ) : hasFilters && Object.keys(accountView.filters?.facets || {}).length > 0 ? (
              <form onSubmit={resetFilters}>
                <Button
                  flex="none"
                  variant="outline"
                  type="submit"
                  size="sm"
                  isDisabled={!canSaveChanges}
                  isLoading={submitting}
                >
                  Reset filters
                </Button>
              </form>
            ) : null}
          </Flex>
        </Flex>

        {loading ? (
          <Center maxHeight="100%" padding={10}>
            <Spinner size="md" color="gray.400" thickness="1.5px" />
          </Center>
        ) : (
          <Stack spacing={5}>
            {!hasFilters ? (
              <Box position="relative" paddingTop={20}>
                <Box position="absolute" top="0px" left="20px" display="flex" alignItems="flex-end" gap="2">
                  <DrawnArrowUpperLeft width="80px" color="purple.500" paddingBottom={2} />
                  <Text fontSize="sm" fontWeight="semibold" fontStyle="italic" color="purple.600">
                    Add filters to define
                    <br /> your territory
                  </Text>
                </Box>
                <EmptyState
                  size="md"
                  heading="Set up your Mission Control"
                  description={`Click "Add Filter" to define your territory and see your accounts.`}
                  icon={IconListSearch}
                />
              </Box>
            ) : opportunities.length > 0 ? (
              <TableContainer>
                <Table size="sm" height="1px">
                  <Thead fontSize="sm">
                    <Tr borderTop="1px solid" borderColor="gray.100">
                      <Th width="300px" py={2}>
                        Opportunity
                      </Th>
                      {displayedColumns.map((column) => (
                        <HeaderRenderer
                          key={column.key + ':header'}
                          columnId={column.id || column.key}
                          columnTitle={column.label}
                          columnIcon={column.icon}
                          columnType={column.type || facets.facetMappings[column.key]?.type}
                        />
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {allStages.map((stage) => {
                      const opps = grouped[stage] || []
                      if (opps.length === 0) {
                        return null
                      }

                      return (
                        <CollapsibleSection
                          key={stage}
                          header={
                            <HStack flex="1 1 auto" spacing={2}>
                              <Heading size="xs" fontWeight="semibold">
                                {stage}
                              </Heading>
                              <Badge size="xs" colorScheme="purple" rounded="md">
                                {counts[stage] || 0}
                              </Badge>
                            </HStack>
                          }
                          columnCount={columnCount}
                          defaultIsOpen
                        >
                          {opps.map((opp) => (
                            <Tr
                              key={opp.id + ':' + stage}
                              _hover={rowHover}
                              css={{
                                '& td:last-child': {
                                  position: 'relative'
                                },
                                '& td:last-child::after': {
                                  position: 'absolute',
                                  top: 0,
                                  bottom: 0,
                                  right: 0,
                                  content: '""',
                                  borderRight: '1px solid var(--chakra-colors-gray-200)'
                                }
                              }}
                            >
                              <LinkCell
                                href={accountPath(opp.account!)}
                                minW="280px"
                                maxWidth="350px"
                                borderLeft="1px solid"
                                borderColor="gray.200"
                              >
                                <HStack spacing={2} isTruncated>
                                  <CompanyAvatar size="xs" name={opp.account?.name} domain={opp.account?.domain} />

                                  <TextEllipsis tooltip fontSize="sm" fontWeight="semibold" lineHeight="1.2">
                                    {opp.name || opp.account?.name || opp.account?.domain}
                                  </TextEllipsis>
                                </HStack>
                              </LinkCell>

                              {displayedColumns.map((column) => {
                                return (
                                  <CellRenderer
                                    key={column.key + ':' + opp.id}
                                    columnId={column.id || column.key}
                                    columnKey={column.key}
                                    columnTitle={column.label}
                                    columnIcon={column.icon}
                                    columnType={column.type || facets.facetMappings[column.key]?.type}
                                    data={opp}
                                  />
                                )
                              })}
                            </Tr>
                          ))}
                          {opps.length < counts[stage] && (
                            <Tr className="bordered">
                              <Td colSpan={columnCount} p={0}>
                                <Box
                                  height="100%"
                                  display="flex"
                                  alignItems="center"
                                  borderX="1px solid"
                                  borderColor="gray.200"
                                  px={5}
                                  py={2}
                                  cursor="pointer"
                                  onClick={() => {
                                    if (loadingStage[stage]) return
                                    loadMore(stage, opps.length)
                                  }}
                                >
                                  <Button
                                    size="xs"
                                    variant="link"
                                    justifyContent="flex-start"
                                    color="gray.500"
                                    isLoading={loadingStage[stage] || false}
                                  >
                                    Load more
                                  </Button>
                                </Box>
                              </Td>
                            </Tr>
                          )}
                        </CollapsibleSection>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <EmptyState
                size="md"
                heading="No opportunities found"
                description="Try adjusting your filters to see opportunities for your accounts, if you have any."
                icon={IconCrosshair}
              />
            )}
          </Stack>
        )}
      </Stack>
    </PageLayout>
  )
}

interface HeaderRendererProps extends TableColumnHeaderProps {
  columnId: string
  columnTitle: string
  columnIcon?: TablerIcon | string
  columnType?: string
}

function HeaderRenderer(props: HeaderRendererProps) {
  const ThRenderer = columnRenderers[props.columnId]?.Th
  if (ThRenderer) {
    return <ThRenderer {...props} />
  }

  const { columnId, columnTitle, columnIcon, columnType, ...rest } = props
  const isNumeric = !!columnType && ['float', 'long', 'number', 'double'].includes(columnType)

  return (
    <Th isNumeric={isNumeric} {...rest}>
      <Flex gap={1} alignItems="center" isTruncated>
        {columnIcon && <Iconify icon={columnIcon} size={15} flex="none" />}
        <TextEllipsis maxW="100%" tooltip>
          {columnTitle}
        </TextEllipsis>
      </Flex>
    </Th>
  )
}

interface CellRendererProps {
  columnId: string
  columnKey: string
  columnTitle?: string
  columnIcon?: TablerIcon | string
  columnType?: string
  data: OpportunityWithAccount
}

function CellRenderer(props: CellRendererProps) {
  const CustomRenderer = columnRenderers[props.columnId]?.Td
  if (CustomRenderer) {
    return <CustomRenderer {...props} />
  }

  const { columnKey, columnType, data } = props
  const value = flatGet(data, columnKey)
  const isNumeric = !!columnType && ['float', 'long', 'number', 'double'].includes(columnType)
  const isDate = columnType === 'date'

  return (
    <Td isNumeric={isNumeric}>
      {isDate ? (
        <TimeAgo time={value} mode="full" />
      ) : typeof value === 'string' ? (
        value
      ) : Array.isArray(value) ? (
        value.filter(Boolean).join(', ')
      ) : typeof value === 'number' && isNumeric ? (
        value.toLocaleString()
      ) : (
        JSON.stringify(value)
      )}
    </Td>
  )
}

interface CollapsibleSectionProps extends UseDisclosureProps {
  header: string | React.ReactNode
  columnCount: number
}

export function CollapsibleSection(props: PropsWithChildren<CollapsibleSectionProps>) {
  const disclosure = useDisclosure(props)

  return (
    <Fragment>
      <Tr>
        <Td colSpan={props.columnCount} px={0} pb={0} paddingTop={6} borderBottom="0px" onClick={disclosure.onToggle}>
          <Box
            display="flex"
            alignItems="center"
            gap={0.5}
            cursor="pointer"
            bg="background.light"
            px={4}
            py={2.5}
            borderTop="1px solid"
            borderBottom={disclosure.isOpen ? 'none' : '1px solid'}
            borderX="1px solid"
            borderColor="gray.200"
            roundedTop="md"
            roundedBottom={disclosure.isOpen ? 'none' : 'md'}
          >
            <IconButton
              aria-label="Toggle section"
              size="xs"
              variant="ghost"
              rounded="full"
              color="gray.400"
              _hover={{ color: 'gray.800' }}
              _groupHover={{ color: 'gray.800' }}
              icon={disclosure.isOpen ? <IconChevronDown size={16} /> : <IconChevronRight size={16} />}
            />
            {props.header}
          </Box>
        </Td>
      </Tr>
      {disclosure.isOpen && props.children}
    </Fragment>
  )
}

interface LinkCellProps extends TableCellProps {
  href: string
  isExternal?: boolean
}

function LinkCell({ children, href, isExternal, ...props }: React.PropsWithChildren<LinkCellProps>) {
  const ignoreChildLinks = React.useCallback((event: MouseEvent<HTMLElement>) => {
    if (event.target === event.currentTarget) {
      return true
    }

    const targetAnchor = (event.target as HTMLElement).closest('a')
    if (targetAnchor !== event.currentTarget && event.currentTarget.contains(targetAnchor)) {
      event.preventDefault()
    }

    if (event.defaultPrevented) {
      return false
    }
  }, [])

  return (
    <Td height="100%" width="1px" padding={0}>
      <Box
        as={Link}
        isExternal={isExternal}
        href={href}
        cursor="pointer"
        display="flex"
        alignItems="center"
        width="100%"
        height="100%"
        textDecoration="none"
        fontWeight="inherit"
        onClick={ignoreChildLinks}
        style={{
          textDecoration: 'none'
        }}
        px={[2, 3]}
        py={2}
        isTruncated
        {...props}
      >
        {children}
      </Box>
    </Td>
  )
}
