import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Stack,
  Text,
  useDisclosure,
  UseDisclosureProps
} from '@chakra-ui/react'
import {
  IconAddressBook,
  IconBriefcaseFilled,
  IconBuildings,
  IconChevronDown,
  IconDots,
  IconDotsVertical,
  IconDownload,
  IconEdit,
  IconMapPinFilled,
  IconMilitaryRankFilled,
  IconPlus,
  IconTableDown,
  IconTrash,
  IconUserCircle,
  IconX
} from '@tabler/icons-react'
import { deepEqual } from 'fast-equals'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { formatNumber } from '../../../../lib/number-format'
import router from '../../../../lib/router'
import { Account } from '../../../../types/Account'
import { Apps } from '../../../../types/App'
import { Company } from '../../../../types/Profile'
import { useSavePersona } from '../../../data/use-personas'
import { filteredProspectsPath } from '../../../data/use-prospects'
import { useUrlFilters } from '../../../data/use-url-filters'
import { Breadcrumb } from '../../../ui/Breadcrumb'
import { Card } from '../../../ui/Card'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import { DeleteConfirmation } from '../../../ui/DeleteConfirmation'
import { OutreachIcon, SalesforceIcon } from '../../../ui/icons'
import { HubSpotIcon } from '../../../ui/icons/HubspotIcons'
import { SearchIcon } from '../../../ui/icons/SearchIcon'
import { projectPath } from '../../../ui/ProjectsContext'
import useLocation from '../../../ui/useLocation'
import useUpdateEffect from '../../../ui/useUpdateEffect'
import { FacetFilters } from '../../accounts'
import { mergeParams } from '../../icps/types'
import { CreatePersonaModal } from '../../personas/components/CreatePersonaModal'
import { RenamePersonaModal } from '../../personas/components/RenamePersonaModal'
import { convertUrlFacetsToPersonaFilters, FilterState } from '../../personas/persona-filters'
import { Persona, PersonaFilters } from '../../prospects/personas'
import { FilterPopover, FilterPopoverProps } from './FilterPopover'
import { ProspectsTableV2 } from './ProspectsTable'

interface Props {
  account: Account
  company: Company
  personas: Persona[]
  selectedPersona: Persona | null
  savedProspectsCount?: number
  apps: Apps
}

export function ExploreProspectsView(props: Props) {
  const facets = useUrlFilters({ initialRange: null })
  const [personas, setPersonas] = useState(props.personas)
  const [selectedPersona, setSelectedPersona] = useState<Persona | null>(props.selectedPersona)

  const location = useLocation()
  const newPersona = location.pathname.endsWith('/new')
  const savedProspectsSelected = location.pathname.endsWith('/saved')
  const everyoneSelected = !selectedPersona && !savedProspectsSelected

  const personaModal = useDisclosure()
  const applyFilters = facets.applyFilters
  const clearFilters = facets.clearFilters

  const [searchQuery, setSearchQuery] = useState(facets.query || '')
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300)

  useUpdateEffect(() => {
    if (debouncedSearchQuery !== facets.query) {
      facets.setQuery(debouncedSearchQuery)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery])

  const filters = useMemo(() => {
    const converted = convertUrlFacetsToPersonaFilters(facets.facetFilters || {})

    if (selectedPersona) {
      const existing = selectedPersona.filters || {}
      const combined = { ...existing, ...converted }
      return combined
    } else {
      return converted
    }
  }, [facets.facetFilters, selectedPersona])

  useEffect(() => {
    setSelectedPersona(props.selectedPersona)
  }, [props.selectedPersona])

  useEffect(() => {
    setPersonas(props.personas)
  }, [props.personas])

  const persona: Partial<Persona> = useMemo(() => {
    const personaFilters = cloneDeep(selectedPersona?.filters || {}) as PersonaFilters

    const facetKeys = [
      'title_keywords',
      'not_keywords',
      'job_title_role',
      'job_title_levels',
      'job_title_sub_role',
      'location_country',
      'location_region',
      'location_locality'
    ]

    facetKeys.forEach((facet) => {
      if (filters[facet]) {
        personaFilters[facet] = filters[facet]
      }
    })

    return {
      persona_type: 'filter',
      filters: personaFilters
    }
  }, [filters, selectedPersona])

  const selectPersona = useCallback(
    (persona: Persona | null) => {
      if (persona) {
        setSelectedPersona(persona)
        router.visit(projectPath(`/prospector?domain=${props.company.domain}&persona=${persona.id}`))
      } else {
        setSelectedPersona(null)
        router.visit(projectPath(`/prospector?domain=${props.company.domain}`))
      }
    },
    [props.company.domain]
  )

  const startNewPersona = useCallback(() => {
    clearFilters()
    router.visit(projectPath(`/prospector/new?domain=${props.company.domain}`))
  }, [props.company.domain, clearFilters])

  const { isLoading: saving, mutateAsync: savePersona } = useSavePersona()

  const updatePersona = useCallback(async () => {
    if (!selectedPersona) return

    const updatedPersona: Partial<Persona> = {
      ...selectedPersona,
      persona_type: 'filter',
      filters: persona.filters
    }

    const res = await savePersona(updatedPersona)
    setSelectedPersona(res.persona)
    setPersonas((personas) => {
      return personas.map((p) => {
        if (p.id === res.persona.id) {
          return res.persona
        } else {
          return p
        }
      })
    })
    clearFilters()
  }, [selectedPersona, persona, savePersona, clearFilters])

  const onRename = useCallback((updates: Partial<Persona>) => {
    setSelectedPersona((prev) => {
      if (prev?.id === updates.id) {
        return { ...prev, name: updates.name } as Persona
      } else {
        return prev
      }
    })
    setPersonas((personas) => {
      return personas.map((p) => {
        if (p.id === updates.id) {
          return { ...p, name: updates.name }
        } else {
          return p
        }
      })
    })
  }, [])

  const onFilterChange = useCallback(
    (filters: Record<string, FilterState>) => {
      // convert the filters to the format that the url filters expect
      const facetFilters: FacetFilters = {}

      for (const [facet, filter] of Object.entries(filters)) {
        if (filter.operator === 'must') {
          facetFilters[facet] = filter.values
        } else if (filter.operator === 'must_not') {
          facetFilters[facet] = { not: filter.values }
        }
      }

      applyFilters(facetFilters)
    },
    [applyFilters]
  )

  const emptyFilters = isEmpty(persona.filters)

  const hasChanges = useMemo(() => {
    if (selectedPersona) {
      return !deepEqual(selectedPersona.filters, persona.filters)
    }

    return !isEmpty(persona.filters)
  }, [selectedPersona, persona])

  return (
    <Flex flex="1 1 auto" overflow="hidden">
      <Box
        bg="white"
        borderRight="1px solid"
        borderColor="gray.200"
        padding={6}
        paddingBottom={0}
        minW="320px"
        width="360px"
        overflow="auto"
        display="flex"
        flexDir="column"
        gap={8}
      >
        <Box flex="none" isTruncated>
          {newPersona ? (
            <Breadcrumb
              display="flex"
              fontSize="13px"
              paths={[
                {
                  path: projectPath(`/prospector?domain=${props.company.domain}`),
                  title: 'Prospector'
                },
                { path: projectPath(`/prospector/new?domain=${props.company.domain}`), title: 'New Persona' }
              ]}
            />
          ) : selectedPersona ? (
            <Breadcrumb
              display="flex"
              fontSize="13px"
              paths={[
                {
                  path: projectPath(`/prospector?domain=${props.company.domain}`),
                  title: 'Prospector'
                },
                {
                  path: projectPath(`/prospector?domain=${props.company.domain}&persona=${selectedPersona.id}`),
                  title: selectedPersona.name || 'Unnamed Persona'
                }
              ]}
            />
          ) : (
            <Breadcrumb
              display="flex"
              fontSize="13px"
              paths={[
                {
                  path: projectPath(`/prospector?domain=${props.company.domain}`),
                  title: 'Prospector'
                },
                {
                  path: projectPath(`/prospector?domain=${props.company.domain}`),
                  title: 'Explore'
                }
              ]}
            />
          )}
        </Box>

        {personas.length > 0 && !newPersona && (
          <Flex flexDir="column" gap={1}>
            <Text fontSize="sm" fontWeight="medium">
              Personas
            </Text>

            <Stack spacing={1}>
              <Box
                paddingLeft={2.5}
                paddingRight={1.5}
                paddingY={2}
                rounded="md"
                cursor="pointer"
                border="1px solid"
                borderColor={everyoneSelected ? 'purple.300' : 'gray.200'}
                bg={everyoneSelected ? 'purple.50' : 'transparent'}
                color={everyoneSelected ? 'purple.600' : 'gray.600'}
                opacity={everyoneSelected ? 0.8 : 1}
                _hover={everyoneSelected ? { opacity: 1 } : { bg: 'gray.50', borderColor: 'gray.200' }}
                onClick={() => selectPersona(null)}
                isTruncated
              >
                <HStack spacing={1.5} isTruncated>
                  <Icon as={IconBuildings} boxSize={4} />
                  <Text flex="1 1 auto" fontSize="sm" fontWeight="medium" isTruncated>
                    Everyone at {props.company.name || props.company.domain}
                  </Text>
                </HStack>
              </Box>
              {!!props.savedProspectsCount && (
                <Box
                  paddingLeft={2.5}
                  paddingRight={1.5}
                  paddingY={2}
                  rounded="md"
                  cursor="pointer"
                  border="1px solid"
                  borderColor={savedProspectsSelected ? 'purple.300' : 'gray.200'}
                  bg={savedProspectsSelected ? 'purple.50' : 'transparent'}
                  color={savedProspectsSelected ? 'purple.600' : 'gray.600'}
                  opacity={savedProspectsSelected ? 0.8 : 1}
                  _hover={savedProspectsSelected ? { opacity: 1 } : { bg: 'gray.50', borderColor: 'gray.200' }}
                  onClick={() => {
                    const path = mergeParams(projectPath(`/prospector/saved`) + location.search, {
                      page: '1',
                      persona: undefined
                    })
                    router.visit(path)
                  }}
                  isTruncated
                >
                  <HStack spacing={1.5} isTruncated>
                    <Icon as={IconAddressBook} boxSize={4} />
                    <Text flex="1 1 auto" fontSize="sm" fontWeight="medium" isTruncated>
                      My Saved Prospects
                    </Text>

                    <Badge flex="none" variant="pill" colorScheme={savedProspectsSelected ? 'purple' : 'gray'}>
                      {formatNumber(props.savedProspectsCount)}
                    </Badge>
                  </HStack>
                </Box>
              )}

              {personas.map((persona) => {
                const isCurrent = persona.id === selectedPersona?.id
                return (
                  <Box
                    key={persona.id}
                    role="group"
                    position="relative"
                    paddingLeft={2.5}
                    paddingRight={1.5}
                    paddingY={2}
                    rounded="md"
                    cursor="pointer"
                    border="1px solid"
                    borderColor={isCurrent ? 'purple.300' : 'gray.200'}
                    bg={isCurrent ? 'purple.50' : 'transparent'}
                    color={isCurrent ? 'purple.600' : 'gray.600'}
                    opacity={isCurrent ? 0.8 : 1}
                    _hover={isCurrent ? { opacity: 1 } : { bg: 'gray.50', borderColor: 'gray.200' }}
                    onClick={() => selectPersona(persona)}
                    isTruncated
                  >
                    <HStack spacing={1.5} isTruncated>
                      <Icon as={IconUserCircle} boxSize={4} />
                      <Text flex="1 1 auto" fontSize="sm" fontWeight="medium" isTruncated>
                        {persona.name}
                      </Text>
                      {persona.total_count_for_company && !hasChanges && (
                        <Badge
                          flex="none"
                          variant="pill"
                          colorScheme={isCurrent ? 'purple' : 'gray'}
                          _groupHover={isCurrent ? undefined : { display: 'none' }}
                        >
                          {persona.total_count_for_company == 10_000
                            ? '10k+'
                            : formatNumber(persona.total_count_for_company)}
                        </Badge>
                      )}
                      <Box
                        position={isCurrent ? 'static' : 'absolute'}
                        right={1.5}
                        display="flex"
                        flex="none"
                        visibility={isCurrent ? 'visible' : 'hidden'}
                        pointerEvents={isCurrent ? 'auto' : 'none'}
                        _groupHover={{ visibility: 'visible', pointerEvents: 'auto' }}
                      >
                        <PersonaOverflowMenu persona={persona} onRename={onRename} size="tiny" variant="ghost" />
                      </Box>
                    </HStack>
                  </Box>
                )
              })}
            </Stack>
          </Flex>
        )}

        <Stack>
          <Text fontSize="sm" fontWeight="medium">
            Filters
          </Text>

          <Card padding={0}>
            <Stack divider={<Divider />} spacing={0}>
              <FilterMenu
                domain={props.company.domain}
                filters={filters}
                onChange={onFilterChange}
                facet="job_title_levels"
                icon={IconMilitaryRankFilled}
              >
                Seniority
              </FilterMenu>
              <FilterMenu
                domain={props.company.domain}
                filters={filters}
                onChange={onFilterChange}
                facet="job_title_role"
                icon={IconBriefcaseFilled}
              >
                Role
              </FilterMenu>
              <FilterMenu
                domain={props.company.domain}
                filters={filters}
                onChange={onFilterChange}
                facet="job_title_sub_role"
                icon={IconBriefcaseFilled}
              >
                Job Function
              </FilterMenu>
              <FilterMenu
                domain={props.company.domain}
                filters={filters}
                onChange={onFilterChange}
                facet="title_keywords"
                notFacet="not_keywords"
                inputType="keyword"
                icon={IconBriefcaseFilled}
              >
                Title Keywords
              </FilterMenu>
              <FilterMenu
                domain={props.company.domain}
                filters={filters}
                onChange={onFilterChange}
                facet="location_country"
                icon={IconMapPinFilled}
              >
                Country
              </FilterMenu>
              <FilterMenu
                domain={props.company.domain}
                filters={filters}
                onChange={onFilterChange}
                facet="location_region"
                icon={IconMapPinFilled}
              >
                State / Region
              </FilterMenu>
              <FilterMenu
                domain={props.company.domain}
                filters={filters}
                onChange={onFilterChange}
                facet="location_locality"
                icon={IconMapPinFilled}
              >
                City
              </FilterMenu>
            </Stack>
          </Card>
        </Stack>

        <Box marginTop="auto" paddingTop={4} position="sticky" bottom={0} paddingBottom={6} bg="white">
          <Flex gap={3} justifyContent="space-between">
            {selectedPersona?.id && hasChanges ? (
              <Button rounded="lg" colorScheme="purple" onClick={updatePersona} isLoading={saving}>
                Save changes
              </Button>
            ) : newPersona || hasChanges ? (
              <Button
                rounded="lg"
                colorScheme={emptyFilters ? 'gray' : 'purple'}
                variant={emptyFilters ? 'outline' : 'solid'}
                leftIcon={<Icon as={IconPlus} boxSize={4} />}
                iconSpacing={1}
                isDisabled={emptyFilters}
                onClick={personaModal.onOpen}
              >
                Save Persona
              </Button>
            ) : (
              <Button
                width={selectedPersona ? 'auto' : 'full'}
                rounded="lg"
                variant="outline"
                leftIcon={<Icon as={IconPlus} boxSize={4} color="purple.600" />}
                iconSpacing={1}
                onClick={startNewPersona}
              >
                Start new search
              </Button>
            )}

            {selectedPersona?.id ? (
              <Flex gap={3} justifyContent="space-between">
                {hasChanges && (
                  <Button
                    flex="none"
                    rounded="lg"
                    variant="outline"
                    onClick={() => {
                      clearFilters()
                    }}
                  >
                    Discard
                  </Button>
                )}
                <PersonaOverflowMenu persona={selectedPersona} onRename={onRename} />
              </Flex>
            ) : newPersona || hasChanges ? (
              <Button
                flex="none"
                rounded="lg"
                variant="outline"
                onClick={() => {
                  selectPersona(null)
                }}
              >
                {emptyFilters ? 'Back' : 'Discard'}
              </Button>
            ) : null}
          </Flex>

          <CreatePersonaModal
            {...personaModal}
            persona={persona}
            onSave={(savedPersona) => {
              selectPersona(savedPersona)
            }}
          />
        </Box>
      </Box>

      <Box width="100%" overflow="auto" mt="-1px">
        <HStack paddingY={2} paddingX={2} justifyContent="space-between" spacing="4">
          <Box flex="1">
            <InputGroup size="sm" flex="1 1 100px">
              <InputLeftElement width="7" pointerEvents="none" color="gray.400">
                <SearchIcon boxSize={3.5} />
              </InputLeftElement>
              <Input
                size="sm"
                fontSize="13px"
                background="white"
                outline="none"
                border="none"
                roundedBottom={0}
                focusBorderColor="transparent"
                placeholder="Search"
                paddingLeft={7}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value)
                }}
              />

              {searchQuery && (
                <InputRightElement>
                  <IconButton
                    size="xs"
                    aria-label="Clear search"
                    variant="ghost"
                    color="gray.400"
                    _hover={{ color: 'gray.600' }}
                    onClick={() => setSearchQuery('')}
                    icon={<IconX size={16} />}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </Box>

          <Flex>
            <Menu size="sm">
              <MenuButton
                as={Button}
                leftIcon={<Icon as={IconDownload} size={16} />}
                rightIcon={<Icon as={IconChevronDown} size={16} />}
                iconSpacing={1.5}
                size="sm"
                variant="outline"
                colorScheme="lightPurple"
                mr="2"
              >
                Export
              </MenuButton>
              <Portal>
                <MenuList zIndex="popover">
                  <MenuItem
                    icon={<IconTableDown size="14" color="green" />}
                    onClick={() => {
                      const url = filteredProspectsPath(props.company.domain, {
                        filters: facets.facetFilters,
                        format: 'csv',
                        persona: selectedPersona?.id,
                        search: searchQuery,
                        only_saved: savedProspectsSelected,
                        page: facets.page
                      })
                      window.open(url)
                    }}
                  >
                    Download CSV
                  </MenuItem>
                  <MenuItem isDisabled icon={<SalesforceIcon size="14" color="salesforce" />}>
                    <Badge variant={'subtle'} colorScheme={'gray'}>
                      SOON
                    </Badge>{' '}
                    Add to Salesforce
                  </MenuItem>
                  <MenuItem isDisabled icon={<HubSpotIcon size="14" color="orange" />}>
                    <Badge colorScheme={'gray'}>SOON</Badge> Add to HubSpot
                  </MenuItem>

                  <MenuItem isDisabled icon={<OutreachIcon size="14" />}>
                    <Badge colorScheme={'gray'}>SOON</Badge> Add to Outreach Sequence
                  </MenuItem>

                  <MenuItem isDisabled icon={<CompanyAvatar domain={'apollo.io'} size="14px" />}>
                    <Badge colorScheme={'gray'}>SOON</Badge> Add to Apollo Sequence
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </Flex>
        </HStack>

        <ProspectsTableV2 account={props.account} company={props.company} persona={selectedPersona} apps={props.apps} />
      </Box>
    </Flex>
  )
}

function FilterMenu(props: React.PropsWithChildren<FilterPopoverProps>) {
  return (
    <Box paddingY={1.5} paddingX={1.5}>
      <FilterPopover {...props} showPreview />
    </Box>
  )
}

interface PersonaOverflowMenu extends UseDisclosureProps {
  persona: Persona
  onRename?: (updates: Partial<Persona>) => void
  size?: 'tiny' | 'sm'
  variant?: 'outline' | 'ghost'
}

function PersonaOverflowMenu(props: PersonaOverflowMenu) {
  const menuDisclosure = useDisclosure(props)
  const renameModal = useDisclosure()
  const destroyModal = useDisclosure()

  return (
    <Box
      display="inline-flex"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <RenamePersonaModal persona={props.persona} {...renameModal} onSave={props.onRename} />
      <DeleteConfirmation
        title={`Remove "${props.persona.name}"?`}
        confirmLabel="Yes, continue"
        deletePath={projectPath(`/personas/${props.persona.id}`)}
        isCentered
        {...destroyModal}
      >
        Are you sure you want to delete the "{props.persona.name}" persona?
      </DeleteConfirmation>

      <Menu {...menuDisclosure}>
        <MenuButton
          size={props.size || 'md'}
          rounded={props.size === 'tiny' ? 'base' : 'lg'}
          flex="none"
          as={IconButton}
          icon={props.size === 'tiny' ? <IconDotsVertical size={14} /> : <IconDots size={16} />}
          variant={props.variant || 'outline'}
          borderColor="gray.200"
        />
        <Portal>
          <MenuList fontSize="sm" zIndex="popover">
            <MenuItem icon={<IconEdit size={16} />} iconSpacing={1.5} onClick={renameModal.onOpen}>
              Rename Persona
            </MenuItem>
            <MenuItem icon={<IconTrash size={16} />} iconSpacing={1.5} color="red.500" onClick={destroyModal.onOpen}>
              Delete Persona…
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  )
}
