import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Stack,
  Switch,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { IconDragDrop2, IconPlus, IconTrashX, IconUserCheck, IconUserSearch } from '@tabler/icons-react'
import { arrayMoveImmutable } from 'array-move'
import React, { useCallback, useMemo, useState, useRef } from 'react'
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort'
import { toast } from 'sonner'
import { put } from '../../../../lib/api'
import { App, Apps } from '../../../../types/App'
import {
  AdvancedRoutingRules,
  AutoOutboundRule,
  CadenceRules,
  CRMRules,
  IntentRules,
  QualificationRules,
  RoutingRules,
  SlackRules,
  TriggerRules
} from '../../../../types/AutoOutbound'
import { useFacets } from '../../../data/use-facets'
import { useSlack } from '../../../data/use-slack'
import { useTeams } from '../../../data/use-teams'
import { User, useUsers } from '../../../data/use-users'
import Avatar from '../../../ui/Avatar'
import { Card, GrayCard } from '../../../ui/Card'
import { CardRadioGroup } from '../../../ui/CardRadioGroup'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import { HelpTooltip } from '../../../ui/HelpTooltip'
import { FacetFilters } from '../../accounts'
import { FilterPreview } from '../../accounts/components/FilterPreview'
import { ChannelPicker } from '../../account_view_subscriptions/components/Slack'
import { PopupConnectDialog } from '../../apps/components/ConnectOauthAppDialog'
import { OutreachSequence } from '../../follow_rules/components/outreach/add-to-sequence'
import { useAutoProspectingEntitlements } from '../../prospects/entitlements'
import { AutoProspectingSetting } from '../../prospects/settings'
import { TriggerMultiSelect } from '../../slack_alerts/components/DefinitionForm'
import { AuthenticityToken } from '../../../ui/AuthenticityToken'
import { autoOutboundRulePath, autoOutboundPath } from '../lib/path-helpers'
import { RulePreview } from './audience-preview'
import { AutoProspect } from './auto-prospect'
import { CampaignDestinations } from './campaign-destinations'

interface DeduplicationRulesProps extends CadenceRules {
  ruleId?: string
}

function DeduplicationRulesSetup(props: DeduplicationRulesProps) {
  return (
    <Stack spacing="4">
      <FormControl>
        <FormLabel>Enrollment and exclusivity</FormLabel>
        <FormHelperText>
          Define how often a prospect can be enrolled across this and other Auto Outbound rules.
        </FormHelperText>
      </FormControl>

      <GrayCard>
        <Stack spacing={6}>
          <FormControl>
            <FormLabel>Prospects can be enrolled...</FormLabel>
            <Select size="sm" bg="white" name="cadence_rules[cadence]" defaultValue={props.cadence ?? 'quarter'}>
              <option value="week">No more than once per week</option>
              <option value="month">No more than once per month</option>
              <option value="quarter">No more than once every 3 months</option>
              <option value="ever">Only once ever</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel>Should enrolled prospects be allowed to enroll in other Auto Outbound rules?</FormLabel>
            <Select
              size="sm"
              bg="white"
              name="cadence_rules[exclusivity]"
              defaultValue={props.exclusivity ?? 'not_exclusive'}
            >
              <option value="not_exclusive">Yes, prospects can be enrolled here and in other rules</option>
              <option value="exclusive">No, enrolled prospects should be exclusive to this rule</option>
            </Select>
          </FormControl>
        </Stack>
      </GrayCard>
    </Stack>
  )
}

interface GlobalEligibilityProps extends QualificationRules {
  ruleId?: string
  apps: Apps
  autoProspectSetting?: AutoProspectingSetting
  onAutoProspectSettingChange: (setting: AutoProspectingSetting) => void
  onProspectingStrategyChange: (strategy: 'identified' | 'anonymous') => void
  prospectingStrategy: 'identified' | 'anonymous'
}

function GlobalEligibilitySetup(props: GlobalEligibilityProps) {
  const [workEmailsOnly, setWorkEmailsOnly] = useState<boolean | undefined>(props.work_emails_only ?? false)
  const [excludeInternalUsers, setExcludeInternalUsers] = useState<boolean | undefined>(
    props.exclude_internal_users ?? false
  )

  const accountRules = useFacets({
    facet_filters: props.account_rules
  })

  const { autoProspectingEnabled } = useAutoProspectingEntitlements()

  const autoProspectingAvailable = useMemo(
    () => autoProspectingEnabled || !!props.autoProspectSetting,
    [autoProspectingEnabled, props.autoProspectSetting]
  )

  return (
    <Stack spacing="8">
      <Stack>
        <FormControl as={Stack} spacing="0">
          <FormLabel>Global Eligibility</FormLabel>
          <FormHelperText>Define who is eligible to be contacted by this auto-outbound rule</FormHelperText>
        </FormControl>

        <GrayCard as={Stack}>
          <Heading size="xs">Accounts</Heading>
          <FilterPreview kind="account" {...accountRules} />
        </GrayCard>
      </Stack>

      <Divider />

      <Stack spacing="12">
        <Stack>
          <Heading size="xs">Prospecting</Heading>

          <CardRadioGroup
            size="sm"
            direction="row"
            value={props.prospectingStrategy}
            onChange={(e) => {
              props.onProspectingStrategyChange(e as 'identified' | 'anonymous')
            }}
            colorScheme="purple"
            options={[
              {
                label: (
                  <Stack>
                    <HStack spacing="2">
                      <IconUserCheck size="20" />
                      <Text fontWeight={'semibold'} fontSize="sm">
                        Identified Visitors
                      </Text>
                    </HStack>
                    <Text fontSize="sm" color="gray.500">
                      Visitors who have been identified by your tracking code.
                    </Text>
                  </Stack>
                ),
                value: 'identified'
              },
              {
                isDisabled: !autoProspectingAvailable,
                label: (
                  <Stack>
                    <HStack spacing="2">
                      <IconUserSearch size="20" />
                      <Text fontWeight={'semibold'} fontSize="sm">
                        Anonymous Visitors
                      </Text>
                    </HStack>
                    <Text fontSize="sm" color="gray.500">
                      Automatically prospect visitors within an account that match your ICP criteria.
                    </Text>
                  </Stack>
                ),
                value: 'anonymous'
              }
            ]}
          />
        </Stack>

        <Stack as={GrayCard} spacing="4">
          {props.prospectingStrategy === 'identified' && (
            <IdentifiedProspects
              excludeInternalUsers={excludeInternalUsers}
              setExcludeInternalUsers={setExcludeInternalUsers}
              workEmailsOnly={workEmailsOnly}
              setWorkEmailsOnly={setWorkEmailsOnly}
              profile_rules={props.profile_rules}
            />
          )}

          {props.prospectingStrategy === 'anonymous' && (
            <AutoProspect onChange={props.onAutoProspectSettingChange} setting={props.autoProspectSetting} />
          )}
        </Stack>

        <input
          type="hidden"
          name="qualification_rules[account_rules]"
          value={JSON.stringify(accountRules.facetFilters)}
        />
      </Stack>
    </Stack>
  )
}

interface CRMRulesProps extends CRMRules {
  ruleId?: string
  apps: Apps

  crmApps: App[]
  selectedCRM: App | null
  setSelectedCRM: (app: App | null) => void

  prospectingStrategy: 'identified' | 'anonymous'
}

function CRMRulesSetup(props: CRMRulesProps) {
  const { crmApps, selectedCRM, setSelectedCRM } = props

  const [noActiveDeals, setNoActiveDeals] = useState<boolean | undefined>(props.rules?.no_active_deals ?? false)
  const [isNotCustomer, setIsNotCustomer] = useState<boolean | undefined>(props.rules?.is_not_customer ?? false)
  const [ignoreContactedSinceToggle, setIgnoreContactedSinceToggle] = useState<boolean | undefined>(
    !!props.rules?.ignore_contacted_since
  )

  const advancedAccountRules = useFacets({
    facet_filters: props.advanced_rules
  })

  const advancedContactRules = useFacets({
    facet_filters: props.advanced_contact_rules,
    facetCloudPath: '/profiles/facet-cloud'
  })

  const usingSalesforce = useMemo(() => selectedCRM?.app_module.includes('Salesforce'), [selectedCRM])
  const usingHubspot = useMemo(() => selectedCRM?.app_module.includes('Hubspot'), [selectedCRM])

  return (
    <Box>
      <Stack spacing="4">
        <FormControl as={Stack} spacing="0">
          <FormLabel>CRM Rules</FormLabel>
          <FormHelperText>Define who is allowed to be outbounded based on CRM rules</FormHelperText>
        </FormControl>

        <Stack as={GrayCard} spacing="8">
          {crmApps.length > 1 && (
            <Stack as={FormControl}>
              <FormLabel>Select your CRM</FormLabel>

              <CardRadioGroup
                size="sm"
                direction="row"
                value={selectedCRM?.app_module ?? ''}
                onChange={(e) => {
                  const app = crmApps.find((app) => app.app_module === e)
                  setSelectedCRM(app ?? null)
                }}
                colorScheme="purple"
                options={crmApps.map((app) => {
                  return {
                    label: (
                      <HStack spacing="2">
                        <Avatar src={app.logo} size="xs" />
                        <Text fontWeight={'semibold'} fontSize="sm">
                          {app.title}
                        </Text>
                      </HStack>
                    ),
                    value: app.app_module
                  }
                })}
              />
            </Stack>
          )}

          <Stack spacing="5">
            <Heading size="xs">Rule Presets</Heading>

            <FormControl>
              <HStack alignItems="flex-start">
                <Checkbox
                  id="crm_rules[no_active_deals]"
                  name="crm_rules[no_active_deals]"
                  marginTop="2px"
                  value={noActiveDeals ? '1' : '0'}
                  isChecked={noActiveDeals}
                  onChange={(e) => {
                    setNoActiveDeals(e.target.checked)
                  }}
                />
                <Stack alignItems="flex-start" spacing={0}>
                  <FormLabel marginBottom={1} htmlFor="crm_rules[no_active_deals]">
                    Ignore active deals
                  </FormLabel>
                  {usingSalesforce && (
                    <Text fontSize="sm" color="gray.500">
                      Excludes accounts that have open opportunities.
                    </Text>
                  )}
                  {usingHubspot && (
                    <Text fontSize="sm" color="gray.500">
                      Excludes companies that have open deals.
                    </Text>
                  )}
                </Stack>
              </HStack>
            </FormControl>

            <FormControl>
              <HStack alignItems="flex-start">
                <Checkbox
                  id="crm_rules[is_not_customer]"
                  name="crm_rules[is_not_customer]"
                  marginTop="2px"
                  value={isNotCustomer ? '1' : '0'}
                  isChecked={isNotCustomer}
                  onChange={(e) => {
                    setIsNotCustomer(e.target.checked)
                  }}
                />
                <Stack alignItems="flex-start" spacing={0}>
                  <FormLabel marginBottom={1} htmlFor="crm_rules[is_not_customer]">
                    Ignore customers
                  </FormLabel>
                  {usingSalesforce && (
                    <Text fontSize="sm" color="gray.500">
                      Excludes any accounts whose Account Type is equal to `Customer`.
                    </Text>
                  )}

                  {usingHubspot && (
                    <Text fontSize="sm" color="gray.500">
                      Excludes any companies whose LifeCycle Stage is equal to `Customer`.
                    </Text>
                  )}
                </Stack>
              </HStack>
            </FormControl>

            <FormControl>
              <HStack alignItems="flex-start">
                <Checkbox
                  id="ignore_contacted_since_toggle"
                  marginTop="2px"
                  isChecked={ignoreContactedSinceToggle}
                  onChange={(e) => {
                    setIgnoreContactedSinceToggle(e.target.checked)
                  }}
                />
                <Stack alignItems="flex-start" spacing={0}>
                  <FormLabel marginBottom={1} htmlFor="ignore_contacted_since_toggle">
                    Ignore accounts recently contacted
                  </FormLabel>

                  {usingSalesforce && (
                    <Text fontSize="sm" color="gray.500" paddingBottom={2.5}>
                      Excludes accounts that have been contacted in the last N days based on the LastActivityDate field
                      on the Salesforce Account object.
                    </Text>
                  )}
                  {usingHubspot && (
                    <Text fontSize="sm" color="gray.500" paddingBottom={2.5}>
                      Excludes companies that have been contacted in the last N days based on the `notes_last_contacted`
                      field on the HubSpot Company object.
                    </Text>
                  )}
                  {ignoreContactedSinceToggle && (
                    <Flex>
                      <Select
                        defaultValue={props.rules?.ignore_contacted_since ?? '7'}
                        name="crm_rules[ignore_contacted_since]"
                        size="xs"
                        bg="white"
                        maxW="400"
                        rounded="md"
                      >
                        <option value="7">In the past 7 days</option>
                        <option value="30">In the past 30 days</option>
                      </Select>
                    </Flex>
                  )}
                </Stack>
              </HStack>
            </FormControl>
          </Stack>

          <Stack spacing="1">
            <Heading size="xs">[Account] Advanced Rules</Heading>
            <FilterPreview
              kind="account"
              {...advancedAccountRules}
              apps={props.apps}
              shouldShowActiveVisitorsFilters={false}
              shouldShowLastSeenFilter={false}
              shouldAllowFreeEntry={false}
              shouldShowCompanyFilters={false}
              shouldShowUserAttributeFilters={false}
              shouldShowCsvUploadFilters={false}
              shouldShowIntentFilters={false}
              shouldShowICPFilters={false}
              shouldShowListFilters={false}
            />
          </Stack>

          {props.prospectingStrategy === 'identified' && (
            <Stack spacing="1">
              <Heading size="xs">[Contact] Advanced Rules</Heading>
              <FilterPreview
                kind="profile"
                {...advancedContactRules}
                apps={props.apps}
                shouldShowActiveVisitorsFilters={false}
                shouldShowLastSeenFilter={false}
                shouldAllowFreeEntry={false}
                shouldShowCompanyFilters={false}
                shouldShowUserAttributeFilters={false}
                shouldShowIntentFilters={false}
                shouldShowCsvUploadFilters={false}
                shouldShowICPFilters={false}
                shouldShowListFilters={false}
              />
            </Stack>
          )}
        </Stack>
      </Stack>

      {selectedCRM && <input type="hidden" name="crm_rules[crm_app_module]" value={selectedCRM?.app_module} />}

      <input type="hidden" name="crm_rules[advanced_rules]" value={JSON.stringify(advancedAccountRules.facetFilters)} />
      <input
        type="hidden"
        name="crm_rules[advanced_contact_rules]"
        value={JSON.stringify(advancedContactRules.facetFilters)}
      />
    </Box>
  )
}

interface IntentRulesProps extends IntentRules {
  ruleId?: string
  initialKQLDefinitionIds: string[]
  setSelectedKQLDefinitionIds: (ids: string[]) => void
}

function IntentRulesSetup(props: IntentRulesProps) {
  const [kqlIds, setKqlIds] = useState<string[]>(props.initialKQLDefinitionIds)

  return (
    <Box>
      <Stack spacing="4">
        <FormControl as={Stack} spacing="0">
          <FormLabel>Intent Rules</FormLabel>
          <FormHelperText>Define who is allowed to be outbounded based on intent rules</FormHelperText>
        </FormControl>

        <Stack w="100%" as={GrayCard} borderWidth="thin">
          <TriggerMultiSelect
            onChange={(incomingKQLIDs, _isAnyIntent) => {
              if (incomingKQLIDs.length === 0 && kqlIds.length === 0) {
                return
              }

              setKqlIds(incomingKQLIDs)
              props.setSelectedKQLDefinitionIds(incomingKQLIDs)
            }}
            skipForms
            skipCreation
            skipAnyIntent
            selectedSignalIds={kqlIds}
            anyIntent={false}
          />
        </Stack>
      </Stack>

      {kqlIds.map((kqlId) => {
        return <input key={kqlId} type="hidden" name="intent_rules[kql_ids][]" value={kqlId} />
      })}
    </Box>
  )
}

interface TriggerRulesProps extends TriggerRules {
  ruleId?: string
}

function TriggerRulesSetup(props: TriggerRulesProps) {
  return (
    <FormControl as={Stack} spacing="4">
      <Stack spacing="0">
        <FormLabel>Timing</FormLabel>
        <FormHelperText>
          Optionally delay the execution of this automation by a certain number of minutes after the intent happens.
        </FormHelperText>
      </Stack>

      <Stack w="100%" as={GrayCard} borderWidth="thin">
        <Heading size="xs">Delay (minutes)</Heading>
        <Input
          bg="white"
          size="sm"
          type="number"
          name="trigger_rules[delay_minutes]"
          defaultValue={props.delay_minutes || ''}
        />
      </Stack>
    </FormControl>
  )
}

export interface RoutingRulesProps extends RoutingRules {
  apps: Apps
  selectedCRM: App | null
  onSkipChange: (skipping: boolean) => void
}

export function RoutingRulesSetup(props: RoutingRulesProps) {
  const { selectedCRM } = props
  const [routingOption, setRoutingOption] = React.useState(props.routing_option ?? 'crm-owner')

  const { data: usersData, isLoading: loadingUsers } = useUsers({ cached: true })
  const [selectedOwnerEmail, setSelectedOwnerEmail] = React.useState<string | null>(props.owner_email ?? null)

  const { data: teamsData, isLoading: loadingTeams } = useTeams()
  const teams = useMemo(() => teamsData?.teams ?? [], [teamsData])
  const [selectedTeamId, setSelectedTeamId] = React.useState<string | null>(props.team_id ?? null)

  const [advancedRules, setAdvancedRules] = React.useState<AdvancedRoutingRules[]>(props.advanced ?? [])

  const usingSalesforce = useMemo(() => selectedCRM?.app_module.includes('Salesforce'), [selectedCRM])
  const usingHubspot = useMemo(() => selectedCRM?.app_module.includes('Hubspot'), [selectedCRM])
  const hasOutreach = useMemo(() => props.apps?.['Apps::Outreach::App']?.connected, [props.apps])

  return (
    <Stack spacing="4">
      <Heading size="xs">Sender</Heading>
      <RadioGroup
        value={routingOption}
        isDisabled={props.skip}
        onChange={(value) =>
          setRoutingOption(value as 'crm-owner' | 'outreach-owner' | 'user' | 'round-robin' | 'advanced')
        }
        size="sm"
        fontSize="xs"
        name="routing_rules[routing_option]"
        position={'relative'}
      >
        <Stack bg="white" rounded="lg" p="4" borderWidth={'thin'} w="100%">
          {props.skip && (
            <>
              <Text>Skipping sender for Auto Outbound. Koala will not select a sender for this rule.</Text>
              <Divider />
            </>
          )}
          <Radio value="crm-owner">
            {usingSalesforce && 'Salesforce Owner'}
            {usingHubspot && 'HubSpot Owner'}
            {!props.selectedCRM && 'CRM Owner'}
          </Radio>
          {hasOutreach && <Radio value="outreach-owner">Outreach Owner</Radio>}
          <Radio value="user">Select a User</Radio>
          <Radio value="round-robin">Round Robin</Radio>
          <Radio value="advanced">Advanced</Radio>
        </Stack>
      </RadioGroup>

      {routingOption === 'crm-owner' && (
        <>
          {usingSalesforce && (
            <Text fontSize="sm" pt="2">
              The sender will be inherited from the owner of the Contact / Lead in Salesforce, then proceed to look for
              the Account Owner as a fallback.{' '}
              <strong>Note: this means Unowned Accounts will not be slated for Outbounding.</strong>
            </Text>
          )}

          {usingHubspot && (
            <Text fontSize="sm" pt="2">
              The sender will be inherited from the owner of the Contact in HubSpot, then proceed to look for the
              Company Owner as a fallback.{' '}
              <strong>Note: this means unowned companies will not be slated for Outbounding</strong>.
            </Text>
          )}
        </>
      )}

      {routingOption === 'outreach-owner' && (
        <>
          <Text fontSize="sm" pt="2">
            The sender will be inherited from the owner of the Prospect in Outreach, then proceed to look for the
            Account Owner as a fallback. Note: this means unowned accounts will not be slated for Outbounding.
          </Text>
        </>
      )}

      {routingOption === 'user' && (
        <FormControl>
          <FormLabel pt="2">Select a User</FormLabel>
          {loadingUsers && <Spinner size="sm" />}
          <ComboboxWithSearch
            items={usersData?.users ?? []}
            selectedItem={usersData?.users?.find((u) => u.email === selectedOwnerEmail) ?? null}
            onChange={(selectedItem) => {
              setSelectedOwnerEmail(selectedItem?.email ?? null)
            }}
            filterItem={(a, val) => a.name.toLowerCase().includes(val)}
            itemToString={(item) => `${item?.name || 'Unknown User'} (${item?.email || 'unknown email'})`}
            placeholder="Select a User"
          />
          {selectedOwnerEmail && <input type={'hidden'} name="routing_rules[owner_email]" value={selectedOwnerEmail} />}
        </FormControl>
      )}

      {routingOption === 'round-robin' && (
        <FormControl>
          <FormLabel pt="2">Choose a Team to round robin</FormLabel>
          {loadingUsers && <Spinner size="sm" />}
          {selectedTeamId && <input type="hidden" name="routing_rules[team_id]" value={selectedTeamId} />}
          {loadingTeams ? (
            <Spinner />
          ) : (
            <Box>
              <ComboboxWithSearch
                items={teams}
                selectedItem={teams.find((t) => selectedTeamId && t.id === selectedTeamId) ?? null}
                onChange={(selectedItem) => {
                  setSelectedTeamId(selectedItem?.id ?? null)
                }}
                filterItem={(a, val) => a.name.toLowerCase().includes(val)}
                itemToString={(item) => item?.name ?? 'Unknown Team'}
                placeholder="Select a Team"
              />
              <FormHelperText>
                Koala will round-robin the users on this team that are also in your Outreach instance for each prospect
                added to the sequence.
              </FormHelperText>
            </Box>
          )}
        </FormControl>
      )}

      {routingOption === 'advanced' && (
        <>
          <FormControl>
            <FormLabel pt="2">Advanced Routing</FormLabel>
            <FormHelperText>
              You can use advanced routing rules to map a specific set of filters to a specific user.
            </FormHelperText>
          </FormControl>

          {loadingUsers && <Spinner size="sm" />}

          <SortableList
            onSortEnd={(oldIndex: number, newIndex: number) => {
              setAdvancedRules((array) => arrayMoveImmutable(array, oldIndex, newIndex))
            }}
            draggedItemClassName="dragged"
            style={{
              userSelect: 'none'
            }}
          >
            <Stack>
              {advancedRules.map((rule) => {
                return (
                  <SortableItem key={JSON.stringify(rule)}>
                    <HStack w="100%">
                      <SortableKnob>
                        <Box cursor={'grab'}>
                          <Tooltip label="Drag to change priorities" placement="top">
                            <IconDragDrop2 color="gray" size="14" />
                          </Tooltip>
                        </Box>
                      </SortableKnob>
                      <AdvancedRoutingRule
                        onRemove={() => {
                          setAdvancedRules(advancedRules.filter((r) => r !== rule))
                        }}
                        filters={rule.filters}
                        ownerEmail={rule.owner_email}
                        users={usersData?.users ?? []}
                        apps={props.apps}
                      />
                    </HStack>
                  </SortableItem>
                )
              })}
            </Stack>
          </SortableList>

          <Flex justifyContent={'flex-end'}>
            <Button
              colorScheme="purple"
              size="sm"
              leftIcon={<IconPlus size="14" />}
              onClick={() => {
                setAdvancedRules([
                  ...advancedRules,
                  {
                    owner_email: '',
                    filters: {}
                  }
                ])
              }}
            >
              Add Sender
            </Button>
          </Flex>
        </>
      )}
    </Stack>
  )
}

interface AdvancedRoutingRuleProps {
  users: User[]
  apps: Apps
  filters?: FacetFilters
  ownerEmail?: string
  onRemove?: () => void
}

function AdvancedRoutingRule(props: AdvancedRoutingRuleProps) {
  const facets = useFacets({
    facet_filters: props.filters
  })

  const users = props.users
  const [selectedOwnerEmail, setSelectedOwnerEmail] = React.useState<string | null>(props.ownerEmail ?? null)

  return (
    <>
      <Stack as={Card} spacing="4" shadow="none" w="100%">
        <Stack w="100%">
          <HStack w="100%">
            <Heading size="xs" color="gray.500" flex="1">
              Routing rules:
            </Heading>
            <IconButton
              size="xs"
              variant="ghost"
              colorScheme="red"
              aria-label="Remove"
              icon={<IconTrashX size="14" />}
              onClick={() => {
                props.onRemove?.()
              }}
            />
          </HStack>
          <FilterPreview kind="profile" {...facets} apps={props.apps} />
        </Stack>

        <Stack>
          <Heading size="xs" color="gray.500">
            Sender:
          </Heading>
          <ComboboxWithSearch
            items={users ?? []}
            selectedItem={users.find((u) => u.email === selectedOwnerEmail) ?? null}
            onChange={(selectedItem) => {
              setSelectedOwnerEmail(selectedItem?.email ?? null)
            }}
            filterItem={(a, val) => a.name.toLowerCase().includes(val)}
            itemToString={(item) => `${item?.name || 'Unknown User'} (${item?.email || 'unknown email'})`}
          />
        </Stack>
      </Stack>

      {selectedOwnerEmail && (
        <input type={'hidden'} name="routing_rules[advanced][][owner_email]" value={selectedOwnerEmail} />
      )}
      <input type={'hidden'} name="routing_rules[advanced][][filters]" value={JSON.stringify(facets.facetFilters)} />
    </>
  )
}

interface SendToSlackSetupProps extends SlackRules {
  ruleId?: string
  apps: Apps
}

function SendToSlackSetup(props: SendToSlackSetupProps) {
  const slackData = useSlack()
  const [enabled, setEnabled] = useState<boolean | undefined>(props.enabled ?? false)
  const [newChannelName, setNewChannelName] = React.useState<string | undefined>(
    props.channel_id ? undefined : props.channel_name
  )
  const [selectedChannel, setSelectedChannel] = React.useState<string | undefined>(props.channel_id)
  const [channelName, setChannelName] = React.useState<string | undefined>(props.channel_name)

  const channels = useMemo(() => slackData.data?.deps?.channels ?? [], [slackData.data?.deps?.channels])

  const onSlackConnected = useCallback(() => {
    slackData.refetch()
  }, [slackData])

  return (
    <Box>
      {selectedChannel && (
        <>
          <input type="hidden" name="slack_rules[channel_id]" value={selectedChannel} />
          <input type="hidden" name="slack_rules[channel_name]" value={channelName} />
        </>
      )}

      {newChannelName && <input type="hidden" name="slack_rules[channel_name]" value={newChannelName} />}

      <Stack spacing="4">
        <HStack spacing={4}>
          <input type="hidden" name="slack_rules[enabled]" value={enabled ? 'true' : 'false'} />
          <Switch
            size="md"
            id="slack_rules[enabled]"
            isChecked={enabled}
            onChange={(e) => setEnabled(e.target.checked)}
          />
          <FormControl>
            <Stack spacing="0">
              <FormLabel>Slack (optional)</FormLabel>
              <FormHelperText>
                Get Slack alerts when someone triggers eligibility, is outbounded, or encounters a failure
              </FormHelperText>
            </Stack>
          </FormControl>
        </HStack>

        {slackData.isLoading ? (
          <Box minH="32px">
            <Spinner color="gray.400" thickness="1.5px" size="sm" />
          </Box>
        ) : enabled ? (
          <Stack w="100%" as={GrayCard} borderWidth="thin" spacing="4">
            <Heading size="xs">Slack Channel</Heading>

            <ChannelPicker
              channels={channels}
              setChannel={(channel, inputValue) => {
                if (channel?.id) {
                  setSelectedChannel(channel?.id)
                  setChannelName(channel?.name)
                  setNewChannelName(undefined)
                } else {
                  setNewChannelName(inputValue ?? undefined)
                  setChannelName(undefined)
                  setSelectedChannel(undefined)
                }
              }}
              isNewChannelEntered={!!newChannelName}
              channel={
                channels.find((c) => c.id === selectedChannel) ||
                (!selectedChannel && props.channel_name ? { id: '', name: props.channel_name } : undefined)
              }
              chakraInputProps={{
                size: 'sm',
                rounded: 'md',
                bg: 'white',
                isRequired: true,
                isDisabled: false
              }}
            />
          </Stack>
        ) : slackData.data && !slackData.data?.connected ? (
          <Stack w="100%" as={GrayCard} borderWidth="thin" spacing="4">
            <PopupConnectDialog app_id={slackData.data.app_id} onConnected={onSlackConnected}>
              {({ onStart }) => (
                <Button size="sm" onClick={onStart} colorScheme="purple" variant="outline">
                  Connect Slack to select a channel
                </Button>
              )}
            </PopupConnectDialog>
          </Stack>
        ) : null}
      </Stack>
    </Box>
  )
}

interface DefinitionProps {
  apps: Apps
  auto_outbound_rule?: AutoOutboundRule
  outreach_sequences?: OutreachSequence[]
}

export function DefinitionForm(props: DefinitionProps) {
  const [autoOutboundRule, setAutoOutboundRule] = useState<AutoOutboundRule | undefined>(props.auto_outbound_rule)

  const [selectedKQLDefinitionIds, setSelectedKQLDefinitionIds] = useState<string[]>(
    props.auto_outbound_rule?.rules?.intent?.kql_ids ?? []
  )

  const crmApps = useMemo(() => {
    return Object.values(props.apps).filter((app) => app.categories.includes('CRM'))
  }, [props.apps])

  const [selectedCRM, setSelectedCRM] = useState<App | null>(
    crmApps.length === 1
      ? crmApps[0]
      : (crmApps.find((app) => app.app_module === props.auto_outbound_rule?.rules.crm?.crm_app_module) ?? null)
  )

  const [prospectingStrategy, setProspectingStrategy] = useState<'identified' | 'anonymous'>(
    props.auto_outbound_rule?.rules?.auto_prospecting?.persona_id ? 'anonymous' : 'identified'
  )

  const [autoProspectSetting, setAutoProspectSetting] = useState<AutoProspectingSetting | undefined>(
    props.auto_outbound_rule?.rules?.auto_prospecting
  )

  const formRef = useRef<HTMLFormElement>(null)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [saveDisabled, setSaveDisabled] = React.useState(false)
  const path = props.auto_outbound_rule?.id ? autoOutboundRulePath(props.auto_outbound_rule?.id) : autoOutboundPath()

  return (
    <form
      action={path}
      method="POST"
      ref={formRef}
      onSubmit={() => {
        setIsSubmitting(true)
      }}
      data-koala-collect="off"
    >
      <AuthenticityToken />
      {props.auto_outbound_rule?.id && <input type={'hidden'} name={'_method'} value={'PATCH'} />}

      <Stack spacing="8">
        <Stack divider={<Divider />} spacing="8">
          <HStack w="100%" as={Stack} flex="1">
            <FormControl>
              <Input
                defaultValue={props.auto_outbound_rule?.name}
                required
                name="auto_outbound[name]"
                fontSize={'lg'}
                fontWeight="semibold"
                maxW={'400px'}
                placeholder="New Auto Outbound rule..."
              />
            </FormControl>
            {autoOutboundRule && (
              <HStack spacing="4">
                <HStack fontSize={'sm'}>
                  <Text>{autoOutboundRule.enabled ? 'Enabled' : 'Disabled'}</Text>
                  <Switch
                    defaultChecked={autoOutboundRule.enabled}
                    value="1"
                    onChange={async (e) => {
                      await put(autoOutboundRulePath(props.auto_outbound_rule!.id, `/status`), {
                        auto_outbound: {
                          enabled: e.target.checked
                        }
                      }).then(() => {
                        setAutoOutboundRule((rule) => {
                          if (!rule) {
                            return rule
                          }

                          return {
                            ...rule,
                            enabled: e.target.checked
                          }
                        })
                        toast.success(`Auto Outbound Rule ${e.target.checked ? 'enabled' : 'disabled'}`)
                      })
                    }}
                  />
                </HStack>
                <HStack fontSize={'sm'}>
                  <Text>{autoOutboundRule.autopilot ? 'Autopilot' : 'Manual'}</Text>
                  <HelpTooltip>
                    <Stack fontSize={'xs'}>
                      <Heading size="xs">Autopilot</Heading>
                      <Text>You can choose to run this rule in Autopilot or Manual mode.</Text>
                      <Text>
                        Running this rule in Autopilot will automatically outbound contacts based on their actions.
                      </Text>
                      <Text>
                        Setting this rule to Manual Mode will require clicking the "Run" button in the AutoOutbound logs
                        for this rule when you want to Outbound a contact.
                      </Text>
                    </Stack>
                  </HelpTooltip>
                  <Switch
                    defaultChecked={autoOutboundRule.autopilot}
                    value="1"
                    onChange={async (e) => {
                      await put(autoOutboundRulePath(props.auto_outbound_rule!.id, `/status`), {
                        auto_outbound: {
                          autopilot: e.target.checked
                        }
                      })
                        .then(() => {
                          setAutoOutboundRule((rule) => {
                            if (!rule) {
                              return rule
                            }

                            return {
                              ...rule,
                              autopilot: e.target.checked
                            }
                          })
                          toast.success(`Autopilot ${e.target.checked ? 'enabled' : 'disabled'}`)
                        })
                        .catch((ee) => {
                          toast.error(`Error: ${ee}`)
                        })
                    }}
                  />
                </HStack>
              </HStack>
            )}
          </HStack>

          <Flex alignItems="flex-end" gap={8} flexWrap="wrap-reverse">
            <Stack flex="1 1 340px" minWidth="340px" divider={<Divider />} spacing="8">
              <GlobalEligibilitySetup
                ruleId={props.auto_outbound_rule?.id}
                apps={props.apps}
                {...props.auto_outbound_rule?.rules.qualification}
                autoProspectSetting={autoProspectSetting}
                onAutoProspectSettingChange={setAutoProspectSetting}
                prospectingStrategy={prospectingStrategy}
                onProspectingStrategyChange={(strategy) => {
                  setProspectingStrategy(strategy)
                }}
              />

              <CRMRulesSetup
                ruleId={props.auto_outbound_rule?.id}
                apps={props.apps}
                crmApps={crmApps}
                selectedCRM={selectedCRM}
                setSelectedCRM={setSelectedCRM}
                prospectingStrategy={prospectingStrategy}
                {...props.auto_outbound_rule?.rules.crm}
              />
              <DeduplicationRulesSetup
                ruleId={props.auto_outbound_rule?.id}
                {...props.auto_outbound_rule?.rules.cadence}
              />
              <IntentRulesSetup
                ruleId={props.auto_outbound_rule?.id}
                {...props.auto_outbound_rule?.rules.intent}
                initialKQLDefinitionIds={selectedKQLDefinitionIds}
                setSelectedKQLDefinitionIds={setSelectedKQLDefinitionIds}
              />
              <TriggerRulesSetup ruleId={props.auto_outbound_rule?.id} {...props.auto_outbound_rule?.rules.trigger} />

              <CampaignDestinations
                apps={props.apps}
                {...props.auto_outbound_rule?.rules.outreach}
                outreachSequences={props.outreach_sequences}
                kqlDefinitionIds={selectedKQLDefinitionIds}
                autoOutboundRule={props.auto_outbound_rule}
                selectedCRM={selectedCRM}
                setSaveDisabled={setSaveDisabled}
              />

              <SendToSlackSetup
                ruleId={props.auto_outbound_rule?.id}
                apps={props.apps}
                {...props.auto_outbound_rule?.rules?.slack}
              />
            </Stack>

            <RulePreview
              formRef={formRef}
              prospectingStrategy={prospectingStrategy}
              autoProspectingSetting={autoProspectSetting}
            />
          </Flex>
        </Stack>
        <Button type="submit" colorScheme={'purple'} isDisabled={saveDisabled} isLoading={isSubmitting}>
          Save
        </Button>
      </Stack>
    </form>
  )
}

interface IdentifiedProspectsProps {
  workEmailsOnly: boolean | undefined
  setWorkEmailsOnly: (value: boolean | undefined) => void
  excludeInternalUsers: boolean | undefined
  setExcludeInternalUsers: (value: boolean | undefined) => void
  profile_rules: FacetFilters | undefined
}

function IdentifiedProspects(props: IdentifiedProspectsProps) {
  const { workEmailsOnly, setWorkEmailsOnly, excludeInternalUsers, setExcludeInternalUsers, profile_rules } = props

  const profileRules = useFacets({
    facet_filters: profile_rules,
    facetCloudPath: '/profiles/facet-cloud'
  })

  return (
    <Stack spacing="4">
      <Checkbox
        name="qualification_rules[work_emails_only]"
        value={workEmailsOnly ? '1' : '0'}
        isChecked={workEmailsOnly}
        onChange={(e) => {
          setWorkEmailsOnly(e.target.checked)
        }}
      >
        Work Emails Only (recommended)
      </Checkbox>

      <Checkbox
        name="qualification_rules[exclude_internal_users]"
        value={excludeInternalUsers ? '1' : '0'}
        isChecked={excludeInternalUsers}
        onChange={(e) => {
          setExcludeInternalUsers(e.target.checked)
        }}
      >
        Exclude internal and test users
      </Checkbox>

      <FilterPreview kind="profile" {...profileRules} shouldShowCompanyFilters={false} shouldShowICPFilters={false} />

      <input
        type="hidden"
        name="qualification_rules[profile_rules]"
        value={JSON.stringify(profileRules.facetFilters)}
      />
    </Stack>
  )
}
