import { Button, Center, Flex, Heading, HStack, Image, Link, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { UrlFilterParams } from '../../../../data/use-url-filters'
import { projectPath } from '../../../../ui/ProjectsContext'
import FilterIllustration from './filter-illustration.svg'
import ListBackground from './list-background.jpg'
import NoPixel from './no-pixel.svg'

interface Props {
  facets: UrlFilterParams
}

export function AccountEmptyState({ facets }: Props) {
  return (
    <Center pt="8">
      <Stack align="center" textAlign="center" maxW="500" spacing="6">
        <Image h="64" width="auto" src={FilterIllustration} />
        <Heading size="md">We couldn't find any Accounts</Heading>

        <Text color="gray.600">
          We couldn't find any Accounts for the filters you have selected. Please try removing some filters or setting a
          longer time range.
        </Text>
        {facets.canClearFilters && (
          <Button variant="link" colorScheme="purple" onClick={facets.clearFilters}>
            Reset Filters
          </Button>
        )}
      </Stack>
    </Center>
  )
}

interface EmptyStateProps {
  type?: 'account' | 'profile'
}

export function NoPixelEmptyState(props: EmptyStateProps) {
  const type = props.type ?? 'account'

  return (
    <Center pt="8">
      <Stack
        align="center"
        textAlign="center"
        w="100%"
        spacing="6"
        bgImage={`url(${ListBackground})`}
        bgSize="contain"
        bgRepeat={'no-repeat'}
        bgPosition="center"
        position={'relative'}
        justifyContent="center"
      >
        <Flex minWidth="300px" maxWidth="100%" p={['0', '6', '12']}>
          <Stack bg="white" p={[8, 12]} maxW="500px" spacing="4" borderWidth="1px" rounded="xl">
            <Center>
              <Image
                src={NoPixel}
                width="100%"
                maxW="300px"
                style={{ filter: type === 'profile' ? 'hue-rotate(320deg)' : undefined }}
              />
            </Center>
            <Heading size="md" fontWeight={'semibold'}>
              Pixel not installed
            </Heading>
            <Text color="gray.600">
              Looks like Koala isn't collecting intent activity on your site yet. Install the pixel to see the hottest
              accounts on your site right now.
            </Text>
            <Flex justifyContent={'center'} pt="4">
              <Button
                as={Link}
                href={projectPath('/settings/install')}
                colorScheme={type === 'profile' ? 'blue' : 'purple'}
              >
                Install the pixel
              </Button>
            </Flex>
          </Stack>
        </Flex>
      </Stack>
    </Center>
  )
}

export function NoRevealEmptyState(props: EmptyStateProps) {
  const type = props.type ?? 'account'

  return (
    <Center pt="8">
      <Stack
        align="center"
        textAlign="center"
        w="100%"
        spacing="6"
        bgImage={`url(${ListBackground})`}
        bgSize="contain"
        bgRepeat={'no-repeat'}
        bgPosition="center"
        position={'relative'}
        justifyContent="center"
      >
        <Flex p="12">
          <Stack bg="white" p="12" maxW="600" spacing="4" borderWidth={'1px'} rounded="xl">
            <Center>
              <Image
                src={NoPixel}
                maxW="600"
                style={{ filter: type === 'profile' ? 'hue-rotate(320deg)' : undefined }}
              />
            </Center>
            <Heading size="md" fontWeight={'semibold'}>
              No Companies Identified
            </Heading>
            <Text color="gray.600" fontSize={'sm'}>
              Good news – you have visitors! But we haven't seen any matched companies yet. You can identify your
              visitors to start seeing accounts, or connect the Clearbit Reveal integration to automatically match your
              anonymous users to accounts.
            </Text>
            <HStack justifyContent={'center'} pt="4">
              <Button
                variant={'outline'}
                as={Link}
                href={'https://getkoala.com/docs/get-started/how-koala-works'}
                isExternal
              >
                Identify Docs
              </Button>
              <Button
                as={Link}
                href={projectPath('/apps/clearbit')}
                colorScheme={type === 'profile' ? 'blue' : 'purple'}
              >
                Clearbit Integration
              </Button>
            </HStack>
          </Stack>
        </Flex>
      </Stack>
    </Center>
  )
}

export function VisitorEmptyState({ facets }: Props) {
  return (
    <Center pt="8">
      <Stack align="center" textAlign="center" maxW="500" spacing="6">
        <Image
          width="auto"
          h="64"
          src={FilterIllustration}
          style={{
            filter: 'hue-rotate(320deg)'
          }}
        />
        <Heading size="md">We couldn't find any Visitors</Heading>
        <Text color="gray.600">
          We couldn't find any Visitors for the filters you have selected. Please try removing some filters or setting a
          longer time range.
        </Text>
        {facets.isFiltering && (
          <Button variant="link" colorScheme="blue" onClick={facets.clearFilters}>
            Clear Filters
          </Button>
        )}
      </Stack>
    </Center>
  )
}
