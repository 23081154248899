import { Flex, FlexProps } from '@chakra-ui/react'
import React, { ReactNode, createContext, useContext, useMemo, useState, PropsWithChildren, useEffect } from 'react'
import { useLayoutMode } from './useLayoutMode'

interface TopBarContextProps {
  content: ReactNode
  setContent: (content: ReactNode) => void
}

export const TopBarContext = createContext<TopBarContextProps>({
  content: null,
  setContent: () => {}
})

export function useTopBarContext() {
  return useContext(TopBarContext)
}

export function TopBarContent({
  children,
  onlyNewNav,
  ...rest
}: PropsWithChildren<FlexProps & { onlyNewNav?: boolean }>) {
  const { setContent } = useTopBarContext()
  const layoutMode = useLayoutMode()

  useEffect(() => {
    if (layoutMode !== 'new') return
    setContent(children)
    return () => setContent(null)
  }, [children, layoutMode, setContent])

  return layoutMode === 'new' || onlyNewNav ? null : (
    <Flex flex="none" width="100%" minH="48px" alignItems="center" {...rest}>
      {children}
    </Flex>
  )
}

export function TopBarProvider({ children }: PropsWithChildren<{}>) {
  const [content, setContent] = useState<ReactNode>(null)
  const state = useMemo(() => ({ content, setContent }), [content, setContent])

  return <TopBarContext.Provider value={state}>{children}</TopBarContext.Provider>
}
