import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { format } from 'friendly-numbers'
import { orderBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { AdminBreadcrumb } from '..'
import router from '../../../../lib/router'
import { Project } from '../../../../types/Project'
import { ComboboxWithSearch } from '../../../ui/ComboboxWithSearch'
import CompanyAvatar from '../../../ui/CompanyAvatar'
import PageLayout from '../../../ui/PageLayout'
import PageTitle from '../../../ui/PageTitle'
import SelectInput from '../../../ui/SelectInput'
import useLocation from '../../../ui/useLocation'
import { IntentBarChart } from '../../accounts/components/Intent/IntentBarChart'
import { Hotness } from '../../icps/icp/breakdown'
import { mergeParams } from '../../icps/types'

const decays = [
  { label: 'Slower (0.9/d)', value: '0.9' },
  { label: 'Normal (0.8/d)', value: '0.8' },
  { label: 'Faster (0.7/d)', value: '0.7' },
  { label: 'Fastest (0.6/d)', value: '0.6' },
  { label: 'Superfast (0.3/d)', value: '0.3' }
]

const windows = [
  { label: '7 days', value: '7' },
  { label: '14 days', value: '14' },
  { label: '4 weeks', value: '28' }
]

const metrics = [
  { label: 'Raw Score', value: 'raw_score' },
  { label: 'SMA', value: 'sma' },
  { label: 'EMA', value: 'ema' },
  { label: 'WMA', value: 'wma' }
]

const trendColor = {
  heating: 'orange',
  surging: 'red',
  cooling: 'blue',
  neutral: 'gray'
}

interface Props {
  slug: string
  project?: null | Project
  projects: Project[]
  domains: string[]
  accounts?: any[]
  max: number
}

export default function IntentPlayground(props: Props) {
  const [bucket, setBucket] = useState<'day' | 'week'>('day')
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const sortKey = searchParams.get('sort') || 'sma'
  const sparkline = searchParams.get('sparkline') || 'sma'
  const days = searchParams.get('window') || '7'

  const metric = metrics.find((m) => m.value === sparkline)?.label

  const accounts = props.accounts ?? []
  const accountsByNewIntent = orderBy(accounts, (a) => a.latest_score?.[sortKey] || 0, 'desc')

  return (
    <PageLayout size="full">
      <AdminBreadcrumb paths={[{ path: '/admin/intent-playground', title: 'Intent Playground' }]} />

      <PageTitle>Intent Playground</PageTitle>

      <Grid gap={2.5} gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))">
        <FormControl size="sm">
          <FormLabel>Workspace</FormLabel>
          <ComboboxWithSearch
            items={props.projects}
            selectedItem={props.projects.find((s) => s.slug === props.project?.slug) || null}
            onChange={(item) => {
              const slug = item?.slug || ''
              const url = mergeParams(window.location.toString(), { slug })
              router.visit(url)
            }}
            filterItem={(a, val) => a.name.toLowerCase().includes(val) || a.slug.toLowerCase().includes(val)}
            itemToString={(item) => item?.name ?? item?.slug ?? ''}
            itemRenderer={WorkspaceRenderer}
            selectButtonRenderer={WorkspaceRenderer}
          />
        </FormControl>
        <FormControl size="sm">
          <FormLabel>Rolling Window</FormLabel>
          <SelectInput
            variant="outline"
            placeholder="All"
            triggerProps={{ fontSize: 'sm' }}
            items={windows}
            itemToString={(item) => item.label}
            popperOptions={{ matchWidth: true }}
            selectedItem={windows.find((s) => s.value === days)}
            onSelectedItemChange={(event) => {
              const value = event.selectedItem.value
              router.visit(mergeParams(window.location.toString(), { window: value }))
            }}
          />
        </FormControl>
        <FormControl size="sm">
          <FormLabel>Decay Rate (WEMA only)</FormLabel>
          <SelectInput
            variant="outline"
            placeholder="All"
            triggerProps={{ fontSize: 'sm' }}
            items={decays}
            itemToString={(item) => item.label}
            popperOptions={{ matchWidth: true }}
            selectedItem={decays.find((s) => s.value === (searchParams.get('decay') || '0.8'))}
            onSelectedItemChange={(event) => {
              const decay = event.selectedItem.value
              router.visit(mergeParams(window.location.toString(), { decay }))
            }}
          />
        </FormControl>
        <FormControl size="sm">
          <FormLabel>Metric</FormLabel>
          <SelectInput
            variant="outline"
            placeholder="All"
            triggerProps={{ fontSize: 'sm' }}
            items={metrics}
            itemToString={(item) => item.label}
            popperOptions={{ matchWidth: true }}
            selectedItem={metrics.find((s) => s.value === (sparkline || 'raw_score'))}
            onSelectedItemChange={(event) => {
              router.visit(mergeParams(window.location.toString(), { sparkline: event.selectedItem.value }))
            }}
          />
        </FormControl>
      </Grid>

      <Flex gap={8}>
        {accounts.length > 0 && (
          <TableContainer flex="1 1 35%">
            <Table>
              <Thead>
                <Tr>
                  <Th>Account</Th>
                  <Th width="1px">Old Intent</Th>
                </Tr>
              </Thead>
              <Tbody>
                {accounts.map((account) => (
                  <Tr key={account.id}>
                    <Td>
                      <HStack spacing={4} py={1}>
                        <CompanyAvatar size="sm" name={account.company.name} domain={account.company.domain} />
                        <Stack spacing={1} minWidth="100px" lineHeight="1.2">
                          <Text
                            as={Link}
                            href={`/projects/${props.project?.slug}/accounts/${account.domain}`}
                            fontSize="md"
                            fontWeight="semibold"
                            textOverflow="ellipsis"
                            overflow="hidden"
                          >
                            {account.company.name || account.domain}
                          </Text>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td width="1px">{account.intent_grade && <Hotness value={account.intent_grade} />}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {accountsByNewIntent.length > 0 && (
          <TableContainer flex="1 1 65%">
            <Table>
              <Thead>
                <Tr>
                  <Th>Account</Th>
                  <Th>
                    <HStack spacing={4}>
                      <Text>Intent</Text>
                      <ButtonGroup size="xs">
                        <Button
                          variant="link"
                          colorScheme={bucket === 'day' ? 'purple' : 'gray'}
                          isActive={bucket === 'day'}
                          onClick={() => setBucket('day')}
                        >
                          Day
                        </Button>
                        <Button
                          variant="link"
                          colorScheme={bucket === 'week' ? 'purple' : 'gray'}
                          isActive={bucket === 'week'}
                          onClick={() => setBucket('week')}
                        >
                          Week
                        </Button>
                      </ButtonGroup>
                    </HStack>
                  </Th>
                  <Th>Trend</Th>
                  <Th isNumeric>Slope ({days}d)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {accountsByNewIntent.map((account) => (
                  <Tr key={account.id}>
                    <Td>
                      <HStack spacing={4}>
                        <CompanyAvatar size="sm" name={account.company.name} domain={account.company.domain} />
                        <Stack spacing={1} minWidth="100px" lineHeight="1.2">
                          <Text
                            as={Link}
                            href={`/projects/${props.project?.slug}/accounts/${account.domain}`}
                            fontSize="md"
                            fontWeight="semibold"
                            textOverflow="ellipsis"
                            overflow="hidden"
                          >
                            {account.company.name || account.domain}
                          </Text>
                        </Stack>
                      </HStack>
                    </Td>
                    <Td>
                      <HStack>
                        <Box flex="none" overflow="hidden">
                          {metric && bucket === 'day' && (
                            <HStack>
                              <IntentBarChart data={account.intentv2} dataKey={sparkline} />
                              <pre>{format(account.latest_score?.[sparkline] || 0, { decimals: 0 })}</pre>
                            </HStack>
                          )}
                          {bucket === 'week' && (
                            <HStack>
                              <IntentBarChart
                                data={bucketByWeekAverage(account.intentv2, sparkline)}
                                granularity="week"
                              />
                              <pre>
                                {format(bucketByWeekAverage(account.intentv2, sparkline).reverse()[0]?.value || 0, {
                                  decimals: 0
                                })}
                              </pre>
                            </HStack>
                          )}
                        </Box>
                      </HStack>
                    </Td>
                    <Td>
                      {account.latest_score?.trend && (
                        <Text
                          fontSize="sm"
                          fontWeight="medium"
                          color={`${trendColor[account.latest_score.trend.toLowerCase()]}.500`}
                        >
                          {account.latest_score.trend} ({days}d)
                        </Text>
                      )}
                      {account.latest_score?.extended_trend &&
                        account.latest_score?.extended_trend != account.latest_score?.trend && (
                          <Text
                            fontSize="sm"
                            fontWeight="medium"
                            color={`${trendColor[account.latest_score.extended_trend.toLowerCase()]}.500`}
                          >
                            {account.latest_score.extended_trend} ({Number(days) * 2}d)
                          </Text>
                        )}
                    </Td>
                    <Td isNumeric>
                      {typeof account.latest_score?.slope === 'number' && (
                        <Text fontFamily="mono" fontSize="sm">
                          {account.latest_score.slope > 0 ? '+' : ''}
                          {format(account.latest_score.slope, { decimals: 2 })}
                        </Text>
                      )}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </Flex>
    </PageLayout>
  )
}

function WorkspaceRenderer(props: { item: Project | null }) {
  const workspace = props.item

  if (!workspace) {
    return <Text fontSize="sm">Select a workspace</Text>
  }

  return (
    <HStack direction="row" spacing={1} lineHeight="22px">
      <Text fontSize="sm" fontWeight="medium">
        {workspace.name}
      </Text>
      <Text fontSize="sm" color="gray.600">
        ({workspace.slug})
      </Text>
    </HStack>
  )
}

function bucketByWeekAverage(data, dataKey) {
  const weeks: Array<{ date: string; value: number }> = []

  data = Array.from(data)

  while (data.length > 0) {
    const period = data.splice(-7)
    const sum = period.reduce((acc, item) => acc + (item[dataKey] || 0), 0)
    const avg = sum / period.length
    weeks.unshift({ date: period[0].date, value: avg })
  }

  return weeks
}
