import {
  Badge,
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useDisclosure,
  Wrap
} from '@chakra-ui/react'
import { Icon as TablerIcon, IconPlus, IconX } from '@tabler/icons-react'
import React, { useCallback } from 'react'
import { FilterState, getOperator, getValues } from '../../personas/persona-filters'
import { CheckboxFilter } from './CheckboxFilter'
import { KeywordFilter } from './KeywordFilter'

const emptyArray = []

export interface FilterPopoverProps {
  domain?: string
  facet: string
  // the extra "exclusion" facet, if any
  notFacet?: string
  inputType?: 'checkbox' | 'keyword'
  filters: Record<string, FilterState>
  icon: TablerIcon
  showPreview?: boolean
  hideCounts?: boolean
  containerRef?: React.RefObject<HTMLElement>
  onChange: (updates: Record<string, FilterState>) => void
}

export function FilterPopover({
  children,
  domain,
  facet,
  notFacet,
  inputType,
  filters,
  icon,
  containerRef,
  showPreview,
  hideCounts,
  onChange
}: React.PropsWithChildren<FilterPopoverProps>) {
  const selected = getValues(filters[facet])
  const excluded = notFacet ? getValues(filters[notFacet]) : emptyArray
  const operator = getOperator(filters[facet])

  const count = selected.length + excluded.length

  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialFocusRef = React.useRef<HTMLInputElement | null>(null)

  const removeSelection = useCallback(
    (value: string) => {
      onChange({
        [facet]: {
          values: selected.filter((v) => v !== value),
          operator
        }
      })
    },
    [facet, selected, operator, onChange]
  )

  const removeExclusion = useCallback(
    (value: string) => {
      if (!notFacet) return

      onChange({
        [notFacet]: {
          values: excluded.filter((v) => v !== value),
          operator
        }
      })
    },
    [notFacet, excluded, operator, onChange]
  )

  return (
    <Box>
      <Popover
        isOpen={isOpen}
        placement="bottom-start"
        initialFocusRef={initialFocusRef}
        onOpen={onOpen}
        onClose={onClose}
        isLazy
        lazyBehavior="keepMounted"
        offset={[0, 4]}
      >
        <PopoverTrigger>
          {typeof children === 'string' ? (
            <Flex
              as="button"
              type="button"
              width="full"
              textAlign="left"
              alignItems="center"
              gap={2}
              paddingX={2}
              paddingY={2}
              color="gray.600"
              role="group"
              cursor="pointer"
              rounded="md"
              _hover={{ bg: 'gray.50' }}
            >
              <Icon as={icon} boxSize={3.5} />
              <Text flex="1 1 auto" fontSize="sm" fontWeight="medium">
                {children}
              </Text>
              {count > 0 && (
                <Badge variant="pill" colorScheme="purple">
                  {count}
                </Badge>
              )}
              <Icon as={IconPlus} boxSize={4} color="gray.400" _groupHover={{ color: 'purple.600' }} />
            </Flex>
          ) : (
            children
          )}
        </PopoverTrigger>

        <Portal containerRef={containerRef}>
          <PopoverContent
            shadow="xl"
            maxHeight="min(600px, calc(65vh - var(--header-height)))"
            maxWidth="min(350px, 92vw)"
            overflow="hidden"
            color="gray.700"
            rootProps={{
              zIndex: 'popover'
            }}
            width="320px"
            _focus={{
              outline: 'none',
              boxShadow: 'xl'
            }}
          >
            <Flex
              gap={4}
              justifyContent="space-between"
              bg="gray.50"
              paddingLeft={3}
              paddingY={1}
              paddingRight={2}
              borderBottom="1px solid"
              borderColor="gray.200"
            >
              <HStack>
                <Icon as={icon} boxSize={3.5} />
                <Text flex="1 1 auto" fontSize="sm" fontWeight="medium">
                  {children}
                </Text>
              </HStack>

              <IconButton
                size="xs"
                aria-label="Close filter menu"
                variant="ghost"
                onClick={onClose}
                icon={<IconX size={14} />}
              />
            </Flex>

            {inputType === 'keyword' ? (
              <KeywordFilter
                facet={facet}
                included={selected}
                notFacet={notFacet}
                excluded={excluded}
                initialFocusRef={initialFocusRef}
                onChange={(changes) => {
                  onChange(changes)
                  onClose()
                }}
              />
            ) : (
              <CheckboxFilter
                domain={domain}
                facet={facet}
                operator={operator}
                selected={selected}
                initialFocusRef={initialFocusRef}
                hideCounts={hideCounts}
                onChange={(newValues, newOperator) => {
                  onChange({
                    [facet]: {
                      values: newValues,
                      operator: newOperator
                    }
                  })
                  onClose()
                }}
              />
            )}
          </PopoverContent>
        </Portal>
      </Popover>

      {showPreview && selected.length > 0 && (
        <Stack px={2} py={2} spacing={1}>
          {operator === 'must_not' && (
            <Text fontSize="xs" fontWeight="semibold">
              Not
            </Text>
          )}
          <Wrap spacingX={1} spacingY={2}>
            {selected.map((value) => (
              <Tag key={value} size="sm" colorScheme={operator === 'must_not' ? 'red' : 'purple'}>
                <TagLabel>{value}</TagLabel>
                <TagCloseButton onClick={() => removeSelection(value)} />
              </Tag>
            ))}
          </Wrap>
        </Stack>
      )}
      {showPreview && excluded.length > 0 && (
        <Stack px={2} py={2} spacing={1}>
          <Text fontSize="xs" fontWeight="semibold">
            Not
          </Text>
          <Wrap spacingX={1} spacingY={2}>
            {excluded.map((value) => (
              <Tag key={value} size="sm" colorScheme="red">
                <TagLabel>{value}</TagLabel>
                <TagCloseButton onClick={() => removeExclusion(value)} />
              </Tag>
            ))}
          </Wrap>
        </Stack>
      )}
    </Box>
  )
}
