import { useDisclosure } from '@chakra-ui/react'
import React from 'react'
import createPersistedState from 'use-persisted-state'
import { CollapsibleSection } from '../../../ui/CollapsibleSection'
import { IconifyProps } from '../../../ui/Iconify'

function useUiState<T>(app: string, initialState: T) {
  return createPersistedState<T>(`integration:intro:${app}`)(initialState)
}

interface IntrodoctionProps {
  app: string
  title?: string
  icon?: IconifyProps['icon']
  description: string | React.ReactNode
  docsLink?: string
}

export function Introduction(props: IntrodoctionProps) {
  const [state, setState] = useUiState(props.app, { isOpen: true })
  const disclosure = useDisclosure({
    defaultIsOpen: true,
    ...state,
    onOpen: () => setState({ isOpen: true }),
    onClose: () => setState({ isOpen: false })
  })

  return (
    <CollapsibleSection
      {...disclosure}
      header={props.title || 'How does it work?'}
      icon={props.icon}
      description={props.description}
      docsLink={props.docsLink}
    />
  )
}
