import { Apps } from '@app/types/App'
import { Box, Button, Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { IconCirclePlus, IconCopyPlus } from '@tabler/icons-react'
import pluralize from 'pluralize'
import React from 'react'
import router from '../../../lib/router'
import { Space } from '../../../types/Space'
import { Template } from '../../../types/Template'
import PageLayout from '../../ui/PageLayout'
import PageTitle from '../../ui/PageTitle'
import { projectPath } from '../../ui/ProjectsContext'
import SquareIcon from '../../ui/SquareIcon'
import { useCurrentUser } from '../../ui/UserContext'
import { mergeParams } from '../icps/types'
import { MCHeader } from './Header'

interface MissionControlShowProps {
  space: Space
  templates: Template[]
  apps?: Apps
}

export default function MissionControlShow(props: MissionControlShowProps) {
  // skip the intro if the space is already created
  const [showIntro, setShowIntro] = React.useState(!props.space?.id || !props.space?.account_views?.length)
  const currentUser = useCurrentUser()
  const user = props.space.owner || currentUser

  return (
    <PageLayout size="full" gap={0}>
      <PageTitle skipRendering>Mission Control</PageTitle>
      <MCHeader space={props.space} apps={props.apps} />

      {showIntro ? (
        <Intro
          onGetStarted={() => {
            // redirect to new list unless there are templates
            if (props.templates.length === 0) {
              const path = mergeParams(window.location.pathname + '/views/new' + window.location.search, {
                'account_view[kind]': 'account',
                'account_view[name]': user?.name ? `${user.name}'s Accounts` : 'My Accounts',
                range: 'any'
              })

              router.visit(path)
            } else {
              setShowIntro(false)
            }
          }}
        />
      ) : props.templates.length > 0 ? (
        <SelectTemplate {...props} />
      ) : null}
    </PageLayout>
  )
}

function Intro(props: { onGetStarted: () => void }) {
  return (
    <Stack spacing={8} alignItems="center" padding={10} maxW="650px" marginX="auto">
      <Stack spacing={3}>
        <Heading size="md" fontWeight="semibold" textAlign="center">
          Welcome to Mission Control
        </Heading>
        <Text fontSize="md" color="gray.600" textAlign="center">
          Mission Control is your personal space to track the accounts you care about. Create multiple lists to organize
          accounts and contacts &ndash; accounts assigned to you in your CRM, a particular territory, or any other
          specific set of accounts (e.g. Target Accounts).
        </Text>
      </Stack>

      <Flex alignItems="center" gap={3}>
        <Button size="md" colorScheme="purple" onClick={props.onGetStarted}>
          Get started
        </Button>
      </Flex>
    </Stack>
  )
}

function SelectTemplate(props: MissionControlShowProps) {
  const currentUser = useCurrentUser()
  const user = props.space?.owner || currentUser

  return (
    <Stack spacing={8} alignItems="center" padding={10} maxW="650px" marginX="auto">
      <Stack spacing={3}>
        <Heading size="md" fontWeight="semibold" textAlign="center">
          Set up your Mission Control
        </Heading>
        <Text fontSize="md" color="gray.600" textAlign="center">
          Your workspace has the following templates set up. You can set up your Mission Control from a template, or you
          can start from scratch to find the accounts and contacts that you want to personally track.
        </Text>
      </Stack>

      <Stack w="100%" spacing={2} py={8}>
        <Text fontSize="11px" textTransform="uppercase" fontWeight="semibold" color="gray.400" textAlign="center">
          Select a template
        </Text>
        {props.templates.map((template) => (
          <Box
            key={template.id}
            border="1px solid"
            borderColor="gray.200"
            rounded="lg"
            bg="white"
            padding={3.5}
            transition="border-color 150ms ease-in-out"
            _hover={{ borderColor: 'gray.300', shadow: 'sm' }}
          >
            <Flex alignItems="center" justifyContent="space-between" gap={3}>
              <Flex alignItems="center" gap={3}>
                <SquareIcon icon={IconCopyPlus} colorScheme="green" padding={2} />
                <Box>
                  <Heading size="xs">{template.name}</Heading>
                  <Text fontSize="xs" color="gray.500">
                    {pluralize('list', (template.template_views ?? []).length, true)}
                  </Text>
                </Box>
              </Flex>

              <Flex alignItems="center" gap={2}>
                {/* <Button size="sm" variant="outline" onClick={() => setPreview(template)}>
                    Preview
                  </Button> */}
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme="lightPurple"
                  as="a"
                  href={projectPath(`/mission-control/templates/${template.id}/apply`) + window.location.search}
                >
                  Apply
                </Button>
              </Flex>
            </Flex>
          </Box>
        ))}

        <Text
          fontSize="11px"
          textTransform="uppercase"
          fontWeight="semibold"
          color="gray.400"
          textAlign="center"
          pt={10}
        >
          Or start from scratch
        </Text>

        <Box
          border="1px solid"
          borderColor="gray.200"
          cursor="pointer"
          rounded="lg"
          bg="white"
          paddingX={3.5}
          paddingY={3.5}
          transition="border-color 150ms ease-in-out"
          _hover={{ borderColor: 'gray.300', shadow: 'sm' }}
          tabIndex={0}
          as="a"
          href={mergeParams(window.location.pathname + '/views/new' + window.location.search, {
            'account_view[kind]': 'account',
            'account_view[name]': user?.name ? `${user.name}'s Accounts` : 'My Accounts',
            range: 'any'
          })}
        >
          <Flex alignItems="flex-start" gap={3}>
            <SquareIcon icon={IconCirclePlus} colorScheme="purple" padding={2} />
            <Box>
              <Heading size="xs">Create your own list</Heading>
              <Text fontSize="xs" color="gray.500">
                Get started by creating a list to organize your accounts or contacts
              </Text>
            </Box>
          </Flex>
        </Box>
      </Stack>
    </Stack>
  )
}
