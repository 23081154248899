import { Box, Button, Divider, Flex, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { IconChevronDown, IconChevronUp, IconId } from '@tabler/icons-react'
import { isDate } from 'date-fns'
import React, { useMemo } from 'react'
import { Account } from '../../../../../types/Account'
import { ProfileRecord } from '../../../../../types/Profile'
import { DetailsCard } from '../../../../ui/Card'
import { CardHeading } from '../../../../ui/CardHeading'
import { JSONTree } from '../../../../ui/json-tree'
import { StackedField } from '../../../../ui/StackedField'
import { useUIState } from './useUIState'

function isValidDate(dateString: string) {
  return isDate(dateString)
}

function type(value: any) {
  if (typeof value === 'string' && isValidDate(value)) {
    return 'date'
  }

  if (value === 'undefined') {
    return 'undefined'
  }

  if (value === null || value === undefined) {
    return 'null'
  }

  return typeof value
}

interface TraitsCardProps {
  object: {
    id: string
    traits?: ProfileRecord['traits'] | Account['traits']
  }
  initiallyCollapsed?: boolean
}

export function TraitsCard(props: TraitsCardProps) {
  const [ui, setUI] = useUIState()
  const cardDisclosure = useDisclosure({
    defaultIsOpen: ui.open?.traits ?? !props.initiallyCollapsed,
    onOpen: () => {
      setUI({ open: { traits: true } })
    },
    onClose: () => {
      setUI({ open: { traits: false } })
    }
  })

  const groups = useMemo(() => {
    if (Array.isArray(props.object.traits)) {
      return props.object.traits.map((t) => ({ id: t.group_id, name: t.name, traits: Object.entries(t.traits || {}) }))
    }

    const id = props.object.id
    const name = props.object.traits?.name
    return [{ id, name: name, traits: Object.entries(props.object.traits || {}) }]
  }, [props.object.traits, props.object.id])

  const total = useMemo(() => groups.reduce((acc, group) => acc + group.traits.length, 0), [groups])

  if (total === 0) {
    return null
  }

  return (
    <DetailsCard px={0}>
      <CardHeading px={5} icon={IconId} count={total} disclosure={cardDisclosure}>
        Traits
      </CardHeading>
      {cardDisclosure.isOpen && (
        <Stack spacing={3} divider={<Divider />}>
          {groups.map((group) => (
            <TraitGroup key={group.id} {...group} showName={groups.length > 1} />
          ))}
        </Stack>
      )}
    </DetailsCard>
  )
}

interface TraitGroupProps {
  id: string
  name?: string
  traits: Record<string, any>
  showName?: boolean
}

export function TraitGroup({ id, name, traits, showName }: TraitGroupProps) {
  const showAll = useDisclosure({ defaultIsOpen: traits.length < 4 })

  const firstSet = traits.slice(0, 4)
  const displayed = showAll.isOpen ? traits : firstSet

  return (
    <Box px={5}>
      {showName && (
        <Flex
          cursor="pointer"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          py={0.5}
          onClick={showAll.onToggle}
        >
          <Text fontSize="xs" textTransform="uppercase" color="purple.500" fontWeight="medium">
            {name || id}
          </Text>
          <Button
            variant="link"
            colorScheme="gray"
            size="xs"
            iconSpacing={0.5}
            rightIcon={showAll.isOpen ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
          >
            {showAll.isOpen ? 'Show less' : 'Show more'}
          </Button>
        </Flex>
      )}
      <Stack spacing={3.5}>
        {/* dont repeat the `name` if we are already showing it */}
        {displayed
          .filter((t) => (showName && !showAll.isOpen ? t[0] !== 'name' : true))
          .map(([key, value]) => (
            <StackedField key={key} label={`${key} (${type(value)})`}>
              {type(value) === 'number' ? value : null}
              {type(value) === 'string' ? value : null}

              {type(value) === 'boolean' ? JSON.stringify(value) : null}
              {type(value) === 'date' ? new Date(value as string).toLocaleString() : null}
              {type(value) === 'null' ? 'null' : null}

              {type(value) === 'object' ? (
                <Box ml="-4" mt="-2">
                  <JSONTree data={value} />
                </Box>
              ) : null}
            </StackedField>
          ))}

        {traits.length > firstSet.length && !showName && (
          <Box>
            <Button
              alignSelf="flex-start"
              variant="link"
              colorScheme="gray"
              size="xs"
              iconSpacing={1}
              rightIcon={showAll.isOpen ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />}
              onClick={showAll.onToggle}
            >
              {showAll.isOpen ? 'Show less' : 'Show more'}
            </Button>
          </Box>
        )}
      </Stack>
    </Box>
  )
}
