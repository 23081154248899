import { Subscription } from '@app/types/AccountView'
import { SlackChannel } from '@app/types/Slack'
import {
  Box,
  Code,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  List,
  ListItem,
  ListItemProps,
  Stack,
  Text,
  usePopper
} from '@chakra-ui/react'
import { IconChevronDown, IconChevronUp, IconHash, IconPlus, IconRefresh } from '@tabler/icons-react'
import { useCombobox } from 'downshift'
import React, { useMemo, useRef } from 'react'
import { useVirtualizer } from '@tanstack/react-virtual'
import { TextEllipsis } from '../../../ui/text-ellipsis'

interface Props {
  app: {
    title: string
    logo: string
    deps: { [key: string]: any }
    connected?: boolean
  }
  subscription: Subscription
  setIsCreateButtonDisabled: (isDisabled: boolean) => void
}

type ChannelOption = SlackChannel & { newChannel?: boolean; refreshChannels?: boolean }

function cleanChannelInput(val?: string): string {
  if (!val) return ''
  return val.replace(/ /g, '-').toLowerCase()
}

interface ChannelPickerProps {
  channels: SlackChannel[]
  channel?: SlackChannel | null
  isNewChannelEntered: boolean
  setChannel: (channel?: ChannelOption | null, inputValue?: string | null) => void
  chakraInputProps?: InputProps
  allowCreateNewChannel?: boolean
}

function estimateSize() {
  return 32
}

export function ChannelPicker(props: ChannelPickerProps) {
  const { channels, channel, isNewChannelEntered, setChannel } = props
  const { popperRef, referenceRef } = usePopper({ matchWidth: true })
  const listRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [items, setItems] = React.useState<ChannelOption[]>(channels)
  const allowCreateNewChannel = useMemo(() => props.allowCreateNewChannel ?? true, [props.allowCreateNewChannel])

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => listRef.current,
    estimateSize,
    overscan: 2
  })

  const {
    isOpen,
    getComboboxProps,
    highlightedIndex,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    setInputValue,
    closeMenu
  } = useCombobox({
    items,
    initialSelectedItem: channel,
    initialInputValue: channel?.name || '',
    onInputValueChange(changes) {
      const newInputValue = changes.inputValue
      const value = cleanChannelInput(newInputValue)
      const matching: ChannelOption[] = channels.filter((channel) => {
        return !value || channel.name.toLowerCase().includes(value)
      })

      const exactMatch = Boolean(matching.find((c) => c.name == value))

      // add "Create new channel" option if no exact match
      if (!exactMatch) {
        if (allowCreateNewChannel) {
          matching.push({
            id: '',
            name: value,
            newChannel: true
          })
        }

        matching.push({
          id: '',
          name: value,
          refreshChannels: true
        })
      }

      // update input value if it's different (e.g. user typed in invalid chars)
      if (value !== newInputValue) {
        // maintain cursor position
        const cursor = inputRef.current?.selectionStart ?? null

        setInputValue(value)

        // preserve the cursor in the input, so it doesn't jump to the end after we replaced the content
        if (cursor) {
          setTimeout(() => {
            inputRef.current?.setSelectionRange(cursor, cursor)
          }, 0)
        }
      }

      setItems(exactMatch ? matching : matching)
    },
    itemToString(item) {
      return item ? item.name : ''
    },
    onSelectedItemChange({ selectedItem, inputValue }) {
      setChannel(selectedItem, inputValue)
      setInputValue(selectedItem?.name || inputValue || '')
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges
      const { inputValue } = state

      if (type === useCombobox.stateChangeTypes.InputBlur) {
        const matching = findChannel(inputValue || '')

        if (matching.length === 1) {
          return {
            ...changes,
            isOpen: false,
            selectedItem: matching[0],
            inputValue: inputValue
          }
        } else {
          return {
            ...changes,
            isOpen: false,
            selectedItem: { newChannel: true, id: '', name: inputValue || '' },
            inputValue: inputValue
          }
        }
      }

      return changes
    },
    onHighlightedIndexChange: ({ highlightedIndex, type }) => {
      if (type !== useCombobox.stateChangeTypes.MenuMouseLeave && typeof highlightedIndex === 'number') {
        rowVirtualizer.scrollToIndex(highlightedIndex)
      }
    }
  })

  function findChannel(name: string) {
    return channels.filter((channel) => {
      return !name || channel.name.toLowerCase() === name.toLowerCase()
    })
  }

  return (
    <Box w="100%" position={'relative'}>
      <InputGroup size="sm" {...getComboboxProps({ ref: referenceRef })}>
        <InputLeftElement width="8" pointerEvents="none" fontSize="1em" color="gray.700">
          <Icon as={IconHash} boxSize={4} />
        </InputLeftElement>
        <Input
          isDisabled={isNewChannelEntered}
          placeholder="Select a channel"
          width={'100%'}
          paddingLeft={8}
          maxLength={80}
          onInput={(e) => {
            const value = cleanChannelInput(e.currentTarget.value)
            setChannel(
              channels.find((c) => c.name == value),
              value
            )
          }}
          {...getInputProps({ ref: inputRef })}
          {...props.chakraInputProps}
          onKeyPress={(e) => {
            // prevent form from submitting when entering
            // a new channel name
            if (e.key === 'Enter' && isNewChannelEntered) {
              e.preventDefault()
              closeMenu()
            }
          }}
        />
        <InputRightElement>
          <IconButton
            flex="none"
            display="inline-flex"
            justifyContent="center"
            color={isOpen ? 'gray.800' : 'gray.500'}
            icon={isOpen ? <IconChevronUp size="18" /> : <IconChevronDown size="16" />}
            aria-label="Toggle menu"
            size="xs"
            variant="unstyled"
            type="button"
            _groupHover={{ color: 'gray.800' }}
            {...getToggleButtonProps()}
          />
        </InputRightElement>
      </InputGroup>
      <Box
        ref={popperRef}
        visibility={isOpen ? 'visible' : 'hidden'}
        background="white"
        w="100%"
        shadow="md"
        rounded="md"
        borderWidth="1px"
        borderColor="gray.200"
        zIndex="overlay"
      >
        <List
          {...getMenuProps({ ref: listRef })}
          spacing={0}
          position="relative"
          fontSize="sm"
          maxH="300px"
          padding={1}
          overflowY="auto"
          scrollBehavior="smooth"
          overscrollBehavior="contain"
        >
          {isOpen && (
            <Box position="relative" width="100%" height={`${rowVirtualizer.getTotalSize()}px`}>
              {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                const item = items[virtualRow.index]
                const index = virtualRow.index
                const isHighlighted = index === highlightedIndex
                return (
                  <ChannelPickerItem
                    display="flex"
                    alignItems="center"
                    gap={2}
                    background={isHighlighted ? 'gray.100' : 'white'}
                    p={2}
                    cursor="pointer"
                    rounded="md"
                    key={`${item.id}-${index}`}
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height={`${virtualRow.size}px`}
                    transform={`translateY(${virtualRow.start}px)`}
                    item={item}
                    {...getItemProps({ index, item })}
                  />
                )
              })}
            </Box>
          )}
        </List>
      </Box>
    </Box>
  )
}

type ChannelPickerItemProps = ListItemProps & { item: ChannelOption }

function ChannelPickerItem(props: ChannelPickerItemProps) {
  if (props.item.newChannel) {
    return (
      <ListItem {...props} isTruncated>
        <Icon flex="none" as={IconPlus} boxSize={4} />
        <Text isTruncated>
          Create a channel called #
          <Text as="span" fontWeight="semibold">
            {props.item.name}
          </Text>
        </Text>
      </ListItem>
    )
  }

  if (props.item.refreshChannels) {
    return (
      <ListItem {...props}>
        <Icon flex="none" as={IconRefresh} boxSize={4} />
        <Text>No channel found. Refresh channels list?</Text>
      </ListItem>
    )
  }

  return (
    <ListItem {...props} isTruncated>
      <Icon flex="none" as={IconHash} boxSize={4} />
      <TextEllipsis tooltip>{props.item.name}</TextEllipsis>
    </ListItem>
  )
}

export function Slack({ app, subscription, setIsCreateButtonDisabled }: Props) {
  const [channel, setChannel] = React.useState<SlackChannel | undefined>(subscription.action?.channel)
  const [newChannelName, setNewChannelName] = React.useState<string>()

  const anyChannelSelected = React.useMemo(() => {
    return channel?.name || newChannelName
  }, [channel, newChannelName])

  React.useEffect(() => {
    const disableCreateButton = !anyChannelSelected
    setIsCreateButtonDisabled(disableCreateButton)
  }, [anyChannelSelected, setIsCreateButtonDisabled])

  const isNewChannelEntered = React.useMemo(() => {
    return (newChannelName?.length ?? 0) > 0
  }, [newChannelName])

  return (
    <Box pt="4">
      <input type="hidden" name="subscription[trigger][event]" value="view.entered" />
      {channel && (
        <>
          <input type="hidden" name="subscription[action][channel][id]" value={channel.id} />
          <input type="hidden" name="subscription[action][channel][name]" value={channel.name} />
        </>
      )}

      {newChannelName && (
        <input type="hidden" name="subscription[action][channel][new_channel_name]" value={newChannelName} />
      )}

      <Stack padding={4} spacing="4" background={'white'} rounded="md">
        <Heading size="xs">Slack Channel</Heading>

        <ChannelPicker
          channels={(app.deps?.channels || []) as SlackChannel[]}
          setChannel={(channel, inputValue) => {
            if (channel?.id) {
              setChannel(channel)
              setNewChannelName(undefined)
            } else {
              setNewChannelName(inputValue ?? undefined)
              setChannel(undefined)
            }
          }}
          isNewChannelEntered={!!newChannelName}
          channel={channel}
          chakraInputProps={{
            size: 'sm',
            rounded: 'md',
            isRequired: true,
            isDisabled: false
          }}
        />

        <Stack spacing="2">
          <Text color="GrayText" fontSize={'sm'}>
            Select a public Slack channel for the list. Or type a new one to create it.
          </Text>

          {isNewChannelEntered && (
            <Stack fontSize="xs" color="gray.500">
              <Text>
                <b>Note:</b> a new channel will be created with the name{' '}
                <Code fontSize={'xs'} fontWeight="semibold">
                  `{newChannelName}`
                </Code>
              </Text>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}
